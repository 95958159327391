export const CCAC = 'Community College of Allegheny County';
export const CCAC_WORKFORCE_LOGO = 'https://turbine-content.s3.us-east-2.amazonaws.com/ccac-workforce-dev.jpg';

export const sponsoredSchools = [
  CCAC
];

export const sponsoredSchoolsLogos = {
  [CCAC]: CCAC_WORKFORCE_LOGO
};
