import { getFirebaseEntry } from '../services/firebaseProxy';
import { RESET_DOCUMENT, SET_DOCUMENT } from './types';

export const getDocument = (id, collection) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      getFirebaseEntry({ collection, id }).then((response) => {
        const doc = response || null;

        dispatch({ type: SET_DOCUMENT, doc });
        resolve(doc);
      }).catch((error) => {
        console.error(error);
        reject(error);
      });
    });
  };
};

export const resetDocument = () => {
  return (dispatch) => {
    dispatch({ type: RESET_DOCUMENT });
  };
};
