import { toast } from 'react-toastify';
import {
  fetchSupabaseEntry,
  fetchSupabaseEntries,
  updateSupabaseEntry
} from './supabaseProxy';
import formatData from './formatData';

export const fetchUserByUid = async (uid = '') => {
  if (!uid) {
    throw new Error('UID is required!');
  }

  try {
    const response = await fetchSupabaseEntries({ 'f.uid[eq]': uid }, 'users');
    const items = response?.items;
    const user = Array.isArray(items) && items[0] !== undefined ? items[0] : null;
    return user;
  } catch (error) {
    console.error(error);
    throw new Error(error);
  }
};

/**
 * Update User in Supabase
 *
 * @param {Object} data
 * @param {String} userId
 * @returns { id: '', data: '' }
 */
export const updateUser = async (data, userId) => {
  try {
    const entry = await fetchSupabaseEntry({
      table: 'users',
      id: userId
    });

    try {
      const response = await updateSupabaseEntry({
        id: userId,
        data: formatData(data, 'user', entry),
        table: 'users'
      });

      return response;
    } catch (updateError) {
      toast.error('Something went wrong updating user.');
      // TODO heap
      console.error(updateError);
    }
  } catch (fetchError) {
    toast.error('Something went wrong fetching user.');
    // TODO heap
    console.error(fetchError);
  }
};
