import { getFirebaseEntry } from './firebaseProxy';

const collection = 'instructionProviders';

/**
 *
 * @param {string} id
 * @returns
 */
export const fetchInstructionProvider = (id) => {
  return new Promise((resolve, reject) => {
    getFirebaseEntry({ collection, id }).then((response) => {
      const instructionProvider = response || null;

      resolve(instructionProvider);
    }).catch((error) => {
      console.error(error);
      reject(error);
    });
  });
};
