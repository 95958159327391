import { TURBINE_CONSOLE } from '../constants/urls';

export const previewLink = (id) => {
  return `${TURBINE_CONSOLE}/external/job-profiles/${id}`;
};

export const jobProfileIframeUrl = ({
  jobProfileId, orgId, hideHeaderTitle = false
}) => {
  let url = `${TURBINE_CONSOLE}/external/job-profiles`;

  if (jobProfileId) url += `/${jobProfileId}`;

  url += '?embedded=true';
  url += `&orgId=${orgId}`;

  if (hideHeaderTitle) url += `&hideHeaderTitle=${hideHeaderTitle}`;

  return url;
};
