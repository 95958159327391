import React from 'react';

const SelectMenu = ({
  defaultOption = { text: 'Choose a value', value: '' },
  required = false,
  className,
  onChange = null,
  defaultValue = '',
  name,
  options
}) => {
  return (
    <select
      name={name}
      key={defaultValue}
      data-key={defaultValue}
      className={`${className || ''} w-auto text-capitalize`}
      required={required}
      onChange={onChange}
      value={defaultValue === null ? '' : defaultValue}
    >
      {defaultOption && (
        <option value={defaultOption.value}>
          {defaultOption.text}
        </option>
      )}

      {options.map(({ value, text }, i) => (
        <option
          key={i}
          value={value}
        >
          { text || value }
        </option>
      ))}
    </select>
  );
};

export default SelectMenu;
