import { pathOr } from 'ramda';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getWageSchedules } from '../../actions/WageSchedules';
import { setCurrentModal } from '../../actions/Modals';
import { INVOICE_DOLLAR } from '../../constants/icons';
import Loading from '../Loading';
import DotMenu from './DotMenu';
import WageSchedule from './WageSchedule';

const WageSchedules = ({
  title = 'Wage Schedules',
  icon = INVOICE_DOLLAR,
  className = 'card mt-3',
  addressState = '',
  showCreateWageScheduleButton,
  showWagePeriodEditMenu,
  showHeader,
  showHelpText
}) => {
  const dispatch = useDispatch();
  // redux
  const employerProfile = useSelector((state) => state.employerProfile);
  const apprenticeship = useSelector((state) => state.apprenticeship);
  const wageSchedulesList = useSelector((state) => state.wageSchedulesList);
  const wageSchedulesFetched = useSelector((state) => state.wageSchedulesFetched);
  // misc
  const ojtHours = pathOr(0, ['ojtHours'], apprenticeship);
  const employerProfileId = pathOr(null, ['id'], employerProfile);
  const apprenticeshipId = pathOr(null, ['id'], apprenticeship);

  useEffect(() => {
    if (!wageSchedulesFetched) {
      dispatch(getWageSchedules({
        employerProfileId,
        apprenticeshipId
      }));
    }
  }, []);

  const openManageWageScheduleModal = () => {
    dispatch(setCurrentModal({
      key: 'manageWageSchedule',
      data: {
        addressState
      }
    }));
  };

  if (!wageSchedulesList && !wageSchedulesFetched) {
    return (
      <Loading
        text="Loading Wage Schedules..."
        className='theme-dark'
      />
    );
  }

  const helpText = "Wage schedules set a trainee's compensation as they progress and gain skills. Each occupation should have at least one wage schedule, but multiple wage progression options can be provided to reflect employer, industry, and location differences.";

  return (
    <div className={className}>
      {showHeader && (
        <div
          className='card-header d-flex justify-content-between align-items-center'
        >
          <div className='h6 m-0 font-weight-bold'>
            <i className={`${icon} mr-1`} /> {title}
          </div>
          {showCreateWageScheduleButton && (
            <DotMenu
              addressState={addressState}
            />
          )}
        </div>
      )}

      <div className='card-body'>
        {showHelpText && (
          <div className='mb-4'>
            {helpText}
          </div>
        )}

        {wageSchedulesFetched && wageSchedulesList ? wageSchedulesList.map((wageSchedule) => (
          <WageSchedule
            key={`wageSchedule-${wageSchedule?.id}`}
            ojtHours={ojtHours}
            addressState={addressState}
            wageSchedule={wageSchedule}
            className="mb-3"
            showWagePeriodEditMenu={showWagePeriodEditMenu}
            readOnly
          />
        )) : (
          <div className='d-flex justify-content-center py-4'>
            {showCreateWageScheduleButton && (
              <button
                type='button'
                className='btn btn-primary btn-sm'
                onClick={openManageWageScheduleModal}
              >
                New Wage Schedule
              </button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default WageSchedules;
