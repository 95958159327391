import { fetchCourses, fetchSyndicatedCourses } from '../services/courses';
import { SET_COURSES, RESET_COURSES } from './types';

export const getCourses = ({ orgId }) => {
  return (dispatch) => {
    let allCourses = [];

    fetchCourses({ orgId }).then((courses) => {
      if (courses && Array.isArray(courses) && courses.length > 0) {
        allCourses = [...courses];
      }

      fetchSyndicatedCourses({ orgIds: [orgId] }).then((syndicatedCourses) => {
        if (syndicatedCourses && Array.isArray(syndicatedCourses) && syndicatedCourses.length > 0) {
          allCourses = [
            ...allCourses,
            ...syndicatedCourses
          ];
        }

        dispatch({
          type: SET_COURSES,
          courses: allCourses.length > 0 ? allCourses : null
        });
      });
    }).catch((error) => {
      console.error(error);
    });
  };
};

export const resetCourses = () => {
  return (dispatch) => {
    dispatch({ type: RESET_COURSES });
  };
};
