import replaceAll from '../utils/replaceAll';

export const currentPageTitle = ({ pathname, currentClass }) => {
  const pathnameArr = pathname.split('/');

  if (pathnameArr[1] === '' && (pathnameArr[0] === '' || '/')) {
    return 'Courses';
  }

  const humanReadablePathname = replaceAll(pathnameArr[1], '-', ' ');

  switch (humanReadablePathname) {
    case 'classes': {
      if (currentClass && currentClass.fields.title) {
        return currentClass.fields.title;
      }

      return humanReadablePathname;
    }
    default:
      return humanReadablePathname;
  }
};
