import { CITY, MAP_PIN } from '../constants/icons';
import isPublished from '../utils/isPublished';
import { client, flattenItem } from './contentful';

export const fetchLocation = ({ locationId }) => {
  return new Promise((resolve, reject) => {
    return client.getEntry(locationId).then((response) => {
      const location = isPublished(response) ? flattenItem(response) : null;

      resolve(location);
    }).catch((error) => {
      console.error(error.message);
      reject(error);
    });
  });
};

export const generateLocationsHash = (locations) => {
  const hash = {};

  if (!locations) return hash;

  locations.forEach((location) => {
    hash[location.id] = {
      ...location
    };
  });

  return hash;
};

export const locationTerminology = ({ orgType }) => {
  return {
    text: orgType !== 'workforce' ? 'Employer' : 'Location',
    icon: orgType !== 'workforce' ? CITY : MAP_PIN
  };
};
