import { v4 as uuid4 } from 'uuid';
import getKeyByValue from '../../../utils/getKeyByValue';

export const apprenticeshipTypesEnum = {
  CB: 'Competency-Based',
  TB: 'Time-Based',
  H: 'Hybrid'
};

export const apprenticeshipIndustryCodeEnum = {
  72: 'Accommodation And Food Services',
  56: 'Administrative And Support Services',
  11: 'Agriculture, Forestry, Fishing, And Hunting',
  71: 'Arts, Entertainment, And Recreation',
  23: 'Construction',
  61: 'Educational Services',
  52: 'Finance And Insurance',
  93: 'Government',
  62: 'Health Care And Social Assistance',
  51: 'Information',
  55: 'Management Of Companies And Enterprises',
  31: 'Manufacturing',
  21: 'Mining, Quarrying, And Oil And Gas Extraction',
  81: 'Other Services (Except Public Administration)',
  54: 'Professional, Scientific, And Technical Services',
  53: 'Real Estate And Rental And Leasing',
  44: 'Retail Trade',
  48: 'Transportation And Warehousing',
  22: 'Utilities',
  42: 'Wholesale Trade'
};

const parseType = (rawData = '') => {
  const checkedBox = '☒';
  const checkBox = '☐';
  let types = rawData.replace('Apprenticeship Type: ', '');
  types = types.replace('', '').trim().split(checkBox);

  let type = types.find((t) => t.includes(checkedBox));
  type = type.replace(checkedBox, '').trim();

  return getKeyByValue(apprenticeshipTypesEnum, type);
};

const formatCompetency = (competency) => {
  const competencyParts = competency.split('.   ');
  return competencyParts[1];
};

const uniqueCompetencyGroupId = (groups = [], uniqueId) => {
  const id = uniqueId;
  const group = groups.find((g) => g.id === id);

  if (group) {
    console.log('DUPLICATE');
    uniqueCompetencyGroupId(groups, uuid4());
  } else {
    return id;
  }
};

const parseCompetencyData = (rawData = []) => {
  const items = rawData.splice(7);
  const competencyGroups = [];
  const competencies = {};

  let stop = false;
  let competencyGroupFlag = false;
  let competencyFlag = false;
  let competencyGroup = {};

  items.forEach((item, i) => {
    // Last Competency Group
    if (item === 'Suggested Related Instruction Outline') {
      // competencyGroups.push(competencyGroup);
      stop = true;
    }

    // First Competency Group
    if (!stop && competencyGroupFlag) {
      competencyGroup = {
        id: uniqueCompetencyGroupId(competencyGroups, uuid4()),
        description: item,
        competencyIds: []
      };
      competencyGroupFlag = false;
    }

    if (!stop && competencyFlag && item.includes('.   ')) {
      const competency = {
        id: uuid4(),
        description: formatCompetency(item)
      };
      competencies[competency.id] = competency;
      competencyGroup.competencyIds.push(competency.id);

      // Check if next item is a Competency Group
      if (items[i + 1] && !items[i + 1].includes('.   ')) {
        competencyGroups.push(competencyGroup);
        competencyFlag = false;
        competencyGroupFlag = true;
      }
    }

    // First Competency Group
    if (!stop && !competencyGroupFlag && item === 'Suggested On-the-Job Learning Outline') {
      competencyGroupFlag = true;
    }

    // Competency
    if (!stop && !competencyFlag && item === 'Initial') {
      competencyFlag = true;
    }
  });

  return {
    competencies,
    competencyGroups
  };
};

const parseTrainingProgram = (data) => {
  const { competencyGroups, competencies } = parseCompetencyData(data);
  const parsedData = {
    title: data[1],
    description: data[2].replace('Job Description: ', ''),
    rapidsCode: data[3].replace('RAPIDS Code: ', ''),
    occupationONetCode: data[4].replace('O*NET Code: ', ''),
    estimatedLength: data[5].replace('Estimated Program Length: ', '').trim(),
    type: parseType(data[6]),
    competencies,
    competencyGroups
  };

  return parsedData;
};

export default parseTrainingProgram;
