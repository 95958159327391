const getImageMetadata = (url) => {
  const img = new Image();

  return new Promise((resolve) => {
    img.src = url;
    img.onload = function getHeightAndWidth() {
      resolve({
        width: this.width,
        height: this.height
      });
    };
  });
};

export default getImageMetadata;
