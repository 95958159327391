import React, { Component } from 'react';

class ModalDialog extends Component {
  state = {
    loading: false
  }

  handlePrimaryBtnFunc = () => {
    const { primaryButton, close } = this.props;

    this.setState({ loading: true });

    if (primaryButton && primaryButton.func) {
      primaryButton.func();
    } else {
      close();
    }
  }

  render() {
    const {
      title,
      text,
      primaryButton,
      secondaryButton,
      close,
      visible
    } = this.props;
    const { loading } = this.state;

    if (!visible) return null;

    return (
      <div className="turbine-modal dialog">
        <div className="modal-container">
          <div className="modal-content">
            {title && <h2 className="title">{title}</h2>}
            <div className="body">{text}</div>
            <div className="control">
              {primaryButton && (
                <button
                  onClick={this.handlePrimaryBtnFunc}
                  className="btn btn-md btn-primary"
                  type="button"
                  disabled={loading}
                >
                  {loading ? 'Loading...' : primaryButton.text}
                </button>
              )}
              <button
                onClick={secondaryButton.func || close}
                className="btn btn-md btn-link"
                type="button"
                disabled={loading}
              >
                {secondaryButton.text || 'Cancel'}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ModalDialog;
