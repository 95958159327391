import {
  SET_SEARCH_USER_FEEDBACK
} from './types';

export const setSearchUserFeedback = ({ searchUserFeedback }) => {
  return (dispatch) => {
    dispatch({
      type: SET_SEARCH_USER_FEEDBACK,
      searchUserFeedback
    });
  };
};
