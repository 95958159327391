import { pathOr } from 'ramda';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getOrg } from '../../actions';
import { getPathway } from '../../actions/Pathways';
import { PATHWAY } from '../../constants/icons';
import { jobProfileIframeUrl } from '../../services/pathways';
import Loading from '../Loading';
import Tabs from './Tabs';

const JobProfile = () => {
  const dispatch = useDispatch();
  const params = useParams();
  // Redux
  const organization = useSelector((state) => state.organization);
  const pathway = useSelector((state) => state.pathway);
  // Misc
  const orgId = pathOr(null, ['id'], organization);
  const orgSlug = pathOr(null, ['orgSlug'], params);
  const pathwayId = pathOr(null, ['pathwayId'], params);
  // Pathway
  const jobProfileId = pathOr(null, ['jobProfileId'], pathway);

  useEffect(() => {
    document.body.classList.add('pathway-page');

    if (!orgId) {
      dispatch(getOrg({
        slug: orgSlug,
        select: [
          'fields.name',
          'fields.orgLogo',
          'fields.slug',
          'fields.appleTouchIcon',
          'fields.androidIconSmall',
          'fields.androidIconLarge'
        ]
      }));
    }
  }, [orgSlug]);

  useEffect(() => {
    if (pathwayId) {
      dispatch(getPathway(pathwayId));
    }
  }, [pathwayId]);

  if (!pathway || !jobProfileId) {
    return (
      <Loading />
    );
  }

  return (
    <div className="row mt-2">
      <div className="col-sm-12">
        <div className="mb-5">
          <div
            className="p-3 border mt-2 bg-white"
          >
            <div className='h5 m-0'>
              <i className={PATHWAY} /> {pathway.title}
            </div>
          </div>

          <Tabs
            activeTab='jobProfile'
          />

          <div
            className="bg-white border-bottom border-left border-right p-3"
          >
            <iframe
              title={pathway.title}
              src={jobProfileIframeUrl({ jobProfileId, orgId, hideHeaderTitle: true })}
              style={{ border: 0, height: 'calc(100vh - 30px)' }}
              className="w-100"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobProfile;
