import * as twApi from './tw-api';
import decodeBase64Buffer from '../utils/decodeBase64Buffer';

/**
 * Uploads a file to the user-data AWS bucket in
 * exchange for a URL.
 *
 * @param {string} prefix - The prefix/folder to upload the file into.
 * @param {File} file - The file to upload.
 * @param {string} organizationId - Organization Id
 * @returns {Promise<string>} - A URL that the uploaded file is accessible at.
 */

export async function uploadFile({
  prefix, file, fileExt, organizationId
}) {
  const formData = new FormData();
  formData.append('file', file);

  const response = await twApi.post(
    '/v1/external/aws/upload',
    {
      ...(fileExt ? { fileExt } : {}),
      prefix,
      organizationId
    },
    formData
  );

  return response.url;
}

/**
 * Move a previously uploaded file to another location.
 *
 * @param {} previousUrl - The 'previous' / current URL of the file to move.
 * @param {*} newPrefix - The new prefix (path) that the file should be moved to (excluding its file name).
 * @param {string} organizationId - Organization Id
 * @returns {Promise<string>} - A URL that the copied file is accessible at.
 */
export async function moveFile({ previousUrl, newPrefix, organizationId }) {
  const response = await twApi.post('/v1/external/aws/copy', {
    file: previousUrl,
    to: newPrefix,
    organizationId
  });

  return response.url;
}

// aws.moveFile({
//   previousUrl: url,
//   newPrefix: `${AWS_USER_PREFIX}/${organizationId}`,
//   organizationId
// });

/**
 * Deletes a previously uploaded image by its URL.
 *
 * @param {string} url - The URL of the uploaded image.
 * @param {string} orgId - Organization Id
 * @returns {Promise<void>}
 */
export async function deleteFile({ url, orgId }) {
  await twApi.post('/v1/external/aws/delete-file', {
    file: url,
    organizationId: orgId
  });
}

/**
 * Deletes a previously uploaded image by its URL.
 *
 * @param {string} url - The URL of the uploaded image.
 * @param {number} expires - defaults to 3600
 * @param {string} orgId - Organization Id
 * @returns {Promise<void>}
 */
export async function fetchPreSignedUrl({ url, expires, orgId }) {
  const response = await twApi.get('/v1/external/aws/preSignedUrl', {
    url,
    ...(expires ? { expires } : {}),
    organizationId: orgId
  });

  return response?.url || null;
}

export async function fetchFileBuffer({ url, orgId, createPreSignedUrl = true }) {
  const response = await twApi.get('/v1/external/aws/buffer', {
    url,
    organizationId: orgId,
    createPreSignedUrl
  });

  let decodedBuffer = null;

  if (response?.buffer) {
    decodedBuffer = decodeBase64Buffer(response?.buffer);
    console.log('decodedBuffer', decodedBuffer);
  }

  return response?.buffer ? decodedBuffer : null;
}
