import { fetchInstructionProvider } from '../services/instructionProviders';
import {
  SET_INSTRUCTION_PROVIDER, RESET_INSTRUCTION_PROVIDER
} from './types';

export const getInstructionProvider = (id) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      fetchInstructionProvider(id).then((instructionProvider) => {
        dispatch({ type: SET_INSTRUCTION_PROVIDER, instructionProvider });

        resolve(instructionProvider);
      }).catch((error) => {
        console.error(error);
        reject(error);
      });
    });
  };
};

export const resetInstructionProvider = () => {
  return (dispatch) => {
    dispatch({ type: RESET_INSTRUCTION_PROVIDER });
  };
};
