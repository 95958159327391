import React from 'react';
import parse from 'html-react-parser';
import ReactMarkdown from 'react-markdown';
import breaks from 'remark-breaks';
import './tinymce.css';
/**
 * Wrapper component for the `react-markdown` dependency - renders
 * markdown strings on the page.
 *
 * @param {} props
 * @param {string} props.source - The source markdown text to render.
 * @param {bool} props.enableNewEditor - The source markdown text to render.
 */
const RenderMarkdown = ({
  className = '',
  source,
  enableNewEditor,
  style = {},
  children
}) => {
  if (enableNewEditor) {
    return (
      <div
        className={`mce-content-body ${className}`}
        style={style}
      >
        {source ? parse(source) : ''}
        {children || null}
      </div>
    );
  }

  return (
    <div
      className={`mce-content-body ${className}`}
      style={style}
    >
      <ReactMarkdown
        source={source}
        escapeHtml={false}
        plugins={[breaks]}
      />
      {children || null}
    </div>
  );
};

export default RenderMarkdown;
