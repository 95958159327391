import {
  createSupabaseEntry, fetchSupabaseEntry, updateSupabaseEntry
} from './supabaseProxy';

const table = 'userProfiles';

export const fetchUserProfile = async (id) => {
  const response = await fetchSupabaseEntry({
    table,
    id
  });

  return response;
};

export const updateUserProfile = (data, id) => {
  return new Promise((resolve, reject) => {
    updateSupabaseEntry({
      id,
      data,
      table
    }).then((response) => {
      resolve(response);
    }).catch((error) => {
      console.error(`update ${table}`, error);
      reject(error);
    });
  });
};

export const createUserProfile = (data) => {
  return new Promise((resolve, reject) => {
    createSupabaseEntry({
      data,
      table
    }).then((response) => {
      resolve(response);
    }).catch((error) => {
      console.error(`create ${table}`, error);
      reject(error);
    });
  });
};
