import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { isSuperAdmin } from '@apprentage/utils';
import { pathOr } from 'ramda';
import { withAuthorization } from '../Session';
import { SLASH } from '../../constants/routes';
import ImportDocument from './ImportDocument';
import TrainingProgram from './Forms/TrainingProgram';

const FileParse = () => {
  const currentUser = useSelector((state) => state.currentUser);
  const role = pathOr([], ['role'], currentUser);
  const [docData, setDocData] = useState(null);

  if (!currentUser?.id) {
    return;
  }

  if (!isSuperAdmin(role)) {
    return (
      <Redirect to={SLASH} />
    );
  }

  const onChangeCallback = (data) => {
    setDocData(data);
  };

  return (
    <div className="mt-3 mb-5">
      <div className="py-2 mt-3 mb-1">
        <h1 className='text-white'>
          <b>FileParse</b>
        </h1>
      </div>

      <ImportDocument
        data={docData}
        onChangeCallback={onChangeCallback}
        type="trainingProgram"
      />

      {docData && (
        <TrainingProgram
          data={docData}
          onChangeCallback={onChangeCallback}
        />
      )}
    </div>
  );
};

const condition = (user) => !!user;

export default withAuthorization(condition)(FileParse);
