const sortArrByArr = (arr, orderArr, key) => arr.sort((a, b) => {
  const x = a[key];
  const y = b[key];

  if (orderArr.indexOf(x) > orderArr.indexOf(y)) {
    return 1;
  }
  return -1;
});

export default sortArrByArr;

/**
 * Example:
 */

// var item_array, item_order, ordered_array;

// item_array = [
//   { id: 2, label: 'Two' },
//   { id: 3, label: 'Three' },
//   { id: 5, label: 'Five' },
//   { id: 4, label: 'Four' },
//   { id: 1, label: 'One'}
// ];

// item_order = [{"category_id":1},{"category_id":2},
// {"category_id":3},{"category_id":4},{"category_id":5}];
// item_order = item_order.map(p=>p.category_id)

// ordered_array = mapOrder(item_array, item_order, 'id');
// console.log("ordered Array ", ordered_array);
