/**
 * @param {string} orgSlug // Ex: consortium, ccacworks
 * @param {string} route // Ex: pathways/id/A57jO6m0LTGVcY9l6ISA, courses/view/html-css
 * @returns public page route
 */
const publicPageRoute = ({ orgSlug, route }) => {
  return `/o/${orgSlug}/${route}`;
};

export default publicPageRoute;
