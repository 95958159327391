import React from 'react';
import { useDispatch } from 'react-redux';
import { ELLIPSIS, TRASH } from '../../../constants/icons';
import { setCurrentModal } from '../../../actions/Modals';

const DotMenu = ({
  wagePeriodIndex,
  wageSchedule,
  addressState = '',
  removeWageSchedule,
  canDelete = false
}) => {
  const dispatch = useDispatch();
  // misc

  return (
    <div className="d-flex justify-content-end">
      <div className="btn-group align-items-center">
        <button
          className="btn-link text-white"
          type="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          title="Manage"
          tabIndex="-1"
        >
          <i className={ELLIPSIS} />
        </button>
        <div className="dropdown-menu dropdown-menu-right p-0">
          <div className="bg-dark pl-4 pr-3 py-1 text-white text-nowrap small font-weight-bold">
            {wageSchedule?.title || ''}
          </div>
          <button
            className='dropdown-item border-bottom py-2'
            type="button"
            onClick={() => {
              dispatch(setCurrentModal({
                key: 'manageWageSchedule',
                data: {
                  currentStep: 1,
                  addressState,
                  ...wageSchedule
                }
              }));
            }}
          >
            Edit
          </button>

          {(canDelete && removeWageSchedule !== undefined) && (
            <button
              className='dropdown-item py-2'
              type="button"
              onClick={() => {
                removeWageSchedule(wagePeriodIndex);
              }}
            >
              <i className={`${TRASH} text-danger mr-1`} /> Delete
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default DotMenu;
