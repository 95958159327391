import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { pathOr } from 'ramda';
import { getCertificationsByKeyword, getOccupation, getTrainingProgramsByKeyword } from '../../services/careerOneStop';
import { getJobProfile } from '../../actions/JobProfiles';
import Loading from '../Loading';
import Occupation from '../Pathways/Occupation';
import { getOrg } from '../../actions';

const JobProfile = ({
  jobProfileId,
  hideHeaderTitle,
  className = 'my-4',
  readOnly
}) => {
  const dispatch = useDispatch();
  // Redux State
  const jobProfile = useSelector((state) => state.jobProfile);
  const organization = useSelector((state) => state.organization);
  // Local State
  const [certifications, setCertifications] = useState(null);
  const [schoolPrograms, setSchoolPrograms] = useState(null);
  const [knowledgeDataList, setKnowledgeDataList] = useState(null);
  const [skillsDataList, setSkillsDataList] = useState(null);
  // Misc
  const viewOnly = readOnly || !organization;

  useEffect(() => {
    if (jobProfileId && !jobProfile) {
      dispatch(getJobProfile(jobProfileId)).then((response) => {
        if (viewOnly) return false;

        const orgId = pathOr(null, ['orgId'], response);

        if (orgId) {
          dispatch(getOrg({
            orgId,
            select: [
              'fields.name',
              'fields.orgLogo',
              'fields.slug',
              'fields.appleTouchIcon',
              'fields.androidIconSmall',
              'fields.androidIconLarge'
            ],
            order: 'fields.name'
          }));
        }
      });
    }
  }, [jobProfileId]);

  useEffect(() => {
    if (jobProfile) {
      getCertificationsByKeyword({
        keyword: jobProfile.OnetCode
      }).then((response) => {
        const CertList = pathOr(null, ['CertList'], response);
        const certificationsList = CertList && Array.isArray(CertList) && CertList.length !== 0 ? CertList : null;

        setCertifications(certificationsList);
      });
      getTrainingProgramsByKeyword({
        keyword: jobProfile.OnetCode,
        location: jobProfile.occupationLocation
      }).then((response) => {
        const SchoolPrograms = pathOr(null, ['SchoolPrograms'], response);

        setSchoolPrograms(SchoolPrograms);
      });
      getOccupation({
        keyword: jobProfile.OnetCode,
        location: jobProfile.occupationLocation
      }).then((response) => {
        const KnowledgeDataList = pathOr(null, ['KnowledgeDataList'], response);
        const SkillsDataList = pathOr(null, ['SkillsDataList'], response);

        setKnowledgeDataList(KnowledgeDataList);
        setSkillsDataList(SkillsDataList);
      });
    }
  }, [jobProfile]);

  if (!jobProfile) return <Loading />;

  return (
    <div className={className}>
      <Occupation
        {...jobProfile}
        certifications={certifications}
        schoolPrograms={schoolPrograms}
        skillsDataList={skillsDataList}
        knowledgeDataList={knowledgeDataList}
        hideHeaderTitle={hideHeaderTitle}
        viewOnly={viewOnly}
      />
    </div>
  );
};

export default JobProfile;
