import { pathOr } from 'ramda';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { ICON_CIRCLE_CHECK, TRIANGLE_BANG } from '../../constants/icons';
import { SLASH } from '../../constants/routes';
import PoweredBy from '../auth/Container/PoweredBy';
// import PoweredBy from '../PoweredBy';

const InviteMessage = () => {
  const params = useParams();
  const history = useHistory();
  // Invite Message
  const messageType = pathOr(null, ['messageType'], params);
  let title = 'Thank you';
  let body = 'Your information was submitted.';
  let icon = ICON_CIRCLE_CHECK;
  let iconClassName = 'text-keppel';

  if (!messageType) {
    history.replace(SLASH);
    return null;
  }

  if (messageType === 'error') {
    title = 'Invalid Invitation';
    body = 'This invitation is no longer valid.';
    icon = TRIANGLE_BANG;
    iconClassName = 'text-danger';
  }

  return (
    <div
      className='d-flex align-items-center justify-content-center flex-column'
      style={{
        height: '100vh'
      }}
    >
      <div className='card mt-3'>
        <div className='card-header text-center h5 m-0 font-weight-bold'>
          {title}
        </div>
        <div className='card-body'>
          <div
            className='text-center'
          >
            <div>
              <i
                style={{
                  fontSize: '2rem'
                }}
                className={`${icon} ${iconClassName}`}
              />
            </div>
            <p className='mt-3'>
              {body}
            </p>
          </div>
        </div>
        <div className='card-footer text-center'>
          A new invitation will be sent if further information is needed.
        </div>
      </div>
      <div className="my-3 text-muted">
        <PoweredBy
          trackingCode="inviteMessage"
        />
      </div>
    </div>
  );
};

export default InviteMessage;
