import React, { useState } from 'react';
import { pathOr } from 'ramda';
import { percentComplete } from '@apprentage/utils';
import { formatCurrency } from '../../utils/currency';

const WageScheduleTable = ({
  wageSchedule,
  className = 'mt-3 mb-0'
}) => {
  const entryWage = pathOr(0, ['entryWage'], wageSchedule);
  const currentWageRates = pathOr([], ['wageRates'], wageSchedule);

  // local state
  const [wageRates] = useState(currentWageRates.length ? currentWageRates : []); // defaultWageRates

  return (
    <div
      className={`WageScheduleTable-container ${className}`}
    >
      <table className="table table-bordered m-0">
        <thead>
          <tr>
            <th className='vertical-align-middle text-center'>Period</th>
            <th>% Journeyworker Wage</th>
            <th>Dollar Amount</th>
            <th>Duration (Hours)</th>
          </tr>
        </thead>
        <tbody>
          {wageRates && wageRates.length && wageRates.map((wageRate, i) => {
            const wageRatePercentage = percentComplete({
              current: wageRate?.wageAmount,
              total: entryWage
            });

            return (
              <tr key={`wageRate-${i}`}>
                <td
                  className='vertical-align-middle text-center font-weight-bold'
                >
                  <span>{i + 1}</span>
                </td>
                <td>
                  <span>{wageRatePercentage}</span>
                </td>
                <td>
                  <span className='d-flex align-items-center text-nowrap'>
                    {formatCurrency(wageRate?.wageAmount)}
                  </span>
                </td>
                <td>
                  <div className='d-flex justify-content-between'>
                    <div className=''>
                      {wageRate?.duration}
                    </div>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default WageScheduleTable;
