import {
  SET_CURRENT_MODAL,
  RESET_CURRENT_MODAL
} from './types';

export const setCurrentModal = (data) => {
  document.body.classList.add('turbine-modal-active');

  return (dispatch) => {
    dispatch({
      type: SET_CURRENT_MODAL,
      currentModal: {
        ...data,
        visible: true
      }
    });
  };
};

export const resetCurrentModal = () => {
  document.body.classList.remove('turbine-modal-active');

  return (dispatch) => {
    dispatch({ type: RESET_CURRENT_MODAL });
  };
};
