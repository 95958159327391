import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { ARROW_LEFT } from '../../constants/icons';

const LargeCircleButton = styled.div`
  font-size: 1.5rem;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none !important;
`;

const OrgConsoleHeader = ({
  pageTitle,
  route,
  rawRoute,
  icon,
  image,
  children,
  className = '',
  imageHeight = '30',
  imageWidth = '30'
}) => {
  const history = useHistory();

  const handleClick = () => {
    if (rawRoute) {
      history.push(rawRoute);
    }
  };

  return (
    <div
      className={`OrgConsoleHeader d-flex align-items-center ${className}`}
    >
      {(route || rawRoute) && (
        <LargeCircleButton
          className="btn btn-lg btn-link shadow mr-3"
          onClick={handleClick}
        >
          <i className={ARROW_LEFT} />
        </LargeCircleButton>
      )}
      <div
        className="card-header bg-light shadow p-3 d-flex align-items-center justify-content-between flex-fill rounded"
        style={{
          minHeight: '64px'
        }}
      >
        <div
          className="m-0 d-flex align-items-center h5"
        >
          {image ? (
            <img
              src={image}
              alt={pageTitle ? `${pageTitle} Logo` : 'Logo'}
              height={imageHeight}
              width={imageWidth}
              style={{
                ...(imageHeight ? { height: `${imageHeight}px` } : {}),
                ...(imageWidth ? { width: `${imageWidth}px` } : {})
              }}
              className='mr-2'
            />
          ) : (
            <i className={`${icon} mr-2`} aria-hidden="true" />
          )}
          <span className='mr-2'>
            {pageTitle}
          </span>
        </div>

        {children || null}

      </div>
    </div>
  );
};

export default OrgConsoleHeader;
