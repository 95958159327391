import React from 'react';

const ProgressBar = ({
  title,
  valueNow,
  valueMin = '',
  valueMax = 100,
  text,
  style,
  className
}) => {
  const defaultText = valueNow === 0 ? '' : `${valueNow}%`;

  return (
    <div
      title={title}
      className={`progress-bar ${className || ''}`}
      role="progressbar"
      aria-valuenow={valueNow}
      aria-valuemin={valueMin}
      aria-valuemax={valueMax}
      style={style || { width: valueNow }}
    >
      {text !== undefined ? text : defaultText}
    </div>
  );
};

export default ProgressBar;
