import React from 'react';
import { useSelector } from 'react-redux';
import { US_DEPT_LABOR } from '../../../constants/assets';
import CheckBoxes from '../CheckBoxes';
import { mmddyyyyDate } from '../../../utils/date';

const ENUM_DISABILITY = {
  yes: 'YES, I HAVE A DISABILITY (or previously had a disability)',
  no: 'NO, I DON’T HAVE A DISABILITY',
  noAnswer: 'I DON’T WISH TO ANSWER'
};

const DisabilityDisclosure = () => {
  const userProfile = useSelector((state) => state.userProfile);
  const apprenticeshipUser = useSelector((state) => state.apprenticeshipUser);
  const apprenticeAgreement = useSelector((state) => state.apprenticeAgreement);
  // Data
  const signerSignatureDate = apprenticeAgreement?.signerSignatureDate || new Date().toDateString();
  const signerName = apprenticeAgreement?.signerName || apprenticeshipUser?.name || '';
  const disability = apprenticeAgreement?.disability || userProfile?.disability || '';

  return (
    <div className='DisabilityDisclosure mt-5'>
      <div
        className='DisabilityDisclosure-header row'
      >
        <div className='col-5 font-size-1'>
          Program Registration and<br />
          Apprenticeship Agreement<br />
          Office of Apprenticeship
        </div>

        <div className='col-7'>
          <div className='d-flex'>
            <img
              src={US_DEPT_LABOR}
              alt='The Seal of the US Department of Labor'
              style={{
                height: '90px'
              }}
            />
            <div className='mt-1 ml-3 w-75 font-size-1'>
              <div className='font-weight-bold'>
                U.S. Department of Labor
              </div>
              <div>
                Employment and Training Administration
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='row border-top border-bottom mt-4 align-items-center'>
        <div className='col-7'>
          <div className='text-right pr-3 font-weight-bold'>
            Voluntary Disability Disclosure
          </div>
        </div>
        <div className='col-5'>
          <div
            className='text-right'
            style={{
              fontSize: '.8rem'
            }}
          >
            OMB No. 1205-0223 Expiration Date: 03/31/2023
          </div>
        </div>
      </div>

      <div className='mt-4'>
        <div className='mb-2'>
          <u>Please check one of the boxes below:</u>
        </div>
        <CheckBoxes
          value={disability}
          optionsEnum={ENUM_DISABILITY}
          vertical
        />
      </div>

      <div className='mt-4 d-flex align-items-center'>
        <div
          className='font-size-1 mr-3 font-weight-bold'
          style={{
            minWidth: '90px'
          }}
        >
          Your name:
        </div>
        <div className='font-size-1'>
          {signerName}
        </div>
      </div>

      <div className='mt-4 d-flex align-items-center'>
        <div
          className='font-size-1 mr-3 font-weight-bold text-right'
          style={{
            minWidth: '90px'
          }}
        >
          Date:
        </div>
        <div className='font-size-1'>
          {mmddyyyyDate(signerSignatureDate)}
        </div>
      </div>

      <div className='mt-5'>
        <div className='h6 font-weight-bold'>
          Why are you being asked to complete this form?
        </div>
        <p>
          Because we are a sponsor of a registered apprenticeship program and participate in the National Registered Apprenticeship System that is regulated by the U.S. Department of Labor, we must reach out to, enroll, and provide equal opportunity in apprenticeship to qualified people with disabilities.[1] To help us learn how well we are doing, we are asking you to tell us if you have a disability or if you ever had a disability. Completing this form is voluntary, but we hope that you will choose to fill it out. If you are applying for apprenticeship, any answer you give will be kept private and will not be used against you in any way.
        </p>
        <p>
          If you already are an apprentice within our registered apprenticeship program, your answer will not be used against you in any way. Because a person may become disabled at any time, we are required to ask all of our apprentices at the time of enrollment, and then remind them yearly, that they may update their information. You may voluntarily self-identify as having a disability on this form without fear of any punishment because you did not identify as having a disability earlier.
        </p>
        <div className='h6 font-weight-bold mt-4'>
          How do I know if I have a disability?
        </div>
        <p>
          You are considered to have a disability if you have a physical or mental impairment or medical condition that substantially limits a major life activity, or if you have a history or record of such an impairment or medical condition. Disabilities include, but are not limited to: blindness, deafness, cancer, diabetes, epilepsy, autism, cerebral palsy, HIV/AIDS, schizophrenia, muscular dystrophy, bipolar disorder, major depression, multiple sclerosis (MS), missing limbs or partially missing limbs, post-traumatic stress disorder (PTSD), obsessive compulsive disorder, impairments requiring the use of a wheelchair, and intellectual disability (previously called mental retardation).
        </p>
      </div>

      <div className='mt-5'>
        <div
          className='border-top mb-2'
          style={{
            width: '250px'
          }}
        />
        <div>
          <sup>[1]</sup> Part 30 – Equal Employment Opportunity in Apprenticeship. For more information about this form or the equal employment obligations of Federal contractors, visit the U.S. Department of Labor’s Office of Apprenticeship website at https://www.doleta.gov/OA/eeo/.
        </div>
      </div>
    </div>
  );
};

export default DisabilityDisclosure;
