import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { updateUser } from '../../../services/user';
import { getApprenticeshipUser } from '../../../actions/Apprenticeships';
import './style.css'; // TODO use styled components
import { resetCurrentModal } from '../../../actions';

const ProfileForm = () => {
  const dispatch = useDispatch();
  // Redux
  const currentModal = useSelector((state) => state.currentModal);
  const apprenticeshipUser = useSelector((state) => state.apprenticeshipUser);
  // Local State
  const [loading, setLoading] = useState(false);
  const [firstName, setFirstName] = useState(apprenticeshipUser?.firstName || '');
  const [lastName, setLastName] = useState(apprenticeshipUser?.lastName || '');
  const [middleName, setMiddleName] = useState(apprenticeshipUser?.middleName || '');
  const [suffix, setSuffix] = useState(apprenticeshipUser?.suffix || '');
  const [phone, setPhone] = useState(apprenticeshipUser?.phone || '');
  const [phoneExt, setPhoneExt] = useState(apprenticeshipUser?.phoneExt || '');
  const [mobilePhone, setMobilePhone] = useState(apprenticeshipUser?.mobilePhone || '');

  const name = useMemo(() => {
    let fullName = '';

    if (firstName) fullName += firstName;
    if (middleName) fullName += ` ${middleName}`;
    if (lastName) fullName += ` ${lastName}`;
    if (suffix) fullName += `, ${suffix}`;

    return fullName;
  }, [firstName, lastName, middleName, suffix]);

  if (!apprenticeshipUser?.id) {
    return null;
  }

  const onSubmit = (e) => {
    e.preventDefault();

    setLoading(true);
    toast.info('Saving...', { toastId: 'profileFormSaving' });

    const dataToSave = {
      name,
      firstName,
      middleName,
      lastName,
      suffix,
      phone,
      phoneExt,
      mobilePhone
    };

    updateUser(dataToSave, apprenticeshipUser?.id).then(() => {
      dispatch(getApprenticeshipUser(apprenticeshipUser?.id)).then(() => {
        toast.dismiss('profileFormSaving');
        toast.success('Information saved!');
        setLoading(false);
        dispatch(resetCurrentModal());

        if (currentModal?.data?.onSubmitCallback) {
          currentModal.data.onSubmitCallback();
        }
      });
    });
  };

  const suffixHelpText = 'Ex: I, II, III, Jr, Sr';

  return (
    <form
      className="edit-user-details-form"
      onSubmit={onSubmit}
    >
      <div className='card-body'>
        <div className="row mb-3">
          <div className="col-sm-6">
            <label htmlFor="firstName">
              First Name <span className="text-danger">*</span>
            </label>
            <input
              data-demo="disabled"
              type="text"
              name="firstName"
              onChange={(e) => {
                const { value: newFirstName } = e.currentTarget;

                setFirstName(newFirstName);
              }}
              className="form-control"
              value={firstName}
              required
            />
          </div>

          <div className="col-sm-6">
            <label htmlFor="lastName">
              Last Name <span className="text-danger">*</span>
            </label>
            <input
              data-demo="disabled"
              type="text"
              name="lastName"
              onChange={(e) => {
                const { value: newLastName } = e.currentTarget;

                setLastName(newLastName);
              }}
              className="form-control"
              value={lastName}
              required
            />
          </div>
        </div>

        <div className="row mb-3">
          <div className="col-sm-6">
            <label htmlFor="middleName">
              Middle Name
            </label>
            <input
              data-demo="disabled"
              type="text"
              name="middleName"
              onChange={(e) => {
                const { value: newMiddleName } = e.currentTarget;

                setMiddleName(newMiddleName);
              }}
              className="form-control"
              value={middleName}
            />
          </div>

          <div className="col-sm-6">
            <label htmlFor="suffix">
              Suffix
            </label>
            <input
              data-demo="disabled"
              type="text"
              name="suffix"
              onChange={(e) => {
                const { value: newSuffix } = e.currentTarget;

                setSuffix(newSuffix);
              }}
              className="form-control"
              value={suffix}
            />
            <div className="small mt-1">
              {suffixHelpText}
            </div>
          </div>
        </div>

        <div className="row mb-3">
          <div className="col-sm-6">
            <label htmlFor="phone">
              Phone
            </label>
            <input
              data-demo="disabled"
              type="text"
              name="phone"
              onChange={(e) => {
                const { value: newPhone } = e.currentTarget;

                setPhone(newPhone);
              }}
              className="form-control"
              value={phone}
            />
            <div className="small mt-1">
              Ex, 412-444-8615
            </div>
          </div>

          <div className="col-sm-6">
            <label htmlFor="phoneExt">
              Phone Extension
            </label>
            <input
              data-demo="disabled"
              type="text"
              name="phoneExt"
              onChange={(e) => {
                const { value: newPhoneExt } = e.currentTarget;

                setPhoneExt(newPhoneExt);
              }}
              className="form-control"
              value={phoneExt}
            />
            <div className="small mt-1">
              Ex, 101
            </div>
          </div>
        </div>

        <div className="mb-3">
          <label htmlFor="mobilePhone">
            Mobile Phone
          </label>
          <input
            data-demo="disabled"
            type="text"
            name="mobilePhone"
            onChange={(e) => {
              const { value: newMobilePhone } = e.currentTarget;

              setMobilePhone(newMobilePhone);
            }}
            className="form-control"
            value={mobilePhone}
          />
          <div className="small mt-1">
            Ex, 412-444-8615
          </div>
        </div>
      </div>
      <div className="card-footer p-3 d-flex align-items-center bg-light">
        <button
          className="btn btn-md btn-primary"
          type="submit"
          disabled={loading}
        >
          {loading ? 'Saving Information...' : 'Save Information'}
        </button>
      </div>
    </form>
  );
};

export default ProfileForm;
