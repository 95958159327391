import React from 'react';

const PageHeader = ({
  className = '',
  text = 'Pennsylvania Standards of Registered Apprenticeship',
  pageBreakBefore,
  pageBreakAfter
}) => {
  return (
    <>
      {pageBreakBefore && (
        <div className='page-break-before' />
      )}

      <div className={className}>
        <div className="text-center mb-3">
          {text}
        </div>
      </div>

      {pageBreakAfter && (
        <div className='page-break-after' />
      )}
    </>
  );
};

export default PageHeader;
