import React from 'react';
import { useSelector } from 'react-redux';
import RenderMarkdown from '../../ManageContent/RenderMarkdown';
import isPublished from '../../../utils/isPublished';
import './style.css';

const Topics = () => {
  const course = useSelector((state) => state.currentClass);

  if (!course) return null;

  const {
    subjects, // TODO course flatten
    enableNewEditor
  } = course;

  let hasFeaturedTopics = false;

  // Check for featured Topics
  if (subjects) {
    subjects.forEach((subject) => {
      // TODO course flatten (use course.topicIds and sort topics order)
      if (isPublished(subject)) {
        if (subject.fields.featured) hasFeaturedTopics = true;
      }
    });
  }

  return (
    <div className="col-md-12 mt-3 px-0">
      <div className="card h-100">
        <div className="card-header bg-light p-3">
          <h5 className="m-0">Topics</h5>
        </div>
        <div className="card-content overflow-hidden h-100">

          {subjects && (
            <ul className="class-dashboard-topics list-group m-0">
              {subjects.map((subject, i) => {
                if (!isPublished(subject)) return null;

                const {
                  fields: { // TODO course flatten (use topics and sort by course.topicIds)
                    featured,
                    title: topicTitle,
                    description: topicDescription
                  }
                } = subject;
                // Class has at least 1 featured Topic, this topic is not featured
                if (!featured && hasFeaturedTopics) return null;

                // Class has no featured Topics, show the first 4 Topics.
                if (!hasFeaturedTopics && i > 3) return null;

                return (
                  <li
                    key={i}
                    className="list-group-item border-left-0 border-right-0 d-flex justify-content-between lh-condensed"
                  >
                    <div>
                      <h5 className="my-0">
                        { topicTitle }
                      </h5>
                      {topicDescription && (
                        <div className="text-muted pt-2">
                          <RenderMarkdown
                            source={topicDescription}
                            enableNewEditor={enableNewEditor}
                          />
                        </div>
                      )}
                    </div>
                  </li>
                );
              })}
            </ul>
          )}
        </div>
      </div>
    </div>
  );
};

export default Topics;
