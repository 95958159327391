import React, { Fragment, useState } from 'react';
import RenderMarkdown from '../../ManageContent/RenderMarkdown';
import isPublished from '../../../utils/isPublished';
import './style.css';

const ChooseClassCodes = ({
  eUnitTracking,
  eUnits,
  onChange
}) => {
  const [eUnitIds, setEUnitIds] = useState([]);

  const handleOnChange = (e) => {
    const { value } = e.currentTarget;

    const data = [
      ...eUnitIds
    ];

    if (!eUnitIds.includes(value)) {
      data.push(value);
    } else {
      const i = eUnitIds.indexOf(value);

      data.splice(i, 1);
    }

    setEUnitIds(data);
    onChange({ eUnitIds: data });
  };

  return (
    <div className="p-3 text-left">

      <h6>
        <b>Select one Class Code:</b>
      </h6>

      {eUnits && eUnits.map((eUnit) => {
        if (!isPublished(eUnit)) return null;

        const {
          sys: { id },
          fields: { // TODO cohort flatten
            code, timeDate, credits
          }
        } = eUnit;

        return (
          <div
            key={code}
            className="border p-2 mb-1"
          >
            <div
              className="custom-control custom-checkbox mb-2 text-left"
            >
              <input
                id={`eUnitCode-${code}`}
                name='eUnitCode'
                value={id}
                className="custom-control-input"
                type="checkbox"
                onChange={handleOnChange}
              />
              <label
                className="custom-control-label d-flex"
                htmlFor={`eUnitCode-${code}`}
              >
                <span>
                  <span className="d-block">
                    <b>{code}</b>
                  </span>
                  <span className="d-block">
                    {timeDate}
                  </span>

                  {credits && (
                    <Fragment>
                      {credits.number && (
                        <span className="d-block">
                          Credits: {credits.number}
                        </span>
                      )}
                      {credits.type && (
                        <span className="d-block">
                          Type: {credits.type}
                        </span>
                      )}
                    </Fragment>
                  )}
                </span>
              </label>
            </div>
          </div>
        );
      })}

      {isPublished(eUnitTracking) && eUnitTracking.fields.info && ( // TODO cohort flatten
        <div className="pt-2">
          <h6>
            <b>Additional Information:</b>
          </h6>
          <RenderMarkdown
            source={eUnitTracking.fields.info}
            enableNewEditor={eUnitTracking.fields.enableNewEditor}
          />
        </div>
      )}
    </div>
  );
};

export default ChooseClassCodes;
