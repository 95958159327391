import React from 'react';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { pathOr } from 'ramda';
import publicPageRoute from '../../utils/publicPageRoute';

const Tabs = ({
  activeTab = 'pathway',
  className = 'mt-3'
}) => {
  const params = useParams();
  // Redux
  const organization = useSelector((state) => state.organization);
  const pathway = useSelector((state) => state.pathway);
  // Misc
  const pathwayId = pathOr(null, ['pathwayId'], params);
  const jobProfileId = pathOr(null, ['jobProfileId'], pathway);
  const orgSlug = pathOr(null, ['slug'], organization);

  return (
    <div className={className}>
      <ul className="nav nav-tabs">
        <li className="nav-item">
          <Link
            className={`nav-link ${activeTab === 'pathway' ? 'active' : ''}`}
            to={publicPageRoute({ route: `pathways/${pathwayId}`, orgSlug })}
            title="All Pathways"
          >
            Pathway
          </Link>
        </li>

        {jobProfileId && (
          <li className="nav-item">
            <Link
              className={`nav-link ml-2 ${activeTab === 'jobProfile' ? 'active' : ''}`}
              to={publicPageRoute({ route: `pathways/${pathwayId}/job-profile/${jobProfileId}`, orgSlug })}
              title="Job Profile"
            >
              Job Profile
            </Link>
          </li>
        )}

        {jobProfileId && (
          <li className="nav-item">
            <Link
              className={`nav-link ml-2 ${activeTab === 'certificates' ? 'active' : ''}`}
              to={publicPageRoute({ route: `pathways/${pathwayId}/certificates`, orgSlug })}
              title="Certificates"
            >
              Certificates
            </Link>
          </li>
        )}

        {/* <li className="nav-item">
          <Link
            className={`nav-link ml-2 ${activeTab === 'courses' ? 'active' : ''}`}
            to={publicPageRoute({ route: `pathways/${pathwayId}/courses`, orgSlug })}
            title="Courses"
          >
            Courses
          </Link>
        </li> */}
      </ul>
    </div>
  );
};

export default Tabs;
