import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { pathOr } from 'ramda';
import { toast } from 'react-toastify';
import ReactSignatureCanvas from 'react-signature-canvas';
import * as aws from '../../../services/aws';
import { AWS_USER_PREFIX } from '../../../constants/aws';
import dataURLToBlob from '../../../utils/dataURLToBlob';
import SignatureFlag from './SignatureFlag';

const UserSignaturePad = ({
  onChange,
  className = '',
  textAboveSignature = '',
  textBelowSignature = 'Sign above',
  primaryButtonText = 'Adopt & Sign'
}) => {
  let sigPad = useRef(null);
  // redux
  const organization = useSelector((state) => state.organization);
  // misc
  const orgId = pathOr(null, ['id'], organization);
  // local state
  const [loading, setLoading] = useState(false);

  const handleSaveSignature = () => {
    if (sigPad.isEmpty()) {
      toast.error('Please provide a signature first.');
    } else {
      setLoading(true);
      toast.info('Saving signature...');

      const dataURL = sigPad.getTrimmedCanvas().toDataURL();
      const blob = dataURLToBlob(dataURL);

      aws.uploadFile({
        prefix: `${AWS_USER_PREFIX}/${orgId}`,
        file: blob,
        fileExt: 'png',
        organizationId: orgId
      }).then((url) => {
        onChange(url);
      });
    }
  };

  const handleClearSignature = () => {
    sigPad.clear();
  };

  // const handleUndoClick = (signaturePad) => {
  //   let data = sigPad.toData();

  //   if (data) {
  //     data.pop(); // remove the last dot or line
  //     signaturePad.fromData(data);
  //   }
  // };

  return (
    <>
      <div className={`card-body bg-light text-center ${className}`}>
        <div
          className='text-center text-muted'
          style={{ fontSize: '.9rem' }}
        >
          {textAboveSignature}
        </div>
        <div className='position-relative d-inline-block'>
          <ReactSignatureCanvas
            ref={(ref) => { sigPad = ref; }}
            penColor='#455371'
            canvasProps={{
              width: 330,
              height: 100,
              className: 'sigCanvas m-auto'
            }}
          />
          <SignatureFlag
            style={{
              bottom: '21px',
              left: '-17px'
            }}
          />
        </div>
        <div
          className='mb-4 text-center no-select'
          style={{ fontSize: '.8rem' }}
        >
          {textBelowSignature}
        </div>
        <div
          className='text-center no-select small'
        >
          By selecting {primaryButtonText}, I agree that the signature will be the electronic representation of my signature for all purposes when I use them on documents - just the same as a pen-and-paper signature.
        </div>
      </div>
      <div
        className='card-footer d-flex align-items-center justify-content-between'
      >
        <button
          className='btn btn-md btn-primary'
          type="button"
          onClick={handleSaveSignature}
          disabled={loading}
        >
          {loading ? 'Saving...' : primaryButtonText}
        </button>
        <button
          className='btn btn-md btn-secondary'
          type="button"
          onClick={handleClearSignature}
          disabled={loading}
        >
          Clear
        </button>
        {/* <button
        type="button"
        onClick={handleUndoClick}
      >
        Undo
      </button> */}
      </div>
    </>
  );
};

export default UserSignaturePad;
