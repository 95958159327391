import { fetchIntegration } from '../services/integrations';
import {
  SET_INTEGRATION,
  RESET_INTEGRATION
} from './types';

export const getIntegration = (id) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      fetchIntegration(id).then((integration) => {
        dispatch({ type: SET_INTEGRATION, integration });

        resolve(integration);
      }).catch((error) => {
        console.error(error);
        reject(error);
      });
    });
  };
};

export const resetUserProfile = () => {
  return (dispatch) => {
    dispatch({ type: RESET_INTEGRATION });
  };
};
