import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { pathOr } from 'ramda';
import Logo from './Logo';
import PoweredBy from './PoweredBy';
import Legal from './Legal';
import './style.css';

const AuthContainer = ({
  children,
  title,
  logo = true,
  locationImage = '',
  locationName = '',
  footer = true,
  footerText = '',
  poweredBy = true,
  className = ''
}) => {
  const params = useParams();
  // redux
  const organization = useSelector((state) => state.organization);
  const currentUser = useSelector((state) => state.currentUser);
  // misc
  const organizationSlug = pathOr(false, ['organizationSlug'], params);

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      document.getElementsByTagName('html')[0].className = 'authpage';

      if (currentUser?.id) {
        document.getElementsByTagName('html')[0].className = 'authpage authpage-logged-in';
      }
    }

    return function cleanup() {
      mounted = false;
      document.getElementsByTagName('html')[0].className = '';
    };
  });

  const { id: orgId, name: orgName, orgLogo } = organization;

  if (organizationSlug && !orgId) return null;

  return (
    <>
      <div className={`auth-container ${className}`}>
        <div className="card bg-light rounded">
          {title && (
            <h5 className="card-header py-3 h6 text-capitalize">
              {title}
            </h5>
          )}

          <div className="card-body">
            {logo && (
              <Logo
                orgName={orgName}
                orgLogo={locationImage || orgLogo}
              />
            )}

            {!logo && orgName && (
              <div className='mb-3'>
                <span className='pb-3 px-2 d-inline-block border-bottom font-weight-bold text-uppercase'>
                  {locationName || orgName}
                </span>
              </div>
            )}

            {children && (
              <>
                {children}
              </>
            )}
          </div>

          {footer && (
            <div className="card-footer px-2">
              <Legal
                text={footerText}
              />
            </div>
          )}
        </div>

        {poweredBy && (
          <div className="my-3 text-muted">
            <PoweredBy />
          </div>
        )}
      </div>
    </>
  );
};

export default AuthContainer;
