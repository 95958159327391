export const PLATFORM_NAME = 'Turbine Workforce';
export const TURBINE_APP_VERSION = process.env.REACT_APP_TURBINE_APP_VERSION;
export const TURBINE_STRIPE_LIVE = process.env.REACT_APP_TURBINE_STRIPE_LIVE;
export const TURBINE_STRIPE_TEST = process.env.REACT_APP_TURBINE_STRIPE_TEST;
// export const TURBINE_TOM_TOM_KEY = process.env.REACT_APP_TOM_TOM_KEY; // https://developer.tomtom.com/user/me/apps
export const TURBINE_GOOGLE_MAPS_KEY = process.env.REACT_APP_GOOGLE_MAPS_KEY; // https://console.cloud.google.com/apis/credentials?authuser=2&project=turbine-workforce
export const TURBINE_ADMIN_EMAIL = 'hello@turbineworkforce.com';
export const TURBINE_ADMIN_BCC_EMAILS = 'alan@turbineworkforce.com,josh@turbineworkforce.com';
export const CLASS_COLOR_DEFAULT = '#b3bac5';
// Web Manifest
export const APPLE_TOUCH_ICON = 'https://turbine-content.s3.us-east-2.amazonaws.com/favicon-turbine/apple-touch-icon.png';
export const ANDROID_192_ICON = 'https://turbine-content.s3.us-east-2.amazonaws.com/favicon-turbine/android-chrome-192x192.png';
export const ANDROID_512_ICON = 'https://turbine-content.s3.us-east-2.amazonaws.com/favicon-turbine/android-chrome-512x512.png';
// Metadata
export const ROBOTS_ON = 'nofollow, noarchive noimageindex notranslate nositelinkssearchbox';
export const ROBOTS_OFF = 'noindex, nofollow';
