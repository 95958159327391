import React from 'react';

const Timeline = ({
  style = {},
  className = '',
  children = null
}) => {
  return (
    <ul
      className={`timeline ${className}`}
      style={style}
    >
      {children}
    </ul>
  );
};

export default Timeline;
