export const prepMsg = (msg) => {
  let message = { ...msg };
  message.time = new Date().toISOString(); // 2019-05-16T18:31:51+00:00

  message = JSON.stringify(message);

  return message;
};

export const toParent = (msg, origin = '*') => {
  const {
    parent
    // ReactNativeWebView
  } = window;
  const message = (msg instanceof Object) ? prepMsg(msg) : msg;

  if (parent) {
    parent.postMessage(message, origin);
  }

  // react-native-webview onMessage was rewritten, api for window.postMessage changed to window.ReactNativeWebView.postMessage;
  // if (ReactNativeWebView && ReactNativeWebView.postMessage) {
  //   ReactNativeWebView.postMessage(message);
  // }
};

export const toIframe = (msg, origin = '*', iframe) => {
  const message = (msg instanceof Object) ? prepMsg(msg) : msg;

  if (iframe) {
    iframe.contentWindow.postMessage(message, origin);
  }
};
