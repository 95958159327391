import React from 'react';

const ApprenticeResponsibility = () => {
  return (
    <div className='ApprenticeResponsibility mt-5'>
      <h5 className='font-weight-bold'>
        RESPONSIBILITIES OF APPRENTICE
      </h5>

      <div>
        <p>
          Apprentices, having been provided with and read the apprenticeship standards formulated by the program sponsor, agree to all the terms and conditions contained herein and agree to abide by the program sponsor’s rules and policies, including any amendments, and to serve such time, perform such manual training, and study such subjects as the program sponsor may deem necessary to become a skilled journeyworker.
        </p>
        <p>
          In signing the apprenticeship agreement, apprentices assume the following responsibilities and obligations under the registered apprenticeship program:
        </p>
      </div>

      <ol>
        <li>
          Perform diligently and faithfully the work of the occupation and other pertinent duties assigned by the program sponsor in accordance with the provisions of these Standards.
        </li>
        <li>
          Respect the property of the employer and abide by the working rules and regulations of the employer, union and/or ATC/JATC.
        </li>
        <li>
          Attend and satisfactorily complete the required hours in the OJL and in related instruction in subjects related to the occupation as provided under these Standards.
        </li>
        <li>
          Maintain and make available such records of work experience and training received on the job and in instruction as may be required by the program sponsor.
        </li>
        <li>
          Develop and practice safe working habits and work in such a manner as to ensure his/her personal safety and that of fellow workers.
        </li>
        <li>
          Work for the employer to whom the apprentice is assigned for the duration of the apprenticeship, unless the apprentice is reassigned to another employer or the apprenticeship agreement is terminated by the sponsor.
        </li>
      </ol>
    </div>
  );
};

export default ApprenticeResponsibility;
