import React from 'react';
import { useSelector } from 'react-redux';
import { pathOr } from 'ramda';
import { TURBINE_TOS_URL } from '../../constants/urls';

const TermsOfServiceLink = ({ useTurbinePolicy = false }) => {
  const organization = useSelector((state) => state.organization);
  const tos = pathOr(null, ['tos'], organization);
  const url = tos && !useTurbinePolicy ? tos : TURBINE_TOS_URL;

  return (
    <a
      target="_blank"
      rel="noopener noreferrer"
      className="btn-link"
      href={url}
    >
      Terms
    </a>
  );
};

export default TermsOfServiceLink;
