import { getFirebaseEntry } from '../services/firebaseProxy';
import { RESET_INVITE, SET_INVITE } from './types';

export const getInvite = (id, collection = 'invites') => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      getFirebaseEntry({ collection, id }).then((response) => {
        const invite = response || null;

        dispatch({ type: SET_INVITE, invite });
        resolve(invite);
      }).catch((error) => {
        console.error(error);
        reject(error);
      });
    });
  };
};

export const resetInvite = () => {
  return (dispatch) => {
    dispatch({ type: RESET_INVITE });
  };
};
