import { pathOr } from 'ramda';
import { client, flattenItems } from '../services/contentful';
import { fetchLocation } from '../services/locations';
import { getEmployerProfile } from './Employers';
import {
  SET_LOCATIONS, RESET_LOCATIONS, SET_LOCATION, SET_DEFAULT_LOCATION
} from './types';

export const getLocations = ({
  orgId, include = 1, order, select, locationIds
}) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      const config = {
        content_type: 'location',
        'fields.orgId': orgId,
        include
      };

      if (locationIds) {
        config['sys.id[in]'] = locationIds.join(',');
      }

      if (select) {
        config.select = select.join(',');
      } else {
        // When select is present there is a chance
        // that name is not included
        config.order = order || 'fields.name';
      }

      return client.getEntries(config).then(({ items }) => {
        const locations = items.length ? flattenItems(items) : null;

        dispatch({ type: SET_LOCATIONS, locations });
        resolve(locations);
      }).catch((error) => {
        console.error(error.message);
        reject(error);
      });
    });
  };
};

export const getLocation = (locationId) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      fetchLocation({ locationId }).then((orgLocation) => {
        const employerProfileId = pathOr(null, ['employerProfileId'], orgLocation);

        if (employerProfileId) {
          dispatch(getEmployerProfile(employerProfileId)).then(() => {
            dispatch({
              type: SET_LOCATION,
              orgLocation
            });

            resolve({ orgLocation });
          });
        } else {
          dispatch({
            type: SET_LOCATION,
            orgLocation
          });

          resolve({ orgLocation });
        }
      }).catch((error) => {
        console.error(error.message);
        reject(error);
      });
    });
  };
};

export const getDefaultLocation = ({ orgId, select }) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      const config = {
        content_type: 'location',
        'fields.orgId': orgId,
        'fields.defaultLocation': true
      };

      if (select) {
        config.select = select.join(',');
      }

      client.getEntries(config).then(({ items }) => {
        const locations = items.length ? flattenItems(items) : null;
        const defaultLocation = locations ? locations[0] : null;

        dispatch({ type: SET_DEFAULT_LOCATION, defaultLocation });

        resolve(defaultLocation);
      }).catch((error) => {
        console.error(error.message);
        reject(error);
      });
    });
  };
};

export const resetLocations = () => {
  return (dispatch) => {
    dispatch({ type: RESET_LOCATIONS });
  };
};
