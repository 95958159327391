import React, { Component, Fragment } from 'react';
import ChooseClassCodes from './ChooseClassCodes';
import isPublished from '../../../utils/isPublished';

class CourseCohorts extends Component {
  state = {
    selectedCohort: this.props.selectedCohort || null
  }

  selectCohort = (selectedCohort) => {
    this.setState({ selectedCohort });
    this.props.onChange({ selectedCohort });
  }

  resetCohort = () => {
    this.setState({ selectedCohort: null });
    this.props.onChange({ selectedCohort: null, eUnitIds: [] });
  }

  render() {
    const { selectedCohort } = this.state;
    const { classTitle, courseCohorts } = this.props;

    if (selectedCohort) {
      const {
        title,
        eUnitTracking: selectedEUnitTracking, // TODO cohort flatten
        eUnits: selectedEUnits // TODO cohort flatten
      } = selectedCohort;

      return (
        <Fragment>
          <div className="card mb-2">
            <div className="card-header d-flex justify-content-between">
              <h5 className="mb-0">
                <i className="fas fa-check-circle text-keppel mr-2" /> Select a Cohort
              </h5>
              <button
                type="button"
                className="btn btn-sm btn-link p-0"
                onClick={this.resetCohort}
              >
                Change
              </button>
            </div>
            <div className="card-body">
              <p className="m-0">
                <b>{title}</b>
              </p>
            </div>
          </div>

          <div className="card text-left">
            <div className="card-header h3 mb-0">
              <h5 className="m-0">
                <i className="far fa-check-circle text-muted opacity-50 mr-2" /> Select Class Codes
              </h5>
            </div>
            <div className="card-body p-0">
              <ChooseClassCodes
                classTitle={classTitle}
                eUnitTracking={selectedEUnitTracking}
                eUnits={selectedEUnits}
                onChange={this.props.onChange}
              />
            </div>
          </div>
        </Fragment>
      );
    }

    const activeCourseCohorts = courseCohorts; // TODO may need activeCohorts

    if (!activeCourseCohorts) {
      return (
        <div className="card text-left">
          <div className="card-header d-flex">
            <h5 className="mb-0">
              Select a Cohort
            </h5>
          </div>
          <div className="card-body text-left">
            <p className="m-0">
              <b>No active Cohorts.</b>
            </p>
          </div>
        </div>
      );
    }

    return (
      <div className="card text-left">
        <div className="card-header d-flex">
          <h5 className="mb-0">
            Select a Cohort
          </h5>
        </div>
        <div className="card-body p-2">
          {activeCourseCohorts.map((cohort, i) => {
            const { title, eUnits } = cohort;

            return (
              <div
                key={i}
                className="cohort-container border rounded mb-3"
              >
                <div className="border-bottom p-2">
                  <h5 className="font-weight-bold m-0">
                    { title }
                  </h5>
                </div>

                {eUnits && ( // TODO course flatten (educationUnitIds)
                  <div className="d-flex flex-column p-2">
                    {eUnits.map((eUnit, eUnitIndex) => {
                      if (!isPublished(eUnit)) return null;

                      return (
                        <div
                          key={eUnitIndex}
                          className="bg-light border p-2 mb-2 rounded"
                        >
                          <div
                            className="font-weight-bold"
                            style={{ fontSize: '1rem' }}
                          >
                            { eUnit.fields.code }
                          </div>
                          <div>
                            { eUnit.fields.timeDate }
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}

                <div className="border-top p-2">
                  <button
                    className="btn btn-sm btn-primary stretched-link"
                    type="button"
                    onClick={() => {
                      this.selectCohort(cohort);
                    }}
                  >
                    Select
                  </button>
                </div>

              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

export default CourseCohorts;
