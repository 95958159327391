import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { toast } from 'react-toastify';
import { withRouter } from 'react-router-dom';
import { setCurrentClass } from '../../../actions/Class';
import { resetCurrentModal } from '../../../actions/Modals';
import CourseCohorts from './CourseCohorts';
import Modal from '../../Modal';
import './style.css';

class EnrollExternalRegisteredClass extends Component {
  state = {
    key: 'enrollExternalRegisteredClass',
    loading: false,
    selectedCohort: null,
    eUnitIds: []
  }

  handleClose = () => {
    this.props.resetCurrentModal();
  }

  handleCourseCohortsChange = (data) => {
    // this.props.setEnroll({
    //   ...data
    // });
    // this.props.currentModal.data.callback({
    //   ...data
    // });
    this.setState({
      ...data
    });
  }

  handleOnClick = () => {
    const { selectedCohort, eUnitIds } = this.state;
    const { currentModal, courseCohorts } = this.props;
    const { data: { classId } } = currentModal;

    this.setState({ loading: true });

    // const { id: userId } = currentUser;

    const data = {
      classId,
      externalRegisterClassId: classId
      // userId
    };

    // Cohorts & Education Units
    if (courseCohorts) {
      if (!selectedCohort) {
        toast.error('Select a Cohort');
        this.setState({ loading: false });
        return false;
      }
      // Include Cohort Id
      data.cohortId = selectedCohort.sys.id;

      if (selectedCohort.eUnits && !eUnitIds.length) { // TODO cohort flatten
        toast.error('Select at least one Class Code');
        this.setState({ loading: false });
        return false;
      }
      // Include Education Units
      data.eUnitIds = eUnitIds;
    }

    this.props.currentModal.data.callback({
      ...data
    });
  }

  render() {
    const {
      currentModal, currentUser, course, courseCohorts
    } = this.props;
    if (currentModal && !currentModal.visible) return null;

    const {
      key, loading, selectedCohort, eUnitIds
    } = this.state;
    if (currentModal.key !== key) return null;

    const { data: { title } } = currentModal;
    const { title: classTitle, registerUrl } = course;

    const activeCourseCohorts = courseCohorts; // TODO may need activeCohorts

    return (
      <Modal
        visible={currentModal.key === key}
        close={this.handleClose}
        cssClassName={`modal-${key}`}
        pos="top"
      >
        <div className="card">
          <div className="card-header h3 mb-0 text-center">
            <h5 className="m-0">
              {title}
            </h5>
          </div>
          <div className="card-body">
            <p className="text-center">
              Enroll in <b>{classTitle}</b>
            </p>

            {activeCourseCohorts && (
              <CourseCohorts
                classTitle={classTitle}
                courseCohorts={activeCourseCohorts}
                selectedCohort={selectedCohort}
                currentUser={currentUser}
                onChange={this.handleCourseCohortsChange}
              />
            )}

            {activeCourseCohorts && selectedCohort && (
              <div className="mt-3">
                <button
                  className="btn btn-md btn-primary"
                  type="submit"
                  onClick={this.handleOnClick}
                  disabled={loading || !eUnitIds.length}
                >
                  {loading ? 'Redirecting...' : 'Continue'}
                </button>
              </div>
            )}

            {/* If class has external register and no courseCohorts */}
            {!activeCourseCohorts && (
              <div className="mt-3 text-center">
                <button
                  className="btn btn-lg btn-primary"
                  type="submit"
                  onClick={this.handleOnClick}
                  disabled={loading}
                >
                  {loading ? 'Redirecting...' : 'Continue'}
                </button>
              </div>
            )}
          </div>

          {registerUrl && (
            <div className="card-footer text-muted">
              <i className="fas fa-exclamation-triangle text-warning" /> Once enrolled, an administrator will verify your registration before you can attend class and earn credits.
            </div>
          )}
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = ({
  currentModal,
  currentUser,
  currentClass,
  courseCohorts
}) => ({
  currentModal,
  currentUser,
  course: currentClass,
  courseCohorts
});

export default compose(
  withRouter,
  connect(mapStateToProps, {
    setCurrentClass,
    resetCurrentModal
    // setEnroll
  })
)(EnrollExternalRegisteredClass);
