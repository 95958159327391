import React, { useState } from 'react';
import PizZip from 'pizzip';
import { DOMParser } from '@xmldom/xmldom';
import parseTrainingProgram from './parsers/trainingProgram';

// str2xml & getParagraphs
// https://matheswaaran.medium.com/read-docx-files-in-react-js-9d03ddb9fe7d

function str2xml(str) {
  if (str.charCodeAt(0) === 65279) {
    // BOM sequence
    str = str.substr(1);
  }
  return new DOMParser().parseFromString(str, 'text/xml');
}

// Get paragraphs as javascript array
function getParagraphs(content) {
  const zip = new PizZip(content);
  const xml = str2xml(zip.files['word/document.xml'].asText());
  const paragraphsXml = xml.getElementsByTagName('w:p');
  const paragraphs = [];

  for (let i = 0, len = paragraphsXml.length; i < len; i += 1) {
    let fullText = '';
    const textsXml = paragraphsXml[i].getElementsByTagName('w:t');
    for (let j = 0, len2 = textsXml.length; j < len2; j += 1) {
      const textXml = textsXml[j];
      if (textXml.childNodes) {
        if (textXml.childNodes[0]) {
          fullText += textXml.childNodes[0].nodeValue;
        }
      }
    }
    if (fullText && fullText !== ' ' && fullText !== '') {
      paragraphs.push(fullText);
    }
  }
  return paragraphs;
}

const ImportDocument = ({ data, onChangeCallback, type }) => {
  const [docName, setDocName] = useState(null);

  const onChange = (e) => {
    const { files } = e.currentTarget;
    const file = files[0];
    const reader = new FileReader();

    reader.onload = (evt) => {
      const content = evt.target.result;

      setDocName(file.name);

      const paragraphs = getParagraphs(content);

      console.log(paragraphs);

      let docData = {};

      if (type === 'trainingProgram') {
        docData = parseTrainingProgram(paragraphs);
      }

      onChangeCallback(docData);
    };

    reader.onerror = (err) => console.error(err);

    reader.readAsBinaryString(file);
  };

  return (
    <div>
      {!data && (
        <div className='bg-white rounded p-3'>
          <label>
            Select a file (docx/pptx/xlsx)
          </label>
          <br />
          <input
            type="file"
            onChange={onChange}
            accept=".docx,.pptx,.xlsx"
          />
        </div>
      )}

      {data && (
        <div
          className='bg-white rounded p-3 d-flex align-items-center'
        >
          <div>
            {docName}
          </div>
          <button
            className='btn btn-sm btn-outline-primary ml-2'
            type='button'
            onClick={() => {
              setDocName('');
              onChangeCallback(null);
            }}
          >
            Change
          </button>
        </div>
      )}
    </div>
  );
};

export default ImportDocument;
