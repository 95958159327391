import React from 'react';
import { formatCurrency } from '../../../../utils/currency';
import Section from '../../Section';

const ApprenticeWageSchedule = ({
  className = '',
  wageSchedulesList,
  children
}) => {
  const entryWages = wageSchedulesList ? wageSchedulesList.map(({ entryWage }) => entryWage).sort() : null;
  const lowestEntryWage = entryWages ? entryWages[0] : 0;

  return (
    <Section
      num={3}
      title="Apprenticeship Wage Schedule"
      className={className}
    >
      <p>
        Apprentices shall be paid a progressively increasing schedule of wages based on a percentage of the current minimum hourly journeyworker wage rate, which is no lower than {formatCurrency(lowestEntryWage)} per hour.
        In no case will the starting wages of apprentices be less than that required by a minimum wage law that may be applicable. The percentages that will be applied to the applicable rate are shown on pg. 2.
      </p>

      {children || null }
    </Section>
  );
};

export default ApprenticeWageSchedule;
