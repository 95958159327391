import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { pathOr } from 'ramda';
import {
  FaLock, FaExclamationTriangle, FaCloudDownloadAlt, FaFilePdf
} from 'react-icons/fa';
import { FaCircleCheck } from 'react-icons/fa6';
import { locationTerminology } from '../../services/locations';
import { getApprenticeship } from '../../actions/Apprenticeships';
import { getApprenticeAgreement } from '../../actions/ApprenticeAgreements';
import { getSponsorUser, setCurrentModal } from '../../actions';
import generatePDF from './generatePDF';
import Loading from '../Loading';
import OrgConsoleHeader from '../OrgConsole/OrgConsoleHeader';
import Form from './Form';
import TimelineItem from '../Timeline/TimelineItem';
import Timeline from '../Timeline';
import SecurityMeasuresLink from '../Legal/SecurityMeasuresLink';
// import LoadPDF from './LoadPDF';
import './styles.css';

const ApprenticeAgreement = ({
  apprenticeAgreementId = '',
  data = {}, // { ...invite } || { ...doc }
  dataType = '' // 'invite' || 'doc'
}) => {
  const dispatch = useDispatch();
  // Redux
  const organization = useSelector((state) => state.organization);
  const defaultLocation = useSelector((state) => state.defaultLocation);
  const orgLocation = useSelector((state) => state.orgLocation);
  const apprenticeship = useSelector((state) => state.apprenticeship);
  const apprenticeAgreement = useSelector((state) => state.apprenticeAgreement);
  const apprenticeshipUser = useSelector((state) => state.apprenticeshipUser);
  const employerAgreement = useSelector((state) => state.employerAgreement);
  const instructionProvider = useSelector((state) => state.instructionProvider);
  const userProfile = useSelector((state) => state.userProfile);
  const wageSchedule = useSelector((state) => state.wageSchedule);
  const sponsorUser = useSelector((state) => state.sponsorUser);
  // Organization
  const orgType = pathOr(null, ['type'], organization);
  const orgName = pathOr(null, ['name'], organization);
  const orgId = pathOr(null, ['id'], organization);
  const locationName = pathOr(null, ['name'], orgLocation);
  const locationTerm = locationTerminology({ orgType });
  // Agreement
  const employerAgreementId = pathOr('', ['employerAgreementId'], apprenticeAgreement);
  const apprenticeshipId = pathOr('', ['apprenticeshipId'], data);
  const instructionProviderId = pathOr('', ['instructionProviderId'], data);
  const userProfileId = pathOr('', ['userProfileId'], apprenticeAgreement);
  const userId = pathOr('', ['userId'], data);
  // Local State
  const [reviewTextSeen, setReviewTextSeen] = useState(false);

  useEffect(() => {
    document.body.classList.add('manage-apprentice-agreement');

    if (apprenticeshipId) {
      dispatch(getApprenticeship(apprenticeshipId));
    }

    if (apprenticeAgreementId) {
      dispatch(getApprenticeAgreement(apprenticeAgreementId));
    }

    // Program Sponsor Signatory
    if (orgLocation?.id && orgLocation?.managerIds) {
      if (Array.isArray(orgLocation.managerIds) && orgLocation.managerIds.length !== 0) {
        const [sponsorUserId] = orgLocation.managerIds;

        dispatch(getSponsorUser(sponsorUserId));
      }
    }
  }, []);

  if (apprenticeAgreementId && !apprenticeAgreement?.id) {
    return (
      <Loading
        text="Loading Apprentice Agreement..."
        className='theme-dark'
      />
    );
  }

  if (employerAgreementId && !employerAgreement?.id) {
    return (
      <Loading
        text="Loading Workforce Info..."
        className='theme-dark'
      />
    );
  }

  if (apprenticeshipId && !apprenticeship?.id) {
    return (
      <Loading
        text="Loading Training Program..."
        className='theme-dark'
      />
    );
  }

  if (instructionProviderId && !instructionProvider?.id) {
    return (
      <Loading
        text="Loading Instruction Provider..."
        className='theme-dark'
      />
    );
  }

  if (userId && !apprenticeshipUser?.id) {
    return (
      <Loading
        text="Loading User..."
        className='theme-dark'
      />
    );
  }

  if (userProfileId && !userProfile?.id) {
    return (
      <Loading
        text="Loading User..."
        className='theme-dark'
      />
    );
  }

  if (!organization?.id || !defaultLocation?.id || !sponsorUser?.id) {
    return (
      <Loading
        text="Loading Sponsor..."
        className='theme-dark'
      />
    );
  }

  if (!orgLocation?.id) {
    return (
      <Loading
        text="Loading Employer..."
        className='theme-dark'
      />
    );
  }

  if (!apprenticeshipId) {
    console.error('No apprenticeshipId');
    return (
      <div>No Training Program data available.</div>
    );
  }

  return (
    <div
      className="row d-flex justify-content-center m-auto"
    >
      <div className="col-12">

        <div className="mt-3">
          {dataType === 'invite' && (
            <div
              className='hide-when-printing'
            >
              <OrgConsoleHeader
                pageTitle={orgLocation?.name}
                icon={locationTerm.icon}
                image={orgLocation?.locationImage}
                imageWidth=''
              />
            </div>
          )}

          {dataType === 'invite' && (
            <div
              className='bg-white p-3 hide-when-printing'
            >
              <p className='m-0'>
                {locationName} is participating in an apprenticeship program with {orgName}.
              </p>

              {reviewTextSeen && !apprenticeAgreement?.signerSignature && (
                <div className='mt-1'>
                  Please review and sign the Apprentice Agreement which is needed for mandatory reporting to government agencies.
                </div>
              )}

              {!apprenticeAgreement?.signerSignature && (
                <p className='mt-3 mb-0 font-weight-bold d-flex align-items-center'>
                  <FaExclamationTriangle size={20} className='text-warning ml-1 mr-2' />
                  <span>
                    For the best experience, use a laptop or desktop.
                  </span>
                </p>
              )}

              {apprenticeAgreement?.signerSignature && (
                <p className='mt-3 mb-0 font-weight-bold d-flex align-items-center'>
                  <FaCircleCheck size={20} className='text-keppel ml-1 mr-2' />
                  <span>
                    Document signed successfully!
                  </span>
                </p>
              )}
            </div>
          )}

          {dataType === 'invite' && reviewTextSeen && !apprenticeAgreement?.signerSignature && (
            <div
              className='bg-white p-3 mt-3 hide-when-printing position-fixed shadow'
              style={{
                zIndex: 1,
                right: 0
              }}
            >
              <button
                className="btn btn-sm btn-primary"
                title="Edit Information"
                onClick={() => {
                  dispatch(setCurrentModal({
                    key: 'manageUserProfile',
                    data: {
                      activeTab: 'privateInfo'
                    }
                  }));
                }}
                type="button"
              >
                <i className="fas fa-pencil-alt mr-1" /> Edit Information
              </button>
            </div>
          )}

          {dataType === 'invite' && !reviewTextSeen && (
            <>
              <Timeline
                style={{
                  background: 'rgba(0, 0, 0, .2)'
                }}
              >
                <TimelineItem
                  title='Review Personal Information'
                  index={1}
                >
                  <p className='mb-2'>
                    Review your information for accuracy and click <span className='font-weight-bold'>Save Information</span> to proceed to the next step.
                  </p>
                  <button
                    className="btn btn-primary btn-lg mt-1"
                    type="button"
                    onClick={() => {
                      dispatch(setCurrentModal({
                        key: 'manageUserProfile',
                        data: {
                          activeTab: 'privateInfo',
                          modalTitle: 'Review Personal Information',
                          onSubmitCallback: () => {
                            setReviewTextSeen(true);
                          }
                        }
                      }));
                    }}
                  >
                    Get Started
                  </button>
                </TimelineItem>

                <TimelineItem
                  title='Review & Sign the Apprentice Agreement'
                  index={2}
                >
                  <p className='mb-1'>
                    Please review and sign the Apprentice Agreement in SECTION A.
                  </p>
                  <div>
                    To update your information at any time, click the <strong>Edit Information</strong> button.
                  </div>
                </TimelineItem>

              </Timeline>
              <div
                className='d-flex align-items-center text-small mb-3 bg-white rounded p-3'
              >
                <FaLock size={20} />
                <div className='m-0 ml-2'>
                  Your private information is used for mandatory reporting on official government documents.
                  <br />
                  Read more about our <SecurityMeasuresLink />.
                </div>
              </div>
            </>
          )}

          {/*
           *
           * AFTER SIGNING, Download button is available
           *
           */}
          {(dataType === 'doc' || apprenticeAgreement?.signerSignature) && (
            <div className='bg-white p-3 mb-3 hide-when-printing'>
              <div className="d-flex align-items-center">
                <button
                  className="btn btn-md btn-primary d-flex align-items-center"
                  title="Download"
                  onClick={() => {
                    generatePDF({
                      orgId,
                      apprenticeship,
                      apprenticeAgreement,
                      apprenticeshipUser,
                      employerAgreement,
                      userProfile,
                      orgLocation,
                      defaultLocation,
                      wageSchedule,
                      sponsorUser,
                      instructionProvider,
                      autoDownload: true
                    });
                  }}
                  type="button"
                >
                  <FaCloudDownloadAlt />
                  <span className='ml-2'>
                    Download
                  </span>
                </button>
                <div className="d-flex align-items-center ml-3">
                  <FaFilePdf className='mr-2' />
                  <span>
                    {apprenticeAgreement?.name}
                  </span>
                </div>
              </div>
            </div>
          )}

          {(dataType === 'doc' || reviewTextSeen) && (
            <Form
              apprenticeAgreementId={apprenticeAgreementId}
              data={data}
              dataType={dataType}
            />
          )}

          {/* {dataType === 'doc' && orgLocation?.id && (
            <LoadPDF />
          )} */}
        </div>
      </div>
    </div>

  );
};

export default ApprenticeAgreement;
