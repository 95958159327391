import { fetchApprenticeship } from '../services/apprenticeships';
import { fetchUser } from '../services/users';
import {
  SET_APPRENTICESHIP, RESET_APPRENTICESHIP, SET_APPRENTICESHIP_USER, RESET_APPRENTICESHIP_USER
} from './types';

export const getApprenticeship = (id) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      fetchApprenticeship(id).then((apprenticeship) => {
        dispatch({ type: SET_APPRENTICESHIP, apprenticeship });
        resolve(apprenticeship);
      }).catch((error) => {
        console.error(error);
        reject(error);
      });
    });
  };
};

export const getApprenticeshipUser = (userId) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      fetchUser({
        userId,
        select: [
          'id',
          // 'userProfileId',
          'name',
          'firstName',
          'lastName',
          'middleName',
          'suffix',
          'phone',
          'phoneExt',
          'mobilePhone',
          // 'locationId',
          'userSignature',
          'email'
          // 'apprenticeshipIds'
        ]
      }).then((apprenticeshipUser) => {
        dispatch({ type: SET_APPRENTICESHIP_USER, apprenticeshipUser });

        resolve(apprenticeshipUser);
      }).catch((error) => {
        console.error('getApprenticeshipUser', error);
        reject(error);
      });
    });
  };
};

export const resetApprenticeshipUser = () => {
  return (dispatch) => {
    dispatch({ type: RESET_APPRENTICESHIP_USER });
  };
};

export const resetApprenticeship = () => {
  return (dispatch) => {
    dispatch({ type: RESET_APPRENTICESHIP });
  };
};
