import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { SLASH } from '../../constants/routes';
import { DEFAULT_ORG_LOGO } from '../../constants/assets';
import './style.css';

const TopNavEmployerPages = () => {
  const organization = useSelector((state) => state.organization);
  const course = useSelector((state) => state.currentClass);

  if (!organization) return null;
  if (!document.location.href.includes('/invite')) return null; // /manage/workforce

  const { orgLogo, name: orgName } = organization;

  return (
    <nav className="navbar bg-white border-bottom flex-md-nowrap p-0">
      <div className="w-100 d-flex align-items-center justify-content-between">
        <Link
          className="navbar-brand px-3 text-dark"
          to={SLASH}
          title="Turbine Workforce"
        >
          <img
            src={orgLogo || DEFAULT_ORG_LOGO}
            className="nav-icon"
            alt={`${orgName} Icon`}
          />
        </Link>
        <div className="px-3 text-right">
          <div className="font-weight-bold">
            {orgName}
          </div>
          {course && (
            <div>
              {course.title}
            </div>
          )}
        </div>
      </div>
    </nav>
  );
};

export default TopNavEmployerPages;
