import React from 'react';
import { useSelector } from 'react-redux';
import { roleReadable } from '../../../services/currentUser';
import InstructorsList from './InstructorsList';
import './style.css';

const Instructors = ({ className }) => {
  const organization = useSelector((state) => state.organization);
  const courseExperts = useSelector((state) => state.courseExperts);

  if (!courseExperts || !organization) return null;

  const { type: orgType } = organization;

  const whichExperts = courseExperts.filter((courseExpert) => courseExpert.testUser !== true);

  if (!whichExperts.length) return null;

  return (
    <div className={`card ${className}`}>
      <div className="card-header bg-light p-3">
        <h5 className="m-0">
          {roleReadable({ role: ['teacher'], orgType })}s
        </h5>
      </div>

      <InstructorsList
        className="card-body py-0 h-100"
      />
    </div>
  );
};

export default Instructors;
