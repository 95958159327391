const decodeBase64Buffer = (bufferBase64) => {
  // Convert Base64 string back to ArrayBuffer
  const binaryString = atob(bufferBase64);
  const { length } = binaryString;
  const bytes = new Uint8Array(length);

  for (let i = 0; i < length; i += 1) {
    bytes[i] = binaryString.charCodeAt(i);
  }

  return bytes.buffer;
};

export default decodeBase64Buffer;
