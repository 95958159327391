import React from 'react';
import { useSelector } from 'react-redux';
import { pathOr } from 'ramda';
import WageSchedules from '../WageSchedules';
import Preamble from './US/PA/Preamble';
import EmployerInformation from './US/PA/EmployerInformation';
import WorkforceNumbers from './US/PA/WorkforceNumbers';
import ApprenticeWageSchedule from './US/PA/ApprenticeWageSchedule';
import EqualEmployment from './US/PA/EqualEmployment';
import ApprenticeRatio from './US/PA/ApprenticeRatio';
import ApprenticeStandards from './US/PA/ApprenticeStandards';
import PageHeader from './US/PA/PageHeader';
import EmployerManagementApproval from './US/PA/EmployerManagementApproval';

const Form = ({
  employerAgreementId = '',
  data = {} // invite || doc
}) => {
  // Redux
  const organization = useSelector((state) => state.organization);
  const orgLocation = useSelector((state) => state.orgLocation);
  const apprenticeship = useSelector((state) => state.apprenticeship);
  const wageSchedulesList = useSelector((state) => state.wageSchedulesList);
  // Organization
  const orgName = pathOr(null, ['name'], organization);
  // Employer
  const addressState = pathOr('', ['addressState'], orgLocation);

  return (
    <div
      className='bg-white px-3 py-4 px-sm-5 py-sm-5 mt-3'
    >
      <PageHeader
        className='mb-3'
      />

      <Preamble
        programSponsorName={orgName}
        occupationName={apprenticeship?.occupationName}
      />

      <EmployerInformation />

      <WorkforceNumbers />

      {wageSchedulesList && (
        <ApprenticeWageSchedule
          className='m-0'
          wageSchedulesList={wageSchedulesList}
        />
      )}

      <PageHeader
        className='show-when-printing'
        pageBreakBefore
      />

      <WageSchedules
        className='mb-2'
        addressState={addressState}
      />

      <EqualEmployment />

      <ApprenticeRatio />

      <ApprenticeStandards />

      <PageHeader
        className='show-when-printing mb-3'
        pageBreakBefore
      />

      <EmployerManagementApproval
        employerAgreementId={employerAgreementId}
        data={data}
      />
    </div>
  );
};

export default Form;
