import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { pathOr } from 'ramda';
import Modal from '../../Modal';
import { resetCurrentModal } from '../../../actions';
import { sendEmailPathwayPrinted } from '../../../services/mailer';
import { SEND } from '../../../constants/icons';
import { isLocalhost } from '../../../serviceWorker';
import { saveJobProfile } from '../../../services/jobProfiles';

class PathwaysPrint extends Component {
  state = {
    key: 'pathwaysPrint',
    loading: false,
    ...this.props.currentModal.data
  }

  handleFieldChange = (e) => {
    const { name, value } = e.currentTarget;

    this.setState({
      [name]: value
    });
  }

  handleClose = () => {
    this.props.resetCurrentModal();
  }

  handleSuccess = (docId = null) => {
    const { handlePrinting } = this.state;

    handlePrinting(docId);
  }

  handleSubmit = (e) => {
    e.preventDefault();

    const {
      jobProfileId,
      orgId,
      OnetTitle,
      OccupationDescription,
      OnetCode,
      location,
      occupationLocation,
      selectedSkills,
      selectedKnowledge,
      selectedCerts,
      selectedSchoolPrograms
    } = this.state;
    const {
      email,
      name,
      phone,
      companyName
      // createApprenticeship,
      // createTraining,
      // trainingTimeline,
      // trainingBudget
    } = e.target.elements;

    const personalData = {
      email: email.value,
      name: name.value,
      phone: phone.value,
      companyName: companyName.value
    };

    const planData = {
      OnetTitle,
      OccupationDescription,
      OnetCode,
      location,
      occupationLocation,
      selectedSkills,
      selectedKnowledge,
      selectedCerts,
      selectedSchoolPrograms
    };

    const dataToSend = {
      ...personalData,
      ...planData
      // createApprenticeship: createApprenticeship.checked,
      // createTraining: createTraining.checked,
      // trainingTimeline: trainingTimeline.value,
      // trainingBudget: trainingBudget.value
    };

    const dataToSave = {
      // if no orgId, save personalData on jobProfile
      ...(orgId ? { orgId } : personalData),
      ...planData
    };

    this.setState({ loading: true });

    if (isLocalhost) console.log(dataToSend);

    if (jobProfileId) {
      // Pathway exists, just send email
      sendEmailPathwayPrinted({
        ...dataToSend,
        id: jobProfileId
      }).then(() => {
        // No need to include docId/jobProfileId as arg from handleSuccess
        // because the user is already on the JOB_PROFILE_PAGE route
        this.handleSuccess();
      }).catch((error) => {
        this.setState({ loading: false });
        toast.error('Unable to send plan, please refresh and try again ');
        console.error(error);
      });
    } else {
      // Save pathway, then send email
      saveJobProfile(dataToSave, 'jobProfiles').then((response) => {
        const docId = pathOr(null, ['docId'], response);

        sendEmailPathwayPrinted({
          ...dataToSend,
          id: docId
        }).then(() => {
          this.handleSuccess(docId);
        }).catch((error) => {
          this.setState({ loading: false });
          toast.error('Unable to send plan, please refresh and try again ');
          console.error(error);
        });
      }).catch((error) => {
        this.setState({ loading: false });
        toast.error('Unable to save & send plan, please refresh and try again.');
        console.error(error);
      });
    }
  }

  render() {
    const { currentModal } = this.props;
    const {
      key,
      OnetTitle,
      loading
    } = this.state;

    if (currentModal && !currentModal.visible) return null;
    if (currentModal.key !== key) return null;

    return (
      <Modal
        cssClassName={`turbine-modal--style-card turbine-modal--${key}`}
        visible={currentModal.key === key}
        close={this.handleClose}
        pos="top"
        theme="dark"
      >
        <div className="card border-0">
          <div className="card-header bg-dark text-white">
            <h1 className="h5 m-0">
              <i className={`${SEND} mr-1`} /> Share Training Plan
            </h1>
          </div>
          <form
            onSubmit={this.handleSubmit}
          >
            <div className="card-body">
              <h5 className="border-bottom pb-2 mb-3 font-weight-bold">
                {OnetTitle}
              </h5>

              <div className="mb-2">
                <label className="h6 font-weight-bold mb-1">
                  Name
                </label>
                <input
                  className="form-control"
                  name="name"
                  minLength="3"
                  required
                />
              </div>
              <div className="mb-2">
                <label className="h6 font-weight-bold mb-1">
                  Organization Name
                </label>
                <input
                  className="form-control"
                  name="companyName"
                  minLength="3"
                  required
                />
              </div>
              <div className="mb-2">
                <label className="h6 font-weight-bold mb-1">
                  Phone
                </label>
                <input
                  className="form-control"
                  name="phone"
                  minLength="7"
                  required
                />
              </div>
              <div className="mb-3">
                <label className="h6 font-weight-bold mb-1">
                  Email
                </label>
                <input
                  className="form-control"
                  name="email"
                  type="email"
                  required
                />
              </div>

              {/* <div className="mb-3 bg-light p-3">
                <label className="h6 font-weight-bold mb-1">
                  What is your intent with this Training Plan?
                </label>
                <div className="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="createApprenticeship"
                    name="createApprenticeship"
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="createApprenticeship"
                    style={{ fontSize: '1rem' }}
                  >
                    Create a registered apprenticeship
                  </label>
                </div>
                <div className="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="createTraining"
                    name="createTraining"
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="createTraining"
                    style={{ fontSize: '1rem' }}
                  >
                    Create a training program
                  </label>
                </div>
              </div> */}

              {/* <div className="mb-3">
                <label className="h6 font-weight-bold mb-1">
                  What is your timeline to begin training?
                </label>
                <select
                  className="custom-select d-block w-100"
                  id="trainingTimeline"
                  name="trainingTimeline"
                  required
                >
                  <option value="">Choose...</option>
                  <option>Immediately</option>
                  <option>Less than 6 months</option>
                  <option>6-12 months</option>
                </select>
              </div>
              <div className="mb-3">
                <label className="h6 font-weight-bold mb-1">
                  What's your training budget?
                </label>
                <select
                  className="custom-select d-block w-100"
                  id="trainingBudget"
                  name="trainingBudget"
                  required
                >
                  <option value="">Choose...</option>
                  <option>$5,000</option>
                  <option>Up to $15,000</option>
                  <option>Up to $25,000</option>
                  <option>More than $25,000</option>
                </select>
              </div> */}

            </div>
            <div className="card-footer">
              <button
                type="submit"
                className="btn btn-md btn-primary"
                disabled={loading}
              >
                <i className={`${SEND} mr-1`} /> {loading ? 'Sending...' : 'Send'}
              </button>
            </div>
          </form>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = ({
  currentModal,
  organization
}) => ({
  currentModal,
  organization
});

export default connect(mapStateToProps, {
  resetCurrentModal
})(PathwaysPrint);
