import { PDFDocument } from 'pdf-lib';
import download from 'downloadjs';
import { USER_CRIMINAL_BKG_ENUM, USER_EMPLOYMENT_ENUM } from '@apprentage/constants';
import { percentComplete } from '@apprentage/utils';
import { mmddyyyyDate } from '../../utils/date';
import { formatCurrency } from '../../utils/currency';
import { EMPTY_SIGNATURE, PDF_APPRENTICE_AGREEMENT } from '../../constants/assets';
import { decodePayload } from '../../services/payload';
import { fetchFileBuffer } from '../../services/aws';

// https://pdf-lib.js.org/docs/api/

const generatePDF = async ({
  orgId,
  formUrl = PDF_APPRENTICE_AGREEMENT,
  formName = 'Appendix B Template 2023 Apprentice Agreement.pdf',
  apprenticeship,
  apprenticeAgreement,
  apprenticeshipUser,
  employerAgreement,
  userProfile,
  orgLocation,
  defaultLocation,
  instructionProvider,
  wageSchedule,
  sponsorUser,
  autoDownload = false
}) => {
  const formPdfBytes = await fetch(formUrl).then((res) => res.arrayBuffer());
  // Load a PDF with form fields
  const pdfDoc = await PDFDocument.load(formPdfBytes);
  // Get Pages
  const pages = pdfDoc.getPages();
  // Get the form containing all the fields
  const form = pdfDoc.getForm();

  /**
   *
   * SIGNATURE IMAGES
   *
   * NOTE: EMPTY_SIGNATURE is a blank png which allows for significantly less conditional code
   *
   *
  */

  // Fetch Apprentice Signature Image
  const userSignatureImageUrl = apprenticeAgreement?.signerSignature || EMPTY_SIGNATURE;
  const userSignatureImageBytes = await fetchFileBuffer({ url: userSignatureImageUrl, orgId });
  // Fetch Sponsor Signature Image
  const sponsorSignatureImageUrl = sponsorUser?.userSignature || EMPTY_SIGNATURE;
  const sponsorSignatureImageBytes = await fetchFileBuffer({ url: sponsorSignatureImageUrl, orgId });

  // Fetch Employer Signature Image
  const employerSignatureImageUrl = employerAgreement?.signerSignature || EMPTY_SIGNATURE;
  const employerSignatureImageBytes = await fetchFileBuffer({ url: employerSignatureImageUrl, orgId });

  /**
   *
   * APPRENTICESHIP USER
   *
  */

  const userSignatureImage = await pdfDoc.embedPng(userSignatureImageBytes);
  // Get the width/height of the PNG image scaled down to 50% of its original size
  const userSignatureDimensions = userSignatureImage.scale(0.4);

  let ssn = apprenticeAgreement?.ssn || userProfile?.ssn || '';
  let ssn1 = '';
  let ssn2 = '';
  let ssn3 = '';

  if (ssn) {
    ssn = await decodePayload(ssn);
    ssn1 = ssn ? ssn.substring(0, 3) : '';
    ssn2 = ssn ? ssn.substring(4, 6) : '';
    ssn3 = ssn ? ssn.substring(7, 11) : '';
  }

  pages[0].drawImage(userSignatureImage, {
    x: 50,
    y: 210,
    width: userSignatureDimensions.width,
    height: userSignatureDimensions.height
  });
  // User Signature Date (Apprentice)
  if (apprenticeAgreement?.signerSignatureDate) {
    pages[0].drawText(mmddyyyyDate(new Date().toISOString()), {
      x: 220,
      y: 220,
      size: 10
    });
  }

  // Get all fields in the PDF by their names
  const firstNameField = form.getTextField('First');
  const middleInitialField = form.getTextField('Middle initial');
  const lastNameField = form.getTextField('Last');
  const ssn1Field = form.getTextField('3');
  const ssn2Field = form.getTextField('4');
  const ssn3Field = form.getTextField('5');
  const dobField = form.getTextField('3 DATE OF BIRTH mmddyyyy');
  const emailField = form.getTextField('AB1');
  const phoneField = form.getTextField('AB2');
  const addressApprenticeField = form.getTextField('6 ADDRESS OF APPRENTICE');
  const employmentStatusDropdown = form.getDropdown('ABdd1');
  const employmentStatusDateField = form.getTextField('Text5');
  const criminalBkgDropdown = form.getDropdown('ABdd2');

  // USER_GENDER_ENUM
  const genderEnum = {
    male: 'CB1',
    female: 'CB2',
    nonBinary: 'CB3',
    notListed: 'CB4',
    noAnswerPref: 'CB5'
  };
  const genderCheckBox = form.getCheckBox(genderEnum[userProfile?.gender]);
  // RACE
  const raceEnum = {
    americanIndianOrAlaskaNative: 'CB9',
    asian: 'CB10',
    blackOrAfricanAmerican: 'CB11',
    nativeHawaiianOrPacificIslander: 'CB12',
    white: 'CB13',
    noAnswer: 'CB14'
  };
  // ETHNICITY
  const ethnicityEnum = {
    hispanicOrLatino: 'CB6',
    notHispanicOrLatino: 'CB7',
    noAnswer: 'CB8'
  };
  const ethnicityCheckBox = form.getCheckBox(ethnicityEnum[userProfile?.ethnicity]);
  // VETERAN
  const veteranStatusEnum = {
    veteran: 'CB15',
    nonVeteran: 'CB16',
    noAnswer: 'CB17'
  };
  const veteranCheckBox = form.getCheckBox(veteranStatusEnum[userProfile?.veteranStatus]);
  // DISABILITY (Section A)
  const disabilitySectionAEnum = {
    yes: 'CB18',
    no: 'CB19',
    noAnswer: 'CB20'
  };
  const disabilitySectionACheckBox = form.getCheckBox(disabilitySectionAEnum[userProfile?.disability]);
  // EDUCATION
  const educationLevelEnum = {
    grade8OrLess: 'CB21',
    grade9To12: 'CB22',
    highSchoolOrGED: 'CB23',
    postSecondaryOrTechTraining: 'CB24',
    someCollege: 'CB25',
    associatesDegree: 'CB26',
    bachelorsDegree: 'CB27',
    mastersDegree: 'CB28'
  };
  const educationLevelCheckBox = form.getCheckBox(educationLevelEnum[userProfile?.educationLevel]);
  // ORG REFERRAL
  // TODO update constants object order to match
  const orgReferralEnum = {
    none: 'CB29',
    youthBuild: 'CB30',
    preApprenticeshipProgram: 'CB31',
    hudStepUp: 'CB32',
    technicalTrainingSchool: 'CB33',
    paCareerLink: 'CB34',
    militaryVeterans: 'CB35',
    highSchool: 'CB36',
    jobCorps: 'CB37',
    other: 'CB38'
  };
  const orgReferralCheckBox = form.getCheckBox(orgReferralEnum[userProfile?.orgReferral]);
  // CITIZEN STATUS
  const citizenStatusEnum = {
    usCitizen: 'CB39',
    permAlien: 'CB40',
    tempAlien: 'CB41',
    refugee: 'CB42',
    illegalAlien: 'CB43',
    nonUsCitizen: 'CB44',
    unknown: 'CB45'
  };
  const citizenStatusCheckBox = form.getCheckBox(citizenStatusEnum[userProfile?.citizenStatus]);
  // ADDRESS APPRENTICE
  const addressApprentice = [
    `${userProfile?.addressLine1}${userProfile?.addressLine2 ? `, ${userProfile?.addressLine2}` : ''}`,
    `${userProfile?.addressCity}, ${userProfile?.addressState} ${userProfile?.addressPostalCode} `
  ].join('\n');

  // NAME
  firstNameField.setText(apprenticeshipUser?.firstName);
  if (apprenticeshipUser?.middleName) {
    middleInitialField.setText(apprenticeshipUser?.middleName?.charAt(0));
  }
  lastNameField.setText(apprenticeshipUser?.lastName);
  // SSN
  ssn1Field.setText(ssn1);
  ssn2Field.setText(ssn2);
  ssn3Field.setText(ssn3);
  // GENDER
  dobField.setText(mmddyyyyDate(userProfile?.dob));
  // EMAIL / PHONE
  emailField.setText(apprenticeshipUser?.email);
  phoneField.setText(apprenticeshipUser?.mobilePhone || apprenticeshipUser?.phone);
  // Gender
  genderCheckBox.check();
  // RACE
  if (userProfile?.races && Array.isArray(userProfile.races) && userProfile.races.length !== 0) {
    userProfile.races.forEach((race) => {
      if (race && raceEnum[race] !== undefined) {
        form.getCheckBox(raceEnum[race]).check();
      }
    });
  }
  // ETHNICITY
  ethnicityCheckBox.check();
  // VETERAN
  veteranCheckBox.check();
  // DISABILITY (Section A)
  disabilitySectionACheckBox.check();
  // EDUCATION
  educationLevelCheckBox.check();
  // ORG REFERAL
  orgReferralCheckBox.check();
  // CITIZEN STATUS
  citizenStatusCheckBox.check();
  // ADDRESS APPRENTICE
  addressApprenticeField.setText(addressApprentice);
  // EMPLOYMENT STATUS
  if (USER_EMPLOYMENT_ENUM[userProfile?.employmentStatus] !== undefined) {
    employmentStatusDropdown.select(USER_EMPLOYMENT_ENUM[userProfile?.employmentStatus]);
  }
  if (userProfile?.employmentStatus === 'ue' && userProfile?.employmentStatusDate) {
    employmentStatusDateField.setText(mmddyyyyDate(userProfile?.employmentStatusDate));
  }
  // CRIMINAL BACKGROUND
  if (USER_CRIMINAL_BKG_ENUM[userProfile?.criminalBkg] !== undefined) {
    criminalBkgDropdown.select(USER_CRIMINAL_BKG_ENUM[userProfile?.criminalBkg]);
  }

  /**
 *
*
* EMPLOYER
*
*/

  // Log fields
  // const fields = form.getFields();
  // fields.forEach((field) => {
  //   // console.log('fieldRef', field.ref);
  //   const type = field.constructor.name;
  //   const name = field.getName();
  //   console.log(`${type}: ${name}`);
  // });

  // const options = employmentStatusDropdown.getOptions();
  // options.forEach((option) => {
  //   console.log('option', option);
  // });

  const sponsorSignatureImage = await pdfDoc.embedPng(sponsorSignatureImageBytes);
  // Get the width/height of the PNG image scaled down to 50% of its original size
  const sponsorSignatureDimensions = sponsorSignatureImage.scale(0.25);

  pages[1].drawImage(sponsorSignatureImage, {
    x: 50,
    y: 350,
    width: sponsorSignatureDimensions.width,
    height: sponsorSignatureDimensions.height
  });
  // Sponsor Signature Date (Apprentice)
  if (apprenticeAgreement?.signerSignatureDate) {
    pages[1].drawText(mmddyyyyDate(new Date().toISOString()), {
      x: 220,
      y: 355,
      size: 10
    });
  }

  const employerSignatureImage = await pdfDoc.embedPng(employerSignatureImageBytes);
  // Get the width/height of the PNG image scaled down to 50% of its original size
  const employerSignatureDimensions = employerSignatureImage.scale(0.25);

  pages[1].drawImage(employerSignatureImage, {
    x: 350,
    y: 350,
    width: employerSignatureDimensions.width,
    height: employerSignatureDimensions.height
  });
  // Employer Signature Date (Apprentice)
  if (apprenticeAgreement?.signerSignatureDate) {
    pages[1].drawText(mmddyyyyDate(new Date().toISOString()), {
      x: 520,
      y: 355,
      size: 10
    });
  }

  const journeyWorkerEntryWageField = form.getTextField('18  APPRENTICE ENDING HOURLY WAGE');
  const sponsorNameAddressField = form.getTextField('1  PROGRAM SPONSOR NAME  ADDRESS');
  const employerNameAddressField = form.getTextField('2  EMPLOYER NAME  ADDRESS');
  const occupationField = form.getTextField('3  OCCUPATION');
  const ojtHrsField = form.getTextField('4  TOTAL LENGTH OF OJT HOURS');
  const probationaryPeriodHrsField = form.getTextField('5  PROBATIONARY PERIOD HOURS');
  const prevJobTrainingHrsField = form.getTextField('6  CREDIT FOR PREVIOUS JOB TRAINING HOURS Maximum 80');
  const instructionProviderField = form.getTextField('7  RELATED TECHNICAL INSTRUCTION PROVIDER');
  const instructionHrsField = form.getTextField('8  TOTAL LENGTH OF INSTRUCTION HOURS');
  const wagesPaidDuringRTIEnum = {
    yes: 'CB46',
    no: 'CB47'
  };
  const wagesPaidDuringRTICheckBox = form.getCheckBox(wagesPaidDuringRTIEnum[userProfile?.wagesPaidDuringRTI || 'no']);
  const RTIDuringWorkHoursEnum = {
    yes: 'CB48',
    no: 'CB49'
  };
  const RTIDuringWorkHoursCheckBox = form.getCheckBox(RTIDuringWorkHoursEnum[userProfile?.RTIDuringWorkHours || 'no']);
  const prevInstructionHrsField = form.getTextField('11  CREDIT FOR PREVIOUS INSTRUCTION HOURS Maximum 100');
  const startDateField = form.getTextField('12  DATE APPRENTICESHIP BEGINS mmddyyyy');
  const endDateField = form.getTextField('13  EXPECTED COMPLETION DATE mmddyyyy');
  const priorHourlyWageField = form.getTextField('Prior');
  const apprenticeEntryHourlyWageField = form.getTextField('Entry');
  const disabilityEnum = {
    yes: 'CB50',
    no: 'CB51',
    noAnswer: 'CB52'
  };
  const disabilityCheckBox = form.getCheckBox(disabilityEnum[userProfile?.disability]);
  const disabilityName = form.getTextField('Text49p3');
  const disabilityDate = form.getTextField('Text50p3');

  // 1. PROGRAM SPONSOR NAME - ADDRESS
  const sponsorNameAddress = [
    defaultLocation?.name,
    `${defaultLocation?.addressLine1}${defaultLocation?.addressLine2 ? `, ${defaultLocation?.addressLine2}` : ''}`,
    `${defaultLocation?.addressCity}, ${defaultLocation?.addressState} ${defaultLocation?.addressPostalCode} `
  ].join('\n');
  sponsorNameAddressField.setText(sponsorNameAddress);
  // 2. EMPLOYER NAME - ADDRESS
  const employerNameAddress = [
    orgLocation?.name,
    `${orgLocation?.addressLine1}${orgLocation?.addressLine2 ? `, ${orgLocation?.addressLine2}` : ''}`,
    `${orgLocation?.addressCity}, ${orgLocation?.addressState} ${orgLocation?.addressPostalCode} `
  ].join('\n');
  employerNameAddressField.setText(employerNameAddress);
  // 3. OCCUPATION
  occupationField.setText(apprenticeship?.occupationName);
  // 4. TOTAL LENGTH OF OJT HOURS
  ojtHrsField.setText(String(apprenticeship?.ojtHours));
  // 5. PROBATIONARY PERIOD HOURS
  probationaryPeriodHrsField.setText('0'); // TODO make this dynamic
  // 6. CREDIT FOR PREVIOUS JOB TRAINING HOURS
  prevJobTrainingHrsField.setText('0'); // TODO make this dynamic
  // 7. RELATED TECHNICAL INSTRUCTION PROVIDER
  instructionProviderField.setText(instructionProvider?.name);
  // 8. TOTAL LENGTH OF  INSTRUCTION HOURS
  instructionHrsField.setText(String(apprenticeship?.instructionHours));
  // 9. WAGES PAID DURING RTI?
  wagesPaidDuringRTICheckBox.check();
  // 10. RTI DURING WORK HOURS?
  RTIDuringWorkHoursCheckBox.check();
  // 11. CREDIT FOR PREVIOUS INSTRUCTION HOURS
  prevInstructionHrsField.setText('0'); // TODO make this dynamic
  // 12. DATE APPRENTICESHIP BEGINS
  if (apprenticeship?.startDate) {
    startDateField.setText(mmddyyyyDate(apprenticeship?.startDate));
  }
  // 13. EXPECTED COMPLETION DATE
  if (apprenticeship?.expectedEndDate) {
    endDateField.setText(mmddyyyyDate(apprenticeship?.expectedEndDate));
  }
  // 14. PRIOR HOURLY WAGE
  priorHourlyWageField.setText('0');
  // 15. APPRENTICE ENTRY HOURLY WAGE
  // const journeyWorkerWage1 = form.getTextField(` of Journeyworker Wage${1}`);
  // const dollarAmount1 = form.getTextField('Dollar Amount1');
  // const durationHrs1 = form.getTextField('Duration Hours1');
  if (form && wageSchedule?.wageRates && Array.isArray(wageSchedule?.wageRates) && wageSchedule?.wageRates.length !== 0) {
    apprenticeEntryHourlyWageField.setText(String(wageSchedule?.wageRates[0].wageAmount));

    // 16.APPRENTICE WAGE PROGRESSION

    wageSchedule?.wageRates.forEach((wageRate, i) => {
      const wageRatePercentage = percentComplete({
        current: wageRate?.wageAmount,
        total: wageSchedule?.entryWage
      });

      const period = String(i + 1);

      let wagePercentId = ' of Journeyworker Wage';

      if (parseInt(period, 10) === 6) {
        // This field is an anomaly from the others, the PDF author
        // decided to exclude the space before "of" for Wage Period #6
        wagePercentId = 'of Journeyworker Wage';
      }

      wagePercentId += period;

      const wagePercentField = form.getTextField(wagePercentId);

      if (wagePercentField) {
        wagePercentField.setText(wageRatePercentage);
      }

      let dollarAmountId = 'Dollar Amount';
      dollarAmountId += period;
      const dollarAmountField = form.getTextField(dollarAmountId);

      if (dollarAmountField) {
        dollarAmountField.setText(formatCurrency(wageRate?.wageAmount));
      }

      let durationHrsId = 'Duration Hours';
      durationHrsId += period;
      const durationHrsField = form.getTextField(durationHrsId);

      if (durationHrsField) {
        durationHrsField.setText(String(wageRate?.duration));
      }
    });
  }

  // 17. JOURNEY WORKER ENTRY WAGE
  journeyWorkerEntryWageField.setText(String(wageSchedule?.entryWage)); // APPRENTICE ENDING HOURLY WAGE

  // 18. SIGNATURE OR PROGRAM SPONSOR
  // TODO

  // 19. SIGNATURE OR PROGRAM SPONSOR
  // TODO

  // DISABILITY
  disabilityCheckBox.check();
  disabilityName.setText(apprenticeAgreement?.signerName || '');

  if (apprenticeAgreement?.signerSignatureDate) {
    disabilityDate.setText(mmddyyyyDate(apprenticeAgreement?.signerSignatureDate));
  }

  // NOTE: Rogue Checkboxes that I couldn't determine what they did
  // PDFCheckBox: CB43 ?
  // PDFCheckBox: CB44 ?
  // PDFCheckBox: CB45 ?

  // PDFTextField:  of Journeyworker Wage1
  // PDFTextField: Dollar Amount1
  // PDFTextField: Duration Hours1

  // Serialize the PDFDocument to bytes (a Uint8Array)
  const pdfBytes = await pdfDoc.save();

  // Trigger the browser to download the PDF Document
  if (autoDownload) {
    download(pdfBytes, formName, 'application/pdf');
  }

  return pdfBytes;
};

export default generatePDF;
