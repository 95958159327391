import { FIREBASE_STORE_COLLECTION } from '../constants/api';
import * as twApi from './tw-api';

/**
 * @summary saves Document to firebase collection
 * @param {object} data
 * @param {string} collection
 */
export const createFirebaseEntry = ({ data, collection }) => {
  return new Promise((resolve, reject) => {
    return twApi.post(`${FIREBASE_STORE_COLLECTION}/${collection}`, '', JSON.stringify(data)).then((response) => {
      resolve(response);
    }).catch((error) => {
      console.error(error);
      reject(error);
    });
  });
};

/**
 * Get firebase Document by id
 *
 * @param {} params
 * @param {string} collection - firebase collection name
 * @param {string} id - firebase Document id
 * @param {object} data - firebase Document id
 * @returns {Promise<string>} - firebase Document object.
 */
export async function updateFirebaseEntry({ collection, id, data }) {
  const dataToSave = JSON.stringify({ id, ...data });
  const response = await twApi.put(`${FIREBASE_STORE_COLLECTION}/${collection}`, '', dataToSave);

  return response;
}

/**
 * Get firebase Document by id
 *
 * @param {} params
 * @param {string} collection - firebase collection name
 * @param {string} id - firebase Document id
 * @returns {Promise<string>} - firebase Document object.
 */
export async function getFirebaseEntry({ collection, id }) {
  const response = await twApi.get(`/v1/external/firebase/store/${collection}`, { id });

  return {
    ...response,
    id
  };
}

/**
 * Delete firebase Document by id
 *
 * @param {} params
 * @param {string} collection - firebase collection name
 * @param {string} id - firebase Document id
 * @returns {Promise<string>}
 */
export async function deleteFirebaseEntry({ collection, id }) {
  if (!id) {
    return false;
  }

  const response = await twApi.requestDelete(`${FIREBASE_STORE_COLLECTION}/${collection}`, { id });

  return response;
}

/**
 * Get firebase documents
 *
 * @param {} params
 * @param {string} collection - firebase collection name
 * @param {string} orgId - organization id (contentful)
 * @returns {Promise<string>} - firebase Document object.
 */
export async function getFirebaseEntries({ collection, id, orgId }) {
  const query = {};

  if (id) query.id = id;
  if (orgId) query.orgId = orgId;

  const response = await twApi.get(`/v1/external/firebase/store/${collection}`, query);

  return response;
}

/**
 * [NEW] Get firebase entries
 *
 * @param {} params
 * @param {string} params.collection - firebase collection name
 * @param {string} params.ids - comma separate string of IDs
 *
 * Field queries with operators: (up to 5 supported)
 * @param {string} ['f.locationId[eq]']
 * @param {string} ['f.apprenticeshipId[eq]']
 *
 * @returns {Promise<string>} - firebase documents
 */
export async function getFirebaseEntriesNew(params) { // fields
  const { collection, ...remainingParams } = params;
  let query = {
    ...remainingParams
  };
  /**
   * Docs: https://github.com/upstreamcoding/lms-api/pull/75
   *
   * Operators:
   * 'lt': '<',
   * 'lte': '<=',
   * 'gt': '>',
   * 'eq': '==',
   * 'gte': '>=',
   * 'ne': '!=',
   * 'in': 'in',
   * 'nin': 'not-in',
   * 'ac': 'array-contains',
   * 'aca': 'array-contains-any',
   *
   * Ex: config['f.locationId[eq]'] = locationId;
   * Ex: config['f.apprenticeshipId[eq]'] = apprenticeshipId;
   *
   */

  if (remainingParams?.ids) {
    query = {}; // ids take precedence over all other queries
    query.ids = remainingParams?.ids;
  }

  const response = await twApi.get(`/v1/external/firebase/store/entries/${collection}`, query);

  return response;
}
