import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withFirebase } from '../Firebase';
import { setLoading } from '../../actions/Session';
import { getOrg, resetOrg } from '../../actions/Organizations';
import {
  setUser, resetUser, setCurrentUser
} from '../../actions/Users';
import {
  setUserAppVersion,
  getUserAppVersion,
  getUserLoggedIn,
  rmUserLoggedIn
} from '../../services/organizations';
import { TURBINE_APP_VERSION } from '../../constants/globals';
import { resetLocations } from '../../actions/Locations';

const withAuthentication = (Component) => {
  class WithAuthentication extends React.Component {
    componentDidMount() {
      const { firebase } = this.props;

      this.props.setLoading(true);

      this.listener = firebase.onAuthUserListener((response) => {
        localStorage.setItem('authUser', JSON.stringify(response.authUser));

        const orgId = response?.currentUser?.orgId || null;

        this.props.setUser(response.authUser);
        this.props.getOrg({ orgId }).then(() => {
          this.props.setCurrentUser(response.currentUser);
          this.props.setLoading(false);
        });

        // Cookie & App Version
        const userLoggedIn = getUserLoggedIn();
        const userAppVersion = getUserAppVersion();

        if (userLoggedIn) {
          rmUserLoggedIn();

          if (!userAppVersion) {
            setUserAppVersion();
          }
        } else if (userAppVersion !== TURBINE_APP_VERSION) {
          setUserAppVersion();
        }
        // Cookie & App Version
      }, () => {
        this.props.setLoading(false);
        localStorage.removeItem('authUser');
        localStorage.removeItem('turbine_token');
        this.props.resetUser();
        this.props.resetOrg();
        this.props.resetLocations();
      });
    }

    componentWillUnmount() {
      this.listener();
    }

    render() {
      return <Component {...this.props} />;
    }
  }

  return compose(
    withFirebase,
    connect(null, {
      setUser,
      resetUser,
      setCurrentUser,
      setLoading,
      getOrg,
      resetOrg,
      resetLocations
    })
  )(WithAuthentication);
};

export default withAuthentication;
