// FILES
export const SET_CURRENT_FILES = 'SET_CURRENT_FILES';
export const RESET_CURRENT_FILES = 'RESET_CURRENT_FILES';

// Current User (contentful)
export const SET_CURRENT_USER = 'SET_CURRENT_USER';

// User (firebase)
export const SET_USER = 'SET_USER';
export const RESET_USER = 'RESET_USER';
export const SET_PHOTO_URL = 'SET_PHOTO_URL';
export const SET_DISPLAY_NAME = 'SET_DISPLAY_NAME';

// COURSES
export const SET_COURSES = 'SET_COURSES';
export const RESET_COURSES = 'RESET_COURSES';

// PATHWAYS
export const SET_PATHWAY = 'SET_PATHWAY';
export const RESET_PATHWAY = 'RESET_PATHWAY';

// JOB PROFILES
export const SET_JOB_PROFILE = 'SET_JOB_PROFILE';
export const RESET_JOB_PROFILE = 'RESET_JOB_PROFILE';
export const SET_JOB_PROFILES = 'SET_JOB_PROFILES';
export const RESET_JOB_PROFILES = 'RESET_JOB_PROFILES';

// Class
export const SET_CURRENT_CLASS = 'SET_CURRENT_CLASS';
export const RESET_CURRENT_CLASS = 'RESET_CURRENT_CLASS';
export const SET_CURRENT_CLASS_EDIT = 'SET_CURRENT_CLASS_EDIT';
export const RESET_CURRENT_CLASS_EDIT = 'RESET_CURRENT_CLASS_EDIT';

export const SET_CURRENT_ENTRY_SUCCESS = 'SET_CURRENT_ENTRY_SUCCESS';
export const SET_CURRENT_ENTRY_EDIT = 'SET_CURRENT_ENTRY_EDIT';

export const UPDATE_CURRENT_ENTRY_SUCCESS = 'UPDATE_CURRENT_ENTRY_SUCCESS';
export const RESET_CURRENT_ENTRY = 'RESET_CURRENT_ENTRY';
export const RESET_CURRENT_ENTRY_EDIT = 'RESET_CURRENT_ENTRY_EDIT';

// TOPIC
export const SET_CURRENT_TOPIC = 'SET_CURRENT_TOPIC';
export const RESET_CURRENT_TOPIC = 'RESET_CURRENT_TOPIC';

// COHORT
export const SET_COHORTS = 'SET_COHORTS';
export const RESET_COHORTS = 'RESET_COHORTS';
export const SET_CURRENT_COHORT = 'SET_CURRENT_COHORT';
export const RESET_CURRENT_COHORT = 'RESET_CURRENT_COHORT';

export const DELETE_ENTRY_SUCCESS = 'DELETE_ENTRY_SUCCESS';

// ORG
export const SET_ORGS = 'SET_ORGS';
export const SET_ORG = 'SET_ORG';
export const RESET_ORG = 'RESET_ORG';
export const SET_ORG_EDIT = 'SET_ORG_EDIT';
export const RESET_ORG_EDIT = 'RESET_ORG_EDIT';
export const SET_PAGE_ORG = 'SET_PAGE_ORG';
export const RESET_PAGE_ORG = 'RESET_PAGE_ORG';

// LOCATIONS
export const SET_LOCATIONS = 'SET_LOCATIONS';
export const RESET_LOCATIONS = 'RESET_LOCATIONS';
export const SET_LOCATION = 'SET_LOCATION';
export const RESET_LOCATION = 'RESET_LOCATION';

// EMPLOYERS (Locations)
export const SET_EMPLOYER = 'SET_EMPLOYER';
export const RESET_EMPLOYER = 'RESET_EMPLOYER';
export const SET_EMPLOYER_PROFILE = 'SET_EMPLOYER_PROFILE';
export const RESET_EMPLOYER_PROFILE = 'RESET_EMPLOYER_PROFILE';

// Toasts
export const SET_TOASTS = 'SET_TOASTS';

// AUTH
export const SET_AUTH_ACTION = 'SET_AUTH_ACTION';
export const RESET_AUTH_ACTION = 'RESET_AUTH_ACTION';
export const SET_AUTH_ALERT = 'SET_AUTH_ALERT';
export const RESET_AUTH_ALERT = 'RESET_AUTH_ALERT';

export const SET_SIGNUP = 'SET_SIGNUP';
export const RESET_SIGNUP = 'RESET_SIGNUP';

// Session
export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE';
export const SET_LOADING = 'SET_LOADING';

export const LOGOUT = 'LOGOUT';

// MODALS
export const SET_CURRENT_MODAL = 'SET_CURRENT_MODAL';
export const RESET_CURRENT_MODAL = 'RESET_CURRENT_MODAL';

// APPRENTICESHIPS
export const SET_APPRENTICESHIP = 'SET_APPRENTICESHIP';
export const RESET_APPRENTICESHIP = 'RESET_APPRENTICESHIP';

export const SET_WAGE_SCHEDULES = 'SET_WAGE_SCHEDULES';
export const RESET_WAGE_SCHEDULES = 'RESET_WAGE_SCHEDULES';

export const SET_INVITE = 'SET_INVITE';
export const RESET_INVITE = 'RESET_INVITE';

export const SET_DOCUMENT = 'SET_DOCUMENT';
export const RESET_DOCUMENT = 'RESET_DOCUMENT';

export const SET_EMPLOYER_AGREEMENT = 'SET_EMPLOYER_AGREEMENT';
export const RESET_EMPLOYER_AGREEMENT = 'RESET_EMPLOYER_AGREEMENT';

// Apprentice Agreement
export const SET_DEFAULT_LOCATION = 'SET_DEFAULT_LOCATION';
export const RESET_DEFAULT_LOCATION = 'RESET_DEFAULT_LOCATION';

export const SET_APPRENTICE_AGREEMENT = 'SET_APPRENTICE_AGREEMENT';
export const RESET_APPRENTICE_AGREEMENT = 'RESET_APPRENTICE_AGREEMENT';

export const SET_INSTRUCTION_PROVIDER = 'SET_INSTRUCTION_PROVIDER';
export const RESET_INSTRUCTION_PROVIDER = 'RESET_INSTRUCTION_PROVIDER';

export const SET_WAGE_SCHEDULE = 'SET_WAGE_SCHEDULE';
export const RESET_WAGE_SCHEDULE = 'RESET_WAGE_SCHEDULE';

export const SET_USER_PROFILE = 'SET_USER_PROFILE';
export const RESET_USER_PROFILE = 'RESET_USER_PROFILE';

export const SET_APPRENTICESHIP_USER = 'SET_APPRENTICESHIP_USER';
export const RESET_APPRENTICESHIP_USER = 'RESET_APPRENTICESHIP_USER';

export const SET_SPONSOR_USER = 'SET_SPONSOR_USER';
export const RESET_SPONSOR_USER = 'RESET_SPONSOR_USER';

// INTEGRATIONS
export const SET_INTEGRATION = 'SET_INTEGRATION';
export const RESET_INTEGRATION = 'RESET_INTEGRATION';

// SEARCH
export const SET_SEARCH_USER_FEEDBACK = 'SET_SEARCH_USER_FEEDBACK';
