import { fetchWageSchedule, fetchWageSchedules } from '../services/wageSchedules';
import {
  RESET_WAGE_SCHEDULE,
  RESET_WAGE_SCHEDULES,
  SET_WAGE_SCHEDULE,
  SET_WAGE_SCHEDULES
} from './types';

export const getWageSchedules = ({
  ids,
  apprenticeshipId,
  employerProfileId
}) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      fetchWageSchedules({
        ids,
        apprenticeshipId,
        employerProfileId
      }).then((wageSchedulesList) => {
        dispatch({ type: SET_WAGE_SCHEDULES, wageSchedulesList });
        resolve(wageSchedulesList);
      }).catch((error) => {
        console.error(error);
        reject(error);
      });
    });
  };
};

export const getWageSchedule = (id) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      fetchWageSchedule(id).then((wageSchedule) => {
        dispatch({ type: SET_WAGE_SCHEDULE, wageSchedule });

        resolve(wageSchedule);
      }).catch((error) => {
        console.error(error);
        reject(error);
      });
    });
  };
};

export const resetWageSchedule = () => {
  return (dispatch) => {
    dispatch({ type: RESET_WAGE_SCHEDULE });
  };
};

export const resetWageSchedules = () => {
  return (dispatch) => {
    dispatch({ type: RESET_WAGE_SCHEDULES });
  };
};
