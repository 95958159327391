import React, { useEffect, useState } from 'react';
import { reactSelectDefaultValues, reactSelectOptionsFromEnum } from '@apprentage/utils';
import { SelectMenu } from '@apprentage/components';
import { apprenticeshipIndustryCodeEnum, apprenticeshipTypesEnum } from '../../parsers/trainingProgram';
import { getOccupation } from '../../../../services/careerOneStop';
import Loading from '../../../Loading';

const StepOne = ({ data, onSubmit }) => {
  const [loading, setLoading] = useState(true);
  const [title, setTitle] = useState(data.title || '');
  const [occupationName, setOccupationName] = useState(data.occupationName || '');
  const [description, setDescription] = useState(data.description || '');
  const [type, setType] = useState(data.type || '');
  const [estimatedLength, setEstimatedLength] = useState(data.estimatedLength || '');
  const [occupationONetCode, setOccupationONetCode] = useState(data.occupationONetCode || '');
  const [rapidsCode, setRapidsCode] = useState(data.rapidsCode || '');
  const [industryCode, setIndustryCode] = useState('');
  const [instructionHours, setInstructionHours] = useState(495);
  const [ojtHours, setOjtHours] = useState(2700);

  // Type
  const apprenticeshipTypeOptions = reactSelectOptionsFromEnum(apprenticeshipTypesEnum);
  const apprenticeshipTypeDefaultValue = reactSelectDefaultValues([type], apprenticeshipTypeOptions);
  // Industry
  const industryCodeOptions = reactSelectOptionsFromEnum(apprenticeshipIndustryCodeEnum);
  const industryCodeDefaultValue = reactSelectDefaultValues([industryCode], industryCodeOptions);

  const handleOnSubmit = (e) => {
    e.preventDefault();

    onSubmit({
      title,
      description,
      type,
      occupationONetCode,
      rapidsCode,
      estimatedLength,
      occupationName,
      instructionHours,
      ojtHours,
      industryCode
    });
  };

  useEffect(() => {
    if (occupationONetCode && !occupationName) {
      setLoading(true);
      getOccupation({ keyword: occupationONetCode, location: 0 }).then((response) => {
        if (response?.OccupationDetail && response?.OccupationDetail?.OnetTitle) {
          setOccupationName(response.OccupationDetail.OnetTitle);
        }
        setLoading(false);
      });
      return;
    }

    setLoading(false);
  }, [occupationONetCode]);

  if (loading) {
    return (
      <Loading text="Fetching Occupation Info..." />
    );
  }

  return (
    <div className='bg-white rounded mt-3'>
      <form
        onSubmit={handleOnSubmit}
        className='card'
      >
        <div className='card-header'>
          <h4 className='m-0'>
            1. Review Data
          </h4>
        </div>
        <div className='card-body'>
          <div className='mb-3'>
            <label>
              <strong>Program Title</strong> <span className="text-danger">*</span>
            </label>
            <input
              className='form-control'
              value={title}
              type="text"
              required
              onChange={(e) => {
                const { value } = e.currentTarget;
                setTitle(value);
              }}
            />
          </div>

          <div className='mb-3'>
            <label>
              <strong>Description</strong> <span className="text-danger">*</span>
            </label>
            <textarea
              className='form-control'
              value={description}
              type="text"
              required
              onChange={(e) => {
                const { value } = e.currentTarget;
                setDescription(value);
              }}
            />
          </div>

          <div className='mb-3'>
            <label>
              <strong>Industry</strong> <span className="text-danger">*</span>
            </label>
            <SelectMenu
              name="industryCode"
              options={industryCodeOptions}
              defaultValue={industryCodeDefaultValue}
              required
              onChange={({ value }) => {
                setIndustryCode(value);
              }}
            />
          </div>

          <div className='mb-3'>
            <label>
              <strong>Type</strong> <span className="text-danger">*</span>
            </label>
            <SelectMenu
              name="type"
              options={apprenticeshipTypeOptions}
              defaultValue={apprenticeshipTypeDefaultValue}
              required
              onChange={({ value }) => {
                setType(value);
              }}
            />
          </div>

          <div className='mb-3'>
            <label>
              <strong>Estimated Program Length</strong> <span className="text-danger">*</span>
            </label>
            <input
              className='form-control'
              value={estimatedLength}
              type="text"
              required
              onChange={(e) => {
                const { value } = e.currentTarget;
                setEstimatedLength(value);
              }}
            />
          </div>

          <div className='row mb-3'>
            <div className='col-6'>
              <div>
                <label>
                  <strong>Instruction Hours</strong> <span className="text-danger">*</span>
                </label>
                <input
                  className='form-control'
                  value={instructionHours}
                  type="number"
                  required
                  onChange={(e) => {
                    const { valueAsNumber } = e.currentTarget;
                    setInstructionHours(valueAsNumber);
                  }}
                />
              </div>
            </div>
            <div className='col-6'>
              <div>
                <label>
                  <strong>OJT Hours</strong> <span className="text-danger">*</span>
                </label>
                <input
                  className='form-control'
                  value={ojtHours}
                  type="number"
                  required
                  onChange={(e) => {
                    const { valueAsNumber } = e.currentTarget;
                    setOjtHours(valueAsNumber);
                  }}
                />
              </div>
            </div>
          </div>

          <div className='mb-3'>
            <label>
              <strong>Occupation Name</strong> <span className="text-danger">*</span>
            </label>
            <input
              className='form-control'
              value={occupationName}
              type="text"
              required
              disabled={occupationName}
              onChange={(e) => {
                const { value } = e.currentTarget;
                setOccupationName(value);
              }}
            />
          </div>

          <div className='row mb-3'>
            <div className='col-6'>
              <div>
                <label>
                  <strong>RAPIDS Code</strong> <span className="text-danger">*</span>
                </label>
                <input
                  className='form-control'
                  value={rapidsCode}
                  type="text"
                  disabled={rapidsCode}
                  required
                  onChange={(e) => {
                    const { value } = e.currentTarget;
                    setRapidsCode(value);
                  }}
                />
              </div>
            </div>
            <div className='col-6'>
              <div>
                <label>
                  <strong>O*NET Code</strong> <span className="text-danger">*</span>
                </label>
                <input
                  className='form-control'
                  value={occupationONetCode}
                  type="text"
                  disabled={occupationONetCode}
                  required
                  onChange={(e) => {
                    const { value } = e.currentTarget;
                    setOccupationONetCode(value);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className='card-footer'>
          <button
            className='btn btn-md btn-primary'
            type='submit'
          >
            Next
          </button>
        </div>
      </form>
    </div>
  );
};

export default StepOne;
