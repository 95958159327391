import { pathOr } from 'ramda';
import * as twApi from './tw-api';
import sortArrByNumericKey from '../utils/sortArrByNumericKey';

/**
 * Gets Occupations by keyword
 * @param {string} keyword - occupation user is searching for
 * @param {number} limit - max number of results
 * @returns {Promise<object>}
 */
export async function getOccupations({
  keyword,
  limit = 20
}) {
  const response = await twApi.get('/v1/external/cos/occupations', {
    keyword,
    limit
  });

  return response;
}
/**
 * Gets Occupations by keyword
 * @param {string} keyword - OnetCode
 * @param {string} location - PA, Pittsburgh, PA, zipcode
 * @param {wages} bool - show wages associated with occupation
 * @returns {Promise<object>}
 */
export async function getOccupation({
  keyword,
  location,
  wages = true,
  training = true
}) {
  const response = await twApi.get('/v1/external/cos/occupation', {
    keyword,
    location,
    wages,
    training
  });
  const { OccupationDetail, ...remainingResponse } = response;
  const occupationData = response && response.OccupationDetail ? OccupationDetail[0] : null;
  const knowledgeData = pathOr(null, ['KnowledgeDataList'], occupationData);
  const skillsData = pathOr(null, ['SkillsDataList'], occupationData);
  const sortedKnowledge = knowledgeData ? sortArrByNumericKey(knowledgeData, 'Importance') : null;
  const sortedSkills = skillsData ? sortArrByNumericKey(skillsData, 'Importance') : null;
  const {
    KnowledgeDataList, SkillsDataList, Wages, ...remainingKeys
  } = occupationData;

  const data = {
    ...remainingResponse,
    KnowledgeDataList: sortedKnowledge,
    SkillsDataList: sortedSkills,
    Wages,
    OccupationDetail: {
      ...remainingKeys
    }
  };

  return data;
}

/**
 * Gets Training Programs by keyword
 * @param {string} keyword - OnetCode
 * @param {string} location - PA, Pittsburgh, PA, zipcode
 * @returns {Promise<object>}
 */
export async function getTrainingProgramsByKeyword({
  keyword,
  location
}) {
  const response = await twApi.get('/v1/external/cos/training', {
    keyword,
    location
  });

  return response;
}

/**
 * Gets Certifications by keyword
 * @param {string} keyword - OnetCode
 * @returns {Promise<object>}
 */
export async function getCertificationsByKeyword({
  keyword
}) {
  const response = await twApi.get('/v1/external/cos/certificationfinder', {
    keyword
  });

  return response;
}
