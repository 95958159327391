const generateWebManifest = ({
  orgName,
  androidIconSmall,
  androidIconLarge
}) => {
  const manifest = document.getElementById('dynamic-manifest');
  const dynamicManifest = {
    name: orgName || 'Turbine Workforce',
    short_name: orgName || 'Turbine',
    icons: [
      {
        src: androidIconSmall,
        sizes: '192x192',
        type: 'image/png'
      },
      {
        src: androidIconLarge,
        sizes: '512x512',
        type: 'image/png'
      }
    ],
    theme_color: '#ffffff',
    background_color: '#ffffff',
    display: 'standalone'
  };

  const stringManifest = JSON.stringify(dynamicManifest);
  const blob = new Blob([stringManifest], { type: 'application/json' });
  const manifestURL = URL.createObjectURL(blob);

  manifest.setAttribute('href', manifestURL);
  manifest.removeAttribute('id');
};

export default generateWebManifest;
