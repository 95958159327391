import { fetchSupabaseEntries } from './supabaseProxy';

// ROLES
const isSuperAdmin = (role) => {
  return (role.indexOf('superAdmin') !== -1);
};

const isOwner = (role) => {
  return (role.indexOf('owner') !== -1);
};

const isAdmin = (role) => {
  return (role.indexOf('admin') !== -1);
};

const isTeacher = (role) => {
  return (role.indexOf('teacher') !== -1);
};

export const roleReadable = ({ role, orgType }) => {
  switch (true) {
    case isSuperAdmin(role):
      return 'Super Admin';
    case isOwner(role):
      return 'Owner';
    case isAdmin(role):
      return 'Admin';
    case isTeacher(role):
      return orgType !== 'workforce' ? 'Teacher' : 'Expert';
    default:
      return orgType !== 'workforce' ? 'Student' : 'User';
  }
};

// PERMISSIONS
export const canBypassCourseEnroll = (course = {}) => {
  if (course?.id && course?.courseReadOnly) {
    return true;
  }

  return false;
};

export const canDeleteEntry = (role) => {
  return (isAdmin(role) || isOwner(role) || isSuperAdmin(role));
};

export const canDeleteFile = (role) => {
  return (isAdmin(role) || isOwner(role) || isSuperAdmin(role));
};

export const canInviteUser = (role) => {
  return (isOwner(role) || isSuperAdmin(role));
};

export const canSeeTestUsers = (role) => {
  return (isAdmin(role) || isOwner(role) || isSuperAdmin(role));
};

export const canDirectMessageUser = (role) => {
  return (isTeacher(role) || isAdmin(role) || isOwner(role) || isSuperAdmin(role));
};

export const canManageContent = (role) => {
  return (isTeacher(role) || isAdmin(role) || isOwner(role) || isSuperAdmin(role));
};

export const canManageAssessments = (role) => {
  return (isTeacher(role) || isAdmin(role) || isOwner(role) || isSuperAdmin(role));
};

export const canAccessTurbineConsole = (role) => {
  return (isSuperAdmin(role));
};

// PERMISSIONS (billing)
export const canConnectStripe = (role) => {
  return (isOwner(role) || isSuperAdmin(role));
};

// /PERMISSIONS
export const classIdsUserIsEnrolled = (response) => {
  if (!response.fields.classes) {
    return [];
  }

  return response.fields.classes.map((course) => course.sys.id);
};

export const canAccessByRole = (role, roles) => {
  function evalRoles(arrayItem) { // index, array
    return role.includes(arrayItem);
  }

  if (isOwner(role) || isSuperAdmin(role)) {
    return true;
  } if (roles === undefined) {
    return true;
  } if (roles.some(evalRoles)) {
    return true;
  }

  return false;
};

// TODO move to user service
export const isActiveMembership = (membership) => {
  return membership.indexOf('inactive') === -1;
};

export const membershipReadable = (membership) => { // TODO switch this to one value in content model.
  switch (true) {
    case membership.includes('inactive'):
      return 'inactive';
    case membership.includes('pro'):
      return 'pro';
    case membership.includes('premium'):
      return 'premium';
    case membership.includes('starter'):
      return 'starter';
    default:
      return membership[0];
  }
};

export const fetchUser = async ({ userId, select }) => {
  const errorUserIdRequired = 'userId is required';

  if (!userId) {
    console.error(errorUserIdRequired);
    throw new Error(errorUserIdRequired);
  }

  try {
    const params = { id: userId };

    if (Array.isArray(select) && select.length) {
      params.select = select.join(',');
    }

    const response = await fetchSupabaseEntries(params, 'users');
    const items = response?.items;
    const user = Array.isArray(items) && items[0] !== undefined ? items[0] : null;
    return user;
  } catch (error) {
    console.error(error);
    throw new Error(error);
  }
};
