import React, { useEffect, useState } from 'react';
import { pathOr } from 'ramda';
import { useDispatch } from 'react-redux';
import OccupationElement from './OccupationElement';
import OccupationCertifications from './OccupationCertifications';
import OccupationSchoolPrograms from './OccupationSchoolPrograms';
import Header from './Header';
import Overview from './Overview';
import Location from './Location';
import { resetCurrentModal, setCurrentModal } from '../../actions';

const Occupation = ({
  hideHeaderTitle,
  handleSearchOccupation,
  occupationLocation,
  occupation,
  occupationDetail,
  knowledgeDataList,
  skillsDataList,
  // wages,
  certifications,
  schoolPrograms,
  resetOccupation,
  className = '',
  selectedSkills,
  selectedKnowledge,
  selectedCerts,
  selectedSchoolPrograms,
  setSelectedSkills,
  setSelectedKnowledge,
  setSelectedCerts,
  setSelectedSchoolPrograms,
  // Firebase
  OnetTitle: FBOnetTitle,
  OnetCode: FBOnetCode,
  OccupationDescription: FBOccupationDescription,
  // selectedKnowledge,
  // selectedSchoolPrograms,
  location: FBlocation,
  viewOnly
}) => {
  const [canPrint] = useState(false);
  const [editTitle, setEditTitle] = useState('');
  const [editSkills, setEditSkills] = useState(false);
  const [editKnowledge, setEditKnowledge] = useState(false);
  const [editCerts, setEditCerts] = useState(false);
  const [editSchoolPrograms, setEditSchoolPrograms] = useState(false);
  const [mustSelectOne, setMustSelectOne] = useState(true);
  const [editDataList, setEditDataList] = useState(null);
  const OnetTitle = pathOr(null, ['OnetTitle'], occupation) || FBOnetTitle;
  const OccupationDescription = pathOr(null, ['OccupationDescription'], occupation) || FBOccupationDescription;
  const OnetCode = pathOr(null, ['OnetCode'], occupation) || FBOnetCode;
  // const BrightOutlook = pathOr(null, ['BrightOutlook'], occupationDetail);
  // const BrightOutlookCategory = pathOr(null, ['BrightOutlookCategory'], occupationDetail);
  // const Green = pathOr(null, ['Green'], occupationDetail);
  // const SocInfo = pathOr(null, ['SocInfo'], occupationDetail);
  const location = pathOr(null, ['Location'], occupationDetail) || FBlocation;
  const topSkills = skillsDataList && skillsDataList.slice(0, 5);
  const topKnowledge = knowledgeDataList && knowledgeDataList.slice(0, 5);

  const dispatch = useDispatch();

  const openEditOccupationModal = () => {
    dispatch(setCurrentModal({
      key: 'editOccupation',
      data: {
        title: editTitle,
        mustSelectOne,
        dataList: editDataList,
        saveDataList,
        currentData: getCurrentData(),
        handleCancel: () => {
          dispatch(resetCurrentModal());
          cancelChooseElements();
        }
      }
    }));
  };

  const openManageSkillModal = () => {
    const config = {
      key: 'manageSkill',
      data: {
        handleSave: (newSkills) => {
          saveDataList(newSkills, 'skill');
        },
        currentData: getCurrentData('skill')
      }
    };

    dispatch(setCurrentModal(config));
  };

  const openManageCertificateModal = () => {
    const config = {
      key: 'manageCertificate',
      data: {
        handleSave: (newCertificates) => {
          saveDataList(newCertificates, 'certificate');
        },
        currentData: getCurrentData('certificate')
      }
    };

    dispatch(setCurrentModal(config));
  };

  useEffect(() => {
    if (editDataList) {
      openEditOccupationModal();
    }
  }, [editDataList]);

  const handleChooseElements = ({ data, title }) => {
    setEditTitle(title);
    setEditDataList(data);
  };

  const cancelChooseElements = () => {
    setEditTitle('');
    setEditDataList(null);
    setEditSkills(false);
    setEditKnowledge(false);
    setEditCerts(false);
    setEditSchoolPrograms(false);
  };

  const saveDataList = (data, dataType) => {
    if (editSkills || dataType === 'skill') {
      setSelectedSkills(data);
      setEditSkills(false);
    }
    if (editKnowledge) {
      setSelectedKnowledge(data);
      setEditKnowledge(false);
    }

    if (editCerts || dataType === 'certificate') {
      setSelectedCerts(data);
      setEditCerts(false);
    }

    if (editSchoolPrograms) {
      setSelectedSchoolPrograms(data);
      setEditSchoolPrograms(false);
    }

    setEditTitle('');
    setEditDataList(null);
  };

  const getCurrentData = (dataType) => {
    if (editSkills || dataType === 'skill') {
      return selectedSkills || topSkills;
    }

    if (editKnowledge) {
      return selectedKnowledge || topKnowledge;
    }

    if (editCerts || dataType === 'certificate') {
      return selectedCerts || certifications;
    }

    if (editSchoolPrograms) {
      return selectedSchoolPrograms || schoolPrograms;
    }
  };

  return (
    <div className={`container bg-white shadow rounded border ${className} ${canPrint ? '' : 'd-print-none'}`}>
      <Header
        hideHeaderTitle={hideHeaderTitle}
        OnetTitle={OnetTitle}
        OnetCode={OnetCode}
        OccupationDescription={OccupationDescription}
        selectedSkills={selectedSkills || topSkills}
        selectedKnowledge={selectedKnowledge || topKnowledge}
        selectedCerts={selectedCerts || certifications}
        selectedSchoolPrograms={selectedSchoolPrograms || schoolPrograms}
        location={location}
        occupationLocation={occupationLocation}
        viewOnly={viewOnly}
      />

      <div className="row">
        <div className="col-sm-12 col-md-7 col-lg-8 col-xl-9 mb-4">
          <Overview
            OccupationDescription={OccupationDescription}
            OnetTitle={OnetTitle}
            skillsDataList={skillsDataList}
            knowledgeDataList={knowledgeDataList}
            schoolPrograms={schoolPrograms}
            certifications={certifications}
            resetOccupation={resetOccupation}
            viewOnly={viewOnly}
          />
        </div>

        <div className="col-sm-12 col-md-5 col-lg-4 col-xl-3 mb-4">
          <Location
            occupationLocation={occupationLocation}
            location={location}
            handleSearchOccupation={handleSearchOccupation}
            viewOnly={viewOnly}
          />
        </div>
      </div>

      {(selectedSkills || topSkills || selectedKnowledge || topKnowledge) && (
        <div className="row">
          <div className="col-sm-12 col-md-6 mb-4">
            <OccupationElement
              title="Skills"
              data={selectedSkills || topSkills}
              viewOnly={viewOnly}
              handleChooseElements={() => {
                handleChooseElements({
                  data: skillsDataList,
                  title: 'Skills'
                });
                setMustSelectOne(true);
                setEditSkills(true);
              }}
              handleAddElements={() => {
                openManageSkillModal();
              }}
            />
          </div>
          <div className="col-sm-12 col-md-6 mb-4">
            <OccupationElement
              title="Knowledge"
              data={selectedKnowledge || topKnowledge}
              viewOnly={viewOnly}
              handleChooseElements={() => {
                handleChooseElements({
                  data: knowledgeDataList,
                  title: 'Knowledge'
                });
                setMustSelectOne(true);
                setEditKnowledge(true);
              }}
            />
          </div>
        </div>
      )}

      <div className="row">
        {certifications && (
          <div className="col-sm-12 mb-4">
            <OccupationCertifications
              title="Certifications"
              element="Certificate"
              data={selectedCerts || certifications}
              hasData={certifications}
              viewOnly={viewOnly}
              handleChooseElements={() => {
                handleChooseElements({
                  data: certifications,
                  title: 'Certificates'
                });
                setMustSelectOne(false);
                setEditCerts(true);
              }}
              handleAddElements={() => {
                openManageCertificateModal();
              }}
            />
          </div>
        )}
        {schoolPrograms && (
          <div className="col-sm-12 mb-4">
            <OccupationSchoolPrograms
              title={schoolPrograms.length === 1 ? 'Training Program' : 'Training Programs'}
              element="Program"
              data={selectedSchoolPrograms || schoolPrograms}
              hasData={schoolPrograms}
              viewOnly={viewOnly}
              handleChooseElements={() => {
                handleChooseElements({
                  data: schoolPrograms,
                  title: 'Programs'
                });
                setMustSelectOne(false);
                setEditSchoolPrograms(true);
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Occupation;
