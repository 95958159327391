import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { pathOr } from 'ramda';
import { getLocation } from '../../actions/Locations';
import { locationTerminology } from '../../services/locations';
import { getOrg } from '../../actions/Organizations';
import { getApprenticeship } from '../../actions/Apprenticeships';
import Loading from '../Loading';
import OrgConsoleHeader from '../OrgConsole/OrgConsoleHeader';
import Form from './Form';
import './styles.css';

const EmployerProfile = () => {
  const dispatch = useDispatch();
  // Redux
  const organization = useSelector((state) => state.organization);
  const orgLocation = useSelector((state) => state.orgLocation);
  const apprenticeship = useSelector((state) => state.apprenticeship);
  const employerProfile = useSelector((state) => state.employerProfile);
  const invite = useSelector((state) => state.invite);
  // Params
  const locationId = pathOr(null, ['locationId'], invite);
  const employerProfileId = pathOr(null, ['employerProfileId'], invite);
  const apprenticeshipId = pathOr(null, ['apprenticeshipId'], invite);
  const orgId = pathOr(null, ['orgId'], invite);
  // Organization
  const orgType = pathOr(null, ['type'], organization);
  const orgName = pathOr(null, ['name'], organization);
  const locationName = pathOr(null, ['name'], orgLocation);
  const locationTerm = locationTerminology({ orgType });

  useEffect(() => {
    document.body.classList.add('employer-workforce-manage');

    dispatch(getOrg({
      orgId,
      select: [
        'fields.name',
        'fields.orgLogo'
      ],
      locationIds: [locationId],
      locationSelect: ['fields.name']
    }));
  }, [orgId, locationId]);

  useEffect(() => {
    if (locationId) {
      dispatch(getLocation(locationId));
    }
  }, []);

  useEffect(() => {
    if (apprenticeshipId) {
      dispatch(getApprenticeship(apprenticeshipId));
    }
  }, []);

  if (employerProfileId && !employerProfile?.id) {
    return (
      <Loading
        text="Loading Workforce Info..."
        className='theme-dark'
      />
    );
  }

  if (apprenticeshipId && !apprenticeship?.id) {
    return (
      <Loading
        text="Loading Apprenticeship Info..."
        className='theme-dark'
      />
    );
  }

  return (
    <div>
      <div className="row d-flex justify-content-center">
        <div className="col-sm-12 col-lg-7">

          <div className="mt-3">
            <OrgConsoleHeader
              pageTitle={orgLocation?.name}
              icon={locationTerm.icon}
              image={orgLocation?.locationImage}
              imageWidth=''
            />

            {apprenticeshipId && (
              <div className='bg-white p-3'>
                <div>
                  {locationName} is participating in an apprenticeship program with {orgName}.
                </div>
                <p className='mt-2 mb-0'>
                  Additional information about {locationName} is needed for mandatory reporting to government agencies.
                </p>
              </div>
            )}

            {apprenticeshipId && (
              <Form data={employerProfile} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmployerProfile;
