import { pathOr } from 'ramda';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { toast } from 'react-toastify';
import { formatCurrency } from '../../../utils/currency';
import percentOfNumber from '../../../utils/percentOfNumber';
import { CHEVRON_LEFT } from '../../../constants/icons';
import { US_FED_MINIMUM_WAGE } from '../../../constants/wageSchedules';
import WageSchedule from '../../WageSchedules/WageSchedule';

const StepTwo = ({
  loading,
  updateState,
  saveData,
  wageSchedule,
  addressState = ''
}) => {
  // redux
  const apprenticeship = useSelector((state) => state.apprenticeship);
  // misc
  const id = pathOr('', ['id'], wageSchedule);
  const title = pathOr('', ['title'], wageSchedule);
  const entryWage = pathOr(US_FED_MINIMUM_WAGE, ['entryWage'], wageSchedule);
  const ojtHours = pathOr(0, ['ojtHours'], apprenticeship);

  const defaultWageRates = useMemo(() => {
    const ojtHoursPeriodSplit = ojtHours / 3;

    const wage1 = percentOfNumber(47, entryWage);
    const wage2 = percentOfNumber(67, entryWage);
    const wage3 = percentOfNumber(87, entryWage);
    return [
      {
        wageAmount: parseFloat(wage1.toFixed(2)),
        duration: ojtHoursPeriodSplit
      },
      {
        wageAmount: parseFloat(wage2.toFixed(2)),
        duration: ojtHoursPeriodSplit
      },
      {
        wageAmount: parseFloat(wage3.toFixed(2)),
        duration: ojtHoursPeriodSplit
      }
    ];
  }, [entryWage, ojtHours]);

  const [wageRates, setWageRates] = useState(wageSchedule?.wageRates || defaultWageRates);

  const totalDurationHours = useMemo(() => {
    return wageRates.reduce((prev, curr) => Math.ceil(prev) + Math.ceil(curr.duration), 0);
  }, [wageRates]);

  const showTotalHoursWarning = useMemo(() => {
    if (totalDurationHours !== ojtHours) {
      return true;
    }

    return false;
  }, [totalDurationHours, ojtHours]);

  const minWageWarning = useMemo(() => {
    let flag = false;

    wageRates.forEach((wageRate) => {
      if (wageRate.wageAmount < US_FED_MINIMUM_WAGE) {
        flag = true;
      }
    });

    if (flag) {
      toast.error(
        `Wages must meet US Federal Min. Wage (${formatCurrency(US_FED_MINIMUM_WAGE)}) requirements.`,
        { autoClose: false, toastId: 'minWageWarning' }
      );
    } else {
      toast.dismiss('minWageWarning');
    }

    return flag;
  }, [wageRates]);

  useEffect(() => {
    updateState({ wageRates });
  }, []);

  const handleSave = (e) => {
    e.preventDefault();

    if (wageRates && !wageRates.length) {
      toast.error('Wage rates required.');
      return;
    }

    updateState({ loading: true });

    saveData();
  };

  return (
    <>
      <div className="card-body">
        <div>
          <div className='d-flex align-items-center mb-2'>
            <span className='font-weight-bold h6 m-0'>
              {title}
            </span>
            <button
              title="Change Entry Wage"
              type='button'
              className='btn btn-sm btn-outline-primary ml-2 px-1 py-0'
              style={{ fontSize: '.75rem' }}
              onClick={() => {
                updateState({ currentStep: 0 });
              }}
            >
              Change
            </button>
          </div>

          <div>
            <span>
              Apprentices shall be paid a progressively increasing schedule of wages based on a percentage of the <abbr data-tip="Journeyworker" title='Journeyworker'>JW</abbr> entry wage, <strong>{formatCurrency(entryWage)}</strong>/hr.
            </span>
            <button
              title="Change Entry Wage"
              type='button'
              className='btn btn-sm btn-outline-primary ml-2 px-1 py-0'
              style={{ fontSize: '.75rem' }}
              onClick={() => {
                updateState({ currentStep: 0 });
              }}
            >
              Change
            </button>
          </div>

          <div
            className='font-weight-bold h6 mt-3'
          >
            Schedule of Wage Rates <span className="text-danger">*</span>
          </div>

          <WageSchedule
            ojtHours={ojtHours}
            addressState={addressState}
            wageSchedule={{
              id,
              title,
              entryWage,
              wageRates
            }}
            className="mt-1"
            onChange={(updatedWageRates) => {
              updateState({
                wageRates: updatedWageRates
              });
              setWageRates(updatedWageRates);
            }}
          />
        </div>
      </div>

      <div className='card-footer d-flex justify-content-between'>
        <div className='d-flex align-items-center'>
          <button
            className="btn btn-sm btn-outline-primary"
            type="button"
            onClick={() => {
              updateState({ currentStep: 0 });
            }}
            disabled={loading}
          >
            <i className={CHEVRON_LEFT} /> Back
          </button>
          <div className='ml-2'>
            Title & <abbr data-tip="Journeyworker" title='Journeyworker'>JW</abbr> Entry Wage
          </div>
        </div>
        <button
          className="btn btn-primary btm-sm"
          type="button"
          disabled={loading || showTotalHoursWarning || minWageWarning}
          onClick={handleSave}
        >
          {loading ? 'Saving...' : 'Save'}
        </button>
      </div>

      <ReactTooltip />

    </>
  );
};

export default StepTwo;
