import React, { Component } from 'react';
import { connect } from 'react-redux';
import { verify } from 'jsonwebtoken';
import { withFirebase } from '../../Firebase';
import { setUser } from '../../../actions';
import * as ROUTES from '../../../constants/routes';
import * as ERRORS from '../../../constants/errors';
import { setUserLoggedIn } from '../../../services/organizations';
import { redirectToAuth } from '../../../services/auth';
import Loading from '../../Loading';
import convertUrlParamsToObject from '../../../utils/convertUrlParamsToObject';

class SSO extends Component {
  componentDidMount() {
    const {
      history,
      firebase,
      location: { search: locationSearch }
    } = this.props;
    const localToken = window.localStorage.getItem('turbine_token');

    if (!localToken && locationSearch && !locationSearch.includes('token')) {
      redirectToAuth({
        error: {
          code: ERRORS.AUTH_NO_TOKEN
        }
      });
    }

    const searchParams = convertUrlParamsToObject(locationSearch);
    const token = (searchParams && searchParams.token) || localToken;

    verify(token, process.env.REACT_APP_AUTH_TOKEN_SECRET, (err, decoded) => {
      if (err) {
        redirectToAuth({
          error: {
            code: ERRORS.AUTH_BAD_TOKEN,
            message: err.message
          }
        });
        return null;
      }

      const { email, password } = decoded;

      firebase.auth.signInWithEmailAndPassword(email, password).then(({ user }) => {
        setUserLoggedIn();
        setUser(user);
        window.localStorage.setItem('turbine_token', token);

        if (searchParams.continueUrl) {
          window.location = searchParams.continueUrl;

          return null;
        }
        history.push(ROUTES.CLASSES);
      }).catch(({ message, code }) => {
        redirectToAuth({
          error: {
            code,
            message
          }
        });
      });
    });
  }

  render() {
    return (
      <Loading />
    );
  }
}

const mapStateToProps = ({ user }) => ({ user });

export default withFirebase(connect(mapStateToProps, { setUser })(SSO));
