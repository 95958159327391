export const SLASH = '/';
export const CLASSES = '/'; // change to classes when we have a dashboard

// INTERNAL
export const FILE_PARSE = '/internal/file-parse'; //

// EXTERNAL
export const PATHWAYS = '/external/pathways'; // [external] Job Profile Creation Tool
export const JOB_PROFILES = '/external/job-profiles';
export const ASSISTANT = '/external/assistant';
// TODO deprecate JOB_PROFILE_PAGE use invitation system
export const JOB_PROFILE_PAGE = `${JOB_PROFILES}/:jobProfileId`; // [external] Job Profile Page (Read Only)
export const DOCUMENT_BASE = '/external/doc';
export const DOCUMENT = '/external/doc/:collection/:docId';
export const INVITE_BASE = '/external/invite';
export const INVITE = `${INVITE_BASE}/id/:inviteId`;
export const INVITE_MESSAGE_BASE = '/external/invite/msg';
export const INVITE_MESSAGE = '/external/invite/msg/:messageType'; // success || error

// PUBLIC PAGES
export const PATHWAY_PAGE = '/o/:orgSlug/pathways/:pathwayId'; // /o/consortium/pathways/A57jO6m0LTGVcY9l6ISA/job-profile/RPQtVqQrjIBfQJF06cKx
export const PATHWAY_PAGE_JOB_PROFILE = '/o/:orgSlug/pathways/:pathwayId/job-profile/:jobProfileId'; // /o/consortium/pathways/A57jO6m0LTGVcY9l6ISA/job-profile/RPQtVqQrjIBfQJF06cKx
export const PATHWAY_PAGE_CERTIFICATES = '/o/:orgSlug/pathways/:pathwayId/certificates'; // /o/consortium/pathways/A57jO6m0LTGVcY9l6ISA/certificates
export const COURSE_PAGE = '/o/:orgSlug/courses/:courseKey/:courseValue'; // <view>/<courseSlug> || <id>/<classId>
// export const COURSES_LOCATION_PAGE = '/o/:orgSlug/l/:locationId/courses';

// MANAGEMENT (super admin)
export const ORG_CONSOLE_BASE = '/org';
export const ORG_CONSOLE_BILLING = `${ORG_CONSOLE_BASE}/billing`;
export const ORG_MANAGE = '/org/manage';
export const ORGANIZATIONS = '/organizations';
export const BILLING = '/billing';
export const ACCOUNT = '/account';
export const ACCOUNT_INTEGRATIONS = `${ACCOUNT}/integrations/:integration`;

// AUTH
export const SSO = '/sso';
export const LOGIN = '/login';
export const LOGIN_ORG = '/login/:organizationSlug?';

// Experts & Admins Only
export const MEMBERS = '/members';
