// import { referenceType } from '../contentful';

function formatOrganization({
  // slug,
  name,
  billing,
  showOrgName,
  restrictRegister
  // integrateSlack,
  // slackToken,
  // slackTeamId,
  // slackJoinLink,
  // tos,
  // register,
  // privacyPolicy,
  // userSeats,
  // expertSeats,
  // welcomeMessage
}) {
  const data = {};

  if (name !== undefined) {
    data.name = {
      'en-US': name
    };
  }

  if (billing !== undefined) {
    data.billing = {
      'en-US': billing
    };
  }

  if (showOrgName !== undefined) {
    data.showOrgName = {
      'en-US': showOrgName
    };
  }

  if (restrictRegister !== undefined) {
    data.restrictRegister = {
      'en-US': restrictRegister
    };
  }

  return { fields: { ...data } };
}

export default formatOrganization;
