import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { pathOr } from 'ramda';
import { SLASH } from '../../constants/routes';
import { TURBINE, TURBINE_ADMIN } from '../../constants/urls';
import { DEFAULT_PROFILE_PIC, TURBINE_LOGO } from '../../constants/assets';
import { logout } from '../../actions/Session';
import { resetUser } from '../../actions';
import { logUserOut } from '../../services/auth';
import './style.css';

const TopNav = () => {
  const dispatch = useDispatch();
  // redux
  const user = useSelector((state) => state.user);
  const currentUser = useSelector((state) => state.currentUser);
  // misc
  const profilePicture = pathOr(DEFAULT_PROFILE_PIC, ['profilePicture'], currentUser);
  const name = pathOr(null, ['name'], currentUser);

  const handleLogout = () => {
    logUserOut().then(() => {
      dispatch(logout());
      dispatch(resetUser());
    });
  };

  if (!user || !currentUser) return null;
  if (document.location.href.includes('/invite')) return null;
  if (document.location.href.includes('/doc')) return null;
  if (document.location.href.includes('/pathways')) return null; // Pathways / Job Profiles
  if (document.location.href.includes('/courses')) return null; // Public Course Page

  return (
    <nav className="navbar bg-white border-bottom flex-md-nowrap p-0">
      <Link
        className="navbar-brand px-3 border-right text-capitalize text-dark"
        to={SLASH}
        title="Turbine Workforce"
      >
        <img
          src={TURBINE_LOGO}
          className="nav-icon"
          alt="Turbine Workforce"
        />
      </Link>

      <ul className="navbar-nav">
        <li className="nav-item dropdown">
          <div
            className="top-nav-trigger nav-link dropdown-toggle text-nowrap border-left d-flex align-items-center"
            id="navbarDropdownMenuLink"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <span className="user-profile">
              <img src={profilePicture} alt={name} />
            </span>
          </div>
          <div
            className="dropdown-menu my-0 py-0 right"
            aria-labelledby="navbarDropdownMenuLink"
          >
            <a
              className="dropdown-item border-top py-2"
              href={TURBINE}
              title="Turbine"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fas fa-fan mr-1" /> Turbine
            </a>
            <a
              className="dropdown-item border-top py-2"
              href={TURBINE_ADMIN}
              title="Turbine Admin"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fas fa-fan mr-1" /> Turbine Admin
            </a>
            <button
              className="dropdown-item border-top py-2"
              onClick={handleLogout}
              type="button"
            >
              <i className="fas fa-sign-out-alt text-danger" /> Sign out
            </button>
          </div>
        </li>
      </ul>
    </nav>
  );
};

export default TopNav;
