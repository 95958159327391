import React, { Fragment } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { pathOr } from 'ramda';
import BackToTurbine from '../Pathways/BackToTurbine';
import convertUrlParamsToObject from '../../utils/convertUrlParamsToObject';
import JobProfile from '../JobProfile';

const JobProfilePage = () => {
  const location = useLocation();
  const params = useParams();
  // Misc
  const jobProfileId = pathOr(null, ['jobProfileId'], params);
  const searchParams = convertUrlParamsToObject(location.search);
  const embedded = pathOr('false', ['embedded'], searchParams);
  const hideHeaderTitleParam = pathOr('false', ['hideHeaderTitle'], searchParams);
  const isEmbedded = embedded === 'true';
  const hideHeaderTitle = hideHeaderTitleParam === 'true';

  return (
    <Fragment>
      {!isEmbedded && (
        <BackToTurbine
          tagline="POWERED BY"
          className="mb-1"
        />
      )}

      <JobProfile
        jobProfileId={jobProfileId}
        hideHeaderTitle={hideHeaderTitle}
        readOnly
      />
    </Fragment>
  );
};

export default JobProfilePage;
