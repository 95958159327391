import dayjs from 'dayjs';
// import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';

// dayjs.extend(relativeTime);
dayjs.extend(timezone);

export const todaysDate = () => {
  return dayjs().format('MMM. D, YYYY', { timeZone: dayjs.tz.guess() }); // 'America/New_York'
};

// export const fromNowDate = () => {
//   dayjs.extend(relativeTime);

//   return dayjs().fromNow();
// }

export const mdyDate = (timestamp) => {
  return dayjs(timestamp).format('MMM. D, YYYY', { timeZone: dayjs.tz.guess() }); // 'America/New_York'
};

export const mmddyyyyDate = (timestamp) => {
  return dayjs(timestamp).format('MM/DD/YYYY', { timeZone: dayjs.tz.guess() }); // 'America/New_York'
};

export const dayMdyDate = (timestamp) => {
  return dayjs(timestamp).format('ddd., MMM. D, YYYY', { timeZone: dayjs.tz.guess() }); // 'America/New_York'
};

export const hrMin = (timestamp) => {
  return dayjs(timestamp).format('h:mm A', { timeZone: dayjs.tz.guess() }); // 'America/New_York'
};

export const ySimple = (timestamp) => {
  return dayjs(timestamp).format('YYYY'); // '2020'
};
