import React from 'react';
import { useSelector } from 'react-redux';
import { DEFAULT_ORG_LOGO } from '../../../constants/assets';
import './style.css';

const TopNavPathwayPage = () => {
  const pageOrganization = useSelector((state) => state.pageOrganization);
  const pathway = useSelector((state) => state.pathway);
  const orgLogo = pageOrganization?.orgLogo || DEFAULT_ORG_LOGO;
  const orgName = pageOrganization?.name || 'Loading...';

  if (!document.location.href.includes('/pathways')) {
    return null;
  }

  if (!pageOrganization?.id) {
    return null;
  }

  return (
    <nav className="navbar bg-white border-bottom flex-md-nowrap p-0">
      <div className="w-100 d-flex align-items-center justify-content-between">
        <div
          className="navbar-brand px-3 text-dark"
        >
          <img
            src={orgLogo || DEFAULT_ORG_LOGO}
            className="nav-icon"
            alt={`${orgName} Icon`}
          />
        </div>
        <div className="px-3 text-right">
          <div className="font-weight-bold">
            {orgName}
          </div>
          {pathway && (
            <div>
              {pathway.title}
            </div>
          )}
        </div>
      </div>
    </nav>
  );
};

export default TopNavPathwayPage;
