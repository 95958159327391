import { client, flattenItems } from '../services/contentful';
import formatData from '../services/formatData';
import { updateEntry } from '../services/entry';
import { getEntry } from './Entry';
import sortArrByKey from '../utils/sortArrByKey';
import {
  SET_COHORTS,
  RESET_COHORTS,
  SET_CURRENT_COHORT,
  RESET_CURRENT_COHORT
} from './types';

export const getCohorts = ({ classId, cohortIds, openCohortEnrollment }) => {
  return (dispatch) => {
    const config = {
      content_type: 'cohort',
      order: '-sys.createdAt',
      include: 1
    };

    if (classId !== undefined) {
      config['fields.classId'] = classId;
    }

    if (cohortIds !== undefined) {
      config['sys.id[in]'] = cohortIds.join(',');
    }

    if (openCohortEnrollment !== undefined) {
      config['fields.openCohortEnrollment'] = openCohortEnrollment;
    }

    return new Promise((resolve, reject) => {
      client.getEntries(config).then(({ items }) => {
        const courseCohorts = items.length ? flattenItems(items) : null;

        dispatch({
          type: SET_COHORTS,
          ...(classId ? { courseCohorts } : {}),
          ...(cohortIds ? { userCohorts: courseCohorts } : {})
        });

        resolve(courseCohorts);
      }).catch((error) => {
        console.error('getCohorts', error);
        reject(error);
      });
    });
  };
};

export const resetCohorts = () => {
  return (dispatch) => {
    dispatch({ type: RESET_COHORTS });
  };
};

export const setCurrentCohort = ({ cohortId }) => (dispatch) => new Promise((resolve, reject) => {
  const config = {
    content_type: 'cohort',
    include: 2,
    'sys.id': cohortId
  };

  client.getEntries(config).then((response) => {
    const currentCohort = response.items[0];
    dispatch({
      type: SET_CURRENT_COHORT,
      currentCohort
    });

    resolve(currentCohort);
  }).catch((error) => {
    reject(error);
  });
});

export const resetCurrentCohort = () => (dispatch) => {
  dispatch({ type: RESET_CURRENT_COHORT });
};

export const updateCurrentCohort = ({
  userId,
  name,
  cohortId
}) => () => new Promise((resolve, reject) => {
  getEntry(cohortId).then((response) => {
    const cohortUsers = response.fields.users;
    const newUser = {
      fields: { name },
      sys: { id: userId }
    };

    const data = {
      userIds: []
    };

    // if existing users, flatten as userIds
    if (cohortUsers && cohortUsers.length !== 0) {
      data.userIds = cohortUsers.map((user) => user.sys.id);

      // reject if user exists in Cohort already
      if (data.userIds.includes(userId)) {
        reject({
          code: 'error_dup_user_cohort',
          message: 'User already in Cohort'
        });

        return false;
      }

      // if existing users, include
      data.userId = [
        ...cohortUsers
      ];
    }

    // add new user
    data.userIds = [
      ...data.userIds,
      newUser
    ];

    // sort
    data.userIds = sortArrByKey(data.userIds, 'name');

    // flatten
    data.userIds = data.userIds.map((user) => user.sys.id);

    // Prepare for DB
    const preppedData = formatData(data, 'cohort');

    updateEntry({
      id: cohortId,
      data: preppedData
    }).then((updatedCohort) => {
      resolve(updatedCohort);
    }).catch((error) => {
      console.error(error);
      reject(error);
    });
  }).catch((error) => {
    console.error(error);
  });
});
