import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { pathOr } from 'ramda';
import { redirectToAuth } from '../../services/auth';
import { setCurrentModal } from '../../actions/Modals';
import { setLoading, setCurrentPage } from '../../actions/Session';
import { setCurrentUser } from '../../actions/Users';
import { getOrg } from '../../actions/Organizations';
import { withFirebase } from '../Firebase';
import { SSO } from '../../constants/routes';
import { TURBINE_ADMIN } from '../../constants/urls';
import { PERM_ROLE_TEACHER_ADMIN } from '../../constants/errors';
import { canAccessTurbineConsole } from '../../services/currentUser';
import { currentPageTitle } from '../../services/global';
import convertUrlParamsToObject from '../../utils/convertUrlParamsToObject';
import convertObjectToUrlParams from '../../utils/convertObjectToUrlParams';
import Loading from '../Loading';

const createSearchQuery = (searchParams) => {
  const { location: { href, search } } = window;
  const baseUrl = href.replace(search, '');
  const searchQuery = { token: searchParams.token };
  const continueUrlparams = { ...searchParams };

  delete continueUrlparams.token; // Don't pass along token in continueUrl

  searchQuery.continueUrl = encodeURIComponent(baseUrl + convertObjectToUrlParams(continueUrlparams));

  return convertObjectToUrlParams(searchQuery);
};

const withAuthorization = (condition) => (Component) => {
  class WithAuthorization extends React.Component {
    componentDidMount() {
      document.body.scrollTop = document.documentElement.scrollTop = 0;

      const { location: { search: locationSearch } } = this.props;
      const searchParams = convertUrlParamsToObject(locationSearch);
      const hasToken = searchParams && searchParams.token;

      this.listener = this.props.firebase.onAuthUserListener((response) => {
        if (!condition(response.authUser)) {
          if (hasToken) {
            this.goToSSO(searchParams);
          } else {
            redirectToAuth({});
          }
        }

        if (!response.currentUser) {
          // No data, send to auth
          redirectToAuth({});

          return null;
        }

        const role = pathOr(null, ['currentUser', 'role'], response); // TODO user roles
        const orgId = pathOr(null, ['currentUser', 'orgId'], response);

        this.props.getOrg({ orgId }).then((organization) => {
          const { slug: orgSlug } = organization;

          if (!canAccessTurbineConsole(role)) {
            redirectToAuth({
              organizationSlug: orgSlug,
              error: {
                code: PERM_ROLE_TEACHER_ADMIN
              }
            });

            return null;
          }

          this.props.setCurrentUser(response.currentUser);
          this.currentPage();
        }).catch((error) => {
          // No org data, send to auth
          redirectToAuth({});
          console.error(error);
        });
      },
      () => {
        if (hasToken) {
          this.goToSSO(searchParams);
        } else {
          const { organization } = this.props;
          const orgSlug = pathOr(null, ['slug'], organization);

          if (orgSlug) {
            redirectToAuth({
              organizationSlug: organization.fields.slug
            });
            return null;
          }

          redirectToAuth({});
        }
      });
    }

    componentWillUnmount() {
      this.listener();
    }

    goToSSO = (searchParams) => {
      const search = createSearchQuery(searchParams);
      const ssoUrl = TURBINE_ADMIN + SSO + search;

      window.location = ssoUrl;
    }

    currentPage() {
      const {
        currentPage,
        location: { pathname }
      } = this.props;
      const pageTitle = currentPageTitle({ pathname });

      if (currentPage !== pageTitle) {
        this.props.setCurrentPage(pageTitle);
      }
    }

    render() {
      const { user, currentUser } = this.props;

      return condition(user) && condition(currentUser) ? (
        <Component {...this.props} />
      ) : (
        <Loading
          className="position-fixed"
        />
      );
    }
  }

  const mapStateToProps = ({
    user,
    currentUser,
    currentClass,
    currentEntry,
    organization,
    currentPage
  }) => ({
    user,
    currentUser,
    currentClass,
    currentEntry,
    organization,
    currentPage
  });

  return compose(
    withRouter,
    withFirebase,
    connect(mapStateToProps, {
      setLoading,
      setCurrentUser,
      setCurrentPage,
      setCurrentModal,
      getOrg
    })
  )(WithAuthorization);
};

export default withAuthorization;
