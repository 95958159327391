import React from 'react';
import { useSelector } from 'react-redux';
import OrgAuthor from '../../Courses/OrgAuthor';
import RenderMarkdown from '../../ManageContent/RenderMarkdown';

const Overview = () => {
  const course = useSelector((state) => state.currentClass);

  if (!course) return null;

  const {
    title,
    overview,
    description,
    showStreamLink,
    streamingLink,
    enableNewEditor,
    classColor,
    topics: tags,
    orgId,
    orgIds
  } = course;

  return (
    <div>
      <div className="card">
        <div className="card-header bg-light p-3 h5 m-0">
          Overview
        </div>

        {classColor && (
          <div
            className="p-1 d-block d-md-none"
            style={{ backgroundColor: classColor }}
          />
        )}

        <div className="card-body overflow-hidden">
          <h5 className="d-block d-md-none font-weight-bold">
            {title}
          </h5>
          <RenderMarkdown
            source={overview || description}
            enableNewEditor={enableNewEditor}
          />

          {tags && (
            <div className="d-block d-md-none mb-2">
              <b>Tags:</b>
              <p className="text-muted mt-1">
                { tags.join(', ') }
              </p>
              {/* {tags.map((tag) => (
                <span className="h6">
                  <span className="badge badge-pill badge-light border mt-1 ml-1">
                    {tag}
                  </span>
                </span>
              ))} */}
            </div>
          )}

          {showStreamLink && streamingLink && (
            <p>
              <b>Live Streaming Link:</b>
              <br />
              <a
                href={streamingLink}
                title="Live Streaming Link"
                target="_blank"
                rel="noopener noreferrer"
              >
                {streamingLink}
              </a>
            </p>
          )}
        </div>

        {orgIds && (
          <OrgAuthor
            className="d-block d-md-none border-top bg-white px-3 py-2"
            orgId={orgId}
          />
        )}
      </div>
    </div>
  );
};

export default Overview;
