import React from 'react';
import { connect, useSelector } from 'react-redux';
import MarkdownCheatsheet from './MarkdownCheatsheet';
import InviteUser from './InviteUser';
import PathwaysPrint from './PathwaysPrint';
import EditOccupation from './EditOccupation';
import EnrollExternalRegisteredClass from './EnrollExternalRegisteredClass';
import ManageSkill from './ManageSkill';
import ManageCertificate from './ManageCertificate';
import ManageWageSchedule from './ManageWageSchedule';
import UserSignature from './UserSignature';
import ManageUserProfile from './ManageUserProfile';

const Modals = () => {
  // redux
  const currentUser = useSelector((state) => state.currentUser);
  const currentModal = useSelector((state) => state.currentModal);

  const isCurrentModal = (key) => {
    return currentModal?.key === key;
  };

  return (
    <>
      {currentUser && (
        <>
          <MarkdownCheatsheet />

          {isCurrentModal('inviteUser') && (
            <InviteUser />
          )}

        </>
      )}

      {isCurrentModal('manageUserProfile') && (
        <ManageUserProfile />
      )}

      {isCurrentModal('userSignature') && (
        <UserSignature />
      )}

      {isCurrentModal('manageWageSchedule') && (
        <ManageWageSchedule />
      )}

      {isCurrentModal('pathwaysPrint') && (
        <PathwaysPrint />
      )}

      {isCurrentModal('editOccupation') && (
        <EditOccupation />
      )}

      {isCurrentModal('enrollExternalRegisteredClass') && (
        <EnrollExternalRegisteredClass />
      )}

      {isCurrentModal('manageSkill') && (
        <ManageSkill />
      )}

      {isCurrentModal('manageCertificate') && (
        <ManageCertificate />
      )}
    </>
  );
};

const mapStateToProps = ({
  currentUser,
  currentModal
}) => ({
  currentUser,
  currentModal
});

export default connect(mapStateToProps)(Modals);
