import React, {
  Fragment,
  useCallback, useEffect, useMemo, useState
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { FaXmark } from 'react-icons/fa6';
import { FaPaperPlane } from 'react-icons/fa';
import BadWordsFilter from 'bad-words';
import { chatEngine } from '../../services/openAi';
import { getOrg, logout } from '../../actions';
import { fetchResourcesByVectorText } from '../../services/resources';
import { getIntegration } from '../../actions/Integrations';
import { withFirebase } from '../Firebase';
import { fetchSearch } from '../../services/searches';
import { setSearchUserFeedback } from '../../actions/Search';
import { isLocalhost } from '../../serviceWorker';
import { createUserPrompt, updateUserPrompt } from '../../services/userPrompts';
import { DEFAULT_AGENT_IMAGE } from '../../constants/assets';
import { toParent } from '../../utils/iframePostMessage';
import Loading from '../Loading';
import SearchBox from './SearchBox';
import DefaultPrompts from './DefaultPrompts';
import MessageContainer from './MessageContainer';
import './style.css';
import PoweredBy from '../PoweredBy';
import { sendAssistantCustomerService } from '../../services/mailer';

const Assistant = ({ firebase }) => {
  const dispatch = useDispatch();
  // Bad Words
  const BadWords = new BadWordsFilter();
  // Redux
  const user = useSelector((state) => state.user);
  const currentUser = useSelector((state) => state.currentUser);
  const organization = useSelector((state) => state.organization);
  const integration = useSelector((state) => state.integration);
  const integrationFetched = useSelector((state) => state.integrationFetched);
  // Misc
  const searchParams = new URLSearchParams(window.location.search);
  // Search Query
  const integrationId = searchParams.get('integrationId');
  const ecosystem = searchParams.get('ecosystem') || 'external';
  // Integration
  const resourceGroupIds = integration?.resourceGroupIds;
  const orgId = integration?.orgId;
  const orgNickname = integration?.orgNickname;
  const systemPrompt = integration?.systemPrompt || null;
  const serviceEmail = integration?.serviceEmail || null;
  const serviceDelayCount = integration?.serviceDelayCount || 2;
  const poweredBy = integration?.poweredBy || null;
  const defaultWelcomeMessage = "👋🏻 Hello, I'm an AI Assistant here to answer any questions you have about {ORG_NICKNAME}.";
  let welcomeMessage = integration?.welcomeMessage || defaultWelcomeMessage;

  if (welcomeMessage.includes('{ORG_NICKNAME}')) {
    welcomeMessage = welcomeMessage.replace('{ORG_NICKNAME}', orgNickname);
  }

  const agentImage = integration?.agentImage || DEFAULT_AGENT_IMAGE;
  const colorBtnBkg = integration?.colorBtnBkg || '#000000';
  const defaultPrompts = useMemo(() => {
    return integration?.defaultPrompts || [];
  }, [integration?.defaultPrompts]);
  // Organization
  const orgName = orgNickname || organization?.name || null;
  // Search
  const searchContentTypes = 'resources';
  const initialState = {
    searchValueIsPrompt: false,
    vectorSearchId: null,
    vectorAnswer: '',
    vectorAnswerDone: false,
    vectorFiles: null,
    vectorNodes: null,
    searchFetching: false
  };

  const [searchValueIsPrompt, setSearchValueIsPrompt] = useState(initialState.searchValueIsPrompt);
  const [searchFetching, setSearchFetching] = useState(initialState.searchFetching);
  const [responses, setResponses] = useState([]);
  const [vectorSearchId, setVectorSearchId] = useState(initialState.vectorSearchId);
  const [vectorAnswer, setVectorAnswer] = useState(initialState.vectorAnswer);
  const [vectorAnswerDone, setVectorAnswerDone] = useState(initialState.vectorAnswerDone);
  const [vectorFiles, setVectorFiles] = useState(initialState.vectorFiles);
  // const [vectorNodes, setVectorNodes] = useState(initialState.vectorNodes);
  const [authorName] = useState('You'); // TODO: setAuthorName
  const [userEmail, setUserEmail] = useState('');
  const [chatHistory, setChatHistory] = useState([]);
  const [numNoResponse, setNumNoResponse] = useState(0);
  const [numBadWords, setNumBadWords] = useState(0);

  const defaultNoResponseMsg = "I can't give an accurate or relevant response to your query. I'll try again, if you rephrase it.";

  const initialPrompts = useMemo(() => {
    if (!defaultPrompts || (Array.isArray(defaultPrompts) && defaultPrompts.length === 0)) {
      return null;
    }

    return defaultPrompts.map((prompt) => ({
      postAsCpu: true,
      isClickable: true,
      onlyMessage: true,
      message: prompt
    }));
  }, [defaultPrompts]);

  useEffect(() => {
    setResponses([
      {
        welcomeMessage: true,
        date: new Date().toISOString(),
        postAsCpu: true,
        message: welcomeMessage
      }
    ]);
  }, [welcomeMessage]);

  useEffect(() => {
    document.body.classList.add('turbine-assistant');

    return function cleanup() {
      document.body.classList.remove('turbine-assistant');
    };
  }, []);

  const handlePrompt = async ({ prompt, resourceVecIds, userPromptId }) => {
    const params = {
      orgId,
      prompt,
      promptType: 'embedPrompt',
      resourceVecIds,
      streaming: true
    };

    if (systemPrompt) {
      params.systemPrompt = systemPrompt;
    }

    const response = await chatEngine(params);

    const reader = response.body
      .pipeThrough(new TextDecoderStream())
      .getReader();

    // eslint-disable-next-line no-constant-condition
    while (true) {
      // eslint-disable-next-line no-await-in-loop
      const { value, done } = await reader.read();
      if (done) {
        // TODO Save updatePrompt .then(() => {})
        setVectorAnswerDone(true);
        if (userPromptId) {
          updateUserPrompt({
            output: vectorAnswer,
            outputType: null,
            outputError: null
          }, userPromptId);
        }
        break;
      }
      setVectorAnswer((prev) => prev + value);
    }
  };

  const saveUserPrompt = async (promptText, promptType, resourceIds) => {
    return await createUserPrompt({
      orgId,
      integrationId: integration?.id,
      resourceIds: Array.isArray(resourceIds) && resourceIds.length > 0 ? resourceIds : null,
      input: promptText,
      inputType: promptType
    });
  };

  const resetSearch = () => {
    setVectorAnswer(initialState.vectorAnswer);
    setSearchValueIsPrompt(initialState.searchValueIsPrompt);
    setVectorAnswerDone(initialState.vectorAnswerDone);
    setVectorFiles(initialState.vectorFiles);
    // setVectorNodes(initialState.vectorNodes);
    setSearchFetching(initialState.searchFetching);
  };

  const updateChatHistory = (newChats) => {
    setChatHistory((prevChats) => {
      return [
        ...prevChats,
        ...newChats
      ];
    });
  };

  // console.log('chatHistory', chatHistory);

  const runSearch = (promptText, promptType) => {
    if (responses[0].welcomeMessage) {
      setResponses([]);
    }

    if (vectorAnswer) {
      setResponses((prevResponses) => {
        return [
          ...prevResponses,
          {
            date: new Date().toISOString(),
            postAsCpu: true,
            message: vectorAnswer
          },
          {
            date: new Date().toISOString(),
            authorName,
            message: promptText
          }
        ];
      });
      updateChatHistory([
        {
          role: 'assistant',
          content: vectorAnswer
        },
        {
          role: 'user',
          content: promptText
        }
      ]);
    } else {
      setResponses((prevResponses) => {
        return [
          ...prevResponses,
          {
            date: new Date().toISOString(),
            authorName,
            message: promptText
          }
        ];
      });
      updateChatHistory([
        {
          role: 'user',
          content: promptText
        }
      ]);
    }

    resetSearch();

    const searchValueSpaces = (promptText || '').trim().match(/([\s]+)/g);
    const numSearchValueSpaces = Array.isArray(searchValueSpaces) ? searchValueSpaces.length : 0;

    if (numSearchValueSpaces >= 4) {
      setSearchValueIsPrompt(true);
    }

    setSearchFetching(true);
    fetchResourcesByVectorText({
      userId: null,
      orgId,
      locationId: null,
      integrationId: integration?.id,
      saveSearch: !isLocalhost,
      searchGroupIds: resourceGroupIds,
      parentType: 'resourceGroup',
      contentTypes: 'resources',
      searchText: promptText
    }).then((searchResponse) => {
      setSearchFetching(false);
      setVectorSearchId(searchResponse?.vectorSearchId);
      setVectorFiles(searchResponse?.vectorFiles);
      // setVectorNodes(searchResponse?.vectorNodes);

      if (searchResponse?.resourceVecIds && numSearchValueSpaces >= 4) {
        saveUserPrompt(promptText, 'embedPrompt', searchResponse?.vectorFileIds).then(async (responseUserPrompt) => {
          try {
            await handlePrompt({
              orgId,
              prompt: promptText,
              promptType,
              resourceVecIds: searchResponse?.resourceVecIds,
              userPromptId: responseUserPrompt?.id
            });
          } catch (error) {
            toast.error('We\'ve been notified that something went wrong, please try again.');
            updateUserPrompt({
              outputError: error?.message || null
            }, responseUserPrompt?.id);
          }
        });
      } else {
        setNumNoResponse((prev) => (prev + 1));
        setResponses((prevResponses) => {
          return [
            ...prevResponses,
            {
              date: new Date().toISOString(),
              postAsCpu: true,
              message: defaultNoResponseMsg,
              noResponseMsg: true
            }
          ];
        });
      }
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const { searchValueInput } = e.currentTarget;

    const valueTrimmed = (searchValueInput.value || '').trim();
    const searchValueSpaces = (valueTrimmed || '').trim().match(/([\s]+)/g);
    const numSearchValueSpaces = Array.isArray(searchValueSpaces) ? searchValueSpaces.length : 0;

    if (BadWords.isProfane(valueTrimmed)) {
      setNumBadWords((prev) => (prev + 1));
      setResponses((prevResponses) => {
        return [
          ...prevResponses,
          {
            date: new Date().toISOString(),
            postAsCpu: true,
            message: "Let's keep it clean, try again.",
            noResponseMsg: true
          }
        ];
      });

      if (numBadWords === 2) {
        toast.error('Please refrain from using profanity.');
        onClose();
      }

      return;
    }

    if (!valueTrimmed.length) {
      toast.warn('Please enter a question so I can better assist you.');
      return false;
    }

    if (valueTrimmed.length < 5 && valueTrimmed.includes('.')) {
      toast.warn('Please enter a longer question so I can better assist you.');
      return false;
    }

    if (searchContentTypes.length === 0) {
      toast.error('Select at least one content type to search.');
      return;
    }

    if (numSearchValueSpaces >= 4) {
      runSearch(valueTrimmed, 'userPrompt');
      return;
    }

    toast.warn('Please rephrase your question, more detail helps me better assist you.');
  };

  const handleDefaultPrompt = (promptText) => {
    runSearch(promptText, 'embedPrompt');
  };

  const logUserOut = useCallback(() => {
    dispatch(logout());
    firebase.doSignOut();
  }, [dispatch, firebase]);

  const hasDefaultPrompts = useMemo(() => {
    return Array.isArray(defaultPrompts) && defaultPrompts.length !== 0;
  }, [defaultPrompts]);

  const validEmail = useMemo(() => {
    return userEmail && userEmail.includes('@') && userEmail.includes('.');
  }, [userEmail]);

  const showCustomerServiceResponse = useMemo(() => {
    if (serviceEmail && Array.isArray(responses) && responses.length > 0) {
      return responses[responses.length - 1].noResponseMsg && numNoResponse === serviceDelayCount;
    }

    return false;
  }, [numNoResponse, responses, serviceDelayCount, serviceEmail]);

  const onClose = () => {
    toParent({
      action: 'closeAssistant',
      integrationId: integration?.id,
      orgId
    });
  };

  const handleSendEmail = () => {
    // console.log('userEmail', userEmail);
    // console.log('chatHistory', chatHistory);
    // TODO set loading
    sendAssistantCustomerService({
      integrationId,
      integrationName: integration?.name,
      chatHistory,
      userEmail,
      serviceEmail,
      orgName: organization?.name,
      orgLogo: organization?.orgLogo
    }).then(() => {
      setNumNoResponse(0);
      toast.success('Message sent!');
    });
  };

  useEffect(() => {
    if (user?.uid || currentUser?.id) {
      logUserOut();
    }
  }, [user?.uid, currentUser?.id, logUserOut]);

  useEffect(() => {
    if (integrationId && !integration?.id) {
      dispatch(getIntegration(integrationId)).then((response) => {
        dispatch(getOrg({
          orgId: response?.orgId,
          select: ['fields.name', 'fields.orgLogo'],
          locationSelect: ['fields.name']
        }));
      });
    }
  }, [dispatch, integration?.id, integrationId]);

  useEffect(() => {
    if (vectorSearchId) {
      fetchSearch(vectorSearchId).then((response) => {
        dispatch(setSearchUserFeedback({ searchUserFeedback: response?.searchUserFeedback }));
      });
    }
  }, [dispatch, vectorSearchId]);

  if (user?.uid || currentUser?.id) {
    return null;
  }

  if (!integrationFetched) {
    return <Loading />;
  }

  return (
    <>
      {ecosystem === 'external' && (
        <button
          onClick={onClose}
          type="button"
          aria-label='Close'
          aria-hidden
          style={{
            position: 'absolute',
            top: '10px',
            right: '10px',
            zIndex: 1
          }}
        >
          <FaXmark size={25} />
        </button>
      )}
      <div className={`row pb-5 ${ecosystem === 'external' ? 'pt-4' : ''}`}>
        <div className="col">

          <div className="my-3">
            {responses.length !== 0 && (
              <div>
                <div className="d-flex flex-column">
                  {responses.map((data, i) => (
                    <Fragment key={`response-${i}`}>
                      <MessageContainer
                        agentImage={agentImage}
                        colorBtnBkg={colorBtnBkg}
                        date={data.date}
                        postAsCpu={data.postAsCpu}
                        authorName={data.authorName}
                        orgName={orgName}
                        text={data.message || null}
                        id={vectorSearchId}
                      />
                    </Fragment>
                  ))}

                  {responses[0].welcomeMessage && hasDefaultPrompts && (
                    <MessageContainer
                      onlyMessage
                    >
                      <>
                        <DefaultPrompts
                          list={initialPrompts}
                          loading={searchFetching}
                          onClick={handleDefaultPrompt}
                        />
                      </>
                    </MessageContainer>
                  )}

                  {showCustomerServiceResponse && (
                    <MessageContainer
                      onlyMessage
                    >
                      <div>
                        <p>
                          If you like, I can forward your question to someone at {orgName} who can answer it.
                        </p>
                        <div className='mb-3'>
                          <label>
                            <strong>Best email to reach you?</strong> <span className="text-danger">*</span>
                          </label>
                          <input
                            className='form-control form-control-sm'
                            value={userEmail}
                            type="text"
                            required
                            autoFocus
                            placeholder='youremail@...'
                            onChange={(e) => {
                              const { value } = e.currentTarget;
                              setUserEmail(value);
                            }}
                          />
                        </div>
                        <div className='d-flex justify-content-end'>
                          <button
                            type="button"
                            onClick={handleSendEmail}
                            className='btn btn-sm btn-primary'
                            disabled={!validEmail}
                          >
                            <span className='d-flex align-items-center'>
                              <span className='mr-2'>
                                Send
                              </span>
                              <FaPaperPlane />
                            </span>
                          </button>
                        </div>
                      </div>
                    </MessageContainer>
                  )}
                </div>
              </div>
            )}

            {((searchValueIsPrompt && searchFetching) || (Array.isArray(vectorFiles) && vectorFiles.length !== 0)) && (
              <div className="my-3">
                {searchValueIsPrompt && (
                  <MessageContainer
                    agentImage={agentImage}
                    colorBtnBkg={colorBtnBkg}
                    postAsCpu
                    orgName={orgName}
                    text={vectorAnswer || null}
                    id={vectorSearchId}
                  />
                )}

                {/* <SearchVectorResults
                  vectorSearchId={vectorSearchId}
                  vectorFiles={vectorFiles}
                  vectorNodes={vectorNodes}
                /> */}
              </div>
            )}

            <SearchBox
              className="p-0 p-sm-3"
              onSubmit={onSubmit}
              placeholder="Ask a question..."
              loading={searchFetching || (vectorAnswer && !vectorAnswerDone)}
              autoClear
            />

            {poweredBy && (
              <PoweredBy
                className='poweredByContainer'
                theme="dark"
                trackingCode={`assistant-${window.location.host}`}
              />
            )}
          </div>

        </div>
      </div>
    </>
  );
};

export default withFirebase(Assistant);
