import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { pathOr } from 'ramda';
import { toast } from 'react-toastify';
import ReactTooltip from 'react-tooltip';
import { updateEmployerProfile } from '../../services/employers';
import { EXTERNAL_LINK } from '../../constants/icons';
import { INVITE_MESSAGE_BASE } from '../../constants/routes';
import {
  currentInviteHistoryEntry, deleteInvite
} from '../../services/invites';
import Timeline from '../Timeline';
import TimelineItem from '../Timeline/TimelineItem';
import PoweredBy from '../PoweredBy';
import WageSchedules from '../WageSchedules';

const Form = ({
  data = {} // employerProfile
}) => {
  // Redux
  const orgLocation = useSelector((state) => state.orgLocation);
  const apprenticeship = useSelector((state) => state.apprenticeship);
  const invite = useSelector((state) => state.invite);
  const wageSchedulesList = useSelector((state) => state.wageSchedulesList);
  // Form elements
  const [loading, setLoading] = useState(false);
  const [ein, setEin] = useState(data?.ein || '');
  const [naicsCode, setNaicsCode] = useState(data?.naicsCode || '');
  const [totalEmployees, setTotalEmployees] = useState(data?.totalEmployees || '');
  const [totalEmployeesFemale, setTotalEmployeesFemale] = useState(data?.totalEmployeesFemale || '');
  const [totalEmployeesColor, setTotalEmployeesColor] = useState(data?.totalEmployeesColor || '');
  const [totalJourneyWorkers, setTotalJourneyWorkers] = useState(data?.totalJourneyWorkers || '');
  const [totalJourneyWorkersFemale, setTotalJourneyWorkersFemale] = useState(data?.totalJourneyWorkersFemale || '');
  const [totalJourneyWorkersColor, setTotalJourneyWorkersColor] = useState(data?.totalJourneyWorkersColor || '');
  // Employer Profile
  const inviteHistory = pathOr([], ['inviteHistory'], data);
  // Employer / Location
  const addressState = pathOr(null, ['addressState'], orgLocation);

  const handleSaveSuccessCallback = () => {
    // TODO send Organization an email that the Employer added Workforce Information
    deleteInvite(invite?.id).then(() => {
      document.location = `${INVITE_MESSAGE_BASE}/success`;
    });
  };

  const handleSaveErrorCallback = (error) => {
    console.error(error);
    setLoading(false);
    toast.error('Something went wrong, try again.');
  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (!wageSchedulesList || (wageSchedulesList && Array.isArray(wageSchedulesList) && !wageSchedulesList.length)) {
      toast.error('At least one Wage Schedule is required.');
      return null;
    }

    setLoading(true);
    toast.info('Saving...');

    const currentInvite = currentInviteHistoryEntry(invite);

    const dataToSave = {
      ein,
      naicsCode,
      totalEmployees,
      totalEmployeesFemale,
      totalEmployeesColor,
      totalJourneyWorkers,
      totalJourneyWorkersFemale,
      totalJourneyWorkersColor,
      inviteHistory: [
        {
          ...currentInvite,
          actionAt: new Date().toISOString(),
          actionTaken: 'Data Submitted'
        },
        ...inviteHistory
      ]
    };

    updateEmployerProfile(dataToSave, invite?.employerProfileId).then(() => {
      handleSaveSuccessCallback();
    }).catch((error) => {
      handleSaveErrorCallback(error);
    });
  };

  return (
    <form
      onSubmit={onSubmit}
    >
      <Timeline>
        {/* <TimelineItem
          noArrow
        >
          <div>
            <i className={`${LOCK} mr-1`} /> This Document will lock after saving.
            <br />
            If further information is needed, {orgName} will send a new invitation.
          </div>
        </TimelineItem> */}

        <TimelineItem
          fullWidth
          title='Company Identification'
          index={1}
        >
          <div className="row">
            <div className="col-sm-6 mb-3">
              <label
                htmlFor="ein"
              >
                EIN <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                name="ein"
                className="form-control"
                value={ein}
                required
                placeholder='XX-XXXXXXX'
                onChange={(e) => {
                  const { value } = e.currentTarget;

                  setEin(value);
                }}
              />
              <div className="small mt-1">
                Ex: 31-5914023
              </div>
            </div>

            <div className="col-sm-6">
              <label htmlFor="naicsCode">
                <abbr data-tip="North American Industry Classification System" title="North American Industry Classification System">NAICS</abbr> Code
              </label>
              <div />
              <input
                type="text"
                name="naicsCode"
                className="form-control"
                value={naicsCode}
                onChange={(e) => {
                  const { value } = e.currentTarget;

                  setNaicsCode(value);
                }}
              />
              <div className="small mt-1">
                <span>
                  Ex: 611210
                </span>
                <a
                  className='ml-2 text-nowrap'
                  href='https://www.naics.com/company-lookup-tool/'
                  target="_blank"
                  rel="noopener noreferrer"
                  title="Lookup NAICS code"
                >
                  Lookup NAICS Code <i className={EXTERNAL_LINK} />
                </a>
              </div>
            </div>
          </div>
        </TimelineItem>

        <TimelineItem
          fullWidth
          title='Employees'
          index={2}
        >
          <div className="mb-3">
            <label htmlFor="totalEmployees">
              Total Employees <span className="text-danger">*</span>
            </label>
            <input
              type="number"
              name="totalEmployees"
              className="form-control"
              minLength={1}
              min={1}
              required
              value={totalEmployees}
              onChange={(e) => {
                const { valueAsNumber } = e.currentTarget;

                setTotalEmployees(valueAsNumber);
              }}
            />
          </div>

          <div className="row">
            <div className="col-sm-6 mb-3">
              <label htmlFor="totalEmployeesFemale">
                Total Female Employees <span className="text-danger">*</span>
              </label>
              <input
                type="number"
                name="totalEmployeesFemale"
                className="form-control"
                value={totalEmployeesFemale}
                minLength={1}
                min={0}
                required
                onChange={(e) => {
                  const { valueAsNumber } = e.currentTarget;

                  setTotalEmployeesFemale(valueAsNumber);
                }}
              />
            </div>
            <div className="col-sm-6">
              <label htmlFor="totalEmployeesColor">
                Total Employees of Color <span className="text-danger">*</span>
              </label>
              <input
                data-demo="disabled"
                type="number"
                name="totalEmployeesColor"
                className="form-control"
                value={totalEmployeesColor}
                minLength={1}
                min={0}
                required
                onChange={(e) => {
                  const { valueAsNumber } = e.currentTarget;

                  setTotalEmployeesColor(valueAsNumber);
                }}
              />
            </div>
          </div>
        </TimelineItem>

        <TimelineItem
          fullWidth
          title='Journeyworkers'
          index={3}
        >
          <div className='mb-3'>
            A Journeyworker ( JW ) is a worker who has attained a level of skills, abilities, and competencies recognized within an industry as mastery of the skills and competencies required for the occupation. It is what an apprentice is referred to after they successfully complete an apprenticeship program. The term may also refer to a mentor, technician, specialist, or other skilled worker who has documented sufficient skills and knowledge of an occupation, either through formal apprenticeship or through practical on-the-job experience and formal training.
          </div>

          <div className="mb-3">
            <label htmlFor="totalJourneyWorkers">
              Total Journeyworkers ( JWs ) <span className="text-danger">*</span>
            </label>
            <input
              type="number"
              name="totalJourneyWorkers"
              className="form-control"
              value={totalJourneyWorkers}
              minLength={1}
              min={0}
              required
              onChange={(e) => {
                const { valueAsNumber } = e.currentTarget;

                setTotalJourneyWorkers(valueAsNumber);
              }}
            />
          </div>

          <div className="row">
            <div className="col-sm-6 mb-3">
              <label htmlFor="totalJourneyWorkersFemale">
                Total Female <abbr data-tip="Journeyworkers" title='Journeyworkers'>JWs</abbr> <span className="text-danger">*</span>
              </label>
              <input
                type="number"
                name="totalJourneyWorkersFemale"
                className="form-control"
                value={totalJourneyWorkersFemale}
                minLength={1}
                min={0}
                required
                onChange={(e) => {
                  const { valueAsNumber } = e.currentTarget;

                  setTotalJourneyWorkersFemale(valueAsNumber);
                }}
              />
            </div>
            <div className="col-sm-6">
              <label htmlFor="totalJourneyWorkersColor">
                Total <abbr data-tip="Journeyworkers" title='Journeyworkers'>JWs</abbr> of Color <span className="text-danger">*</span>
              </label>
              <input
                data-demo="disabled"
                type="number"
                name="totalJourneyWorkersColor"
                minLength={1}
                min={0}
                required
                onChange={(e) => {
                  const { valueAsNumber } = e.currentTarget;

                  setTotalJourneyWorkersColor(valueAsNumber);
                }}
                className="form-control"
                value={totalJourneyWorkersColor}
              />
            </div>
          </div>
        </TimelineItem>

        {apprenticeship && (
          <TimelineItem
            fullWidth
            title={apprenticeship?.title}
            index={3}
          >
            {/* Appendix E #3 */}
            <WageSchedules
              addressState={addressState}
              showHeader
              showHelpText
              showCreateWageScheduleButton
              showWagePeriodEditMenu
            />
          </TimelineItem>
        )}

        <TimelineItem
          noArrow
        >
          <button
            className="btn btn-primary btn-md"
            type="submit"
            disabled={loading}
          >
            {loading ? 'Saving...' : 'Save'}
          </button>
          <div className='mt-3 pt-1 border-top'>
            <PoweredBy
              theme="dark"
              className='m-0'
              trackingCode='employerWorkforce'
            />
          </div>
        </TimelineItem>
      </Timeline>
      <ReactTooltip />
    </form>
  );
};

export default Form;
