import React from 'react';
import { useSelector } from 'react-redux';

const JoinCourseButton = ({ joinLink, handleEnrollCourseCohort }) => {
  const pageOrganization = useSelector((state) => state.pageOrganization);
  const course = useSelector((state) => state.currentClass);
  const courseCohorts = useSelector((state) => state.courseCohorts);
  const isLive = course?.isLive || null;

  if (!pageOrganization?.id) {
    return null;
  }

  return (
    <div>
      {!courseCohorts && (
        <a
          className="courseBtn btn btn-lg btn-primary d-flex align-items-center"
          href={joinLink}
        >
          Join {isLive ? 'Session' : 'Course'}
        </a>
      )}

      {Array.isArray(courseCohorts) && courseCohorts.length <= 1 && (
        <a
          className="courseBtn btn btn-lg btn-primary d-flex align-items-center"
          href={joinLink}
        >
          Join {isLive ? 'Session' : 'Course'}
        </a>
      )}

      {courseCohorts && Array.isArray(courseCohorts) && courseCohorts.length > 1 && (
        <button
          type="button"
          className="courseBtn btn btn-lg btn-primary d-flex align-items-center"
          onClick={handleEnrollCourseCohort}
        >
          Join {isLive ? 'Session' : 'Course'}
        </button>
      )}
    </div>
  );
};

export default JoinCourseButton;
