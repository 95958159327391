import * as twApi from './tw-api';
import { getFirebaseEntry } from './firebaseProxy';
import { FIREBASE_STORE_COLLECTION } from '../constants/api';
import { SET_JOB_PROFILE } from '../actions/types';
/**
 * @summary saves job profile to firebase
 * @param {object} data
 */
export const saveJobProfile = (data, collection) => {
  return new Promise((resolve, reject) => {
    //  TODO use "createFirebaseEntry"
    return twApi.post(`${FIREBASE_STORE_COLLECTION}/${collection}`, '', JSON.stringify(data)).then((response) => {
      resolve(response);
    }).catch((error) => {
      console.error(error);
      reject(error);
    });
  });
};

export const getJobProfile = (id, collection = 'jobProfiles') => {
  return (dispatch) => {
    getFirebaseEntry({ collection, id }).then((jobProfile) => {
      dispatch({
        type: SET_JOB_PROFILE,
        jobProfile
      });
    });
  };
};
