export const typeOptions = [
  {
    text: 'Advanced', value: 'Advanced'
  },
  {
    text: 'Core', value: 'Core'
  },
  {
    text: 'Specialty', value: 'Specialty'
  },
  {
    text: 'Product/Equipment', value: 'Product/Equipment'
  },
  {
    text: 'Unclassified', value: 'Unclassified'
  }
];
