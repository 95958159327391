import React from 'react';
import { useSelector } from 'react-redux';
import { CALENDAR } from '../../../constants/icons';
import { DEFAULT_PROFILE_PIC } from '../../../constants/assets';
import RenderMarkdown from '../../ManageContent/RenderMarkdown';
import './style.css';

const InstructorsList = ({
  className = ''
}) => {
  const courseExperts = useSelector((state) => state.courseExperts);

  const whichExperts = courseExperts.filter((courseExpert) => courseExpert.testUser !== true);

  if (!whichExperts.length) return null;

  return (
    <div className={className}>
      <div className="row">
        {whichExperts && whichExperts.map((courseExpert, i) => {
          const {
            title,
            profilePicture,
            name,
            aboutMe,
            calendarLink
          } = courseExpert;

          return (
            <div
              key={`courseExpert-${i}`}
              className="col-md-6 col-lg-4 my-4"
            >
              <div className="experts-list-item card h-100 text-center">
                <div className="card-body">
                  <img
                    data-demo="disabled"
                    className="bd-placeholder-img rounded-circle mb-2"
                    width="120"
                    height="120"
                    style={{ height: '120px', width: '120px' }}
                    src={profilePicture || DEFAULT_PROFILE_PIC}
                    alt={name}
                  />
                  <h5
                    data-demo="disabled"
                    className="card-title"
                  >
                    { name }
                  </h5>

                  <div className="card-text">
                    <div className="font-weight-bold mb-1">
                      {title || 'Instructor' }
                    </div>

                    {aboutMe && (
                      <RenderMarkdown
                        source={aboutMe}
                        enableNewEditor
                      />
                    )}
                  </div>
                </div>
                <div className="card-footer">
                  {calendarLink && (
                    <a
                      className="btn btn-sm btn-outline-primary ml-2"
                      title="Schedule Meeting"
                      rel="noopener noreferrer"
                      target="_blank"
                      href={calendarLink}
                    >
                      <i className={`${CALENDAR} mr-1`} /> Schedule Meeting
                    </a>
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default InstructorsList;
