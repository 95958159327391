import { pathOr } from 'ramda';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import qs from 'query-string';
import { toast } from 'react-toastify';
import { resetCurrentModal, setCurrentModal } from '../../actions';
import { SEND } from '../../constants/icons';
import { saveJobProfile } from '../../services/jobProfiles';
import { previewLink } from '../../services/pathways';
import { toParent } from '../../utils/iframePostMessage';

const Header = ({
  hideHeaderTitle,
  OnetTitle,
  OccupationDescription,
  OnetCode,
  selectedSkills,
  selectedKnowledge,
  selectedCerts,
  selectedSchoolPrograms,
  location,
  occupationLocation
  // viewOnly
}) => {
  const dispatch = useDispatch();
  const params = useParams();
  const queryParams = qs.parse(window.location.search);
  const organization = useSelector((state) => state.organization);
  const orgId = pathOr(null, ['id'], organization);
  const locationId = pathOr(null, ['locationId'], queryParams);
  const jobProfileId = pathOr(null, ['jobProfileId'], params);

  const handlePrePrint = () => {
    dispatch(setCurrentModal({
      key: 'pathwaysPrint',
      data: {
        jobProfileId,
        orgId,
        OnetTitle,
        OccupationDescription,
        OnetCode,
        location,
        occupationLocation,
        selectedSkills,
        selectedKnowledge,
        selectedCerts,
        selectedSchoolPrograms,
        handlePrinting: (docId) => {
          dispatch(resetCurrentModal());
          toast.success('Training Plan sent!');
          if (docId) {
            document.location.assign(previewLink(docId));
          }
        }
      }
    }));
  };

  const handleSave = () => {
    if (!orgId) return null; // TODO signup as org if no orgId.
    const config = {
      OnetTitle,
      OccupationDescription,
      OnetCode,
      location,
      occupationLocation,
      selectedSkills,
      selectedKnowledge,
      selectedCerts,
      selectedSchoolPrograms,
      orgId
    };

    if (locationId) {
      config.locationId = locationId;
      config.isExternal = true;
    }

    saveJobProfile(config, 'jobProfiles').then(({ docId }) => {
      console.log(`Job Profile ${docId} Created.`);
      toast.success('Job Profile Created!');
      document.location.assign(previewLink(docId));
      // Send data to Turbine Admin
      toParent({
        action: 'jobProfileCreate',
        docId,
        orgId
      });
    });
  };

  return (
    <div className="row">
      <div className="col-sm-12">
        <div
          className="py-3 d-flex align-items-center justify-content-between"
        >
          {!hideHeaderTitle && (
            <h5 className="m-0 font-weight-bold">
              Training Plan
            </h5>
          )}
          <div className="d-flex">
            {orgId && !jobProfileId && (
              <button
                type='button'
                className="btn btn-sm btn-primary d-block d-print-none mr-2"
                title="Save"
                onClick={handleSave}
              >
                Save
              </button>
            )}
            {/* Update this logic to allow sharing after job profile already exists */}
            {/* {!orgId || (orgId && jobProfileId) && ( */}
            {!orgId && !jobProfileId && (
              <button
                type='button'
                className="btn btn-sm btn-outline-primary d-block d-print-none"
                title="Print"
                onClick={handlePrePrint}
              >
                <i className={`${SEND} mr-1`} /> Share
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
