import React, { useEffect, useMemo, useState } from 'react';
import ReactCodeInput from 'react-code-input';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { pathOr } from 'ramda';
import {
  INVITE_TYPE_APPRENTICE_AGREEMENT_SIGNER_APPRENTICE,
  INVITE_TYPE_EMPLOYER_AGREEMENT_SIGNER_MGMT,
  INVITE_TYPE_EMPLOYER_PROFILE_UPDATE,
  INVITE_TYPE_REGISTRATION
} from '@apprentage/constants';
import { toast } from 'react-toastify';
import { getInvite, resetInvite } from '../../actions/Invites';
import { INVITE_MESSAGE_BASE, SLASH } from '../../constants/routes';
import { isLocalhost } from '../../serviceWorker';
import { getLocation } from '../../actions/Locations';
import { getApprenticeship } from '../../actions/Apprenticeships';
import { getOrg } from '../../actions';
import { locationTerminology } from '../../services/locations';
import { updateInvite } from '../../services/invites';
import AuthContainer from '../auth/Container';
import EmployerProfile from '../EmployerProfile';
import EmployerAgreement from '../EmployerAgreement';
import ApprenticeAgreement from '../ApprenticeAgreement';
import Loading from '../Loading';

const Invite = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const history = useHistory();
  // redux
  const organization = useSelector((state) => state.organization);
  const orgLocation = useSelector((state) => state.orgLocation);
  const invite = useSelector((state) => state.invite);
  const inviteType = pathOr(null, ['inviteType'], invite);
  // Search Params
  const searchParams = new URLSearchParams(document.location.search);
  // Organization
  const orgType = pathOr('community', ['type'], organization);
  // Employer
  const locationTerm = locationTerminology({ orgType });
  const employerName = pathOr(null, ['name'], orgLocation);
  const employerImage = pathOr(null, ['locationImage'], orgLocation);
  // Access Code
  const [accessCodeApproved, setAccessCodeApproved] = useState(false);
  const accessCode = pathOr('', ['accessCode'], invite);
  const showAccessCodePrompt = useMemo(() => {
    const inviteTypesWithAccessCodes = [
      INVITE_TYPE_EMPLOYER_PROFILE_UPDATE,
      INVITE_TYPE_EMPLOYER_AGREEMENT_SIGNER_MGMT,
      INVITE_TYPE_APPRENTICE_AGREEMENT_SIGNER_APPRENTICE
    ];

    if (accessCodeApproved) {
      return false;
    }

    if (inviteTypesWithAccessCodes.includes(invite?.inviteType)) {
      return true;
    }

    return false;
  }, [inviteType, accessCodeApproved]);

  useEffect(() => {
    dispatch(getInvite(params?.inviteId)).then((response) => {
      // If no Document found (401) or there's no orgId
      if (response?.status === 401 || !response?.orgId) {
        history.replace(`${INVITE_MESSAGE_BASE}/error`);
        console.error(response?.message);

        return false;
      }

      dispatch(
        getOrg({
          orgId: response?.orgId,
          select: ['fields.name', 'fields.orgLogo'],
          locationIds: [response?.locationId],
          locationSelect: ['fields.name']
        })
      );

      if (response?.locationId) {
        dispatch(getLocation(response?.locationId));
      }
    });

    return function cleanup() {
      dispatch(resetInvite());
    };
  }, []);

  useEffect(() => {
    if (accessCode && inviteType && searchParams.has('accesCode')) {
      handleCheckAccessCode(searchParams.get('accessCode'));
    }
  }, [inviteType, accessCode]);

  useEffect(() => {
    if (!showAccessCodePrompt && invite?.id) {
      if (invite?.apprenticeshipId) {
        dispatch(getApprenticeship(invite?.apprenticeshipId));
      }

      updateInvite(
        {
          viewedAt: new Date().toISOString()
        },
        invite?.id
      );
    }
  }, [showAccessCodePrompt, invite?.id]);

  const handleCheckAccessCode = (accessCodeToCheck) => {
    const isEqual = accessCode && accessCodeToCheck && parseInt(accessCodeToCheck, 10) === accessCode;
    toast.dismiss('accessCodeInvalid');
    setAccessCodeApproved(isEqual);

    if (!isEqual && accessCodeToCheck.length === 6) {
      toast.error('Invalid access code.', { toastId: 'accessCodeInvalid', autoClose: false });
    }
  };

  if (!params?.inviteId) {
    return <Redirect to={SLASH} />;
  }

  // User logged in, waiting on Redux.
  if (params?.inviteId && !invite?.id) {
    return (
      <Loading
        text="Loading Invitation..."
        className="theme-dark"
      />
    );
  }

  // Organization
  if (!organization?.id) {
    return (
      <Loading
        text="Loading Organization..."
        className="theme-dark"
      />
    );
  }

  // Location / Employer
  if (!orgLocation?.id) {
    return (
      <Loading
        text={`Loading ${locationTerm.text}...`}
        className="theme-dark"
      />
    );
  }

  if (showAccessCodePrompt) {
    return (
      <div
        className="d-flex align-items-center justify-content-center"
        style={{
          height: '100vh'
        }}
      >
        <AuthContainer
          title="Access Code"
          logo={!!employerImage}
          locationImage={employerImage}
          locationName={employerName}
          footerText="Enter the access code associated with this invitation."
        >
          <div className="mt-4 d-flex justify-content-center">
            <ReactCodeInput
              type="text"
              fields={6}
              autoFocus
              value={isLocalhost ? accessCode.toString() : ''}
              onChange={handleCheckAccessCode}
            />
          </div>
        </AuthContainer>
      </div>
    );
  }

  if (inviteType === INVITE_TYPE_REGISTRATION && invite?.registerLink) {
    document.location = `${invite?.registerLink}&inviteId=${invite?.id}`;
    return;
  }

  // Employer Agreement : Employer Signature
  if (inviteType === INVITE_TYPE_EMPLOYER_AGREEMENT_SIGNER_MGMT) {
    return (
      <EmployerAgreement
        employerAgreementId={invite?.employerAgreementId}
        data={invite}
      />
    );
  }

  // Apprentice Agreement : Apprentice Signature
  if (inviteType === INVITE_TYPE_APPRENTICE_AGREEMENT_SIGNER_APPRENTICE) {
    return (
      <ApprenticeAgreement
        apprenticeAgreementId={invite?.apprenticeAgreementId}
        data={invite}
        dataType="invite"
      />
    );
  }

  // TODO: Employer Agreement : Union Signature

  // Employer Profile Update Workforce Data
  if (inviteType === INVITE_TYPE_EMPLOYER_PROFILE_UPDATE) {
    return <EmployerProfile />;
  }

  // TODO show UI "invite has an unsupported type"

  return <div>Invite: {invite.id}</div>;
};

export default Invite;
