import { pathOr } from 'ramda';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { getOrg } from '../../actions';
import { getJobProfile } from '../../actions/JobProfiles';
import { getPathway } from '../../actions/Pathways';
import { PATHWAY } from '../../constants/icons';
import publicPageRoute from '../../utils/publicPageRoute';
import Loading from '../Loading';
import Collapse from '../ManageContent/Collapse';
import Tabs from './Tabs';

const Certificates = () => {
  const dispatch = useDispatch();
  const params = useParams();
  // Redux
  const organization = useSelector((state) => state.organization);
  const pathway = useSelector((state) => state.pathway);
  const jobProfile = useSelector((state) => state.jobProfile);
  // Misc
  const orgId = pathOr(null, ['id'], organization);
  // Params
  const orgSlug = pathOr(null, ['orgSlug'], params);
  const pathwayId = pathOr(null, ['pathwayId'], params);
  // Pathway
  const jobProfileId = pathOr(null, ['jobProfileId'], pathway);

  console.log('params', params);

  useEffect(() => {
    document.body.classList.add('pathway-page');

    if (!orgId) {
      dispatch(getOrg({
        slug: orgSlug,
        select: [
          'fields.name',
          'fields.orgLogo',
          'fields.slug',
          'fields.appleTouchIcon',
          'fields.androidIconSmall',
          'fields.androidIconLarge'
        ]
      }));
    }
  }, [orgSlug]);

  useEffect(() => {
    if (pathwayId) {
      dispatch(getPathway(pathwayId));
    }
  }, [pathwayId]);

  useEffect(() => {
    if (jobProfileId) {
      dispatch(getJobProfile(jobProfileId));
    }
  }, [jobProfileId]);

  if (!pathway) {
    return (
      <Loading />
    );
  }

  const badgeClassName = (CertDetailListValue) => {
    if (CertDetailListValue === 'Yes') {
      return 'badge badge-success';
    }

    if (CertDetailListValue === 'No') {
      return 'badge badge-danger';
    }

    return 'font-weight-bold mt-2';
  };

  return (
    <div className="row mt-2">
      <div className="col-sm-12">
        <div className="mb-5">
          <div
            className="p-3 border mt-2 bg-white"
          >
            <div className='h5 m-0'>
              <i className={PATHWAY} /> {pathway.title}
            </div>
          </div>

          <Tabs
            activeTab='certificates'
          />

          <div className="mt-3">
            <ul className="nav nav-tabs">
              <li className="nav-item">
                <Link
                  className="nav-link"
                  to={publicPageRoute({ route: `pathways/${pathwayId}`, orgSlug })}
                  title="All Pathways"
                >
                  Pathway
                </Link>
              </li>

              {jobProfileId && (
                <li className="nav-item">
                  <Link
                    className="nav-link ml-2"
                    to={publicPageRoute({ route: `pathways/${pathwayId}/job-profile/${jobProfileId}`, orgSlug })}
                    title="Job Profile"
                  >
                    Job Profile
                  </Link>
                </li>
              )}

              {jobProfileId && (
                <li className="nav-item">
                  <Link
                    className="nav-link active ml-2"
                    to={publicPageRoute({ route: `pathways/${pathwayId}/certificates`, orgSlug })}
                    title="Certificates"
                  >
                    Certificates
                  </Link>
                </li>
              )}

              {/* <li className="nav-item">
                <Link
                  className="nav-link ml-2"
                  to={publicPageRoute({ route: `pathways/${pathwayId}/courses`, orgSlug })}
                  title="Courses"
                >
                  Courses
                </Link>
              </li> */}
            </ul>
          </div>
          <div
            className="bg-white border-bottom border-left border-right p-3"
          >
            {jobProfile && jobProfile.selectedCerts && (
              <div>
                <div className="mt-3">
                  {jobProfile.selectedCerts.map((selectedCert) => (
                    <Collapse
                      key={`selectedCerts-${selectedCert.Id}`}
                      title={selectedCert.Name}
                      className="my-2"
                      badge={() => {
                        if (selectedCert.Acronym) {
                          return (
                            <span className='badge badge-info'>
                              {selectedCert.Acronym}
                            </span>
                          );
                        }

                        return null;
                      }}
                      id={`certificate-${selectedCert.Id}`}
                    >
                      {selectedCert.Description && (
                        <div className="mb-3">
                          {selectedCert.Description}
                        </div>
                      )}

                      {selectedCert.Organization && (
                        <div className="mb-3">
                          <div className="font-weight-bold mb-2">
                            Accrediting Organization:
                          </div>
                          <div className="mb-1">
                            {selectedCert.OrganizationUrl ? (
                              <a
                                className="font-weight-bold"
                                href={selectedCert.OrganizationUrl}
                                rel="noopener noreferrer"
                                target="_blank"
                              >
                                {selectedCert.Organization}
                              </a>
                            ) : (
                              <span className="font-weight-bold">
                                {selectedCert.Organization}
                              </span>
                            )}
                          </div>

                          {selectedCert.OrganizationAddress && (
                            <div>
                              {selectedCert.OrganizationAddress}
                            </div>
                          )}
                        </div>
                      )}

                      {selectedCert.CertDetailList && (
                        <div className="mb-3">
                          <h6 className="font-weight-bold">
                            Details:
                          </h6>

                          {selectedCert.CertDetailList.map(({ Name, Value }, i) => (
                            <div
                              key={`certDetailList-${i}`}
                              className="mb-1 p-2 border"
                            >
                              {Name && (
                                <div>
                                  {Name}
                                </div>
                              )}
                              {Value && (
                                <div className='h6 m-0'>
                                  <div
                                    className={badgeClassName(Value)}
                                  >
                                    {Value}
                                  </div>
                                </div>
                              )}
                            </div>
                          ))}
                        </div>
                      )}
                    </Collapse>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Certificates;
