import React, { useEffect, useState } from 'react';
import { TURBINE_LOGO } from '../../../../constants/assets';
import getImageMetadata from '../../../../utils/getImageMetadata';
import './style.css';

const Logo = ({
  orgName,
  orgLogo,
  height = 38,
  width = 100,
  className = ''
}) => {
  // local state
  const [imgReady, setImgReady] = useState(false);
  const [imgDimensions, setImgDimensions] = useState({});

  useEffect(() => {
    if (orgLogo) {
      getImageMetadata(orgLogo).then(({
        width: logoWidth,
        height: logoHeight
      }) => {
        // Landscape || Square
        if (logoHeight > logoWidth || logoWidth === logoHeight) {
          setImgDimensions({ height });
        }

        // Portrait
        if (logoWidth > logoHeight) {
          setImgDimensions({ width });
        }

        setImgReady(true);
      });
    }
  }, [orgLogo]);

  if (imgReady) {
    return (
      <div className="d-flex justify-content-center">
        <img
          src={orgLogo}
          className={className}
          alt={orgName}
          {...imgDimensions}
          style={{
            ...(imgDimensions?.height ? { height: imgDimensions.height } : {}),
            ...(imgDimensions?.width ? { width: imgDimensions.width } : {})
          }}
        />
      </div>
    );
  }

  return (
    <div className="authpage-logo mb-3">
      <img
        src={orgLogo || TURBINE_LOGO}
        alt={orgName || 'Turbine Workforce'}
      />
    </div>
  );
};

export default Logo;
