import { fetchApprenticeAgreement } from '../services/apprenticeAgreements';
import { getApprenticeshipUser } from './Apprenticeships';
import { getEmployerAgreement } from './EmployerAgreements';
import { getInstructionProvider } from './InstructionProviders';
import { getDefaultLocation } from './Locations';
import { getUserProfile } from './UserProfiles';
import { getWageSchedule } from './WageSchedules';
import {
  SET_APPRENTICE_AGREEMENT, RESET_APPRENTICE_AGREEMENT
} from './types';

export const getApprenticeAgreement = (id) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      fetchApprenticeAgreement(id).then((apprenticeAgreement) => {
        dispatch({ type: SET_APPRENTICE_AGREEMENT, apprenticeAgreement });

        // Program Sponsor
        if (apprenticeAgreement?.orgId) {
          dispatch(getDefaultLocation({
            orgId: apprenticeAgreement?.orgId,
            select: [
              'fields.name',
              'fields.addressLine1',
              'fields.addressLine2',
              'fields.addressCity',
              'fields.addressState',
              'fields.addressPostalCode',
              'fields.defaultLocation'
            ]
          }));
        }

        // Instruction Provider
        if (apprenticeAgreement?.instructionProviderId) {
          dispatch(getInstructionProvider(apprenticeAgreement?.instructionProviderId));
        }

        // Employer Agreement
        if (apprenticeAgreement?.employerAgreementId) {
          dispatch(getEmployerAgreement(apprenticeAgreement?.employerAgreementId));
        }

        // Employer Agreement
        if (apprenticeAgreement?.wageScheduleId) {
          dispatch(getWageSchedule(apprenticeAgreement?.wageScheduleId));
        }

        // User Profile
        if (apprenticeAgreement?.userProfileId) {
          dispatch(getUserProfile(apprenticeAgreement?.userProfileId));
        }

        // Apprenticeship User
        if (apprenticeAgreement?.userId) {
          dispatch(getApprenticeshipUser(apprenticeAgreement?.userId));
        }

        resolve(apprenticeAgreement);
      }).catch((error) => {
        console.error(error);
        reject(error);
      });
    });
  };
};

export const resetApprenticeAgreement = () => {
  return (dispatch) => {
    dispatch({ type: RESET_APPRENTICE_AGREEMENT });
  };
};
