import { fetchUser } from '../services/users';

import {
  SET_CURRENT_USER,
  SET_USER,
  RESET_USER,
  SET_DISPLAY_NAME,
  RESET_SPONSOR_USER,
  SET_SPONSOR_USER
} from './types';

export const setUser = (user) => {
  return (dispatch) => {
    dispatch({
      type: SET_USER,
      user
    });
  };
};

export const resetUser = () => {
  return (dispatch) => {
    dispatch({
      type: RESET_USER
    });
  };
};

export const setDisplayName = (displayName) => {
  return (dispatch) => {
    dispatch({
      type: SET_DISPLAY_NAME,
      displayName
    });
  };
};

export const setCurrentUser = (currentUser) => {
  return (dispatch) => {
    return new Promise((resolve) => {
      dispatch({
        type: SET_CURRENT_USER,
        currentUser
      });

      setTimeout(() => {
        resolve();
      }, 100);
    });
  };
};

export const getSponsorUser = (userId) => {
  return (dispatch) => {
    return new Promise((resolve) => {
      fetchUser({
        userId,
        select: [
          'id',
          'userSignature',
          'name'
        ]
      }).then((sponsorUser) => {
        dispatch({
          type: SET_SPONSOR_USER,
          sponsorUser
        });

        resolve(sponsorUser);
      });
    });
  };
};

export const resetSponsorUser = () => {
  return (dispatch) => {
    dispatch({ type: RESET_SPONSOR_USER });
  };
};
