import {
  SET_USER,
  RESET_USER,
  SET_DISPLAY_NAME,
  SET_AUTH_ACTION,
  RESET_AUTH_ACTION,
  SET_AUTH_ALERT,
  RESET_AUTH_ALERT,
  SET_CURRENT_PAGE,
  SET_CURRENT_USER,
  SET_CURRENT_CLASS,
  SET_CURRENT_TOPIC,
  RESET_CURRENT_TOPIC,
  SET_CURRENT_CLASS_EDIT,
  RESET_CURRENT_CLASS_EDIT,
  RESET_CURRENT_CLASS,
  // ORG
  SET_ORG,
  RESET_ORG,
  SET_ORGS,
  SET_ORG_EDIT,
  RESET_ORG_EDIT,
  SET_PAGE_ORG,
  RESET_PAGE_ORG,
  // COHORTS
  SET_CURRENT_COHORT,
  RESET_CURRENT_COHORT,
  // ENTRY
  SET_CURRENT_ENTRY_SUCCESS,
  SET_CURRENT_ENTRY_EDIT,
  RESET_CURRENT_ENTRY,
  RESET_CURRENT_ENTRY_EDIT,

  SET_LOADING,
  SET_TOASTS,
  LOGOUT,
  // MODALS
  SET_CURRENT_MODAL,
  RESET_CURRENT_MODAL,
  // CDN FILES
  SET_CURRENT_FILES,
  RESET_CURRENT_FILES,
  // JOB PROFILES
  SET_LOCATIONS,
  RESET_LOCATIONS,
  SET_COHORTS,
  RESET_COHORTS,
  SET_JOB_PROFILES,
  RESET_JOB_PROFILES,
  SET_JOB_PROFILE,
  RESET_JOB_PROFILE,
  SET_PATHWAY,
  RESET_PATHWAY,
  SET_COURSES,
  RESET_COURSES,
  SET_LOCATION,
  RESET_LOCATION,
  SET_EMPLOYER_PROFILE,
  RESET_EMPLOYER_PROFILE,
  SET_WAGE_SCHEDULES,
  RESET_WAGE_SCHEDULES,
  SET_APPRENTICESHIP,
  RESET_APPRENTICESHIP,
  SET_INVITE,
  SET_EMPLOYER_AGREEMENT,
  SET_DOCUMENT,
  SET_APPRENTICE_AGREEMENT,
  SET_INSTRUCTION_PROVIDER,
  SET_WAGE_SCHEDULE,
  SET_USER_PROFILE,
  SET_APPRENTICESHIP_USER,
  SET_DEFAULT_LOCATION,
  SET_SPONSOR_USER,
  SET_INTEGRATION,
  RESET_INTEGRATION,
  SET_SEARCH_USER_FEEDBACK
} from '../actions/types';
import { classPreReqs, topicPreReqs } from '../services/courses';
import { generateLocationsHash } from '../services/locations';

const initialState = {
  authAction: null,
  authAlert: null,
  organization: null,
  organizationEdit: null,
  orgLocation: null,
  pageOrganization: null,
  employerProfile: null,
  employerProfileFetched: false,
  organizations: null,
  locations: null,
  locationsHash: {},
  loading: false,
  membershipReadable: null,
  user: null,
  users: null,
  currentUser: null,
  // PATHWAY
  pathway: null,
  // COURSES
  courses: null,
  coursesFetched: false,
  classPreReqs: {},
  topicPreReqs: {}, // hash table of topic prerequisites.
  // classes: [],
  currentClassEdit: null,
  currentClass: null,
  currentPage: null,
  currentCohort: null,
  toasts: [],
  // FILES
  currentClassFiles: null,
  currentEntryFiles: null,
  // MODALS
  currentModal: {
    visible: false,
    key: null
  },
  // JOB PROFILE
  jobProfile: null,
  jobProfiles: null,
  // APPRENTICESHIP
  apprenticeship: null,
  apprenticeshipFetched: false,
  // APPRENTICESHIP: WAGE SCHEDULES
  wageSchedulesList: null,
  wageSchedulesFetched: false,
  invite: null,
  doc: null,
  // APPRENTICE AGREEMENT
  defaultLocation: null,
  apprenticeAgreement: null,
  instructionProvider: null,
  wageSchedule: null,
  userProfile: null,
  apprenticeshipUser: null,
  sponsorUser: null,
  // INTEGRATIONS
  integration: null,
  integrationFetched: false,
  searchUserFeedback: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CURRENT_FILES:
    case RESET_CURRENT_FILES:
      return {
        ...state,
        ...action // currentClassFiles, currentEntryFiles
      };
    case SET_AUTH_ACTION:
      return {
        ...state,
        authAction: action.authAction
      };
    case RESET_AUTH_ACTION:
      return {
        ...state,
        authAction: null
      };
    case SET_AUTH_ALERT:
      return {
        ...state,
        authAlert: action.authAlert
      };
    case RESET_AUTH_ALERT:
      return {
        ...state,
        authAlert: null
      };
    case SET_DOCUMENT:
      return {
        ...state,
        doc: action.doc
      };
    case SET_INVITE:
      return {
        ...state,
        invite: action.invite
      };
    case SET_EMPLOYER_AGREEMENT:
      return {
        ...state,
        employerAgreement: action.employerAgreement
      };
    // APPRENTICE AGREEMENT
    case SET_APPRENTICE_AGREEMENT:
      return {
        ...state,
        apprenticeAgreement: action.apprenticeAgreement
      };
    case SET_INSTRUCTION_PROVIDER:
      return {
        ...state,
        instructionProvider: action.instructionProvider
      };
    case SET_WAGE_SCHEDULE:
      return {
        ...state,
        wageSchedule: action.wageSchedule
      };
    case SET_USER_PROFILE:
      return {
        ...state,
        userProfile: action.userProfile
      };
    case SET_APPRENTICESHIP_USER:
      return {
        ...state,
        apprenticeshipUser: action.apprenticeshipUser
      };
    case SET_SPONSOR_USER:
      return {
        ...state,
        sponsorUser: action.sponsorUser
      };
    // /APPRENTICE AGREEMENT
    case SET_LOADING:
      return {
        ...state,
        loading: action.loading
      };
    case SET_JOB_PROFILES:
      return {
        ...state,
        jobProfiles: action.jobProfiles
      };
    case RESET_JOB_PROFILES:
      return {
        ...state,
        jobProfiles: initialState.jobProfiles
      };
    case SET_JOB_PROFILE:
      return {
        ...state,
        jobProfile: action.jobProfile
      };
    case RESET_JOB_PROFILE:
      return {
        ...state,
        jobProfile: initialState.jobProfile
      };
    case SET_PATHWAY:
      return {
        ...state,
        pathway: action.pathway
      };
    case RESET_PATHWAY:
      return {
        ...state,
        pathway: initialState.pathway
      };
    case SET_COURSES: {
      return {
        ...state,
        coursesFetched: true,
        courses: action.courses,
        classPreReqs: classPreReqs({ courses: action.courses }),
        topicPreReqs: topicPreReqs({ courses: action.courses })
      };
    }
    case RESET_COURSES:
      return {
        ...state,
        courses: initialState.courses,
        coursesFetched: initialState.coursesFetched
      };
    case SET_CURRENT_CLASS: {
      const {
        currentClass, topics, courseExperts
      } = action;

      return {
        ...state,
        currentClass,
        topics,
        courseExperts
      };
    }
    case SET_CURRENT_TOPIC:
      return {
        ...state,
        currentTopic: action.currentTopic
      };
    case RESET_CURRENT_TOPIC:
      return {
        ...state,
        currentTopic: null
      };
    case SET_CURRENT_CLASS_EDIT: {
      return {
        ...state,
        currentClassEdit: action.currentClassEdit
      };
    }
    case RESET_CURRENT_CLASS_EDIT: {
      return {
        ...state,
        currentClassEdit: null
      };
    }
    case RESET_CURRENT_CLASS: {
      return {
        ...state,
        currentClass: null,
        currentClassFiles: null,
        topics: null,
        currentEntry: null,
        currentEntryFiles: null
      };
    }
    case SET_CURRENT_COHORT:
      return {
        ...state,
        currentCohort: action.currentCohort
      };
    case RESET_CURRENT_COHORT:
      return {
        ...state,
        currentCohort: null
      };
    case SET_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.currentPage
      };
    case SET_CURRENT_ENTRY_SUCCESS:
      return {
        ...state,
        currentEntry: action.currentEntry,
        currentEntryFiles: action.currentEntryFiles
      };
    case SET_CURRENT_ENTRY_EDIT:
      return {
        ...state,
        currentEntryEdit: action.currentEntryEdit,
        currentEntryFiles: action.currentEntryFiles
      };
    case RESET_CURRENT_ENTRY:
      return {
        ...state,
        currentEntry: null,
        currentEntryFiles: null
      };
    case RESET_CURRENT_ENTRY_EDIT:
      return {
        ...state,
        currentEntryEdit: null,
        currentEntryFiles: null
      };
    case SET_USER:
      return {
        ...state,
        user: action.user
      };
    case RESET_USER:
      return {
        ...state,
        user: null,
        currentUser: null
      };
    case SET_CURRENT_USER:
      return {
        ...state,
        currentUser: action.currentUser
      };
    case SET_TOASTS:
      return {
        ...state,
        toasts: action.toasts
      };
    case SET_DISPLAY_NAME:
      return {
        ...state,
        user: {
          ...state.user,
          displayName: action.displayName
        }
      };
    case SET_COHORTS:
      return {
        ...state,
        ...(action.courseCohorts ? { courseCohorts: action.courseCohorts } : {}),
        ...(action.userCohorts ? { userCohorts: action.userCohorts } : {})
      };
    case RESET_COHORTS:
      return {
        ...state,
        courseCohorts: null
      };
    case SET_LOCATIONS:
      return {
        ...state,
        locations: action.locations,
        locationsHash: generateLocationsHash(action.locations)
      };
    case RESET_LOCATIONS:
      return {
        ...state,
        locations: initialState.locations,
        locationsHash: initialState.locationsHash
      };
    case SET_ORG:
      return {
        ...state,
        organization: action.organization
      };
    case RESET_ORG:
      return {
        ...state,
        organization: null
      };
    case SET_PAGE_ORG:
      return {
        ...state,
        pageOrganization: action.pageOrganization
      };
    case RESET_PAGE_ORG:
      return {
        ...state,
        pageOrganization: null
      };
    case SET_LOCATION:
      return {
        ...state,
        orgLocation: action.orgLocation
      };
    case SET_DEFAULT_LOCATION:
      return {
        ...state,
        defaultLocation: action.defaultLocation
      };
    case RESET_LOCATION:
      return {
        ...state,
        orgLocation: initialState.orgLocation
      };
    case SET_EMPLOYER_PROFILE:
      return {
        ...state,
        employerProfile: action.employerProfile,
        employerProfileFetched: true
      };
    case RESET_EMPLOYER_PROFILE:
      return {
        ...state,
        employerProfile: initialState.employerProfile,
        employerProfileFetched: initialState.employerProfileFetched
      };
    case SET_ORGS:
      return {
        ...state,
        organizations: action.organizations
      };
    case SET_ORG_EDIT: {
      return {
        ...state,
        organizationEdit: action.organizationEdit
      };
    }
    case RESET_ORG_EDIT: {
      return {
        ...state,
        organizationEdit: null
      };
    }
    case LOGOUT:
      return {
        ...initialState,
        organization: state.organization,
        locations: state.locations
      };
    case SET_CURRENT_MODAL:
      return {
        ...state,
        currentModal: action.currentModal
      };
    case RESET_CURRENT_MODAL:
      return {
        ...state,
        currentModal: initialState.currentModal
      };
    case SET_APPRENTICESHIP:
      return {
        ...state,
        apprenticeship: action.apprenticeship,
        apprenticeshipFetched: true
      };
    case RESET_APPRENTICESHIP:
      return {
        ...state,
        apprenticeship: initialState.apprenticeship,
        apprenticeshipFetched: initialState.apprenticeshipFetched
      };
    case SET_WAGE_SCHEDULES:
      return {
        ...state,
        wageSchedulesList: action.wageSchedulesList,
        wageSchedulesFetched: true
      };
    case RESET_WAGE_SCHEDULES:
      return {
        ...state,
        wageSchedulesList: initialState.wageSchedulesList,
        wageSchedulesFetched: initialState.wageSchedulesFetched
      };
    case SET_SEARCH_USER_FEEDBACK:
      return {
        ...state,
        searchUserFeedback: action.searchUserFeedback !== null ? action.searchUserFeedback : null
      };
    case SET_INTEGRATION:
      return {
        ...state,
        integration: action.integration,
        integrationFetched: true
      };
    case RESET_INTEGRATION:
      return {
        ...state,
        integration: initialState.integration,
        integrationFetched: initialState.integrationFetched
      };
    default:
      return state;
  }
};

export default reducer;
