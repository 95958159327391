import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { pathOr } from 'ramda';
import RenderMarkdown from '../../ManageContent/RenderMarkdown';
import { getClassImage } from '../../../utils/image';
import PreReqStatus from '../PreReqStatus';
import GoLive from '../../GoLive';
import OrgAuthor from '../OrgAuthor';
import './ribbon.css';
import './style.css';

const Card = ({
  course,
  classDashboard,
  className = ''
}) => {
  // Redux
  const organization = useSelector((state) => state.organization);
  // Misc
  const orgId = pathOr(null, ['id'], organization);
  const {
    title,
    description,
    topics: tags,
    schedule,
    testClass,
    streamingLink,
    classColor,
    classImage,
    enableNewEditor,
    orgId: courseOrgId,
    orgIds: courseOrgIds
  } = course;

  return (
    <div
      className={`class-card-container ${className}`}
    >
      <div className="card mb-4 shadow-sm">
        { getClassImage({ classImage, classColor, title }) }

        {classImage && (
          <div
            className="p-1"
            style={{ backgroundColor: classColor }}
          />
        )}

        <div className="card-body p-3">
          <h5>
            <b>{ title }</b> {testClass && (<Fragment><span className="badge badge-warning">Test Class</span></Fragment>)}
          </h5>
          <div className="card-text">
            <RenderMarkdown
              source={description}
              enableNewEditor={enableNewEditor}
            />
          </div>

          {tags && (
            <div className="mb-2">
              <b>Tags:</b>
              <p className="text-muted mt-1 mb-0">
                { tags.join(', ') }
              </p>
            </div>
          )}

          {schedule && (
            <div className='mb-2'>
              <b>Schedule:</b>
              <div className="text-muted mt-1">
                <RenderMarkdown
                  source={schedule}
                  enableNewEditor={enableNewEditor}
                />
              </div>
            </div>
          )}

          <PreReqStatus
            course={course}
            className="mb-2"
          />
        </div>

        {orgId && courseOrgIds && courseOrgIds.includes(orgId) && (
          <OrgAuthor
            className="border-top bg-white px-3 py-2"
            orgId={courseOrgId}
          />
        )}

        <div className="go-live-slim-banner-container bg-light p-2 border-top">
          <GoLive
            streamingLink={streamingLink}
            title={title}
            slim
            className="mb-0"
          />
        </div>

        <div className="card-footer p-3">
          <span>Not enrolled</span>
        </div>

        <div className={`ribbon-wrapper ${classDashboard ? 'ribbon-class-dashboard' : ''}`}>
          <div className="ribbon alert-info">
            <i className="fas fa-chalkboard-teacher" /> Live!
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;
