import {
  ICON_FILE,
  ICON_FILE_EXCEL,
  ICON_FILE_IMAGE,
  ICON_FILE_PDF,
  ICON_LINK,
  ICON_RESOURCE
} from '@apprentage/constants';
// import * as aws from './aws';
import { fetchSupabaseEntries } from './supabaseProxy';
import { vectorSearch } from './openAi';
import { fetchDocuments } from './documents';

const table = 'resources';

export const fileType = (type = '') => {
  switch (type) {
    case 'material':
      return {
        name: 'material',
        icon: ICON_FILE
      };
    case 'link':
      return {
        name: 'link',
        icon: ICON_LINK
      };
    case 'image/jpg':
    case 'image/jpeg':
    case 'image/png':
    case 'image/gif':
      return {
        name: 'image',
        icon: ICON_FILE_IMAGE
      };
    case 'application/pdf':
      return {
        name: 'excel',
        icon: ICON_FILE_PDF
      };
    case 'application/vnd.ms-excel':
      return {
        name: 'excel',
        icon: ICON_FILE_EXCEL
      };
    case 'video/mp4':
      return {
        name: 'video',
        icon: ICON_RESOURCE
      };
    default:
      return {
        name: 'file',
        icon: ICON_FILE
      };
  }
};

export const fetchResources = async ({
  orgId,
  parentId,
  parentType,
  name,
  body,
  ids,
  order = 'name',
  select,
  limit = 1000
}) => {
  const params = {
    order,
    limit
  };

  if (orgId) {
    params.orgId = orgId;
    params['f.orgId[eq]'] = orgId;
  }

  if (parentId) {
    params['f.parentId[eq]'] = parentId;
  }

  if (parentType) {
    params['f.parentType[eq]'] = parentType;
  }

  if (name) {
    params['f.name[ilike]'] = name;
  }

  if (body) {
    params['f.body[ilike]'] = body;
  }

  if (Array.isArray(select) && select.length) {
    params.select = select.join(',');
  }

  if (Array.isArray(ids) && ids.length) {
    params.ids = ids.join(',');
  }

  if (!Object.values(params).length) {
    throw new Error('Missing params', params);
  }

  const response = await fetchSupabaseEntries(params, table);

  return response;
};

export const fetchResourcesByVectorText = ({
  orgId,
  locationId,
  userId,
  integrationId,
  saveSearch,
  searchText,
  contentTypes,
  prompt,
  parentType,
  searchGroupIds,
  count
}) => {
  return new Promise((resolve, reject) => {
    vectorSearch({
      orgId,
      locationId,
      userId,
      ...(integrationId ? { integrationId } : {}),
      parentType,
      ...(searchGroupIds ? { parentIds: searchGroupIds } : {}),
      contentTypes,
      saveSearch,
      ...(searchText ? { searchText } : {}),
      ...(prompt ? { prompt } : {}),
      threshold: 0.30,
      count
    }).then((vecResponse) => {
      // Response: [{
      //   id: (embeddingId),
      //   refId: (resourceId),
      //   similarity: (cosign similarity score)
      // }, {...}]
      if (Array.isArray(vecResponse?.items) && vecResponse?.items?.length) {
        const ids = vecResponse.items.reduce((acc, curr) => {
          if (!acc.includes(curr.id)) {
            // Make sure ids are unique
            acc.push(curr.id);
          }
          return acc;
        }, []);
        const refIds = vecResponse.items.reduce((acc, curr) => {
          if (!acc.includes(curr.refId)) {
            // Make sure refIds are unique
            acc.push(curr.refId);
          }
          return acc;
        }, []);
        fetchDocuments({
          orgId,
          ids,
          select: ['rawText', 'id', 'refId', 'pageNumber', 'metadata'],
          limit: count
        }).then((responseDocs) => {
          fetchResources({
            orgId,
            ids: refIds
          }).then((response) => {
            const vectorNodes = [];
            const groupedEmbeddings = responseDocs.items.reduce((acc, curr) => {
              if (acc[curr.refId] === undefined) {
                acc[curr.refId] = [];
              }
              const resource = response.items.find((r) => r.id === curr?.refId);
              const item = vecResponse.items.find((vec) => vec.id === curr?.id);

              acc[curr.refId].push({
                ...curr,
                similarity: item?.similarity,
                resource
              });

              vectorNodes.push({
                ...curr,
                similarity: item?.similarity,
                resource
              });

              return acc;
            }, {});

            const vectorFiles = response?.items.reduce((acc, curr) => {
              const item = vecResponse.items.find((vec) => vec.refId === curr?.id);
              acc.push({
                ...curr,
                similarity: item?.similarity,
                embeddings: groupedEmbeddings[curr?.id]
              });

              return acc;
            }, []);

            resolve({
              vectorSearchId: vecResponse?.searchId,
              vectorFileIds: refIds,
              vectorFiles,
              vectorNodes,
              resourceVecIds: vecResponse?.resourceVecIds
            });
          }).catch((error) => {
            console.error(error);
            reject(error);
          });
        });
      } else {
        resolve({ vectorFiles: [] });
      }
    });
  });
};
