import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { findIndex, pathOr, propEq } from 'ramda';
import Modal from '../../Modal';
import { resetCurrentModal } from '../../../actions';
import { isLocalhost } from '../../../serviceWorker';
import sortArrByNumericKey from '../../../utils/sortArrByNumericKey';
import sortArrByArr from '../../../utils/sortArrByArr';
import { sponsoredSchools, sponsoredSchoolsLogos } from '../../../constants/pathways';

const EditOccupation = () => {
  const dispatch = useDispatch();
  const currentModalKey = 'editOccupation';
  const currentModal = useSelector((state) => state.currentModal);
  const modalKey = pathOr(null, ['key'], currentModal);
  const modalData = pathOr(null, ['data'], currentModal);
  const {
    dataList,
    title,
    mustSelectOne,
    saveDataList,
    currentData,
    handleCancel
  } = modalData;
  // const [data] = useState(currentData);
  const [selectedData, setSelectedData] = useState(currentData);

  const handleClick = ({ element, isSelected }) => {
    const {
      ElementId, Importance, Id, SchoolName
    } = element;
    const key = ElementId || Id || SchoolName;
    let idKey = '';

    if (ElementId) idKey = 'ElementId'; // Skill || Knowledge
    if (Id) idKey = 'Id'; // Certs
    if (SchoolName) idKey = 'SchoolName'; // School Programs

    const elementIndex = isSelected ? findIndex(propEq(idKey, key))(selectedData) : -1;

    let data = selectedData ? [...selectedData] : [];

    if (elementIndex !== -1) {
    // Remove element
      data.splice(elementIndex, 1);
    } else {
    // Add Element
      data.push(element);
    }

    if (Importance) {
    // Skills || Knowledge
      data = sortArrByNumericKey(data, 'Importance');
    }

    if (SchoolName) {
    // Skills || Knowledge
      data = sortArrByArr(data, dataList, 'SchoolName');
    }

    setSelectedData(data);
  };

  const handleClose = () => {
    dispatch(resetCurrentModal());
    handleCancel();
  };

  return (
    <Modal
      cssClassName={`turbine-modal--style-card turbine-modal--${modalKey}`}
      visible={modalKey === currentModalKey}
      close={false}
      pos="top"
      theme="dark"
    >
      <div className="EditOccupationElement-container">
        <div className="card h-100">
          <div className="card-header d-flex align-items-center justify-content-between">
            <div className="h5 mb-0">
              Choose
              {' '}
              {title}
            </div>
            <div className="h5">
              <span className="badge badge-primary">
                {selectedData.length}
              </span>
            </div>
          </div>
          <div className="card-body">
            <div className="row overflow-auto" style={{ height: '70vh' }}>
              {dataList && (
              <div className="mb-4 w-100">
                {dataList.map((element, i) => {
                  const {
                  // Skills || Knowledge
                    ElementName,
                    ElementDescription,
                    ElementId,
                    Importance,
                    // Certs
                    Id,
                    Name,
                    Description,
                    Type,
                    // School Programs
                    ID,
                    ProgramName,
                    SchoolName,
                    StateAbbr
                  } = element;

                  let hasPrivacy = !!SchoolName;

                  if (SchoolName && sponsoredSchools.includes(SchoolName)) {
                  // School is Sponsored
                    hasPrivacy = false;
                  }

                  if (isLocalhost) hasPrivacy = false;

                  const id = ElementId || Id || ID;
                  const key = ElementId || Id || SchoolName;
                  const name = ElementName || Name || ProgramName;

                  let idKey = '';
                  if (ElementId) idKey = 'ElementId'; // Skill || Knowledge
                  if (Id) idKey = 'Id'; // Certs
                  if (SchoolName) idKey = 'SchoolName'; // School Programs

                  let description = ElementDescription || Description || SchoolName;
                  if (hasPrivacy) {
                    description = 'Upgrade to view school name';
                  }

                  const info = Importance || Type || StateAbbr;

                  let infoLabel = '';
                  if (Importance) infoLabel = 'Importance'; // Skill || Knowledge
                  if (Type) infoLabel = 'Type'; // Certs
                  if (StateAbbr) infoLabel = 'State'; // School Programs

                  const isSelected = currentData.find((d) => d[idKey] === key);

                  return (
                    <div
                      key={`element-${i}`}
                      className="input-group mb-3 w-100 flex-nowrap"
                    >
                      <div className="input-group-prepend">
                        <div className="input-group-text">
                          <input
                            id={`id-${i}`}
                            type="checkbox"
                            aria-label={`Checkbox for ${name}`}
                            value={id}
                            defaultChecked={!!isSelected}
                            onChange={() => {
                              handleClick({
                                element,
                                isSelected: !!isSelected
                              });
                            }}
                          />
                        </div>
                      </div>
                      <label
                        className="border m-0 flex-fill"
                        htmlFor={`id-${i}`}
                      >
                        <div className="p-3 w-100">
                          <div
                            className="font-weight-bold"
                            style={{ fontSize: '1rem' }}
                          >
                            {name}
                          </div>
                          <div className={`mt-3 d-flex align-items-center ${hasPrivacy ? 'privacy-blur' : ''}`}>
                            {SchoolName && sponsoredSchools.includes(SchoolName) && (
                            <img
                              src={sponsoredSchoolsLogos[SchoolName]}
                              height="30"
                              style={{ height: '30px' }}
                              className="mr-2"
                              alt={`${SchoolName} Logo`}
                            />
                            )}

                            <span>
                              {description}
                            </span>
                          </div>
                        </div>
                        <div className="card-footer text-muted small d-flex align-items-center justify-content-between">
                          <div className="h6 mb-0">
                            <small>
                              {infoLabel}
                              :
                            </small>
                            {' '}
                            <span className="badge badge-secondary ml-1">{info}</span>
                          </div>
                          <div className="text-muted">
                            ID:
                            {' '}
                            {id}
                          </div>
                        </div>
                      </label>
                    </div>
                  );
                })}

                {/* Add custom */}
                {/* <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <div className="input-group-text">
                    <input type="checkbox" aria-label="Checkbox for following text input" />
                  </div>
                </div>
                <input type="text" className="form-control" aria-label="Text input with checkbox" />
              </div> */}

              </div>
              )}
            </div>
          </div>
          <div className="card-footer">
            <button
              className="btn btn-md btn-primary"
              type="button"
              onClick={() => {
                if (mustSelectOne) {
                  if (selectedData.length === 0) {
                    toast.error('Select at least one.');
                    return null;
                  }
                }
                handleClose();
                saveDataList(selectedData);
              }}
            >
              Save
              {' '}
              {title}
            </button>
            <button
              type="button"
              className="btn btn-md btn-link ml-1"
              onClick={handleCancel}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default (EditOccupation);
