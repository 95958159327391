import {
  client,
  clientManagement,
  CSPACE
} from '../services/contentful';
// import { formatData } from '../services/formatData';
import removeObjectFromArray from '../utils/removeObjectFromArray';

import {
  SET_CURRENT_ENTRY_SUCCESS,
  SET_CURRENT_ENTRY_EDIT,
  UPDATE_CURRENT_ENTRY_SUCCESS,
  RESET_CURRENT_ENTRY,
  RESET_CURRENT_ENTRY_EDIT,
  DELETE_ENTRY_SUCCESS,
  SET_CURRENT_FILES,
  RESET_CURRENT_FILES
} from './types';

export const getEntry = (entryId) => {
  return new Promise((resolve, reject) => {
    client.getEntry(entryId).then((response) => {
      resolve(response);
    }).catch((error) => {
      console.error(error);
      reject(error);
    });
  });
};

export const setCurrentEntry = (entryId) => {
  return (dispatch) => {
    return client.getEntry(entryId).then((response) => {
      const data = {
        type: SET_CURRENT_ENTRY_SUCCESS,
        currentEntry: response,
        currentEntryFiles: response
          && response.fields
          && response.fields.integration
          && response.fields.integration.cdnFiles
      };

      dispatch(data);
    }).catch((error) => {
      console.error(error);
    });
  };
};

export const setCurrentEntryEdit = ({ entryId }) => {
  return (dispatch) => {
    return client.getEntry(entryId).then((response) => {
      dispatch({
        type: SET_CURRENT_ENTRY_EDIT,
        currentEntryEdit: response,
        currentEntryFiles: response
          && response.fields
          && response.fields.integration
          && response.fields.integration.cdnFiles
      });
    }).catch((error) => {
      console.error(error);
    });
  };
};

export const resetCurrentEntry = () => {
  return (dispatch) => {
    dispatch({ type: RESET_CURRENT_ENTRY });
  };
};

export const resetCurrentEntryEdit = () => {
  return (dispatch) => {
    dispatch({ type: RESET_CURRENT_ENTRY_EDIT });
  };
};

export const updateCurrentEntry = ({ data, id, dataFormatted }) => {
  const {
    // Shared
    title,
    body,
    prerequisite,
    locked
  } = data;

  return (dispatch) => {
    return new Promise((resolve, reject) => {
      // Update entry
      clientManagement.getSpace(CSPACE)
        .then((space) => space.getEnvironment('master'))
        .then((environment) => environment.getEntry(id))
        .then((entry) => {
        // TODO use formatData for this section
          if (locked !== undefined) {
            entry.fields.locked = {
              'en-US': locked
            };
          }

          if (title !== undefined) {
            entry.fields.title = {
              'en-US': title
            };
          }

          if (body !== undefined) {
            entry.fields.body = {
              'en-US': body
            };
          }

          if (prerequisite !== undefined) {
            entry.fields.prerequisite = {
              'en-US': prerequisite // TODO, ref may be needed
            };
          }

          // TODO rm everything above once all data passed to this function
          // is pre-formatted
          if (dataFormatted) {
            entry.fields = {
              ...entry.fields, // copy existing data
              ...data.fields // updated formatted data
            };
          }

          return entry.update();
        })
        .then((entry) => entry.publish())
        .then((entry) => {
          setTimeout(() => {
            dispatch({ type: UPDATE_CURRENT_ENTRY_SUCCESS });
            resolve(entry);
          }, 250);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  };
};

export const deleteEntry = (id) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      // Update entry
      clientManagement.getSpace(CSPACE)
        .then((space) => space.getEnvironment('master'))
        .then((environment) => environment.getEntry(id))
        .then((entry) => entry.unpublish())
        .then((entry) => entry.delete())
        .then((entry) => {
          setTimeout(() => {
            dispatch({ type: DELETE_ENTRY_SUCCESS });
            resolve(entry);
          }, 500);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  };
};

/**
 *
 * @summary returns key to be used in dispatch event
 * @param {string} type class, topic, entry (material)
 */
function keyFromType(type) {
  // Construct Dispatch Data based on type [class, topic, entry (everything else)]
  switch (type) {
    case 'class':
      return 'currentClassFiles';
    default:
      return 'currentEntryFiles';
  }
}

export const saveCdnFiles = ({ data, id, type }) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      // Update entry
      clientManagement.getSpace(CSPACE)
        .then((space) => space.getEnvironment('master'))
        .then((environment) => environment.getEntry(id))
        .then((entry) => {
          if (data.cdnFiles) {
          // if no integration key, create default structure
            if (entry.fields.integration === undefined) {
              entry.fields.integration = {
                'en-US': {}
              };
            }

            let newFiles = [];

            if (entry.fields.integration['en-US'].cdnFiles === undefined) {
            // if cdnFiles undefined, set to data
              newFiles = data.cdnFiles;
            } else {
            // has cdnFiles, spread existing, add new from data
              newFiles = [
                ...entry.fields.integration['en-US'].cdnFiles,
                ...data.cdnFiles
              ];
            }

            entry.fields.integration['en-US'].cdnFiles = newFiles;

            dispatch({
              type: SET_CURRENT_FILES,
              [keyFromType(type)]: newFiles // dynamic key, ex: current[type]Files
            });
          }

          return entry.update();
        })
        .then((entry) => entry.publish())
        .then((entry) => {
          resolve(entry);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
};

export const updateCdnFiles = ({ file, id, type }) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      // Update entry
      clientManagement.getSpace(CSPACE)
        .then((space) => space.getEnvironment('master'))
        .then((environment) => environment.getEntry(id))
        .then((entry) => {
          if (file) {
          // Has integration
            if (entry.fields.integration && entry.fields.integration['en-US']) {
            // Has cdnFiles
              if (entry.fields.integration['en-US'].cdnFiles) {
                const cdnFilesLen = entry.fields.integration['en-US'].cdnFiles.length;

                if (cdnFilesLen === 1) {
                // Only one file in cdnFiles, delete the key
                  delete entry.fields.integration['en-US'].cdnFiles;

                  const integrationKeys = Object.keys(entry.fields.integration['en-US']);

                  if (integrationKeys.length === 0) {
                  // No other keys in integration, delete key
                    delete entry.fields.integration;
                  }

                  dispatch({
                    type: RESET_CURRENT_FILES,
                    [keyFromType(type)]: null // dynamic key, ex: current[type]Files
                  });
                } else {
                  const newFiles = removeObjectFromArray(entry.fields.integration['en-US'].cdnFiles, 'url', file.url);

                  entry.fields.integration['en-US'].cdnFiles = newFiles;

                  dispatch({
                    type: SET_CURRENT_FILES,
                    [keyFromType(type)]: newFiles
                  });
                }
              }
            }
          }

          return entry.update();
        })
        .then((entry) => entry.publish())
        .then((entry) => {
          resolve(entry);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
};
