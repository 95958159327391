import { sign, verify } from 'jsonwebtoken';
import { ERROR_AUTH_BAD_TOKEN } from '@apprentage/constants';

export const encodePayload = (obj) => {
  return sign(obj, process.env.REACT_APP_AUTH_TOKEN_SECRET);
};

export const decodePayload = (encodedPayload) => {
  return new Promise((resolve) => {
    const secret = process.env.REACT_APP_AUTH_TOKEN_SECRET;

    verify(encodedPayload, secret, (err, decoded) => {
      if (err) {
        resolve({
          error: {
            code: ERROR_AUTH_BAD_TOKEN,
            message: err.message
          }
        });
      }

      resolve(decoded);
    });
  });
};
