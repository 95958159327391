import React from 'react';
import isPublished from '../../../utils/isPublished';

const PreReqStatus = ({
  course,
  className = '',
  slim
}) => {
  if (!course) return null;

  const { prerequisite } = course; // TODO course flatten (prerequisteIds)
  if (!isPublished(prerequisite)) return null;

  if (slim) {
    return ( // TODO course flatten
      <div className="text-muted">
        <b>Prereq:</b> {prerequisite.fields.title}
      </div>
    );
  }

  return (
    <div className={className}>
      <b>Prerequisite:</b>
      <p className="text-muted mt-1 mb-0 d-flex align-items-center">
        { prerequisite.fields.title }
      </p>
    </div>
  );
};

export default PreReqStatus;
