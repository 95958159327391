import { toast } from 'react-toastify';
import {
  client, clientManagement, CSPACE, flattenItems, referenceType
} from './contentful';
import * as twApi from './tw-api';
import { TURBINE_ADMIN_EMAIL } from '../constants/globals';
import removeObjectFromArray from '../utils/removeObjectFromArray';
import isPublished from '../utils/isPublished';

export const fetchCourses = ({ orgId, select }) => {
  const config = {
    content_type: 'class',
    include: 1,
    'fields.orgId': orgId
  };

  if (select) {
    config.select = select.join(',');
  }

  return new Promise((resolve, reject) => {
    client.getEntries(config).then(({ items }) => {
      const courses = items.length ? flattenItems(items) : null;

      resolve(courses);
    }).catch((error) => {
      console.error(error);
      reject(error);
    });
  });
};

export const fetchSyndicatedCourses = ({ orgIds, select }) => {
  const config = {
    content_type: 'class',
    include: 1,
    'fields.orgIds[in]': orgIds.join(',')
  };

  if (select) {
    config.select = select.join(',');
  }

  return new Promise((resolve, reject) => {
    client.getEntries(config).then(({ items }) => {
      const syndicatedCourses = items.length ? flattenItems(items) : null;

      resolve(syndicatedCourses);
    }).catch((error) => {
      console.error(error);
      reject(error);
    });
  });
};

export const classPreReqs = ({ courses }) => {
  const hash = {};

  if (!courses) return hash;

  courses.forEach((course) => {
    if (course.prerequisite) {
      hash[course.id] = course.prerequisite.sys.id;
    }
  });

  return hash;
};

export const topicPreReqs = ({ courses }) => {
  const hash = {};

  if (!courses) return hash;

  courses.forEach((course) => {
    const { subjects } = course;

    if (subjects) {
      subjects.forEach((subject) => {
        if (isPublished(subject) && subject.fields.prerequisite) { // TODO course flatten
          hash[subject.sys.id] = subject.fields.prerequisite.sys.id;
        }
      });
    }
  });

  return hash;
};

/**
 * Checks if cohorts exists and have eUnitTracking
 * @param {obj} currentClass // { sys, fields }
 */
export const hasContinueEducationSettings = ({ currentClass }) => {
  if (currentClass && currentClass.fields) {
    const { fields: { cohorts } } = currentClass;

    const firstCohort = cohorts && cohorts.length && cohorts[0] !== undefined;

    if (firstCohort) {
      if (cohorts[0].fields && cohorts[0].fields.eUnitTracking) {
        return true;
      }
    }
  }

  return false;
};

/**
 * Invites users to onboard by sending an invite that contains a link to register
 * @param {string} emails // 'alan.mabry@gmail.com' comma separated emails
 * @param {string} link
 * @returns {Promise<string>} emails, link
 */
export const inviteUsers = ({
  emails, link, orgName, orgEmail
}) => {
  return new Promise((resolve, reject) => {
    // Turbine gmail api
    const to = orgEmail || TURBINE_ADMIN_EMAIL;
    const emailParts = {
      to,
      from: {
        name: orgName
      },
      replyTo: to,
      bcc: emails,
      subject: `Invitation to Join ${orgName}`,
      body: '',
      htmlBody: `<p>You've been invited to join ${orgName}'s portal. Click the link below:</p> <br/> ${link}`
    };

    return twApi.post('/v1/external/gmail/send', '', JSON.stringify(emailParts)).then((response) => {
      if (response && response.status === 200) {
        toast.success('Invitations sent!');
        resolve({ emails, link });
      } else {
        toast.error(response.message); // ex: 'Something went wrong, try again'
        console.error(response.status); // ex: 'error_invitation_fail'

        reject({
          message: response.message,
          status: response.status
        });
      }
    }).catch((error) => {
      console.error(error);
    });
  });
};

export const routeWithClassId = (route, classId) => `${route}?classId=${classId}`;

export const addTopicToClass = ({ topicId, classId }) => {
  return new Promise((resolve, reject) => {
    // Update Class
    return clientManagement.getSpace(CSPACE)
      .then((space) => space.getEnvironment('master'))
      .then((environment) => environment.getEntry(classId))
      .then((entry) => {
      // TODO remove subjects once topicIds and topic migration is complete
        if (entry.fields.subjects === undefined) {
          entry.fields.subjects = {
            'en-US': []
          };
        }

        entry.fields.subjects = {
          'en-US': [
            ...entry.fields.subjects['en-US'],
            referenceType(topicId)
          ]
        };

        // topicIds - new data structure
        if (entry.fields.topicIds === undefined) {
          entry.fields.topicIds = { 'en-US': [] };
        }

        entry.fields.topicIds = {
          'en-US': [
            ...entry.fields.topicIds['en-US'],
            topicId
          ]
        };

        return entry.update();
      })
      .then((entry) => entry.publish())
      .then((entry) => {
        resolve(entry);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const removeTopicFromClass = ({ topicId, classId }) => {
  return new Promise((resolve, reject) => {
    // Update entry
    return clientManagement.getSpace(CSPACE)
      .then((space) => space.getEnvironment('master'))
      .then((environment) => environment.getEntry(classId))
      .then((entry) => {
        const classSubjects = entry.fields.subjects['en-US'];
        const subjects = removeObjectFromArray(classSubjects, 'sys.id', topicId);
        // TODO consider case when removing last topic from class
        entry.fields.subjects = {
          'en-US': subjects
        };

        // TODO update logic to not use subjects once topicIds and topic migration complete
        if (subjects && Array.isArray(subjects) && subjects.length > 0) {
          if (entry.fields.topicIds === undefined) {
            entry.fields.topicIds = { 'en-US': [] };
          }

          entry.fields.topicIds['en-US'] = subjects.map((subject) => subject.sys.id);
        } else {
          entry.fields.topicIds['en-US'] = undefined;
        }

        return entry.update();
      })
      .then((entry) => entry.publish())
      .then((entry) => {
        setTimeout(() => {
          resolve(entry);
        }, 250);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
