import { DEFAULT_PROFILE_PIC } from '@apprentage/constants';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { fetchUser } from '../../services/currentUser';

export const ProfilePictureImgContainer = styled.div`
  border: 1px solid #2c3a47;
  border-radius: 100%;
  margin: 0 auto;
  overflow: hidden;
  height: ${(props) => props.height}px;
  width: ${(props) => props.width}px;

  img {
    height: ${(props) => props.height}px;
    width: ${(props) => props.width}px;
  }
`;

const NotificationAvatar = styled.div`
  border-radius: 100%;
  font-family: 'Arial Bold', sans-serif;
  font-size: 1.25rem;
  font-weight: bold;
  height: 40px;
  width: 40px;
`;

function avatar(text) {
  const textArr = text.split('');

  return textArr[0];
}

const UserProfilePicture = ({
  userId,
  height = '40',
  width = '40',
  src,
  alt,
  select = [
    'id',
    'profilePicture',
    'name'
  ]
}) => {
  const [imgSrc, setImgSrc] = useState(src || DEFAULT_PROFILE_PIC);
  const [imgAlt, setImgAlt] = useState(alt || '');

  useEffect(() => {
    if (userId) {
      fetchUser({ userId, select }).then((response) => {
        if (response?.profilePicture && response?.name) {
          setImgAlt(response?.name || '');
          setImgSrc(response?.profilePicture);
        }
      });
    }
  }, [select, userId]);

  if (!imgSrc) {
    return (
      <NotificationAvatar
        className="d-flex align-items-center justify-content-center border bg-light"
      >
        {avatar(alt)}
      </NotificationAvatar>
    );
  }

  return (
    <ProfilePictureImgContainer
      height={height}
      width={width}
    >
      <img
        src={imgSrc}
        className="w-100"
        alt={imgAlt}
      />
    </ProfilePictureImgContainer>
  );
};

export default UserProfilePicture;
