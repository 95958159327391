import Cookies from 'js-cookie';
import { TURBINE_APP_VERSION } from '../constants/globals';
import { client, flattenItem } from './contentful';

const { navigator, localStorage } = window;

export const fetchOrg = ({
  orgId, slug, select, include
}) => {
  const config = {
    content_type: 'organization',
    include
  };

  if (orgId) {
    config['sys.id'] = orgId;
  } else if (slug) {
    config['fields.slug'] = slug;
  }

  if (select) {
    config.select = select.join(',');
  }

  return new Promise((resolve, reject) => {
    client.getEntries(config).then(({ items }) => {
      const organization = items[0] !== undefined ? flattenItem(items[0]) : null;

      resolve(organization);
    }).catch((error) => {
      console.error(error.message);
      reject(error);
    });
  });
};

function setUserAppVersion() {
  if (navigator.cookieEnabled) {
    Cookies.set('upstream_app_version', TURBINE_APP_VERSION);
  }

  if (localStorage) {
    localStorage.setItem('upstream_app_version', TURBINE_APP_VERSION);
  }
}

function getUserAppVersion() {
  if (navigator.cookieEnabled) {
    return Cookies.get('upstream_app_version');
  }

  if (localStorage) {
    return localStorage.getItem('upstream_app_version');
  }

  return false;
}

function setUserLoggedIn() {
  const newDate = new Date().toString();

  if (navigator.cookieEnabled) {
    Cookies.set('upstream_user_logged_in', newDate);
  }

  if (localStorage) {
    localStorage.setItem('upstream_user_logged_in', newDate);
  }
}

function getUserLoggedIn() {
  if (navigator.cookieEnabled) {
    return Cookies.get('upstream_user_logged_in');
  }

  if (localStorage) {
    return localStorage.getItem('upstream_user_logged_in');
  }

  return false;
}

function rmUserLoggedIn() {
  if (navigator.cookieEnabled) {
    Cookies.remove('upstream_user_logged_in');
  }

  if (localStorage) {
    localStorage.removeItem('upstream_user_logged_in');
  }
}

function usingLatestAppVersion() {
  const versionFromBrowser = getUserAppVersion();

  if (TURBINE_APP_VERSION === versionFromBrowser) {
    return true;
  }

  setUserAppVersion();

  return false;
}

export {
  setUserAppVersion,
  getUserAppVersion,
  usingLatestAppVersion,
  setUserLoggedIn,
  getUserLoggedIn,
  rmUserLoggedIn
};
