import React from 'react';
import { hrMin } from '../../../utils/date';
import RenderMarkdown from '../../ManageContent/RenderMarkdown';
import UserProfilePicture from '../UserProfilePicture';
import './three-dot-loader.css';
import './style.css';

const MessageContainer = ({
  id,
  agentImage,
  colorBtnBkg,
  className = 'mb-3',
  orgName,
  authorName,
  text,
  date = new Date().toISOString(),
  postAsCpu,
  children,
  onlyMessage = false
}) => {
  return (
    <div
      key={`message-${id}`}
      className={`MessageContainer ${className}`}
    >
      <div
        style={{
          display: onlyMessage ? 'none' : 'flex',
          justifyContent: 'center'
        }}
      >
        {postAsCpu ? (
          <div>
            <div
              className="MessageAvatar"
              style={{
                background: colorBtnBkg
              }}
            >
              <img
                src={agentImage}
                alt={orgName}
                height={28}
                style={{
                  height: '28px'
                }}
              />
            </div>
          </div>
        ) : (
          <div>
            <UserProfilePicture
              alt={orgName}
            />
          </div>
        )}
      </div>

      <div
        className="ml-3 w-100"
      >
        <div className="d-flex align-items-end justify-content-between mb-1">
          <div
            className='text-dark' // opacity-75
            style={{ fontSize: '1rem' }}
          >
            <div className='font-weight-bold'>
              {postAsCpu ? orgName : authorName}
            </div>
            {postAsCpu && !onlyMessage && (
              <div
                className=""
                style={{
                  fontSize: '14px',
                  display: onlyMessage ? 'none' : 'block'
                }}
              >
                AI Agent
              </div>
            )}
          </div>
          <div className='d-flex flex-column align-items-end'>
            {!onlyMessage && (
              <div
                className='text-nowrap'
                style={{ fontSize: '11px' }}
              >
                {hrMin(date)}
              </div>
            )}
            {/* {postAsCpu && (
            <PromptDotMenu
              key={material?.id || id}
              output={output}
              outputTitle={outputTitle}
              resourceId={resource?.id}
              promptId={id}
              promptType={promptType}
              promptText={promptText}
            />
          )} */}
          </div>
        </div>
        <div
          className="shadow p-3 rounded bg-white position-relative"
          style={{
            wordBreak: 'break-word'
          }}
        >
          {children || (
            <>
              {text ? (
                <RenderMarkdown
                  source={text || ''}
                />
              ) : (
                <div className="pb-3 px-3 pt-0">
                  <div className="three-dot-loader m-0" />
                </div>
              )}
            </>
          )}

        </div>
      </div>

    </div>
  );
};

export default MessageContainer;
