import { pathOr } from 'ramda';
import { toast } from 'react-toastify';
import {
  createFirebaseEntry,
  getFirebaseEntriesNew,
  getFirebaseEntry,
  updateFirebaseEntry
} from './firebaseProxy';

const collection = 'wageSchedules';

/**
 *
 * @param {string} id
 * @returns
 */
export const fetchWageSchedule = (id) => {
  return new Promise((resolve, reject) => {
    getFirebaseEntry({ collection, id }).then((response) => {
      const instructionProvider = response || null;

      resolve(instructionProvider);
    }).catch((error) => {
      console.error(error);
      reject(error);
    });
  });
};

/**
 * @param {Array} ids
 * @param {String} locationId
 * @param {String} apprenticeshipId
 * @param {String} employerProfileId
 */
export const fetchWageSchedules = ({
  ids = [],
  locationId,
  apprenticeshipId,
  employerProfileId
}) => {
  return new Promise((resolve, reject) => {
    const config = {};

    // Apprenticeship: Workforce
    if (locationId) {
      config['f.locationId[eq]'] = locationId;
    }

    if (employerProfileId) {
      config['f.employerProfileId[eq]'] = employerProfileId;
    }

    if (apprenticeshipId) {
      config['f.apprenticeshipId[eq]'] = apprenticeshipId;
    }

    if (ids.length) {
      config.ids = ids.join(',');
    }

    if (!Object.values(config).length) {
      reject(new Error('Missing params', config));
    }

    getFirebaseEntriesNew({
      collection,
      ...config
    }).then(({ items }) => {
      const list = items.length ? items : null;

      resolve(list);
    }).catch((error) => {
      console.error(error);
      reject(error);
    });
  });
};

export const createWageSchedule = (data) => {
  return new Promise((resolve) => {
    createFirebaseEntry({ data, collection }).then((response) => {
      const wageScheduleId = pathOr(null, ['docId'], response);

      resolve(wageScheduleId);
    }).catch((error) => {
      toast.error('Something went wrong');
      console.error(`create ${collection}`, error);
    });
  });
};

/**
 *
 * @param {string} employerProfileId
 * @param {object} data
 */
export const updatedWageSchedule = (data, wageScheduleId) => {
  return new Promise((resolve) => {
    updateFirebaseEntry({
      collection,
      data,
      id: wageScheduleId
    }).then(() => {
      resolve();
    }).catch((error) => {
      toast.error('Something went wrong');
      console.error(`update ${collection}`, error);
    });
  });
};
