import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { pathOr } from 'ramda';
import { v4 as uuid } from 'uuid';
import { toast } from 'react-toastify';
import Modal from '../../Modal';
import { resetCurrentModal } from '../../../actions';
import SelectMenu from '../../SelectMenu';
import { typeOptions } from './constants';

const ManageCertificate = () => {
  const dispatch = useDispatch();
  const currentModalKey = 'manageCertificate';
  const currentModal = useSelector((state) => state.currentModal);
  const modalKey = pathOr(null, ['key'], currentModal);
  const modalData = pathOr(null, ['data'], currentModal);
  const customName = pathOr(null, ['Name'], modalData);
  const customDescription = pathOr(null, ['Description'], modalData);
  const customAcronym = pathOr(null, ['Acronym'], modalData);
  const customUrl = pathOr(null, ['Url'], modalData);
  const customType = pathOr(null, ['Type'], modalData);
  const customOrganization = pathOr(null, ['Organization'], modalData);
  const customOrganizationUrl = pathOr(null, ['OrganizationUrl'], modalData);
  const customOrganizationAddress = pathOr(null, ['OrganizationAddress'], modalData);
  const currentData = pathOr(null, ['currentData'], modalData);
  const { handleSave } = modalData;
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState(customName || '');
  const [description, setDescription] = useState(customDescription || '');
  const [url, setUrl] = useState(customUrl || '');
  const [acronym, setAcronym] = useState(customAcronym || '');
  const [type, setType] = useState(customType || '');
  const [organization, setOrganization] = useState(customOrganization || '');
  const [organizationUrl, setOrganizationUrl] = useState(customOrganizationUrl || '');
  const [organizationAddress, setOrganizationAddress] = useState(customOrganizationAddress || '');

  const onSubmit = (e) => {
    e.preventDefault();

    setLoading(true);

    const newCertificate = {
      Id: uuid(),
      Name: name,
      Description: description,
      Url: url,
      Type: type, // <Advanced, Core, Specialty, Product/Equipment, Unclassified>
      Acronym: acronym,
      Organization: organization, // Organization Name
      OrganizationUrl: organizationUrl, // Organization URL
      OrganizationAddress: organizationAddress // Organization Address
    };

    handleClose();
    handleSave([newCertificate, ...currentData]);
    toast.success(`${name} added!`);
  };

  const handleClose = () => {
    dispatch(resetCurrentModal());
  };

  return (
    <Modal
      cssClassName={`turbine-modal--style-card turbine-modal--${modalKey}`}
      visible={modalKey === currentModalKey}
      close={false}
      pos="top"
      theme="dark"
    >
      <div className="EditOccupationElement-container">
        <div className="card h-100">
          <form onSubmit={onSubmit}>
            <div className="card-header d-flex align-items-center justify-content-between">
              <div className="h5 mb-0">
                Create Certificate
              </div>
            </div>
            <div className="card-body">
              <div id="group-name" className="mb-4">
                <label htmlFor="name">
                  <b>Name:</b> <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="name"
                  defaultValue={name}
                  onChange={(e) => {
                    const { value: newName } = e.currentTarget;
                    setName(newName);
                  }}
                  autoFocus
                  required
                />
              </div>

              <div id="group-description" className="mb-4">
                <label htmlFor="description">
                  <b>Description:</b> <span className="text-danger">*</span>
                </label>
                <textarea
                  type="text"
                  className="form-control"
                  name="description"
                  onChange={(e) => {
                    const { value: newDescription } = e.currentTarget;
                    setDescription(newDescription);
                  }}
                  defaultValue={description}
                  required
                />
              </div>

              <div id="group-url" className="mb-4">
                <label htmlFor="url">
                  <b>URL:</b> <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="url"
                  defaultValue={url}
                  onChange={(e) => {
                    const { value: newUrl } = e.currentTarget;
                    setUrl(newUrl);
                  }}
                  required
                />
              </div>

              <div id="group-type">
                <div>
                  <label className="mb-1">
                    <b>Type:</b> <span className="text-danger">*</span>
                  </label>
                  <div className="text-muted medium mb-2">
                    Select the type of certificate.
                  </div>
                  <SelectMenu
                    defaultOption={{ text: 'Select type', value: '' }}
                    className="form-control-sm mt-2"
                    onChange={(e) => {
                      const { value: newType } = e.currentTarget;

                      setType(newType);
                    }}
                    defaultValue={type}
                    name="type"
                    options={typeOptions}
                    required
                  />
                </div>
              </div>

              <div id="group-acronym" className="mb-4">
                <label htmlFor="acronym">
                  <b>Acronym:</b> <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="acronym"
                  defaultValue={acronym}
                  onChange={(e) => {
                    const { value: newAcronym } = e.currentTarget;
                    setAcronym(newAcronym);
                  }}
                  required
                />
              </div>

              <h6>
                Organization
              </h6>
              <hr />

              <div id="group-organization" className="mb-4">
                <label htmlFor="organization">
                  <b>Organization Name:</b> <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="organization"
                  defaultValue={organization}
                  onChange={(e) => {
                    const { value: newOrg } = e.currentTarget;
                    setOrganization(newOrg);
                  }}
                  required
                />
              </div>

              <div id="group-organizationUrl" className="mb-4">
                <label htmlFor="organizationUrl">
                  <b>Organization URL:</b> <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="organizationUrl"
                  defaultValue={organizationUrl}
                  onChange={(e) => {
                    const { value: newOrgUrl } = e.currentTarget;
                    setOrganizationUrl(newOrgUrl);
                  }}
                  required
                />
              </div>

              <div id="group-organizationAddress" className="mb-4">
                <label htmlFor="organizationAddress">
                  <b>Organization Address:</b> <span className="text-danger">*</span>
                </label>
                <textarea
                  className="form-control"
                  name="organizationAddress"
                  defaultValue={organizationAddress}
                  onChange={(e) => {
                    const { value: newOrgAddress } = e.currentTarget;
                    setOrganizationAddress(newOrgAddress);
                  }}
                  required
                />
              </div>

            </div>
            <div className="card-footer">
              <button
                className="btn btn-md btn-primary"
                type="submit"
                disabled={loading}
              >
                {loading ? 'Saving' : 'Save'}
              </button>
              <button
                type="button"
                className="btn btn-md btn-link ml-1"
                onClick={handleClose}
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};

export default ManageCertificate;
