import { fetchSupabaseEntry } from './supabaseProxy';

const table = 'apprenticeships';

/**
 *
 * @param {string} id
 */
export const fetchApprenticeship = (id) => {
  return new Promise((resolve, reject) => {
    fetchSupabaseEntry({ table, id })
      .then((response) => {
        const apprenticeship = response || null;

        resolve(apprenticeship);
      })
      .catch((error) => {
        console.error(`fetch (single) ${table}`, error);
        reject(error);
      });
  });
};
