import { pathOr } from 'ramda';
import { toast } from 'react-toastify';
import { createFirebaseEntry, getFirebaseEntriesNew } from './firebaseProxy';

const collection = 'apprenticeshipTemplates';

export const createApprenticeshipTemplate = (data) => {
  return new Promise((resolve) => {
    createFirebaseEntry({ data, collection }).then((response) => {
      const apprenticeshipTemplateId = pathOr(null, ['docId'], response);

      resolve(apprenticeshipTemplateId);
    }).catch((error) => {
      toast.error('Something went wrong');
      console.error(`create ${collection}`, error);
    });
  });
};

/**
 * @param {String} orgId
 * @param {String} employerId
 */
export const fetchApprenticeshipTemplates = ({
  rapidsCode,
  occupationONetCode
}) => {
  return new Promise((resolve, reject) => {
    const config = {};

    if (rapidsCode) {
      config['f.rapidsCode[eq]'] = rapidsCode;
    }

    if (occupationONetCode) {
      config['f.occupationONetCode[eq]'] = occupationONetCode;
    }

    if (!Object.values(config).length) {
      reject(new Error('Missing params', config));
    }

    getFirebaseEntriesNew({
      collection,
      ...config
    }, 'v2').then((response) => {
      const list = response?.items?.length ? response?.items : null;

      resolve(list);
    }).catch((error) => {
      console.error(`fetch ${collection}`, error);
      reject(error);
    });
  });
};
