import React, { useEffect, useMemo, useState } from 'react';
import { v5 as uuid5 } from 'uuid';
import { pathOr } from 'ramda';
import { Collapse } from '@apprentage/components';
import { percentComplete } from '@apprentage/utils';
import { FaCircleCheck } from 'react-icons/fa6';
import { toast } from 'react-toastify';
import { createCompletion } from '../../../../services/openAi';
import ClassificationTags from './ClassificationTags';
import ProgressBar from '../../../ProgressBar';

const StepTwo = ({ data, onSubmit }) => {
  const [loading, setLoading] = useState(false);
  const [dataToSave, setDataToSave] = useState({
    ...data,
    classifications: {}
  });

  const competencies = pathOr({}, ['competencies'], data);
  const [currentIndex, setCurrentIndex] = useState(0);

  const allCompetencies = useMemo(() => {
    return Object.values(competencies);
  }, competencies);

  const currentCompetency = useMemo(() => {
    return allCompetencies[currentIndex];
  }, [currentIndex]);

  const currPercent = useMemo(() => {
    return percentComplete({ current: currentIndex + 1, total: allCompetencies.length });
  }, [currentIndex, allCompetencies]);

  const hoursPerCompetency = () => {
    const hours = data.ojtHours / allCompetencies.length;

    if (Number.isInteger(hours)) {
      return hours;
    }

    // Number of hours is not an integer, round down
    const roundHours = Math.round(hours);

    // Ex: 2700 hours, 13 competencies
    if (currentIndex === 0) {
      return data.ojtHours % roundHours; // 1x Competency => 204
    }

    return roundHours; // 12x Competencies => 208
  };

  useEffect(() => {
    console.log('StepTwo', data);

    if (data.competencyGroups.length !== 0) {
      toast.info('Classifying competencies....');
      setTimeout(() => {
        classifyAndSave();
      }, 2000);
    }
  }, []);

  const classifyCompetency = async (description) => {
    let sanitizedDescription = description.trim();

    if (sanitizedDescription.substring(sanitizedDescription.length - 1) === '.') {
      sanitizedDescription = sanitizedDescription.substring(0, sanitizedDescription.length - 1);
    }

    const stopChar = '\n';
    let prompt = 'Provide five apprenticeship competency categories separated by commas based on the following text:';
    prompt += stopChar;
    // Human input
    prompt += sanitizedDescription;
    prompt += stopChar; // 2
    prompt += 'Tags:';
    prompt += stopChar;

    const response = await createCompletion({
      prompt
    });

    // var response = '1. Attention to Detail,\n 2. Technical Writing,\n 3. Design,\n 4. Drafting,\n 5. Engineering Knowledge.'
    // var response = '1. Mechanical Assembly\n2. Interpreting Drawings\n3. Measurement and Tolerance\n4. Mechanical Components\n5. Computer Support.';

    if (response?.data && typeof response?.data === 'string') {
      let sanitizedChoices = response?.data;

      if (/\r|\n/.exec(sanitizedChoices)) {
        sanitizedChoices = sanitizedChoices.replace(/\n/g, ' ');
      }

      if (sanitizedChoices && sanitizedChoices.includes('1.')) {
        console.log('response is numbered list', sanitizedChoices);
        const replaceChar = sanitizedChoices.includes(',') ? '' : ',';

        sanitizedChoices = sanitizedChoices.replace(/\n/g, ' ');
        sanitizedChoices = sanitizedChoices.replace(/\s{0,1}\d\./gm, replaceChar);
        // sanitizedChoices = sanitizedChoices.substring(2, sanitizedChoices.length - 1);
      }

      if (sanitizedChoices && sanitizedChoices.includes(',')) {
        return sanitizedChoices.split(',');
      }
    }

    return [];
  };

  const classifyAndSave = () => {
    setLoading(true);

    classifyCompetency(currentCompetency.description).then((response) => { // Create AI classifications based on description
      if (response.length) {
        const newData = {
          ...dataToSave
        };

        newData.competencies[currentCompetency.id].classificationIds = {};

        // Total Hours
        newData.competencies[currentCompetency.id].totalHours = hoursPerCompetency();

        response.forEach((classification) => {
          // Remove any extra spaces
          const classificationSanitized = classification.trim();
          // Create unique ID based on classification text using UUID v5
          // This prevents duplicate Classifications from being added
          const id = uuid5(classificationSanitized, data.competencyNameSpace);
          // Save Classification on apprenticeship
          newData.classifications[id] = classificationSanitized;
          // Save Classification IDs on Competency
          newData.competencies[currentCompetency.id].classificationIds[id] = true;
        });

        setDataToSave(newData);
        // console.log('competency', newData.competencies[currentCompetency.id]);
        // console.log('classificationIds', newData.competencies[currentCompetency.id].classificationIds);
        setTimeout(() => {
          setCurrentIndex((prev) => prev + 1);
          setLoading(false);
          setTimeout(() => {
            document.getElementById('step2NextBtn').click();
          }, 2000);
        }, 2000);
      }
    }).catch((error) => {
      setLoading(false);
      console.error(error);
    });
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();

    if (currentIndex === allCompetencies.length) {
      onSubmit(dataToSave);
      return;
    }

    classifyAndSave();
  };

  return (
    <div className='bg-white rounded mt-3'>
      <form
        onSubmit={handleOnSubmit}
        className='card'
      >
        <div className='card-header'>
          <h4 className='m-0'>
            Classify Competencies {allCompetencies && <span>({currentIndex + 1} / {allCompetencies.length})</span>}
          </h4>
        </div>
        <div className='card-body'>
          {currentCompetency && (
            <div>
              <div
                className="progress mb-3"
                style={{
                  height: '40px'
                }}
              >
                <ProgressBar
                  title="Classified"
                  className="progress-bar-striped progress-bar-animated bg-primary"
                  valueMax={allCompetencies.length}
                  valueNow={currentIndex + 1}
                  animated
                  style={{
                    height: '40px',
                    fontSize: '1rem',
                    fontWeight: 'bold',
                    width: currPercent
                  }}
                  text={currPercent}
                />
              </div>
              <h3 className='d-flex'>
                <span>
                  <span className='badge badge-secondary mr-2'>
                    {currentIndex + 1}
                  </span>
                </span>
                {currentCompetency.description}
              </h3>
              {currentCompetency && dataToSave.competencies[currentCompetency.id]?.classificationIds && dataToSave.competencies[currentCompetency.id]?.classificationIds.length !== 0 && (
                <Collapse
                  className="mt-2"
                  title="Classifications"
                  id="competencyClassifications"
                  ariaExpanded
                  badge={() => {
                    return (
                      <span className="h6 m-0 ml-2">
                        <span
                          className="badge badge-info"
                        >
                          <i className="fa fa-bolt" style={{ fontSize: '.7rem ' }} aria-hidden="true" /> Auto-Generated
                        </span>
                      </span>
                    );
                  }}
                >
                  <ClassificationTags
                    data={dataToSave?.competencies[currentCompetency.id].classificationIds}
                    classifications={dataToSave?.classifications}
                  />
                </Collapse>
              )}
            </div>
          )}

          {!currentCompetency && (
            <div>
              <h2 className='m-0'>
                <FaCircleCheck className='text-keppel' /> Complete
              </h2>
            </div>
          )}

        </div>
        <div className='card-footer d-flex justify-content-between'>
          <div>
            <button
              id="step2NextBtn"
              style={{ opacity: 0 }}
              className='btn btn-md btn-primary'
              type='submit'
              disabled={loading}
            >
              Next
            </button>
          </div>

          <div className='h4 d-flex'>
            <div className='badge badge-secondary mr-2'>
              {currentIndex + 1} / {allCompetencies.length}
            </div>
            <div>
              Classified
            </div>
          </div>

        </div>
      </form>
    </div>
  );
};

export default StepTwo;
