export const CLASS = 'fas fa-layer-group';
export const COHORT = 'fas fa-users';
export const TOPIC = 'far fa-sticky-note';
export const MATERIAL = 'far fa-file-alt';
export const CHALLENGE = 'fas fa-clipboard-list';
export const QUIZ = 'fas fa-list-ol';
export const ROLE = 'fas fa-shield-alt';
export const SEND = 'fas fa-paper-plane';
export const COPY = 'far fa-clipboard';
export const LINK = 'fas fa-link';
export const EXPERT = 'fas fa-user-ninja';
export const BILLING = 'fas fa-dollar-sign';
export const PENCIL = 'fas fa-pencil-alt';
export const CALENDAR = 'far fa-calendar-alt';
export const EXTERNAL_LINK = 'fas fa-external-link-alt';
export const PATHWAY = 'fas fa-wave-square';
export const ARROW_LEFT = 'fas fa-arrow-left';
export const ROCKET = 'fas fa-rocket';
export const BRIEFCASE = 'fas fa-briefcase';
export const GRADUATION_CAP = 'fas fa-graduation-cap'; // apprenticeships
export const CITY = 'fas fa-city'; // employers
export const MAP_PIN = 'fas fa-map-marker-alt';
export const MAP_LOCATION = 'fas fa-map-marked-alt';
export const USER_INFO = 'far fa-address-card';
export const LOCK = 'fas fa-lock';
export const PLUS = 'fas fa-plus';
export const TRASH = 'far fa-trash-alt';
export const SETTINGS = 'fas fa-cog';
export const ELLIPSIS = 'fas fa-ellipsis-h';
export const CHEVRON_LEFT = 'fas fa-chevron-left';
export const CHEVRON_RIGHT = 'fas fa-chevron-right';
export const INFO_CIRCLE = 'fas fa-info-circle';
export const INVOICE_DOLLAR = 'fas fa-file-invoice-dollar'; // wage schedule
export const TRIANGLE_BANG = 'fas fa-exclamation-triangle';
export const ICON_CIRCLE_CHECK = 'fas fa-check-circle';
