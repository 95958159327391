import React from 'react';

const TimelineItem = ({
  index,
  noArrow,
  fullWidth,
  className = 'timeline-inverted',
  title,
  icon,
  children = null
}) => {
  return (
    <li
      className={className}
    >
      {index && (
        <div className="timeline-badge bg-white">
          {index}
        </div>
      )}
      <div
        className={`timeline-panel ${noArrow ? 'no-arrow' : ''}`}
        style={fullWidth ? { width: 'calc(100% - 90px)' } : {}}
      >
        {title && (
          <div className="timeline-heading">
            <h5 className="timeline-title font-weight-bold mb-2">
              {icon && (<i className={`${icon} mr-1`} />)} {title}
            </h5>
          </div>
        )}

        {children}
      </div>
    </li>
  );
};

export default TimelineItem;
