import formatOrganization from './formatOrganization';
import formatClass from './formatClass';
import formatUser from './formatUser';

function formatData(args, type, entry) {
  switch (type) {
    case 'user':
      return formatUser(args, entry);
    case 'class':
      return formatClass(args);
    case 'organization':
      return formatOrganization(args);
    default:
      return false;
  }
}

export default formatData;
