import React from 'react';

const Preamble = ({
  className = '',
  title = 'APPENDIX B',
  subtitle = 'Apprenticeship Agreement Between Apprentice and Program Sponsor & Employer'
}) => {
  return (
    <div className={className}>
      <div
        className="text-center"
        style={{
          marginTop: '2rem',
          marginBottom: '1.5rem'
        }}
      >
        <div
          className="h5 font-weight-bold"
        >
          {title}
        </div>

        <div
          className="h5 font-weight-bold"
        >
          {subtitle}
        </div>
      </div>
    </div>
  );
};

export default Preamble;
