import React from 'react';
import styled from 'styled-components';

const TitleContainer = styled.div`
  background-color: #dbe5f1;
  padding: .5rem .25rem;
  margin-bottom: 1rem;
`;

const Section = ({
  children,
  num,
  title,
  className = ''
}) => {
  return (
    <div
      className={className}
    >
      <TitleContainer>
        <h6 className="m-0 font-weight-bold">
          {num ? `${num}. ` : ''}{title}
        </h6>
      </TitleContainer>
      {children && (
        <div className="px-4 py-2">
          {children}
        </div>
      )}
    </div>
  );
};

export default Section;
