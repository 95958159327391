import React from 'react';
import {
  sponsoredSchools,
  sponsoredSchoolsLogos
} from '../../constants/pathways';
import { isLocalhost } from '../../serviceWorker';

const OccupationSchoolPrograms = ({
  title,
  element,
  hasData,
  data,
  handleChooseElements,
  viewOnly
}) => {
  if (!data || (Array.isArray(data) && data.length === 0)) {
    if (hasData) {
      return (
        <div className="card h-100 mb-4">
          <div className="card-header h5 mb-0">
            {title}
          </div>
          <div className="card-body">
            No school programs required.
          </div>
          <div className="card-footer">
            {!viewOnly && (
              <button
                type="button"
                className="btn btn-sm btn-primary"
                onClick={handleChooseElements}
              >
                Choose
                {' '}
                {title}
              </button>
            )}
          </div>
        </div>
      );
    }

    return null;
  }

  return (
    <div className="card h-100 mb-4">
      <div className="card-header h5 mb-0">
        {title}
      </div>
      <div
        className="d-flex justify-content-between align-items-center border-bottom px-3 py-2"
      >
        <div>
          {element}
        </div>
        <div>
          State
        </div>
      </div>
      <div className="card-body">
        {data.map((program, i) => {
          const {
            // ID,
            ProgramName,
            SchoolName,
            StateAbbr
          } = program;

          let hasPrivacy = !!SchoolName;

          if (sponsoredSchools.includes(SchoolName)) {
            // School is Sponsored
            hasPrivacy = false;
          }

          if (isLocalhost) hasPrivacy = false;

          return (
            <div
              key={`program-${i}`}
              className="d-flex justify-content-between align-items-center mb-4"
            >
              <div className="left d-flex align-items-center">
                {sponsoredSchools.includes(SchoolName) && (
                  <img
                    src={sponsoredSchoolsLogos[SchoolName]}
                    height="30"
                    style={{ height: '30px' }}
                    className="mr-2"
                    alt={`${SchoolName} Logo`}
                  />
                )}

                <div className="text pr-3">
                  <h6 className="mb-0 d-flex align-items-center">
                    <span>
                      {ProgramName}
                    </span>
                    <span className="dot dot-sm ms-2 bg-indigo" />
                  </h6>
                  <div className={`text-muted ${hasPrivacy ? 'privacy-blur' : ''}`}>
                    {hasPrivacy ? 'Upgrade to view school name' : SchoolName}
                  </div>
                </div>
              </div>
              <div className="right ms-5 ms-sm-0 ps-3 ps-sm-0">
                <div className="font-weight-bold">
                  {StateAbbr}
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <div className="card-footer d-flex d-print-none">
        {!viewOnly && (
          <button
            type="button"
            className="btn btn-sm btn-primary"
            onClick={handleChooseElements}
          >
            Choose
            {' '}
            {title}
          </button>
        )}
      </div>
    </div>
  );
};

export default OccupationSchoolPrograms;
