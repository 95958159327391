import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { parse } from 'qs';
import { setCurrentClass, resetCurrentClass } from '../../actions/Class';
import { getOrg } from '../../actions/Organizations';
import { SET_PAGE_ORG } from '../../actions/types';
import { canBypassCourseEnroll } from '../../services/currentUser';
import { SLASH } from '../../constants/routes';
import { CLASS } from '../../constants/icons';
import { TURBINE } from '../../constants/urls';
import { withFirebase } from '../Firebase';
import { logout } from '../../actions';
// Dynamic Web Manifest
import {
  ANDROID_192_ICON, ANDROID_512_ICON, APPLE_TOUCH_ICON, ROBOTS_OFF, ROBOTS_ON
} from '../../constants/globals';
import generateWebManifest from '../../utils/generateWebManifest';
// /Dynamic Web Manifest
import Topics from './Topics';
import Overview from './Overview';
import Instructors from './Instructors';
import GoLive from '../GoLive';
import Loading from '../Loading';
import Card from '../Courses/Card';
import JoinCourseActions from './JoinCourseActions';
import PreReqStatus from '../Courses/PreReqStatus';
import './style.css';

const CoursePage = ({ firebase }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const params = useParams();
  // Redux
  const user = useSelector((state) => state.user);
  const currentUser = useSelector((state) => state.currentUser);
  const pageOrganization = useSelector((state) => state.pageOrganization);
  const course = useSelector((state) => state.currentClass);
  // Search Params
  const searchParams = parse(location.search);
  const cohortId = searchParams?.cohortId || null; // undefined
  // Course
  const classId = course?.id || null;
  const classTitle = course?.title || 'Course';
  // Organization
  const orgId = pageOrganization?.id || null;
  const orgName = pageOrganization?.name || null;
  const appleTouchIcon = pageOrganization?.appleTouchIcon || APPLE_TOUCH_ICON;
  const androidIconSmall = pageOrganization?.androidIconSmall || ANDROID_192_ICON;
  const androidIconLarge = pageOrganization?.androidIconLarge || ANDROID_512_ICON;
  // Params
  const courseKey = params?.courseKey || null; // undefined
  const courseValue = params?.courseValue || null; // undefined
  const locationId = params?.locationId || null;
  const orgSlug = params?.orgSlug || null;

  const courseEnum = useMemo(() => {
    return {
      id: 'classId',
      view: 'courseSlug'
    };
  }, []);

  // Local
  useEffect(() => {
    document.body.classList.add('course-page');

    dispatch(getOrg({
      reduxKey: 'pageOrganization',
      actionType: SET_PAGE_ORG,
      slug: orgSlug,
      select: [
        'fields.name',
        'fields.orgLogo',
        'fields.slug',
        'fields.appleTouchIcon',
        'fields.androidIconSmall',
        'fields.androidIconLarge'
      ],
      order: 'fields.name',
      locationIds: [locationId],
      locationSelect: ['fields.name']
    }));

    const config = {
      [courseEnum[courseKey]]: courseValue
    };

    // Given: courseKey exists in courseEnum
    // And: courseValue is present
    if (courseEnum[courseKey] && courseValue) {
      dispatch(setCurrentClass(config));
    }

    return function cleanup() {
      dispatch(resetCurrentClass());
    };
  }, [courseEnum, courseKey, courseValue, dispatch, locationId, orgSlug]);

  // Dynamic Web Manifest
  useEffect(() => {
    const manifest = document.getElementById('dynamic-manifest');

    // The id attribute is removed in generateWebManifest
    // to ensure this runs one time
    if (orgId && manifest) {
      generateWebManifest({
        orgName,
        androidIconSmall,
        androidIconLarge
      });
    }
  }, [androidIconLarge, androidIconSmall, orgId, orgName]);
  // /Dynamic Web Manifest

  const logUserOut = useCallback(() => {
    dispatch(logout());
    firebase.doSignOut();
  }, [dispatch, firebase]);

  useEffect(() => {
    if (user?.uid || currentUser?.id) {
      logUserOut();
    }
  }, [user?.uid, currentUser?.id, logUserOut]);

  const currentClassRoute = useMemo(() => {
    if (classId) {
      const urlParams = new URLSearchParams({
        autoForward: true,
        track: window.location.href
      });

      return `${TURBINE}/classes/${classId}?${urlParams.toString()}`;
    }
  }, [classId]);

  // Given: courseKey does NOT exist in courseEnum
  // Or: courseValue is NOT present
  if (!courseEnum[courseKey] || !courseValue) {
    // Then: redirect user to /
    history.push(SLASH);
    return;
  }

  // Given: course organization is fetched
  // Or: course organization is not fetched
  if (!pageOrganization?.id || !course?.id) {
    // Then: Show loading screen
    return (
      <Loading className="position-fixed" />
    );
  }

  if (!course?.isPublic) {
    return (
      <div className="row mt-2">
        <div className="col-sm-12">
          <div className="mb-5">
            <div
              className="p-3 border mt-2 bg-white"
            >
              <div className='h5 m-0'>
                <i className={CLASS} /> This course is private.
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  // // User accessing class outside of their pageOrganization, send to '/'
  // if (course.orgId !== currentUserOrgId) {
  //   // Course Syndication
  //   // Check if user has access to Org
  //   if (!course.orgIds || (course.orgIds && !course.orgIds.includes(currentUserOrgId))) {
  //     history.replace(ROUTES.SLASH);

  //     return (
  //       <Loading className="position-fixed" />
  //     );
  //   }
  // }

  const {
    title,
    isLive,
    enableCourseContent,
    searchEnginesEnabled
  } = course;

  const siliconPhotonicsHash = {
    '7cmeronj81mRG7jWM1Waby': true,
    '3X4Y7HfmUmWY3SITrkAODC': true,
    '6nACOZ2CmrNWAn5h9Smeio': true
  };

  const gritSafetyMakerspacesHash = {
    '2x5ai2m6EfzDB6jNrtjXmK': true
  };

  return (
    <>
      <Helmet>
        <title>{classTitle} | {orgName}</title>
        <link rel="apple-touch-icon" sizes="180x180" href={appleTouchIcon} />
        <meta name="robots" content={searchEnginesEnabled ? ROBOTS_ON : ROBOTS_OFF} />
        {siliconPhotonicsHash[classId] !== undefined && (
          <script async src="https://console.turbinelms.com/turbine-assistants.js" data-id="3e310fed-efb4-4218-8266-bc81053ac323" data-version="1" />
        )}

        {gritSafetyMakerspacesHash[classId] !== undefined && (
          <script async src="https://console.turbinelms.com/turbine-assistants.js" data-id="fb7011b4-919c-4062-b4c9-575e6c00626a" data-version="1" />
        )}
      </Helmet>
      <div className="row class-dashboard-container">
        <div className="col-md-7 col-lg-8 py-3">
          <div className="bg-light p-4 border mb-3 d-flex align-items-center justify-content-center">
            {canBypassCourseEnroll(course) && currentClassRoute ? (
              <a
                className="internalCourseBtn btn btn-lg btn-primary"
                href={currentClassRoute}
              >
                Course Content
              </a>
            ) : (
              <JoinCourseActions
                cohortId={cohortId}
                classId={classId}
              />
            )}

            <PreReqStatus
              course={course}
              className="ml-4 border-left pl-4"
            />
          </div>

          {/* <Cohorts
            classId={classId}
            className="my-3 d-block d-md-none"
          /> */}

          {isLive && (
            <GoLive
              title={title}
              buttonText="Enroll"
              slim
              // className="d-block d-md-none"
              hideButton
            />
          )}

          <Overview />

          {enableCourseContent && (
            <Topics
              currentClass={course}
            />
          )}
        </div>

        <div className="d-none d-md-block col-md-5 col-lg-4 pt-3">
          {course && (
            <Card
              course={course}
              classDashboard
              className="course-dashboard-card"
            />
          )}

          {/* <Cohorts classId={classId} className="mt-3" /> */}
        </div>
      </div>

      {course && (
        <div className="row mb-3">
          <div className="col-sm-12">
            <Instructors className="my-3" />
          </div>
        </div>
      )}
    </>
  );
};

export default withFirebase(CoursePage);
