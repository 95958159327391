import React, { Fragment } from 'react';

const Overview = ({
  OccupationDescription,
  OnetTitle,
  skillsDataList,
  knowledgeDataList,
  schoolPrograms,
  certifications,
  resetOccupation,
  viewOnly
}) => (
  <div className="card h-100">
    <div className="card-body">
      <div className="row">
        <div className="col-sm-12">
          {OnetTitle && (
          <Fragment>
            <h1
              className="h4 font-weight-bold"
              style={{ lineHeight: '2rem' }}
            >
              <span>
                {OnetTitle}
              </span>
              {!viewOnly && (
                <button
                  type="button"
                  className="btn btn-sm btn-outline-primary ml-2 d-inline d-print-none"
                  onClick={() => {
                    resetOccupation(null);
                  }}
                >
                  Change
                </button>
              )}
            </h1>
          </Fragment>
          )}

          {OccupationDescription && (
          <div>
            {OccupationDescription}
          </div>
          )}
        </div>
      </div>

      <div className="row d-flex d-md-none d-lg-flex">
        {skillsDataList && (
        <div className="col-sm-12 col-lg-3 mt-4">
          <div className="card h-100">
            <div className="card-body rounded text-center">
              <div className="h4 text-center m-0 font-weight-bold">
                {skillsDataList.length}
              </div>
            </div>
            <div className="card-footer bg-white text-center">
              Skills
            </div>
          </div>
        </div>
        )}

        {knowledgeDataList && (
        <div className="col-sm-12 col-lg-3 mt-4">
          <div className="card h-100">
            <div className="card-body rounded text-center">
              <div className="h4 text-center m-0 font-weight-bold">
                {knowledgeDataList.length}
              </div>
            </div>
            <div className="card-footer bg-white text-center">
              Knowledge
            </div>
          </div>
        </div>
        )}

        {schoolPrograms && (
        <div className="col-sm-12 col-lg-3 mt-4">
          <div className="card h-100">
            <div className="card-body rounded text-center">
              <div className="h4 text-center m-0 font-weight-bold">
                {schoolPrograms.length}
              </div>
            </div>
            <div className="card-footer bg-white text-center">
              {schoolPrograms.length === 1 ? 'Program' : 'Programs'}
            </div>
          </div>
        </div>
        )}

        {certifications && (
        <div className="col-sm-12 col-lg-3 mt-4">
          <div className="card h-100">
            <div className="card-body rounded text-center">
              <div className="h4 text-center m-0 font-weight-bold">
                {certifications.length}
              </div>
            </div>
            <div className="card-footer bg-white text-center">
              {certifications.length === 1 ? 'Certification' : 'Certifications'}
            </div>
          </div>
        </div>
        )}
      </div>

      {/* {SocInfo && (
          <Fragment>
            <h3>Standard Occupational Classification</h3>
            <div>
              SocTitle: {SocInfo.SocTitle} ({SocInfo.SocCode})
            </div>
            <div>
              SocDescription: {SocInfo.SocDescription}
            </div>
          </Fragment>
        )} */}
    </div>

  </div>
);

export default Overview;
