import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { pathOr } from 'ramda';
import { v4 as uuid } from 'uuid';
import { toast } from 'react-toastify';
import Modal from '../../Modal';
import { resetCurrentModal } from '../../../actions';
import sortArrByNumericKey from '../../../utils/sortArrByNumericKey';

const ManageSkill = () => {
  const dispatch = useDispatch();
  const currentModalKey = 'manageSkill';
  const currentModal = useSelector((state) => state.currentModal);
  const modalKey = pathOr(null, ['key'], currentModal);
  const modalData = pathOr(null, ['data'], currentModal);
  const customName = pathOr(null, ['Name'], modalData);
  const customDescription = pathOr(null, ['Description'], modalData);
  const customDataValue = pathOr(null, ['DataValue'], modalData);
  const customImportance = pathOr(null, ['Importance'], modalData);
  const currentData = pathOr(null, ['currentData'], modalData);
  const { handleSave } = modalData;
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState(customName || '');
  const [description, setDescription] = useState(customDescription || '');
  const [dataValue, setDataValue] = useState(customDataValue || '');
  const [importance, setImportance] = useState(customImportance || '');

  const onSubmit = (e) => {
    e.preventDefault();

    setLoading(true);

    const newSkill = {
      ElementId: uuid(),
      ElementName: name,
      ElementDescription: description,
      DataValue: dataValue,
      Importance: importance
    };

    const selectedData = sortArrByNumericKey([newSkill, ...currentData], 'Importance');

    handleClose();
    handleSave(selectedData);
    toast.success(`${name} added!`);
  };

  const handleClose = () => {
    dispatch(resetCurrentModal());
  };

  return (
    <Modal
      cssClassName={`turbine-modal--style-card turbine-modal--${modalKey}`}
      visible={modalKey === currentModalKey}
      close={false}
      pos="top"
      theme="dark"
    >
      <div className="EditOccupationElement-container">
        <div className="card h-100">
          <form onSubmit={onSubmit}>
            <div className="card-header d-flex align-items-center justify-content-between">
              <div className="h5 mb-0">
                Create Skill
              </div>
            </div>
            <div className="card-body">
              <div id="group-name" className="mb-4">
                <label htmlFor="name">
                  <b>Name:</b> <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="name"
                  defaultValue={name}
                  onChange={(e) => {
                    const { value: newName } = e.currentTarget;
                    setName(newName);
                  }}
                  autoFocus
                  required
                />
              </div>

              <div id="group-description" className="mb-4">
                <label htmlFor="description">
                  <b>Description:</b> <span className="text-danger">*</span>
                </label>
                <textarea
                  className="form-control"
                  name="description"
                  onChange={(e) => {
                    const { value: newDescription } = e.currentTarget;
                    setDescription(newDescription);
                  }}
                  defaultValue={description}
                  required
                />
              </div>

              <div id="skill-difficulty">
                <div>
                  <label className="mb-1">
                    <b>Difficulty:</b> <span className="text-danger">*</span>
                  </label>
                  <div className="text-muted medium mb-2">
                    Rate the difficulty of this skill from 1-5.
                  </div>
                  <select
                    type="text"
                    className="form-control"
                    name="difficulty"
                    defaultValue={dataValue}
                    style={{ width: '100px' }}
                    onChange={(e) => {
                      const { value: newDataValue } = e.currentTarget;
                      setDataValue(newDataValue);
                    }}
                    required
                  >
                    {Array.from({ length: 5 }, (_v, k) => k + 1).map((value, i) => (
                      <option key={i} value={value}>
                        {value}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div id="skill-importance">
                <div>
                  <label className="mb-1">
                    <b>Importance:</b> <span className="text-danger">*</span>
                  </label>
                  <div className="text-muted medium mb-2">
                    Rate the importance of this skill from 1-100.
                  </div>
                  <select
                    type="text"
                    className="form-control"
                    name="difficulty"
                    defaultValue={dataValue}
                    style={{ width: '100px' }}
                    onChange={(e) => {
                      const { value: newImportance } = e.currentTarget;
                      setImportance(newImportance);
                    }}
                    required
                  >
                    {Array.from({ length: 100 }, (_v, k) => k + 1).map((value, i) => (
                      <option key={i} value={value}>
                        {value}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="card-footer">
              <button
                className="btn btn-md btn-primary"
                type="submit"
                disabled={loading}
              >
                {loading ? 'Saving' : 'Save'}
              </button>
              <button
                type="button"
                className="btn btn-md btn-link ml-1"
                onClick={handleClose}
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};

export default ManageSkill;
