import React from 'react';
import { useSelector } from 'react-redux';
import { USER_BOOLEAN_ENUM } from '@apprentage/constants';
import Section from '../Section';
import CheckBoxes from '../CheckBoxes';
import { mmddyyyyDate } from '../../../utils/date';
import AddressReadable from '../../ManageContent/AddressReadable';
import WageScheduleTable from '../../WageScheduleTable';

const SectionSponsor = () => {
  const organization = useSelector((state) => state.organization);
  const orgLocation = useSelector((state) => state.orgLocation); // Employer
  const defaultLocation = useSelector((state) => state.defaultLocation); // Program Sponsor
  const apprenticeship = useSelector((state) => state.apprenticeship);
  const apprenticeAgreement = useSelector((state) => state.apprenticeAgreement);
  const instructionProvider = useSelector((state) => state.instructionProvider);
  const wageSchedule = useSelector((state) => state.wageSchedule);
  const employerAgreement = useSelector((state) => state.employerAgreement);
  const sponsorUser = useSelector((state) => state.sponsorUser);
  // DEFAULT DATA (dates)
  const timestamp = new Date().toISOString();
  const signerSignatureDate = apprenticeAgreement?.signerSignatureDate || '';
  const sponsorSignerSignatureDate = signerSignatureDate || timestamp;
  const employerSignerSignatureDate = signerSignatureDate || timestamp; // employerAgreement?.signerSignatureDate is available
  // DEFAULT DATA (temporarily hard coded)
  const hoursProbationary = apprenticeAgreement?.hoursProbationary || 0;
  const creditPrevJob = apprenticeAgreement?.creditPrevJob || 0;
  const wagesPaidDuringRTI = apprenticeAgreement?.wagesPaidDuringRTI || 'no';
  const RTIDuringWorkHrs = apprenticeAgreement?.RTIDuringWorkHrs || 'no';
  const creditPrevRTIHrs = apprenticeAgreement?.creditPrevRTIHrs || 0;
  const priorHourlyWage = apprenticeAgreement?.priorHourlyWage || 0;
  // DEFAULT DATA (Apprenticeship)
  const occupationName = apprenticeAgreement?.occupationName || apprenticeship?.occupationName || '';
  const ojtHours = apprenticeAgreement?.ojtHours || apprenticeship?.ojtHours || '';
  const instructionHours = apprenticeAgreement?.instructionHours || apprenticeship?.instructionHours || '';
  const startDate = apprenticeAgreement?.startDate || apprenticeship?.startDate || '';
  const expectedEndDate = apprenticeAgreement?.expectedEndDate || apprenticeship?.expectedEndDate || '';

  return (
    <Section
      num="B"
      title="TO BE COMPLETED BY THE PROGRAM SPONSOR AND EMPLOYER. PLEASE PRINT CLEARLY."
      className='w-100 overflow-hidden sponsor-section border'
    >
      {/** ROW 1 */}
      <div className='d-flex border-bottom'>
        <div className='p-2 td-5'>
          <div className='form-field-title mb-1'>
            <strong>1. PROGRAM SPONSOR NAME & ADDRESS</strong>
          </div>
          <div>
            {organization?.name}
          </div>
          <AddressReadable
            addressLine1={defaultLocation?.addressLine1}
            addressLine2={defaultLocation?.addressLine2}
            addressCity={defaultLocation?.addressCity}
            addressState={defaultLocation?.addressState}
            addressPostalCode={defaultLocation?.addressPostalCode}
          />
        </div>
        <div className='p-2 border-left td-5'>
          <div className='form-field-title mb-1'>
            <strong>2. EMPLOYER NAME & ADDRESS</strong>
          </div>
          <div>
            {orgLocation?.name}
          </div>
          <AddressReadable
            addressLine1={orgLocation?.addressLine1}
            addressLine2={orgLocation?.addressLine2}
            addressCity={orgLocation?.addressCity}
            addressState={orgLocation?.addressState}
            addressPostalCode={orgLocation?.addressPostalCode}
          />
        </div>
      </div>

      {/** ROW 2 */}
      <div className='d-flex border-bottom'>
        <div className='p-2 td-4'>
          <div className='form-field-title mb-1'>
            <strong>3. OCCUPATION</strong>
          </div>
          <p className='m-0'>
            {occupationName}
          </p>
        </div>
        <div className='p-2 border-left td-2'>
          <div className='form-field-title mb-1'>
            <strong>4. TOTAL LENGTH OF <br />OJT HOURS</strong>
          </div>
          <p className='m-0'>
            {ojtHours}
          </p>
        </div>
        <div className='p-2 border-left td-15'>
          <div className='form-field-title mb-1'>
            <strong>5. PROBATIONARY <br />PERIOD HOURS</strong>
          </div>
          <p className='m-0'>
            {hoursProbationary}
          </p>
        </div>
        <div className='p-2 border-left td-25'>
          <div className='form-field-title mb-1'>
            <strong>6. CREDIT FOR PREVIOUS JOB <br />TRAINING HOURS</strong> (Maximum 80%)
          </div>
          <p className='m-0'>
            {creditPrevJob}
          </p>
        </div>
      </div>

      {/** ROW 3 */}
      <div className='d-flex border-bottom'>
        <div className='p-2 td-45'>
          <div className='form-field-title mb-1'>
            <strong>7. RELATED TECHNICAL INSTRUCTION PROVIDER</strong>
          </div>
          <div className='m-0'>
            <div>
              {instructionProvider?.name}
            </div>
          </div>
        </div>
        <div className='p-2 border-left td-2'>
          <div className='form-field-title mb-1'>
            <strong>8. TOTAL LENGTH OF <br />INSTRUCTION HOURS</strong>
          </div>
          <p className='m-0'>
            {instructionHours}
          </p>
        </div>
        <div className='p-2 border-left td-15'>
          <div className='form-field-title mb-1'>
            <strong>9. WAGES PAID <br />DURING RTI?</strong>
          </div>
          <CheckBoxes
            value={wagesPaidDuringRTI}
            optionsEnum={USER_BOOLEAN_ENUM}
            vertical
          />
        </div>
        <div className='p-2 border-left td-15'>
          <div className='mb-1 form-field-title text-nowrap'>
            <strong>10. RTI DURING <br />WORK HOURS?</strong>
          </div>
          <CheckBoxes
            value={RTIDuringWorkHrs}
            optionsEnum={USER_BOOLEAN_ENUM}
            vertical
          />
        </div>
        <div className='p-2 border-left td-35'>
          <div className='form-field-title mb-1'>
            <strong>11. CREDIT FOR PREVIOUS INSTRUCTION<br />HOURS</strong> (Maximum 100%)
          </div>
          <p className='m-0'>
            {creditPrevRTIHrs}
          </p>
        </div>
      </div>

      {/** ROW 4 */}
      <div className='d-flex border-bottom'>
        <div
          className='p-2 td-3'
        >
          <div className='form-field-title mb-1'>
            <strong>12. DATE APPRENTICESHIP BEGINS</strong> (mm/dd/yyy)
          </div>
          {startDate && (
            <p className='m-0'>
              {mmddyyyyDate(startDate)}
            </p>
          )}
        </div>
        <div className='p-2 border-left td-3'>
          <div className='form-field-title mb-1'>
            <strong>13. EXPECTED COMPLETION DATE</strong> (mm/dd/yyy)
          </div>
          {expectedEndDate && (
            <p className='m-0'>
              {mmddyyyyDate(expectedEndDate)}
            </p>
          )}

        </div>
        <div className='p-2 border-left td-2'>
          <div className='form-field-title mb-1'>
            <strong>14. PRIOR HOURLY WAGE</strong>
          </div>

          <p className='m-0'>
            <strong className='mr-1 ml-4'>$</strong>
            <span>
              {priorHourlyWage}
            </span>
          </p>
        </div>
        <div
          className='p-2 border-left td-35'
          style={{
            width: '40.5%'
          }}
        >
          <div className='form-field-title mb-1'>
            <strong>15. APPRENTICE ENTRY HOURLY WAGE</strong>
          </div>
          <p className='m-0'>
            <strong className='mr-1 ml-4'>$</strong>
            {wageSchedule?.wageRates && Array.isArray(wageSchedule?.wageRates) && wageSchedule?.wageRates.length !== 0 && (
              <span>
                {wageSchedule?.wageRates[0].wageAmount}
              </span>
            )}
          </p>
        </div>
      </div>

      {/** ROW 5 */}
      <div className='border-bottom p-2'>
        <div className='form-field-title mb-1'>
          <strong>16. APPRENTICE WAGE PROGRESSION:</strong> The schedule of pay should be listed for each advancement period. The wage schedule is included in the work process of the standards,
          which are part of this agreement.
        </div>
        <WageScheduleTable
          key={`wageSchedule-${wageSchedule?.id}`}
          wageSchedule={wageSchedule}
          className="mt-3"
        />
      </div>

      {/** ROW 6 */}
      <div className='border-bottom p-2'>
        <div className='form-field-title mb-1'>
          <strong>17. JOURNEY WORKER ENTRY WAGE</strong>
        </div>
        <p className='m-0'>
          <strong className='mr-1 ml-4'>$</strong>
          <span>
            {wageSchedule?.entryWage}
          </span>
        </p>
      </div>

      {/** ROW 7 */}
      <div className='d-flex border-bottom'>
        <div className='p-2 td-4'>
          <div className='mb-1'>
            <div className='d-flex justify-content-between'>

              <div className='form-field-title font-weight-bold'>
                18. SIGNATURE OF PROGRAM SPONSOR
              </div>
              <div className='text-left form-field-title mr-5'>
                <strong>DATE</strong>
              </div>
            </div>
          </div>
          <div className='d-flex justify-content-between mt-2'>
            {sponsorUser?.userSignature && (
              <>
                <img
                  src={sponsorUser?.userSignature}
                  height={50}
                  style={{ height: '50px' }}
                  alt={`${sponsorUser?.name} Signature (Sponsor)`}
                />
                <p className='m-0'>
                  {mmddyyyyDate(sponsorSignerSignatureDate)}
                </p>
              </>
            )}
          </div>
        </div>
        <div className='p-2 td-4 border-left'>
          <div className='d-flex justify-content-between'>
            <div className='form-field-title font-weight-bold'>
              19. SIGNATURE OF EMPLOYER
            </div>
            <div className='text-left form-field-title mr-5'>
              <strong>DATE</strong>
            </div>
          </div>
          <div className='d-flex justify-content-between mt-2'>
            {employerAgreement?.signerSignature && (
              <>
                <img
                  src={employerAgreement?.signerSignature}
                  height={50}
                  style={{ height: '50px' }}
                  alt={`${employerAgreement?.signerName} Signature (Employer)`}
                />
                <p className='m-0'>
                  {mmddyyyyDate(employerSignerSignatureDate)}
                </p>
              </>
            )}
          </div>
        </div>
      </div>
    </Section>
  );
};

export default SectionSponsor;
