import React from 'react';
import { ICON_EYE_SLASH } from '@apprentage/components';

const Tabs = ({ activeTab, setActiveTab, className = 'pt-3 bg-light' }) => {
  return (
    <div className={className}>
      <ul
        data-cy="CoursesSubNav"
        className="nav nav-tabs"
      >
        <li className="nav-item ml-2">
          <button
            className={`nav-link ${activeTab === 'profile' ? 'active' : ''}`}
            type="button"
            onClick={() => {
              setActiveTab('profile');
            }}
          >
            General
          </button>
        </li>

        <li className="nav-item ml-2">
          <button
            className={`nav-link ${activeTab === 'privateInfo' ? 'active' : ''}`}
            type="button"
            onClick={() => {
              setActiveTab('privateInfo');
            }}
          >
            <i className={`${ICON_EYE_SLASH} mr-1`} /> Private
          </button>
        </li>

      </ul>
    </div>
  );
};

export default Tabs;
