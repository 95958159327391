import * as twApi from '../tw-api';
import { PLATFORM_NAME, TURBINE_ADMIN_BCC_EMAILS, TURBINE_ADMIN_EMAIL } from '../../constants/globals';
import { GMAIL_SEND } from '../../constants/api';
import baseTemplate from './templates/baseTemplate';
import { TURBINE_MARKETING_SITE } from '../../constants/urls';
import sendAssistantCustomerServiceHtml from './templates/sendAssistantCustomerServiceHtml';
import pathwayEmail from './templates/pathway'; // TODO refactor

export const sendAssistantCustomerService = ({
  integrationId,
  integrationName,
  chatHistory,
  userEmail,
  serviceEmail,
  orgName,
  orgLogo
}) => {
  const subject = 'Website Visitor Needs Assistance';
  const title = '';
  const trackingSlug = `assistant-help-${integrationId}`;

  const emailParts = {
    to: serviceEmail,
    from: {
      name: 'Turbine Workforce'
    },
    replyTo: userEmail,
    bcc: TURBINE_ADMIN_BCC_EMAILS,
    subject,
    body: '',
    htmlBody: baseTemplate({
      orgName,
      orgLogo,
      title,
      trackingSlug,
      bodyContent: sendAssistantCustomerServiceHtml({
        integrationId,
        integrationName,
        chatHistory,
        userEmail
      })
    })
  };

  return new Promise((resolve, reject) => {
    return twApi
      .post(GMAIL_SEND, '', JSON.stringify(emailParts))
      .then((response) => {
        if (response && response.status === 200) {
          resolve();
        } else {
          console.error(response.status); // ex: 'error_invitation_fail'

          reject({
            message: response.message,
            status: response.status
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  });
};

/**
 * Notify Organization Location Managers & Observers
 * @param {string} email // User's email
 * @param {string} name // User's name
 * @param {string} phone // User's name
 * @param {string} companyName
 * @param {string} occupationLocation // location of pathway, zip, etc
 * @param {bool} createApprenticeship // Training intention
 * @param {bool} createTraining // Training intention
 * @param {string} trainingTimeline // 1 month, etc
 * @param {string} trainingBudget // $
 */
export const sendEmailPathwayPrinted = (data) => {
  return Promise.all([
    sendEmailPathwayNotify(data),
    sendEmailPathwayUser(data)
  ]);
};

/**
 * Notify Organization Location Managers & Observers
 * @param {string} id // Firebase ID of jobProfile
 * @param {string} email // User's email
 * @param {string} name // User's name
 * @param {string} phone // User's name
 * @param {string} companyName
 * @param {string} occupationLocation // location of pathway, zip, etc
 * @param {bool} createApprenticeship // Training intention
 * @param {bool} createTraining // Training intention
 * @param {string} trainingTimeline // 1 month, etc
 * @param {string} trainingBudget // $
 */
export const sendEmailPathwayUser = ({
  id,
  email,
  OnetTitle,
  OccupationDescription,
  OnetCode,
  location,
  occupationLocation,
  selectedSkills,
  selectedKnowledge,
  selectedCerts,
  selectedSchoolPrograms,
  bcc = TURBINE_ADMIN_BCC_EMAILS
}) => {
  const to = email;

  const emailParts = {
    from: {
      name: 'Turbine Workforce'
    },
    to,
    subject: `Training plan: ${OnetTitle}`,
    bcc,
    body: '',
    htmlBody: pathwayEmail({
      id,
      OnetTitle,
      OnetCode,
      location,
      occupationLocation,
      OccupationDescription,
      selectedSkills,
      selectedKnowledge,
      selectedCerts,
      selectedSchoolPrograms
    })
  };

  return new Promise((resolve, reject) => {
    // Turbine gmail api
    return twApi.post(GMAIL_SEND, '', JSON.stringify(emailParts)).then((response) => {
      if (response && response.status === 200) {
        resolve();
      } else {
        console.error(response.status); // ex: 'error_invitation_fail'

        reject({
          message: response.message,
          status: response.status
        });
      }
    }).catch((error) => {
      // TODO move reject here
      console.error(error);
    });
  });
};

/**
 * Notify Organization Location Managers & Observers
 * @param {string} email // User's email
 * @param {string} name // User's name
 * @param {string} phone // User's name
 * @param {string} companyName
 * @param {string} occupationLocation // location of pathway, zip, etc
 * @param {bool} createApprenticeship // Training intention
 * @param {bool} createTraining // Training intention
 * @param {string} trainingTimeline // 1 month, etc
 * @param {string} trainingBudget // $
 */
export const sendEmailPathwayNotify = ({
  id,
  email,
  name,
  phone,
  companyName,
  OnetTitle,
  OnetCode,
  location,
  occupationLocation,
  // createApprenticeship,
  // createTraining,
  // trainingTimeline,
  // trainingBudget,
  bcc = TURBINE_ADMIN_BCC_EMAILS
}) => {
  const timeStamp = new Date();
  const to = TURBINE_ADMIN_EMAIL;

  const emailParts = {
    from: {
      name: 'Turbine Workforce'
    },
    to,
    subject: 'Training Plan Created',
    bcc,
    body: '',
    htmlBody: `
      <h4>
        General Info:
      </h4>
      <ol>
        <li>
          Name: ${name}
        </li>
        <li>
          Email: ${email}
        </li>
        <li>
          Phone: ${phone}
        </li>
        <li>
          Company Name: ${companyName}
        </li>
        <li>
          Date/Time: ${timeStamp}
        </li>
      </ol>

      <h4>
        Training info:
      </h4>
      <ol>
        <li>
          OnetTitle: ${OnetTitle}
        </li>
        <li>
          OnetCode: ${OnetCode}
        </li>
        <li>
          Location: ${occupationLocation} (${location})
        </li>
      </ol>

      <h4>
        Plan ID: ${id}
      </h4>

      <br />
      <br />
      <font color="#888888">--</font>
      <br />
      <div>
        Powered by <a href="${TURBINE_MARKETING_SITE}" target="_blank" title="${PLATFORM_NAME}">${PLATFORM_NAME}</a>
      </div>
      <br />
      <br />
    `
  };

  // <li>
  //         Timeline: ${trainingTimeline}
  //       </li>
  //       <li>
  //         Budget: ${trainingBudget}
  //       </li>

  // <h4>
  //       What is your intent with this Training Plan?
  //     </h4>
  //     <ol>
  //       <li>
  //         Create a registered apprenticeship: <b>${createApprenticeship ? 'Yes' : 'No'}</b>
  //       </li>
  //       <li>
  //         Create a training program: <b>${createTraining ? 'Yes' : 'No'}</b>
  //       </li>
  //     </ol>

  return new Promise((resolve, reject) => {
    // twApi gmail api
    return twApi.post('/v1/external/gmail/send', '', JSON.stringify(emailParts)).then((response) => {
      if (response && response.status === 200) {
        resolve();
      } else {
        console.error(response.status); // ex: 'error_invitation_fail'

        reject({
          message: response.message,
          status: response.status
        });
      }
    }).catch((error) => {
      console.error(error);
    });
  });
};
