import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { updateApprenticeAgreement } from '../../services/apprenticeAgreements';
import { updateUser } from '../../services/user';
import Preamble from './US/PA/Preamble';
import PageHeader from './US/PA/PageHeader';
import SectionApprentice from './US/SectionApprentice';
import SectionSponsor from './US/SectionSponsor';
import ApprenticeResponsibility from './US/ApprenticeResponsibility';
import DisabilityDisclosure from './US/DisabilityDisclosure';
import PageFooter from './US/PA/PageFooter';
import goToElem from '../../utils/goToElem';
import { deleteFile } from '../../services/aws';
import { getApprenticeshipUser } from '../../actions/Apprenticeships';
import { getApprenticeAgreement } from '../../actions/ApprenticeAgreements';
import { deleteInvite } from '../../services/invites';

const Form = ({ dataType = '' }) => {
  const dispatch = useDispatch();
  // Redux
  const organization = useSelector((state) => state.organization);
  const apprenticeship = useSelector((state) => state.apprenticeship);
  const apprenticeshipUser = useSelector((state) => state.apprenticeshipUser);
  const apprenticeAgreement = useSelector((state) => state.apprenticeAgreement);
  const invite = useSelector((state) => state.invite);
  // DEFAULT DATA (temp. hard coded)
  const hoursProbationary = apprenticeAgreement?.hoursProbationary || 0;
  const creditPrevJob = apprenticeAgreement?.creditPrevJob || 0;
  const wagesPaidDuringRTI = apprenticeAgreement?.wagesPaidDuringRTI || 'no';
  const RTIDuringWorkHrs = apprenticeAgreement?.RTIDuringWorkHrs || 'no';
  const creditPrevRTIHrs = apprenticeAgreement?.creditPrevRTIHrs || 0;
  const priorHourlyWage = apprenticeAgreement?.priorHourlyWage || 0;
  // DEFAULT DATA (Apprenticeship)
  const occupationName = apprenticeAgreement?.occupationName || apprenticeship?.occupationName || '';
  const ojtHours = apprenticeAgreement?.ojtHours || apprenticeship?.ojtHours || '';
  const instructionHours = apprenticeAgreement?.instructionHours || apprenticeship?.instructionHours || '';
  const startDate = apprenticeAgreement?.startDate || apprenticeship?.startDate || '';
  const expectedEndDate = apprenticeAgreement?.expectedEndDate || apprenticeship?.expectedEndDate || '';

  const handleUpdateApprenticeAgreement = (formData) => {
    const dataToSave = {
      ...formData,
      // Sponsor Section
      hoursProbationary,
      creditPrevJob,
      wagesPaidDuringRTI,
      RTIDuringWorkHrs,
      creditPrevRTIHrs,
      priorHourlyWage,
      occupationName,
      ojtHours,
      instructionHours,
      startDate,
      expectedEndDate
    };

    if (formData?.signerSignature) {
      // NOTE: How to permanently preserve userSignatures in Agreements?
      // Maybe convert images to base64 strings and save to Agreements/PDFs...

      toast.info('Saving Agreement...');

      // Remove any old userSignature before saving new
      if (apprenticeshipUser?.userSignature) {
        deleteFile({
          url: apprenticeshipUser?.userSignature,
          orgId: organization?.id
        });
      }

      updateUser({ userSignature: formData?.signerSignature }, apprenticeshipUser?.id).then(() => {
        updateApprenticeAgreement(dataToSave, apprenticeAgreement?.id).then(() => {
          // Delete Invite
          deleteInvite(invite?.id);

          dispatch(getApprenticeAgreement(apprenticeAgreement?.id)).then(() => {
            dispatch(getApprenticeshipUser(apprenticeshipUser?.id)).then(() => {
              toast.success('Apprentice Agreement Accepted & Signed!');
              goToElem('root');
            });
          });
        });
      });
    } else {
      toast.error('Something went wrong, please refresh and try again', { autoClose: false });
    }
  };

  return (
    <>
      <div
        className='bg-white px-3 py-4 mt-3 text-black'
      >
        <PageHeader
          className='mb-5'
        />

        <Preamble />

        <div className='border'>
          <div className='p-3'>
            <div
              className='m-0'
              style={{
                fontSize: '.78rem',
                color: 'black'
              }}
            >
              The apprentice, program sponsor and employer agree to the terms of the apprenticeship standards as incorporated as part of this agreement which may be amended during the period of the agreement. Neither the sponsor nor the employer will discriminate in the selection and training of the apprentice in accordance with the Equal Opportunity Standards in Section 30.3, Title 29, Code of Federal Regulation, Part 30. This agreement may be terminated by any of the parties, citing cause(s) with notification to the registration agency listed in Section C. 1., in compliance with 34 Pa. Code § 83.6. Arising differences that cannot be resolved locally may be referred to the registration agency. PRIVACY STATEMENT: The information requested herein is used for apprenticeship program statistical purpose and may not be otherwise disclosed without the express permission of the undersigned apprentice. Privacy Act of 1974- P.L. 93-579
            </div>
          </div>

          <SectionApprentice
            dataType={dataType}
            onSave={handleUpdateApprenticeAgreement}
          />
        </div>
      </div>

      <div
        className='bg-white px-3 py-4 mt-3 text-black'
      >
        <PageHeader
          pageBreakBefore
          className='mb-5'
        />

        <SectionSponsor />
      </div>

      <div
        className='bg-white px-3 py-4 mt-3 text-black'
      >
        <PageHeader
          pageBreakBefore
          className='mb-5'
        />
        <ApprenticeResponsibility />
      </div>

      <div
        className='bg-white px-3 py-4 mt-3 text-black'
      >
        <PageHeader
          pageBreakBefore
          className='mb-5'
        />
        <DisabilityDisclosure />
      </div>

      <PageFooter
        className='show-when-printing'
      />
    </>
  );
};

export default Form;
