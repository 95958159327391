import React, { Fragment } from 'react';
import Alert from '../Alert';
import './style.css';

const GoLive = ({
  title,
  buttonText,
  buttonClassName,
  streamingLink,
  slim,
  className = '',
  hideButton
}) => {
  if (slim) {
    return (
      <Alert
        type="info"
        icon="fas fa-chalkboard-teacher"
        className={`alertWithIconSlim ${className}`}
      >
        <div className="d-flex justify-content-between align-items-center">
          <div className="h6 mb-0 font-weight-bold">
            Class Live Now!
          </div>
          {!hideButton && (
            <a
              className={buttonClassName || 'btn btn-info btn-sm'}
              href={streamingLink}
              role="button"
              target="_blank"
              rel="noopener noreferrer"
            >
              {buttonText || 'Join'}
            </a>
          )}
        </div>
      </Alert>
    );
  }

  return (
    <Alert type="info" icon="fas fa-chalkboard-teacher" className={className}>
      <h2>
        Class Live Now!
      </h2>
      <p>
        Click the button below to join <strong>{ title }</strong>.
      </p>
      {!hideButton && (
        <Fragment>
          <hr className="mb-3" />
          <a
            className={buttonClassName || 'btn btn-info btn-md'}
            href={streamingLink}
            role="button"
            target="_blank"
            rel="noopener noreferrer"
          >
            {buttonText || 'Join'}
          </a>
        </Fragment>
      )}
    </Alert>
  );
};

export default GoLive;
