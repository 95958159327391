import { referenceType } from '../contentful';

function formatClass({
  dashboard,
  title,
  overview,
  schedule,
  subjects,
  description,
  topics,
  prerequisiteId, // prerequisite
  image,
  integration,
  slug,
  locked,
  openEnrollment,
  trackAttendance,

  // EDUCATION
  eUnitTracking,
  eUnits,
  //

  // CONTINUING INTEGRATION
  classCatalogIds,
  classCatalogIdsInfo,
  // CONTINUING INTEGRATION

  // stripeSku, // deprecated
  // stripeSkuTest, // deprecated
  // duration,
  slackChannel,
  streamingLink,
  isLive,
  showStreamLink,
  classImage
}) {
  const data = {};

  if (dashboard !== undefined) {
    data.dashboard = {
      'en-US': dashboard
    };
  }

  if (title !== undefined) {
    data.title = {
      'en-US': title
    };
  }

  if (overview !== undefined) {
    data.overview = {
      'en-US': overview
    };
  }

  if (schedule !== undefined) {
    data.schedule = {
      'en-US': schedule // string
    };
  }

  if (eUnits !== undefined && eUnits.length) {
    data.eUnits = {
      'en-US': eUnits.map(({ sys }) => referenceType(sys.id))
    };
  }

  if (eUnitTracking !== undefined && eUnitTracking.sys) {
    data.eUnitTracking = {
      'en-US': referenceType(eUnitTracking.sys.id)
    };
  }

  if (subjects !== undefined && subjects.length) {
    data.subjects = {
      'en-US': subjects.map(({ sys }) => referenceType(sys.id))
    };
  }

  if (description !== undefined) {
    data.description = {
      'en-US': description
    };
  }

  if (topics !== undefined) { // TODO more descriptive key would be categories
    data.topics = {
      'en-US': topics // array
    };
  }

  if (prerequisiteId !== undefined) {
    data.prerequisite = {
      'en-US': referenceType(prerequisiteId)
    };
  }

  if (image !== undefined) { // TODO replace with AWS
    data.image = {
      'en-US': image
    };
  }

  if (integration !== undefined) { // TODO may make sense to bring back the two deprecated stripe fields
    data.integration = {
      'en-US': integration
    };
  }

  if (slug !== undefined) {
    data.slug = {
      'en-US': slug
    };
  }

  if (locked !== undefined) {
    data.locked = {
      'en-US': locked // boolean
    };
  }

  if (openEnrollment !== undefined) {
    data.openEnrollment = {
      'en-US': openEnrollment // boolean
    };
  }

  if (trackAttendance !== undefined) {
    data.trackAttendance = {
      'en-US': trackAttendance // boolean
    };
  }

  // CONTINUING EDUCATION

  if (classCatalogIds !== undefined) {
    data.classCatalogIds = {
      'en-US': classCatalogIds // array
    };
  }

  if (classCatalogIdsInfo !== undefined) {
    data.classCatalogIdsInfo = {
      'en-US': classCatalogIdsInfo // string
    };
  }

  if (classImage !== undefined) {
    data.classImage = {
      'en-US': classImage // string
    };
  }

  // /CONTINUING EDUCATION

  if (slackChannel !== undefined) {
    data.slackChannel = {
      'en-US': slackChannel
    };
  }

  if (streamingLink !== undefined) {
    data.streamingLink = {
      'en-US': streamingLink
    };
  }

  if (isLive !== undefined) {
    data.isLive = {
      'en-US': isLive // boolean
    };
  }

  if (showStreamLink !== undefined) {
    data.showStreamLink = {
      'en-US': showStreamLink // boolean
    };
  }

  return { fields: { ...data } };
}

export default formatClass;
