import { isLocalhost } from '../serviceWorker';
import * as twApi from './tw-api';

/**
 * Gets Geo-location info by visitor IP
 * @param {string} ip - optional ip address
 * @returns {Promise<string>} - An Object of geo-location data
 */
export async function getGeoLocation(ip) {
  let config = ip ? { ip } : undefined;

  if (isLocalhost) {
    config = { ip: '71.116.62.57' };
    // {
    //   city: "Plum"
    //   state: "PA"
    //   stateReadable: "Pennsylvania"
    //   zip: "15147"
    // }
  }
  const response = await twApi.get('/v1/external/ipapi', config);

  return response;
}
