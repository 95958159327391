import { fetchUserProfile } from '../services/userProfiles';
import {
  SET_USER_PROFILE, RESET_USER_PROFILE
} from './types';

export const getUserProfile = (id) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      fetchUserProfile(id).then((userProfile) => {
        dispatch({ type: SET_USER_PROFILE, userProfile });

        resolve(userProfile);
      }).catch((error) => {
        console.error(error);
        reject(error);
      });
    });
  };
};

export const resetUserProfile = () => {
  return (dispatch) => {
    dispatch({ type: RESET_USER_PROFILE });
  };
};
