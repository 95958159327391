import React from 'react';
import { useSelector } from 'react-redux';
import { pathOr } from 'ramda';
import { getFullAddress } from '../../../../services/employers';
import Section from '../../Section';

const EmployerInformation = ({
  className = 'mb-3'
}) => {
  // redux
  const orgLocation = useSelector((state) => state.orgLocation);
  const employerProfile = useSelector((state) => state.employerProfile);
  // Employer Profile
  const contactFirstName = pathOr('', ['contactFirstName'], employerProfile);
  const contactLastName = pathOr('', ['contactLastName'], employerProfile);
  const contactTitle = pathOr('', ['contactTitle'], employerProfile);
  const contactPhone = pathOr('', ['contactPhone'], employerProfile);
  const contactEmail = pathOr('', ['contactEmail'], employerProfile);
  // Org Location
  const locationName = pathOr(null, ['name'], orgLocation);
  const addressState = pathOr('', ['addressState'], orgLocation);
  const addressLine1 = pathOr('', ['addressLine1'], orgLocation);
  const addressLine2 = pathOr('', ['addressLine2'], orgLocation);
  const addressCity = pathOr('', ['addressCity'], orgLocation);
  const addressPostalCode = pathOr('', ['addressPostalCode'], orgLocation);

  const employerAddress = getFullAddress({
    location: {
      addressLine1,
      addressLine2,
      addressState,
      addressCity,
      addressPostalCode
    }
  });

  return (
    <Section
      num={1}
      title="Employer Information"
      className={className}
    >
      <div
        className="mb-2"
      >
        <strong>Name of Employer</strong>: {locationName}
      </div>
      <div
        className="mb-2"
      >
        <strong>Employer Address</strong>: {employerAddress}
      </div>
      <div
        className="mb-2"
      >
        <strong>Contact Name</strong>: {contactFirstName} {contactLastName}
      </div>
      {contactTitle && (
        <div
          className="mb-2"
        >
          <strong>Contact Title</strong>: {contactTitle}
        </div>
      )}
      <div
        className="mb-2"
      >
        <strong>Contact Phone</strong>: {contactPhone}
      </div>
      <div>
        <strong>Contact Email</strong>: {contactEmail}
      </div>
    </Section>
  );
};

export default EmployerInformation;
