import React from 'react';

const Preamble = ({
  className = '',
  title = 'EMPLOYER ACCEPTANCE AGREEMENT – Appendix E',
  subtitle = 'Registered Apprenticeship Standards Developed in Cooperation with the Pennsylvania Apprenticeship and Training Council and the Pennsylvania Apprenticeship and Training Office',
  programSponsorName = 'program sponsor name',
  occupationName = 'occupation name'
}) => {
  return (
    <div className={className}>
      <div
        className="text-center"
        style={{
          marginTop: '2rem',
          marginBottom: '1.5rem'
        }}
      >
        <div
          className="h5 font-weight-bold"
        >
          {title}
        </div>

        <div
          className="font-weight-bold"
          style={{
            fontSize: '.8rem'
          }}
        >
          {subtitle}
        </div>
      </div>

      <p>
        The undersigned employer hereby subscribes to the provisions of the Registered Apprenticeship Standards for <strong>{occupationName}</strong> formulated and registered by <strong>{programSponsorName}</strong> and agrees to carry out the intent and purpose of said standards and to abide by the rules and decisions of the program sponsor established under these standards. The employer has been provided a copy of the Standards and have read and understood them, and requests certification to train apprentices under the provisions of these standards.
      </p>

      <p>
        The apprentice is hereby guaranteed assignment to a skilled and competent journey worker and is guaranteed
        that the work assigned to the apprentice will be rotated to ensure training in all phases of work. Employer
        shall meet all requirements of PA Regulations 81.11 through 81.21 and indemnify and hold harmless sponsor
        for failure to meet said standards. This employer acceptance agreement will remain in effect until canceled
        voluntarily or revoked by the sponsor or the Pennsylvania Apprenticeship and Training Council (PATC).
      </p>

    </div>
  );
};

export default Preamble;
