import React from 'react';
import usStates from './usStates';
import countries from '../../../constants/countries';

const Address = ({
  title,
  className = '',
  address = {},
  editMode,
  onChange,
  toggleEditMode,
  inputFieldStyle = {},
  autoFocus = true
}) => {
  if (!editMode) {
    return (
      <div className={`card ${className}`}>
        <div className="card-body p-2">
          <div className="d-flex align-items-center">
            <b>
              {title || 'Address'}:
            </b>
            <button
              className="btn-link ml-2"
              onClick={toggleEditMode}
              type="button"
            >
              <i className="fas fa-pencil-alt" />
            </button>
          </div>
          {address.line1 && (<div className="mt-2">{address.line1}</div>)}
          {address.line2 && (<div>{address.line2}</div>)}
          {(address.state || address.postal_code || address.country) && (
            <div>
              {address.state}, {address.postal_code}, {address.country}
            </div>
          )}
        </div>
      </div>
    );
  }

  return (
    <div className={`card ${className}`}>
      <div className="card-header bg-secondary text-white font-weight-bold">
        {title || 'Address'}
      </div>
      <div className="card-body">
        <div className="mb-3">
          <label htmlFor="line1">
            Street <span className="text-danger">*</span>
          </label>
          <input
            type="text"
            className="form-control"
            style={inputFieldStyle}
            id="line1"
            name="line1"
            required
            defaultValue={address.line1}
            onChange={onChange}
            autoFocus={autoFocus}
          />
        </div>

        <div className="mb-3">
          <label htmlFor="line2">
            Apt, Suite, etc. <small className="text-muted">(Optional)</small>
          </label>
          <input
            type="text"
            className="form-control"
            style={inputFieldStyle}
            id="line2"
            name="line2"
            defaultValue={address.line2}
            onChange={onChange}
          />
        </div>

        <div className="row">
          <div className="col-md-5 mb-3">
            <label htmlFor="city">
              City <span className="text-danger">*</span>
            </label>
            <input
              type="text"
              className="form-control"
              style={inputFieldStyle}
              id="city"
              name="city"
              defaultValue={address.city}
              onChange={onChange}
            />
          </div>

          <div className="col-md-4 mb-3">
            <label htmlFor="state">
              State <span className="text-danger">*</span> {/* Postal */}
            </label>
            <select
              className="custom-select d-block w-100"
              id="state"
              name="state"
              required
              defaultValue={address.state}
              onChange={onChange}
            >
              <option value="">Choose...</option>
              {usStates.map(({ name, abbreviation }) => (
                <option key={abbreviation} value={abbreviation}>{ name }</option>
              ))}
            </select>
          </div>

          <div className="col-md-3 mb-3">
            <label htmlFor="zip">
              Zip <span className="text-danger">*</span> {/* Postal */}
            </label>
            <input
              type="text"
              className="form-control"
              style={inputFieldStyle}
              id="postal_code"
              name="postal_code"
              placeholder=""
              required
              defaultValue={address.postal_code}
              onChange={onChange}
            />
          </div>
        </div>

        <div className="mb-3">
          <label htmlFor="country">
            Country <span className="text-danger">*</span>
          </label>
          <select
            className="custom-select d-block w-100"
            id="country"
            name="country"
            required
            defaultValue={address.country}
            onChange={onChange}
          >
            <option value="">Choose...</option>
            {countries.map(({ name, code }) => (
              <option key={code} value={code}>{ name }</option>
            ))}
          </select>
        </div>
      </div>
    </div>
  );
};

export default Address;
