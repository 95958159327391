import { pathOr } from 'ramda';
import { membershipReadable } from '../services/currentUser';

const serializeCurrentUser = (currentUser) => {
  const membership = pathOr(null, ['membership'], currentUser);

  if (currentUser !== undefined) {
    // if (currentUser.payments) {
    //   currentUser.payments = currentUser.payments.reverse();
    // }

    if (membership) {
      currentUser.membershipReadable = membershipReadable(currentUser.membership);
    }
  }

  return currentUser;
};

export default serializeCurrentUser;
