import { toast } from 'react-toastify';
import {
  deleteFirebaseEntry, updateFirebaseEntry
} from './firebaseProxy';

const collection = 'invites';

/**
 *
 * @param {string} employerProfileId
 * @param {object} data
 */
export const updateInvite = (data, id) => {
  return new Promise((resolve) => {
    updateFirebaseEntry({
      collection,
      data,
      id
    }).then(() => {
      resolve();
    }).catch((error) => {
      toast.error('Something went wrong');
      console.error(`update ${collection}`, error);
    });
  });
};

/**
 *
 * @param {string} id // "docId"
 * @param {object} data
 */
export const deleteInvite = (id) => {
  return new Promise((resolve) => {
    deleteFirebaseEntry({
      collection,
      id
    }).then((response) => {
      resolve(response);
    }).catch((error) => {
      toast.error('Something went wrong');
      console.error(`delete ${collection}`, error);
    });
  });
};

export const currentInviteHistoryEntry = (invite) => {
  const {
    // exclude keys before adding current Invite to inviteHistory
    createdAt,
    updatedAt,
    id,
    viewedAt,
    sentAt,
    locationId,
    orgId,
    employerProfileId,
    accessCode,
    ...currentInvite
  } = invite;

  return currentInvite;
};
