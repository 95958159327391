import React from 'react';

const PageFooter = ({
  className = '',
  text = 'Rev. March 2023'
}) => {
  return (
    <div
      className={`${className} ApprenticeAgreement-PageFooter position-fixed`}
      style={{
        bottom: '10px'
      }}
    >
      <div className='text-center'>
        {text}
      </div>
    </div>
  );
};

export default PageFooter;
