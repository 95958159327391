import React from 'react';
import { reactSelectOptionsFromEnum } from '@apprentage/utils';

const CheckBoxes = ({
  value,
  values,
  optionsEnum,
  vertical = false,
  className = 'mr-4',
  minWidth = ''
}) => {
  const options = reactSelectOptionsFromEnum(optionsEnum);

  const isChecked = (optionValue) => {
    if (value) {
      return optionValue === value;
    }

    if (values) {
      return values.includes(optionValue);
    }
  };

  return (
    <div
      className={`d-flex flex-wrap ${vertical ? 'flex-column' : ''}`}
    >
      {options.map((option, i) => {
        return (
          <label
            key={`checkbox-${option.value}-${i}`}
            className={`${className} d-flex align-items-center mb-0`}
            style={{
              ...(minWidth ? { minWidth } : {}),
              fontSize: '.8rem'
            }}
          >
            <input
              type="checkbox"
              value={option?.value}
              checked={isChecked(option?.value)}
              readOnly
            />
            <span
              className='ml-1 text-nowrap'
            >
              {option?.label}
            </span>
          </label>
        );
      })}
    </div>
  );
};

export default CheckBoxes;
