import { getFirebaseEntries, getFirebaseEntry } from '../services/firebaseProxy';
import { SET_JOB_PROFILE, RESET_JOB_PROFILE, SET_JOB_PROFILES } from './types';

export const getJobProfile = (id, collection = 'jobProfiles') => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      getFirebaseEntry({ collection, id }).then((response) => {
        const jobProfile = response || null;

        dispatch({ type: SET_JOB_PROFILE, jobProfile });
        resolve(jobProfile);
      }).catch((error) => {
        console.error(error);
        reject(error);
      });
    });
  };
};

export const getJobProfiles = ({ orgId }) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      getFirebaseEntries({
        collection: 'jobProfiles',
        orgId
      }).then(({ items }) => {
        const jobProfiles = items || [];

        dispatch({
          type: SET_JOB_PROFILES,
          jobProfiles
        });
        resolve(jobProfiles);
      }).catch((error) => {
        console.error(error);
        reject(error);
      });
    });
  };
};

export const resetJobProfile = () => {
  return (dispatch) => {
    dispatch({ type: RESET_JOB_PROFILE });
  };
};
