import React from 'react';

const OccupationElement = ({
  title,
  data,
  handleChooseElements,
  handleAddElements,
  viewOnly
}) => {
  if (!data || (Array.isArray(data) && data.length === 0)) {
    return null;
    // return (
    //   <div className="card h-100">
    //     <div className="card-header h5 mb-0">
    //       <div>
    //         {title}
    //       </div>
    //     </div>
    //     <div
    //       className="d-flex justify-content-between align-items-center border-bottom px-3 py-2"
    //     >
    //       No {title} available.
    //     </div>
    //   </div>
    // );
  }

  return (
    <div className="card h-100 mb-4">
      <div className="card-header h5 mb-0">
        <div>
          {title}
        </div>
      </div>
      <div
        className="d-flex justify-content-between align-items-center border-bottom px-3 py-2"
      >
        <div>
          Name
        </div>
        <div>
          Importance
        </div>
      </div>
      <div className="card-body">
        {data.map((sdl) => {
          const {
            ElementName,
            ElementDescription,
            ElementId,
            Importance
          } = sdl;

          return (
            <div
              key={ElementId}
              className="d-flex justify-content-between align-items-center mb-4"
            >
              <div className="left d-flex align-items-center">
                <div className="text pr-3">
                  <h6 className="mb-0 d-flex align-items-center">
                    <span>
                      {ElementName}
                    </span>
                    <span className="dot dot-sm ms-2 bg-indigo" />
                  </h6>
                  <small className="text-muted">
                    {ElementDescription}
                  </small>
                </div>
              </div>
              <div className="right ms-5 ms-sm-0 ps-3 ps-sm-0">
                <h5 className="mb-0">
                  {Importance}
                </h5>
              </div>
            </div>
          );
        })}
      </div>
      <div className="card-footer d-flex justify-content-between d-print-none">
        {!viewOnly && (
          <button
            type="button"
            className="btn btn-sm btn-primary"
            onClick={handleChooseElements}
          >
            Choose {title}
          </button>
        )}
        {!viewOnly && handleAddElements && (
          <button
            type="button"
            className="btn btn-sm btn-outline-primary"
            onClick={handleAddElements}
          >
            Add {title}
          </button>
        )}
      </div>
    </div>
  );
};

export default OccupationElement;
