import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { setCurrentModal } from '../actions';
import SignatureFlag from './Modals/UserSignature/SignatureFlag';

const defaultText = 'I attest the information provided is accurate.';

const UserSignatureBlock = ({
  id = 'UserSignatureBlock',
  className = 'border rounded my-3 px-3 py-2',
  onChange,
  text = defaultText,
  textAboveSignature = `By signing, ${defaultText}`,
  textBelowSignature = '',
  signerName = '',
  signerSignature = '',
  primaryButtonText = '',
  showSignatureFlag = false,
  disabled = false
}) => {
  const dispatch = useDispatch();
  // misc
  const [signature, setSignature] = useState(signerSignature);

  const addSignature = () => {
    dispatch(setCurrentModal({
      key: 'userSignature',
      data: {
        primaryButtonText,
        textAboveSignature,
        textBelowSignature,
        callback: (newUserSignature) => {
          setSignature(newUserSignature);
          onChange(newUserSignature);
        }
      }
    }));
  };

  return (
    <div
      id={id}
      className={className}
    >
      {text && (
        <div className="mb-4 pb-4 text-center">
          {text}
        </div>
      )}

      {signature ? (
        <img
          src={signature}
          style={{
            height: '44px',
            marginBottom: '0.75rem'
          }}
          alt={`${signerName} Signature`}
        />
      ) : (
        <div className='hide-when-printing'>
          <div
            className='d-flex justify-content-center position-relative'
          >
            {showSignatureFlag && (
              <SignatureFlag
                style={{
                  bottom: '15px'
                }}
              />
            )}

            <button
              className='btn btn-sm btn-warning mb-4'
              onClick={addSignature}
              type="button"
              disabled={disabled}
            >
              Add Signature
            </button>
          </div>
        </div>
      )}

      {(textBelowSignature || signerName) && (
        <SignatureBelowText>
          {textBelowSignature || signerName}
        </SignatureBelowText>
      )}
    </div>
  );
};

const SignatureBelowText = styled.div`
  border-top: 1px dashed #dee2e6;
  text-align: center;
  margin-bottom: 0 0 20px;
  padding-top: 10px;
`;

export default UserSignatureBlock;
