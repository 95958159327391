import React, { Fragment, useState } from 'react';
import AboutThisData from './AboutThisData';

const OccupationSearch = ({
  handleSearch,
  loading,
  handleFocus,
  searchValue,
  searchResults,
  selectSearchResult,
  className = ''
}) => {
  const [showSearchInput, setShowSearchInput] = useState(!searchValue);

  const handleSelectResult = (result) => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    selectSearchResult(result);
  };

  return (
    <Fragment>
      <div className={`container ${className}`}>
        <div className="row">
          <div className="col">
            <div className="card rounded shadow">
              {!searchValue && (
                <div className="py-5 pathways-header border-bottom">
                  <div className="row justify-content-center py-2">
                    <div className="col-lg-10 text-center">
                      <div className="m-2">
                        <div className="h3 font-weight-normal mb-0">
                          Employer-driven
                        </div>
                        <div className="h2 font-weight-bold mb-0">
                          Career Pathways
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className="card-body">
                <form
                  onSubmit={(e) => {
                    handleSearch(e);
                    setShowSearchInput(false);
                  }}
                >
                  <div className="mb-3 text-left d-flex align-items-center">
                    <div className="mr-2">
                      <div
                        className={`pathways-step-number ${searchValue ? 'bg-success' : 'bg-dark'}`}
                      >
                        {searchValue ? <i className="fas fa-check" /> : '1'}
                      </div>
                    </div>
                    <h4 className="font-weight-bold m-0">
                      Search occupations
                    </h4>
                  </div>
                  <div className="font-weight-bold mb-3 h5">
                    Which position or apprenticeship is this training plan for?
                  </div>
                  {showSearchInput ? (
                    <Fragment>
                      <div className="input-group input-group-lg">
                        <input
                          type="text"
                          className="form-control mr-2"
                          name="inputOccupationKeywords"
                          defaultValue={searchValue}
                          // placeholder="Enter position"
                          onFocus={handleFocus}
                          autoFocus
                          required
                        />
                        <button type="submit" className="btn btn-primary btn-md">
                          Search
                        </button>
                      </div>
                      <div className="mt-2">
                        Example: CNC Operator
                        <span className="d-none d-sm-inline">, Advanced Manufacturing Supervisors</span>
                      </div>
                    </Fragment>
                  ) : (
                    <div className="d-flex align-items-center">
                      <div className="border-bottom h4 pb-2 mb-0 ml-2">
                        {searchValue}
                      </div>
                      <button
                        className="btn btn-outline-primary btn-sm ml-3"
                        type="button"
                        onClick={() => {
                          setShowSearchInput(true);
                        }}
                      >
                        Change
                      </button>
                    </div>
                  )}
                </form>
              </div>
              <div className="card-footer">
                <AboutThisData />
              </div>
            </div>

          </div>
        </div>
      </div>

      {!searchResults && !loading && (
        <div className="container">
          <div className="row">
            <div className="col-sm-6 mx-auto text-center">
              <img
                className="mb-5"
                width="250"
                src="https://s3.us-west-2.amazonaws.com/turbineworkforce.com/no-results.jpg"
                alt="Magnifying glass no Results"
              />
              <h4 className="font-weight-bold">
                No results found
              </h4>
              <p>
                Try a more general search or check spelling.
              </p>
            </div>
          </div>
        </div>
      )}

      {/* {!searchResults && (
        <div className="container">
          <div className="row">
            <div className="col-sm-6 mx-auto text-center">
              <img
                className="mb-5"
                width="300"
                src="https://s3.us-west-2.amazonaws.com/turbineworkforce.com/ship-sinking.jpg"
                alt="Ship sinking somethings wrong"
              />
              <h4 className="font-weight-bold">
                Something wrong here...
              </h4>
              <p>
                We're having some technical issues (as you can see).
                Trying to refresh the page may solve the problem.
              </p>
            </div>
          </div>
        </div>
      )} */}

      {searchValue && searchResults && (
        <div className="container">
          <div className="card border shadow">
            <div className="card-header bg-white py-4">
              <div className="d-flex align-items-center">
                <div className="mr-2">
                  <div className="pathways-step-number bg-dark">
                    2
                  </div>
                </div>
                <h4 className="font-weight-bold m-0">
                  Choose an occupation profile that best matches your search
                </h4>
              </div>
              {/* <div className="font-weight-bold m-0 h5">
                Choose an occupation profile that best matches your search
              </div> */}
            </div>
            <div className="card-body">
              <div className="row">
                {searchResults.map((result) => {
                  const {
                    OnetTitle,
                    OccupationDescription,
                    OnetCode
                  } = result;

                  return (
                    <div
                      key={OnetCode}
                      className="col-sm-12 mb-4"
                    >
                      <div className="card">
                        <div className="card-body">
                          <p
                            className="font-weight-bold"
                          >
                            {OnetTitle}
                          </p>
                          <div>
                            {OccupationDescription}
                          </div>
                        </div>
                        <div className="card-footer d-flex justify-content-between align-items-center">
                          <button
                            type='button'
                            className="btn btn-sm btn-primary"
                            onClick={() => {
                              handleSelectResult(result);
                            }}
                          >
                            Select
                          </button>
                          <div>
                            {OnetCode}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>

        </div>
      )}
    </Fragment>
  );
};

export default OccupationSearch;
