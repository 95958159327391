import { pathOr } from 'ramda';
import React from 'react';
import { useSelector } from 'react-redux';
import Section from '../../Section';

const WorkforceNumbers = ({
  className = 'mb-3'
}) => {
  // Redux
  const employerProfile = useSelector((state) => state.employerProfile);
  // Employees | Employer Profile
  const totalEmployees = pathOr(0, ['totalEmployees'], employerProfile);
  const totalEmployeesColor = pathOr(0, ['totalEmployeesColor'], employerProfile);
  const totalEmployeesFemale = pathOr(0, ['totalEmployeesFemale'], employerProfile);
  // Journeyworkers | Employer Profile
  const totalJourneyWorkers = pathOr(0, ['totalJourneyWorkers'], employerProfile);
  const totalJourneyWorkersColor = pathOr(0, ['totalJourneyWorkersColor'], employerProfile);
  const totalJourneyWorkersFemale = pathOr(0, ['totalJourneyWorkersFemale'], employerProfile);

  return (
    <Section
      num={2}
      title="Workforce Numbers"
      className={className}
    >
      <table className="table table-striped table-sm">
        <tbody>
          <tr>
            <td>
              <strong>Total Employees in Company:</strong> {totalEmployees}
            </td>
          </tr>
          <tr>
            <td>
              <strong>Female:</strong> {totalEmployeesFemale}
            </td>
          </tr>
          <tr>
            <td>
              <strong>People of Color:</strong> {totalEmployeesColor}
            </td>
          </tr>
        </tbody>
      </table>

      <br />

      <table className="table table-striped table-sm">
        <tbody>
          <tr>
            <td>
              <strong>Total Journeyworkers in Company:</strong> {totalJourneyWorkers}
            </td>
          </tr>
          <tr>
            <td>
              <strong>Female:</strong> {totalJourneyWorkersFemale}
            </td>
          </tr>
          <tr>
            <td>
              <strong>People of Color:</strong> {totalJourneyWorkersColor}
            </td>
          </tr>
        </tbody>
      </table>
    </Section>
  );
};

export default WorkforceNumbers;
