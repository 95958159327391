import { fetchEmployerProfile } from '../services/employers';
import { fetchLocation } from '../services/locations';
import {
  RESET_EMPLOYER,
  RESET_EMPLOYER_PROFILE,
  SET_EMPLOYER,
  SET_EMPLOYER_PROFILE
} from './types';

export const getEmployerProfile = (employerProfileId) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      fetchEmployerProfile(employerProfileId).then((employerProfile) => {
        dispatch({ type: SET_EMPLOYER_PROFILE, employerProfile });
        resolve(employerProfile);
      }).catch((error) => {
        console.error(error);
        reject(error);
      });
    });
  };
};

export const setEmployerProfile = (employerProfile) => {
  return (dispatch) => {
    return new Promise((resolve) => {
      dispatch({ type: SET_EMPLOYER_PROFILE, employerProfile });
      resolve(employerProfile);
    });
  };
};

export const getEmployer = (locationId) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      return fetchLocation({ locationId }).then((employer) => {
        // Has Profile
        if (employer && employer.employerProfileId) {
          dispatch(getEmployerProfile(employer.employerProfileId)).then(() => {
            dispatch({
              type: SET_EMPLOYER,
              employer
            });
            resolve(employer);
          });
        } else {
          // No profile
          dispatch({
            type: SET_EMPLOYER,
            employer
          });
          resolve(employer);
        }
      }).catch((error) => {
        console.error(error.message);
        reject(error);
      });
    });
  };
};

export const resetEmployer = () => {
  return (dispatch) => {
    dispatch({ type: RESET_EMPLOYER });
  };
};

export const resetEmployerProfile = () => {
  return (dispatch) => {
    dispatch({ type: RESET_EMPLOYER_PROFILE });
  };
};
