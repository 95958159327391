import { pathOr } from 'ramda';
import { toast } from 'react-toastify';
import {
  createFirebaseEntry,
  getFirebaseEntry,
  updateFirebaseEntry
} from './firebaseProxy';

export const fetchEmployerProfile = (id) => {
  return new Promise((resolve, reject) => {
    getFirebaseEntry({ collection: 'employerProfiles', id }).then((response) => {
      const employerProfile = response || null;

      resolve(employerProfile);
    }).catch((error) => {
      console.error(error);
      reject(error);
    });
  });
};

/**
 *
 * @param {string} employerProfileId
 * @param {object} data
 */
export const updateEmployerProfile = (data, employerProfileId) => {
  return new Promise((resolve) => {
    updateFirebaseEntry({
      collection: 'employerProfiles',
      data,
      id: employerProfileId
    }).then(() => {
      resolve();
    }).catch((error) => {
      toast.error('Something went wrong');
      console.error('update employerProfile', error);
    });
  });
};

export const createEmployerAgreement = (data) => {
  return new Promise((resolve) => {
    createFirebaseEntry({ data, collection: 'employerAgreements' }).then((response) => {
      const docId = pathOr(null, ['docId'], response);

      resolve(docId);
    }).catch((error) => {
      toast.error('Something went wrong');
      console.error('update employerProfile', error);
    });
  });
};

// TODO move to @apprentage/utils (update lms-admin & lms-console)
export const getFullAddress = ({ location }) => {
  const addressLine1 = pathOr(null, ['addressLine1'], location);
  const addressLine2 = pathOr(null, ['addressLine2'], location);
  const addressCity = pathOr(null, ['addressCity'], location);
  const addressState = pathOr(null, ['addressState'], location);
  const addressPostalCode = pathOr(null, ['addressPostalCode'], location);

  let fullAddress = addressLine1;

  if (addressLine2) {
    fullAddress += ` ${addressLine2}`;
  }

  fullAddress += ', ';
  fullAddress += `${addressCity}, `;
  fullAddress += addressState;
  fullAddress += ` ${addressPostalCode}`;

  return fullAddress;
};
