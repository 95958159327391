import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ICON_USER_EDIT } from '@apprentage/constants';
import Modal from '../../Modal';
import Tabs from './Tabs';
import ProfileForm from './ProfileForm';
import PrivateInfoForm from './PrivateInfoForm';
import { resetCurrentModal } from '../../../actions';

const ManageUserProfile = () => {
  const dispatch = useDispatch();
  // redux
  const currentModal = useSelector((state) => state.currentModal);
  const apprenticeshipUser = useSelector((state) => state.apprenticeshipUser);
  const userProfile = useSelector((state) => state.userProfile);
  // Local State
  const [activeTab, setActiveTab] = useState(currentModal?.data?.activeTab || 'profile');
  const modalTitle = currentModal?.data?.modalTitle || 'Edit Personal Information';
  const handleClose = () => {
    dispatch(resetCurrentModal());
  };

  if (!apprenticeshipUser?.id || !userProfile?.id) {
    return;
  }

  return (
    <Modal
      cssClassName={`turbine-modal--style-card turbine-modal--${currentModal?.key}`}
      visible={currentModal?.visible}
      close={handleClose}
      pos="top"
      theme="dark"
    >
      <div className="card">
        <div className="card-header bg-dark">
          <h5 className="m-0 font-weight-bold text-white">
            <i className={`${ICON_USER_EDIT} mr-1`} /> {modalTitle}
          </h5>
        </div>

        <div className='px-3 pt-3 pb-2 bg-light text-right'>
          <h6 className='font-weight-bold'>
            {apprenticeshipUser?.name}
          </h6>
          <div>
            {apprenticeshipUser?.email}
          </div>
        </div>

        <Tabs
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          className='bg-light'
        />

        {activeTab === 'profile' && (
          <div
            className="card-content"
            id="userProfileCollapse"
          >
            <ProfileForm />
          </div>
        )}

        {activeTab === 'privateInfo' && (
          <div
            className="card-content"
            id="userApprenticeInfoCollapse"
          >
            <PrivateInfoForm />
          </div>
        )}
      </div>
    </Modal>
  );
};

export default ManageUserProfile;
