import { fetchEmployerAgreement } from '../services/employerAgreements';
import {
  SET_EMPLOYER_AGREEMENT, RESET_EMPLOYER_AGREEMENT
} from './types';

export const getEmployerAgreement = (id) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      fetchEmployerAgreement(id).then((employerAgreement) => {
        dispatch({ type: SET_EMPLOYER_AGREEMENT, employerAgreement });
        resolve(employerAgreement);
      }).catch((error) => {
        console.error(error);
        reject(error);
      });
    });
  };
};

export const resetEmployerAgreement = () => {
  return (dispatch) => {
    dispatch({ type: RESET_EMPLOYER_AGREEMENT });
  };
};
