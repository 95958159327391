import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { pathOr } from 'ramda';
import { locationTerminology } from '../../services/locations';
import { getApprenticeship } from '../../actions/Apprenticeships';
import { getEmployerAgreement } from '../../actions/EmployerAgreements';
import Loading from '../Loading';
import OrgConsoleHeader from '../OrgConsole/OrgConsoleHeader';
import Form from './Form';
import './styles.css';

const EmployerAgreement = ({
  showPrintButton = false,
  hideHeader = false,
  employerAgreementId = '',
  data = {} // invite || doc
}) => {
  const dispatch = useDispatch();
  // Redux
  const organization = useSelector((state) => state.organization);
  const orgLocation = useSelector((state) => state.orgLocation);
  const apprenticeship = useSelector((state) => state.apprenticeship);
  const employerProfile = useSelector((state) => state.employerProfile);
  const employerAgreement = useSelector((state) => state.employerAgreement);
  // Organization
  const orgType = pathOr(null, ['type'], organization);
  const orgName = pathOr(null, ['name'], organization);
  const locationName = pathOr(null, ['name'], orgLocation);
  const locationTerm = locationTerminology({ orgType });
  //
  const employerProfileId = pathOr(null, ['employerProfileId'], data);
  const apprenticeshipId = pathOr(null, ['apprenticeshipId'], data);

  useEffect(() => {
    document.body.classList.add('employer-manage-employer-agreement');

    if (apprenticeshipId) {
      dispatch(getApprenticeship(apprenticeshipId));
    }

    if (employerAgreementId) {
      dispatch(getEmployerAgreement(employerAgreementId));
    }
  }, []);

  if (employerProfileId && !employerProfile?.id) {
    return (
      <Loading
        text="Loading Workforce Info..."
        className='theme-dark'
      />
    );
  }

  if (apprenticeshipId && !apprenticeship?.id) {
    return (
      <Loading
        text="Loading Training Program..."
        className='theme-dark'
      />
    );
  }

  if (employerAgreementId && !employerAgreement?.id) {
    return (
      <Loading
        text="Loading Employer Agreement..."
        className='theme-dark'
      />
    );
  }

  return (
    <div>
      <div className="row d-flex justify-content-center">
        <div className="col-sm-12 col-lg-7">

          <div className="mt-3">
            {!hideHeader && (
              <div
                className='hide-when-printing'
              >
                <OrgConsoleHeader
                  pageTitle={orgLocation?.name}
                  icon={locationTerm.icon}
                  image={orgLocation?.locationImage}
                  imageWidth=''
                />
              </div>
            )}

            {!hideHeader && apprenticeshipId && (
              <div
                className='bg-white p-3 hide-when-printing'
              >
                <div>
                  {locationName} is participating in an apprenticeship program with {orgName}.
                </div>
                <p className='mt-2 mb-0'>
                  Please review and sign the Employer Agreement which is needed for mandatory reporting to government agencies.
                </p>
              </div>
            )}

            {apprenticeshipId && (
              <>
                {showPrintButton && (
                  <div className='bg-white p-3 mb-3 hide-when-printing'>
                    <button
                      className="btn btn-md btn-primary"
                      title="Print"
                      onClick={() => {
                        window.print();
                      }}
                      type="button"
                    >
                      <i className="fas fa-print" /> Print
                    </button>
                  </div>
                )}
                <Form
                  employerAgreementId={employerAgreementId}
                  data={data}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmployerAgreement;
