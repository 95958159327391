import React from 'react';
import styled from 'styled-components';
import { TURBINE_LOGO } from '../constants/assets';
import { PLATFORM_NAME } from '../constants/globals';

const LogoContainer = styled.div`
  align-items: center;
  background-color: #f8f9fa;
  color: #6c757d;
  display: flex;
  flex-direction: column;
  font-size: 1.25rem;
  height: 100vh;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;

  i {
    font-size: 2rem;
    margin-bottom: .5rem;
  }

  &.position-fixed {
    z-index: 100;
  }
`;

const Slash = () => {
  return (
    <LogoContainer>
      <img
        src={TURBINE_LOGO}
        alt={`${PLATFORM_NAME} Logo`}
        height="50"
        style={{ height: '50px' }}
      />
    </LogoContainer>
  );
};

export default Slash;
