import React from 'react';
import Section from '../../Section';

const EqualEmployment = ({
  className = 'mb-3'
}) => {
  return (
    <Section
      num={4}
      title="Equal Employment/Affirmative Action Plan with Selection Procedures"
      className={className}
    >
      <p>
        Equal employment opportunity is required of every registered apprenticeship program. Such requirements apply to the recruitment, selection, employment and training of apprentices throughout their apprenticeship. Employers with five or more active apprentices must have a written Affirmative Action Plan and Selection Procedures that have been approved by the Pennsylvania Apprenticeship and Training Council (Appendix C and Appendix D of the Standards).
      </p>
    </Section>
  );
};

export default EqualEmployment;
