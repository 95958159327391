import { fetchSupabaseEntry } from './supabaseProxy';

const table = 'searches';

export const fetchSearch = async (id) => {
  const response = await fetchSupabaseEntry({
    table,
    id
  });

  return response;
};
