import React from 'react';
import { TURBINE_LOGO_BLACK } from '../../constants/assets';
import { TURBINE_MARKETING_SITE } from '../../constants/urls';

const BackToTurbine = ({
  tagline = 'BACK TO',
  className = ''
}) => {
  return (
    <div
      className={`row border-bottom py-2 bg-white d-flex d-print-none ${className}`}
    >
      <div className="col-sm-12 d-flex align-items-center justify-content-center">
        <a
          href={TURBINE_MARKETING_SITE}
          className="d-flex align-items-center backToTurbine rounded text-nowrap"
        >
          <small
            className="mr-2 font-weight-bold"
          >
            {tagline}
          </small>
          <img
            src={TURBINE_LOGO_BLACK}
            height="28"
            style={{ height: '28px' }}
            alt="Turbine Workforce Logo"
          />
        </a>
      </div>
    </div>
  );
};

export default BackToTurbine;
