import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { pathOr } from 'ramda';
import { toast } from 'react-toastify';
import { INVITE_TYPE_EMPLOYER_AGREEMENT_SIGNER_MGMT } from '@apprentage/constants';
import { mdyDate } from '../../../../utils/date';
import { getGeoLocation } from '../../../../services/ipApi';
import {
  currentInviteHistoryEntry,
  deleteInvite
} from '../../../../services/invites';
import { updateEmployerAgreement } from '../../../../services/employerAgreements';
import Section from '../../Section';
import UserSignatureBlock from '../../../UserSignatureBlock';

const EmployerManagementApproval = ({
  className = 'mb-3',
  employerAgreementId = '',
  data // invite || doc
}) => {
  // Redux
  const invite = useSelector((state) => state.invite);
  // Search Params
  const searchParams = new URLSearchParams(window.location.search);
  // Data
  const inviteType = pathOr(null, ['inviteType'], data);
  const inviteHistory = pathOr([], ['inviteHistory'], data); // Document
  // Local State
  const [loading, setLoading] = useState(false);
  // User Signature
  const [signerSignatureDate, setSignerSignatureDate] = useState(
    data?.signerSignatureDate || new Date().toISOString()
  );
  const [signerSignature, setSignerSignature] = useState(
    data?.signerSignature || ''
  );
  const [signerTitle, setSignerTitle] = useState(
    data?.signerTitle || data?.title
  );
  const [signerInviteSentAt] = useState(
    data?.signerInviteSentAt || data?.sentAt
  );
  const [signerEmail] = useState(data?.signerEmail || data?.email);
  const [signerIpAddress, setSignerIpAddress] = useState('');

  const fallbackFirstLastName = useMemo(() => {
    let name = '';
    if (data?.firstName) {
      name += data?.firstName;
    }

    if (data?.lastName) {
      name += ` ${data?.lastName}`;
    }

    return name;
  }, [data?.firstName, data?.lastName]);

  const [signerName, setSignerName] = useState(
    data?.signerName || fallbackFirstLastName
  );

  const signerComplete = useMemo(() => {
    if (signerSignatureDate && signerSignature && signerName && signerTitle) {
      return true;
    }

    return false;
  }, [signerSignatureDate, signerSignature, signerName, signerTitle]);

  const formCanBeSubmitted = useMemo(() => {
    if (signerComplete) {
      return true;
    }

    // NOTE: this is set up to support a union signature in the future

    return false;
  }, [signerComplete]);

  const showCancelButton = useMemo(() => {
    if (searchParams.get('preview')) {
      return false;
    }

    if (!signerSignature) {
      return true;
    }

    return false;
  }, [signerSignature]);

  const disableSignatureBlock = useMemo(() => {
    if (
      inviteType !== INVITE_TYPE_EMPLOYER_AGREEMENT_SIGNER_MGMT
      || !signerName
      || !signerTitle
      || searchParams.get('preview')
    ) {
      return true;
    }

    return false;
  }, [inviteType, signerName, signerTitle]);

  const handleSaveSuccessCallback = () => {
    toast.success('Employer Agreement Signed & Accepted!', {
      autoClose: false
    });

    deleteInvite(invite?.id).then(() => {
      setLoading(false);
    });
  };

  const handleSaveErrorCallback = (error) => {
    console.error(error);
    setLoading(false);
    toast.error('Something went wrong, try again.');
  };

  useEffect(() => {
    getGeoLocation().then(({ ip }) => {
      setSignerIpAddress(ip);
    });
  }, []);

  const handleAcceptAgreement = (newSignerSignature) => {
    if (!newSignerSignature.length) {
      toast.error('A signature is required.');
      return null;
    }

    setLoading(true);
    toast.info('Signing Document...');

    const currentInvite = currentInviteHistoryEntry(invite);
    const dataToSave = {
      signerName,
      signerTitle,
      signerSignature: newSignerSignature,
      signerSignatureDate,
      signerIpAddress,
      signerEmail,
      signerInviteSentAt,
      // Save inviteHistory on Document
      inviteHistory: [
        {
          ...currentInvite,
          actionAt: signerSignatureDate,
          actionTaken: 'Employer Agreement Signed'
        },
        ...inviteHistory
      ]
    };

    updateEmployerAgreement(dataToSave, employerAgreementId)
      .then(() => {
        handleSaveSuccessCallback();
      })
      .catch((error) => {
        handleSaveErrorCallback(error);
      });
  };

  return (
    <>
      <Section
        // num={4}
        title="Employer Management Approval"
        className={`${className} ${formCanBeSubmitted ? '' : 'bg-light'}`}
      >
        <form>
          <table className="table table-sm">
            <tbody>
              <tr>
                <td className="border-0 pr-3 pl-0">
                  <input
                    value={signerName}
                    type="text"
                    disabled={
                      inviteType !== INVITE_TYPE_EMPLOYER_AGREEMENT_SIGNER_MGMT
                    }
                    className={`form-control ${formCanBeSubmitted ? 'border-0' : ''}`}
                    onChange={(e) => {
                      const { value } = e.currentTarget;

                      setSignerName(value);
                    }}
                    required
                  />
                  <div className="border-top w-100 mt-2 text-muted">
                    Printed Name
                  </div>
                </td>
                <td className="border-0 px-0">
                  <input
                    value={signerTitle}
                    type="text"
                    disabled={
                      inviteType !== INVITE_TYPE_EMPLOYER_AGREEMENT_SIGNER_MGMT
                    }
                    className={`form-control ${formCanBeSubmitted ? 'border-0' : ''}`}
                    onChange={(e) => {
                      const { value } = e.currentTarget;

                      setSignerTitle(value);
                    }}
                    required
                  />
                  <div className="border-top w-100 mt-2 text-muted">Title</div>
                </td>
              </tr>
              <tr>
                <td className="border-0 pr-3 pl-0">
                  <UserSignatureBlock
                    text=""
                    className="mt-3"
                    signerSignature={signerSignature}
                    signerName={signerName}
                    textAboveSignature="Employer Management Approval"
                    textBelowSignature="Signature"
                    primaryButtonText="Sign & Accept Agreement"
                    disabled={disableSignatureBlock}
                    onChange={(newUserSignature) => {
                      setSignerSignature(newUserSignature);
                      setSignerSignatureDate(new Date().toISOString());
                      handleAcceptAgreement(newUserSignature);
                    }}
                  />
                </td>
                <td className="border-0 px-0">
                  <div
                    style={{
                      marginTop: '1.25rem'
                    }}
                  >
                    <div
                      style={{
                        padding: '0.75rem'
                      }}
                    >
                      {mdyDate(signerSignatureDate)}
                    </div>

                    <div className="border-top w-100 mt-2 text-muted">Date</div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>

          <div className="mb-3 mt-5 hide-when-printing">
            <div className="d-flex justify-content-end">
              {/* <input
                type="submit"
                className='btn btn-md btn-primary background-none'
                value="Accept Agreement"
                disabled={loading || !formCanBeSubmitted}
              /> */}

              {showCancelButton && (
                <button
                  type="button"
                  className="btn btn-link ml-2"
                  onClick={() => {
                    window.location.reload();
                  }}
                  disabled={loading}
                >
                  Cancel
                </button>
              )}
            </div>
          </div>
        </form>
      </Section>

      {signerSignature && (
        <div className="mb-2 text-center text-muted small">
          Signed {signerSignatureDate} by {signerName}, {signerEmail}, IP:{' '}
          {signerIpAddress}
        </div>
      )}

      {signerInviteSentAt && (
        <div className="mb-3 mb-2 text-center text-muted small">
          Sent {signerInviteSentAt} to {signerName}, {signerEmail}, IP:{' '}
          {signerIpAddress}
        </div>
      )}
    </>
  );
};

export default EmployerManagementApproval;
