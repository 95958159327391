import React from 'react';
import Section from '../../Section';

const ApprenticeRatio = ({
  className = ',b-3'
}) => {
  return (
    <Section
      num={5}
      title="Ratio of Apprentices to Journeyworkers"
      className={className}
    >
      <p>
        No apprentice shall work without adequate supervision of a journeyworker. In the state of Pennsylvania, the required apprentice to journeyworker ratio is:
      </p>

      <ul>
        <li>1 apprentice to 1- 4 journeyworkers</li>
        <li>2 apprentices to 5-9 journeyworkers</li>
        <li>3 apprentices to 10-14 journeyworkers</li>
        <li>Each additional apprentice requires an additional 5 journeyworkers</li>
      </ul>

      <p>
        The apprentice to journeyworker ratio for joint programs shall be consistent with proper supervision, training, safety, continuity of employment, and applicable provisions in CBAs.
      </p>
    </Section>
  );
};

export default ApprenticeRatio;
