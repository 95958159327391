import React from 'react';
import { useDispatch } from 'react-redux';
import { PLUS } from '../../constants/icons';
import { setCurrentModal } from '../../actions/Modals';

const DotMenu = ({
  addressState = ''
}) => {
  const dispatch = useDispatch();

  const openManageWageScheduleModal = () => {
    dispatch(setCurrentModal({
      key: 'manageWageSchedule',
      data: {
        addressState
      }
    }));
  };

  return (
    <div className="btn-group align-items-center">
      <button
        className="btn-link rounded text-ships-officer"
        type="button"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        title="Manage"
      >
        <i className="fas fa-ellipsis-h" />
      </button>
      <div className="dropdown-menu dropdown-menu-right p-0">
        <div className="bg-dark px-4 py-2 text-white text-nowrap" />

        <button
          type='button'
          className="dropdown-item py-2"
          onClick={openManageWageScheduleModal}
          title="New Wage Schedule"
        >
          <i className={`${PLUS} mr-1`} /> New Wage Schedule
        </button>
      </div>
    </div>
  );
};

export default DotMenu;
