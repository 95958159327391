import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { pathOr } from 'ramda';
import { resetCurrentModal } from '../../../actions/Modals';
import Modal from '../../Modal';
import UserSignaturePad from './UserSignaturePad';
import './style.css';

const UserSignature = () => {
  const key = 'userSignature';
  const dispatch = useDispatch();
  // Redux
  const currentModal = useSelector((state) => state.currentModal);
  // Misc
  const modalTitle = pathOr('Signature', ['data', 'modalTitle'], currentModal);
  const modalTextAboveSignature = pathOr('', ['data', 'textAboveSignature'], currentModal);
  const modalTextBelowSignature = pathOr('', ['data', 'textBelowSignature'], currentModal);
  const primaryButtonText = pathOr('', ['data', 'primaryButtonText'], currentModal);

  if (currentModal && !currentModal.visible) return null;
  if (currentModal.key !== key) return null;

  return (
    <Modal
      cssClassName={`turbine-modal--style-card turbine-modal--${key}`}
      visible={currentModal.key === key}
      close={() => {
        dispatch(resetCurrentModal());
      }}
      pos="middle"
    >
      <div className="card">
        <div className="card-header">
          <h5 className="m-0 font-weight-bold">
            {modalTitle}
          </h5>
        </div>
        <UserSignaturePad
          textAboveSignature={modalTextAboveSignature}
          textBelowSignature={modalTextBelowSignature}
          primaryButtonText={primaryButtonText}
          onChange={(newUserSignature) => {
            currentModal.data.callback(newUserSignature);
            dispatch(resetCurrentModal());
          }}
        />
      </div>
    </Modal>
  );
};

export default UserSignature;
