import { getFirebaseEntry } from '../services/firebaseProxy';
import { SET_PATHWAY, RESET_PATHWAY } from './types';

export const getPathway = (id, collection = 'pathways') => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      getFirebaseEntry({ collection, id }).then((response) => {
        const pathway = response || null;

        dispatch({ type: SET_PATHWAY, pathway });
        resolve(pathway);
      }).catch((error) => {
        console.error(error);
        reject(error);
      });
    });
  };
};

export const resetPathway = () => {
  return (dispatch) => {
    dispatch({ type: RESET_PATHWAY });
  };
};
