import { Collapse } from '@apprentage/components';
import { pathOr } from 'ramda';
import React, { useEffect, useState } from 'react';
import ClassificationTags from './ClassificationTags';
import { apprenticeshipIndustryCodeEnum, apprenticeshipTypesEnum } from '../../parsers/trainingProgram';

const StepThree = ({ data, onSubmit }) => {
  // Competencies
  const [loading, setLoading] = useState(false);
  const competencies = pathOr({}, ['competencies'], data);
  const competencyGroups = pathOr([], ['competencyGroups'], data);

  useEffect(() => {
    console.log('StepThree', data);
  }, []);

  const handleOnSubmit = () => {
    setLoading(true);

    onSubmit();
  };

  return (
    <div className='bg-white rounded mt-3'>
      <div className='card'>
        <div className='card-header'>
          <h4 className='m-0'>
            3. Review
          </h4>
        </div>
        <div className='card-body'>
          <div className='mb-3'>
            <div>
              <strong>Title:</strong> {data?.title}
            </div>
            <div>
              <strong>Description:</strong> {data?.description}
            </div>
            <div>
              <strong>Type:</strong> {apprenticeshipTypesEnum[data?.type]}
            </div>
            <div>
              <strong>Industry:</strong> {apprenticeshipIndustryCodeEnum[data?.industryCode]}
            </div>
            <div>
              <strong>O*Net Code:</strong> {data?.occupationONetCode}
            </div>
            <div>
              <strong>RAPIDS Code:</strong> {data?.rapidsCode}
            </div>
            <div>
              <strong>Estimated Length:</strong> {data?.estimatedLength}
            </div>
            <div>
              <strong>Occupation:</strong> {data?.occupationName}
            </div>
            <div>
              <strong>Instruction Hours:</strong> {data?.instructionHours}
            </div>
            <div>
              <strong>OJT Hours:</strong> {data?.ojtHours}
            </div>
          </div>
          {competencyGroups.length !== 0 && (
            <Collapse
              className=''
              id="competencyGroups"
              title='Competency Groups'
              ariaExpanded
            >
              {competencyGroups.length && competencyGroups.map((competencyGroup) => {
                const childCompetencies = competencyGroup?.competencyIds?.length
                  ? competencyGroup?.competencyIds.map((cId) => competencies[cId])
                  : [];
                return (
                  <Collapse
                    key={`competencyGroup-${competencyGroup.id}`}
                    id={`competencyGroup-${competencyGroup.id}`}
                    title={competencyGroup.description}
                    className='mb-2'
                    ariaExpanded
                  >
                    {childCompetencies.map((competency) => (
                      <div
                        className='mb-2 border p-3'
                        key={`competency-${competency.id}`}
                      >
                        <h6>
                          {competency.description}
                        </h6>
                        <div>
                          <strong>Total Hours:</strong> {competency.totalHours}
                        </div>
                        {competency?.classificationIds && competency?.classificationIds.length !== 0 && (
                          <Collapse
                            className="mt-2"
                            title="Classifications"
                            id="competencyClassifications"
                            ariaExpanded
                            badge={() => {
                              return (
                                <span className="h6 m-0 ml-2">
                                  <span
                                    className="badge badge-info"
                                  >
                                    <i className="fa fa-bolt" style={{ fontSize: '.7rem ' }} aria-hidden="true" /> Auto-Generated
                                  </span>
                                </span>
                              );
                            }}
                          >
                            <ClassificationTags
                              data={competency.classificationIds}
                              classifications={data?.classifications}
                            />
                          </Collapse>
                        )}
                      </div>
                    ))}
                  </Collapse>
                );
              })}
            </Collapse>
          )}
        </div>
        <div className='card-footer d-flex justify-content-between'>
          <div>
            <button
              id="step3SaveBtn"
              className='btn btn-md btn-primary'
              type='button'
              onClick={handleOnSubmit}
              disabled={loading}
            >
              {loading ? 'Saving...' : 'Save'}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StepThree;
