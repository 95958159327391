import React, { useState } from 'react';
import { v4 as uuid4 } from 'uuid';
import { toast } from 'react-toastify';
import StepOne from './StepOne';
import StepTwo from './StepTwo';
import StepThree from './StepThree';
import { createApprenticeshipTemplate, fetchApprenticeshipTemplates } from '../../../../services/apprenticeshipTemplates';

const TrainingProgram = ({ data, onChangeCallback }) => {
  const [step, setStep] = useState(1);
  const [dataToSave, setDataToSave] = useState(null);

  const stepOneOnSubmit = (step1Data) => {
    fetchApprenticeshipTemplates({
      rapidsCode: step1Data.rapidsCode,
      occupationONetCode: step1Data.occupationONetCode
    }).then((response) => {
      if (!response) {
        setDataToSave({
          ...step1Data,
          competencyNameSpace: uuid4(),
          competencies: data?.competencies,
          competencyGroups: data.competencyGroups
        });

        setStep(2);
      } else {
        toast.error('Training Program exists');
        onChangeCallback(null);
      }
    });
  };

  const stepTwoOnSubmit = (step2Data) => {
    console.log('stepTwoOnSubmit', step2Data);
    setDataToSave((prev) => ({
      ...prev,
      ...step2Data
    }));
    setStep(3);
  };

  const stepThreeOnSubmit = () => {
    console.log('stepThreeOnSubmit', dataToSave);

    toast.info('Saving Training Program');
    createApprenticeshipTemplate(dataToSave).then((apprenticeshipTemplateId) => {
      toast.success('Training Program Created!');
      console.log('apprenticeshipTemplateId', apprenticeshipTemplateId);
      onChangeCallback(null);
    });
  };

  return (
    <>
      <h4 className='my-4 text-white'>
        Create Training Program
      </h4>

      {step === 1 && (
        <StepOne
          data={data}
          onSubmit={stepOneOnSubmit}

        />
      )}

      {dataToSave && step === 2 && (
        <StepTwo
          data={dataToSave}
          onSubmit={stepTwoOnSubmit}
        />
      )}

      {dataToSave && step === 3 && (
        <StepThree
          data={dataToSave}
          onSubmit={stepThreeOnSubmit}
        />
      )}

    </>
  );
};

export default TrainingProgram;
