import React, {
  useCallback, useEffect, useState
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TURBINE } from '../../constants/urls';
import JoinCourseButton from './JoinCourseButton';
import ExternalRegister from '../Courses/ExternalRegister';
import { setCurrentModal } from '../../actions';

const JoinCourseActions = ({ classId, cohortId }) => {
  const dispatch = useDispatch();
  const pageOrganization = useSelector((state) => state.pageOrganization);
  const course = useSelector((state) => state.currentClass);
  const courseCohorts = useSelector((state) => state.courseCohorts);
  const orgSlug = pageOrganization?.slug || null;

  const [courseCohortId, setCourseCohortId] = useState(cohortId);

  const [joinLink, setJoinLink] = useState(null);

  const createJoinLink = useCallback((joinData) => {
    let url = `${TURBINE}/login/${orgSlug}?action=signup`;

    url += '&pcp=true'; // Public Course Page

    // Class
    if (classId) {
      url += `&classId=${classId}`;
      if (joinData) {
        if (joinData.externalRegisterClassId) {
          url += `&extRegClassId=${joinData.externalRegisterClassId}`;
        }

        // Cohort
        if (joinData.cohortId || courseCohortId) {
          url += `&cohortId=${joinData.cohortId || courseCohortId}`;

          if (joinData.eUnitIds) {
            url += `&eUnitIds=${joinData.eUnitIds.join(',')}`;
          }
        }
      }
    }

    return url;
  }, [classId, courseCohortId, orgSlug]);

  useEffect(() => {
    let mounted = true;

    if (classId) {
      if (mounted && !courseCohortId) {
        if (courseCohorts && Array.isArray(courseCohorts)) {
          if (courseCohorts.length === 1) {
            setCourseCohortId(courseCohorts[0].id);
            setJoinLink(createJoinLink({ cohortId: courseCohorts[0].id }));
          }
        } else {
          setJoinLink(createJoinLink());
        }
      }
    }

    return function cleanup() {
      mounted = false;
    };
  }, [classId, courseCohortId, courseCohorts, createJoinLink]);

  const handleEnrollCourseCohort = () => {
    const selectedCohort = courseCohortId ? courseCohorts.find(((c) => c.id === courseCohortId)) : undefined;

    dispatch(setCurrentModal({
      key: 'enrollExternalRegisteredClass',
      data: {
        title: 'Choose Cohort',
        selectedCohort,
        classId,
        callback: (newCohortData) => {
          // setCourseCohortId(selectedCohortId);

          document.location.assign(createJoinLink(newCohortData));
        }
      }
    }));
  };

  if (!pageOrganization?.id || !course?.id) {
    return;
  }

  const {
    isLive, openEnrollment, registerUrl, integration, inviteOnly
  } = course;

  // Course set openEnrollment = false
  // Course has Stripe integrations for payment
  const preventEnrollment = !openEnrollment || integration?.stripe;

  if (inviteOnly) {
    return (
      <div>Invitation Only</div>
    );
  }

  if (preventEnrollment) {
    return (
      <div
        className="d-flex align-items-center justify-content-center"
      >
        <button
          type="button"
          className="comingSoonCourseBtn btn btn-md btn-primary my-2 btn-lg"
          title={`Join ${isLive ? 'Session' : 'Course'}`}
          disabled
        >
          Join {isLive ? 'Session' : 'Course'}
        </button>
        <button
          type="button"
          className="btn btn-md btn-link my-2 btn-lg"
          disabled
        >
          <i className="fas fa-lock" aria-hidden="true" />
        </button>
      </div>
    );
  }

  return (
    <div className="d-flex align-items-center justify-content-center">
      {registerUrl ? (
        <ExternalRegister
          handleClickSecondary={() => {
            handleEnrollCourseCohort();
          }}
        />
      ) : (
        <JoinCourseButton
          joinLink={joinLink}
          handleEnrollCourseCohort={() => {
            handleEnrollCourseCohort();
          }}
        />
      )}
    </div>
  );
};

export default JoinCourseActions;
