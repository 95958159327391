import { pathOr } from 'ramda';
import { client, flattenItem, flattenItems } from '../services/contentful';
import { getCohorts } from './Cohorts';
import formatData from '../services/formatData';
import { updateEntry } from '../services/entry';
import {
  SET_CURRENT_CLASS,
  SET_CURRENT_CLASS_EDIT,
  RESET_CURRENT_CLASS,
  RESET_CURRENT_CLASS_EDIT
} from './types';

export const setCurrentClass = ({ classId, courseSlug }) => {
  return (dispatch) => {
    const config = {
      content_type: 'class',
      include: 2
    };

    if (classId) {
      config['sys.id'] = classId;
    }

    if (courseSlug) {
      config['fields.courseSlug'] = courseSlug;
    }

    // TODO change this to getEntry after refactoring data references
    return client.getEntries(config).then(({ items }) => {
      const course = items[0] !== undefined ? flattenItem(items[0]) : null;

      // TODO course flatten (courseExpertIds)
      let courseExperts = pathOr(null, ['teachers'], course);
      if (courseExperts) courseExperts = flattenItems(courseExperts);

      // TODO course flatten (courseTopicIds)
      let topics = pathOr(null, ['subjects'], course);
      if (topics) topics = flattenItems(topics);

      dispatch(getCohorts({
        classId: (course && course.id) || classId,
        openCohortEnrollment: true
      })).then(() => {
        const data = {
          type: SET_CURRENT_CLASS,
          currentClass: course, // TODO course flatten (remove currentClass)
          courseExperts
        };

        // TODO course flatten (courseTopicIds)
        // TODO dispatch(getTopics({ classId }))
        if (topics) data.topics = topics;

        dispatch(data);
      });
    }).catch((error) => {
      console.error(error);
    });
  };
};

export const resetCurrentClass = () => {
  return (dispatch) => {
    dispatch({ type: RESET_CURRENT_CLASS });
  };
};

export const setCurrentClassEdit = ({ classId }) => (dispatch) => {
  const config = {
    content_type: 'class',
    include: 2,
    'sys.id': classId
  };

  return new Promise((resolve, reject) => {
    client.getEntries(config).then((response) => {
      dispatch({
        type: SET_CURRENT_CLASS_EDIT,
        currentClassEdit: response.items[0]
      });

      setTimeout(() => {
        resolve(response.items[0]);
      }, 250);
    }).catch((error) => {
      console.error('setCurrentClassEdit', error);
      reject(error);
    });
  });
};

export const resetCurrentClassEdit = () => (dispatch) => {
  dispatch({ type: RESET_CURRENT_CLASS_EDIT });
};

export const updateCurrentClass = (data) => (_dispatch, getState) =>
  new Promise((resolve, reject) => {
    const {
      currentClass: { sys: { id: classId } }
    } = getState();

    const preppedData = formatData(data, 'class');

    updateEntry({
      id: classId,
      data: preppedData
    }).then((updatedClass) => {
      resolve(updatedClass);
    }).catch((error) => {
      console.error(error);
      reject(error);
    });
  });
