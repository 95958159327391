const sendAssistantCustomerServiceHtml = ({
  integrationId,
  integrationName,
  userEmail,
  chatHistory
}) => {
  let invitationBody = '';

  if (Array.isArray(chatHistory) && chatHistory.length > 0) {
    invitationBody += `
        <h4>
          Chat History
        </h4>
      `;

    chatHistory.forEach((ch) => {
      invitationBody += `
          <p>
            <strong>${ch?.role}:</strong>
            <br/>
            ${ch?.content}
          </p>
        `;
    });
  }

  return `
    <p>
      A website visitor requested assistance.
    </p>

    <p>
      You can reply directly to provide further assistance.
    </p>

    ${invitationBody}

    <hr />

    <p>
      Visitor Email: ${userEmail}
      <br/>
      Assistant: ${integrationName}
      <br/>
      Assistant ID: ${integrationId}
    </p>

    <p>
      Team Turbine Workforce
    </p>
  `;
};

export default sendAssistantCustomerServiceHtml;
