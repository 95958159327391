import { pathOr } from 'ramda';
import React, { Fragment, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import qs from 'query-string';
import {
  getCertificationsByKeyword,
  getOccupation,
  getOccupations,
  getTrainingProgramsByKeyword
} from '../../services/careerOneStop';
import { getGeoLocation } from '../../services/ipApi';
import { isLocalhost } from '../../serviceWorker';
import replaceNonAlphaNumeric from '../../utils/replaceNonAlphaNumeric';
import { getOrg } from '../../actions';
import { fetchLocation } from '../../services/locations';
import { TURBINE_CONSOLE } from '../../constants/urls';
import Loading from '../Loading';
import Occupation from './Occupation';
import OccupationSearch from './OccupationSearch';
import BackToTurbine from './BackToTurbine';
import './style.css';

const Pathways = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const queryParams = qs.parse(window.location.search);
  const occupationKeyword = pathOr(null, ['occupationKeyword'], queryParams);
  const orgId = pathOr(null, ['orgId'], queryParams);
  const locationId = pathOr(null, ['locationId'], queryParams);
  const embedded = pathOr('false', ['embedded'], queryParams);
  const [isEmbedded] = useState(embedded === 'true');
  const organization = useSelector((state) => state.organization);
  // Local State
  const [searchValue, setSearchValue] = useState(occupationKeyword ? replaceNonAlphaNumeric(occupationKeyword) : undefined);
  const [occupations, setOccupations] = useState([]);
  const [occupation, setOccupation] = useState(null);
  const [occupationDetail, setOccupationDetail] = useState([]);
  const [knowledgeDataList, setKnowledgeDataList] = useState(null);
  const [skillsDataList, setSkillsDataList] = useState(null);
  const [wages, setWages] = useState(null);
  const [certifications, setCertifications] = useState(null);
  const [schoolPrograms, setSchoolPrograms] = useState(null);
  const [occupationLocation, setOccupationLocation] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedSkills, setSelectedSkills] = useState(null);
  const [selectedKnowledge, setSelectedKnowledge] = useState(null);
  const [selectedCerts, setSelectedCerts] = useState(null);
  const [selectedSchoolPrograms, setSelectedSchoolPrograms] = useState(null);

  useEffect(() => {
    // TODO move this to more centralized location
    localStorage.setItem('embedded', isEmbedded);

    if (orgId) {
      // TODO use iframePostMessage to pass down organization info from turbine admin
      dispatch(getOrg({ orgId }));
    }
    // /END TODO

    if (locationId) {
      // Validate locationId
      fetchLocation({ locationId }).catch(() => {
        document.location.assign(TURBINE_CONSOLE);
      });
    }

    if (occupationKeyword) {
      searchOccupations(replaceNonAlphaNumeric(occupationKeyword));
      history.replace({ search: '' });
    }
  }, []);

  const searchOccupations = (keyword) => {
    setLoading(true);
    setSearchValue(keyword);

    if (isLocalhost) {
      // prevent hitting ip api for local dev to reduce usage costs
      setOccupationLocation('15238');
    } else {
      getGeoLocation().then(({ zip }) => {
        setOccupationLocation(zip);
      });
    }

    getOccupations({
      keyword
      // limit: 20,
    }).then((occupationsList) => {
      const OccupationList = pathOr(null, ['OccupationList'], occupationsList);

      setLoading(false);
      setOccupations(OccupationList);
    });
  };

  const resetOccupation = () => {
    setSelectedSkills(null);
    setSelectedKnowledge(null);
    setSelectedCerts(null);
    setSelectedSchoolPrograms(null);
    setOccupation(null);
  };

  const handleSearch = (e) => {
    e.preventDefault();
    const { inputOccupationKeywords } = e.currentTarget;

    inputOccupationKeywords.blur();

    searchOccupations(inputOccupationKeywords.value);
  };

  const searchOccupation = ({
    occupationData,
    searchLocation
  }) => {
    const OnetCode = pathOr(null, ['OnetCode'], occupationData || occupation);
    setOccupationDetail([]);

    getTrainingProgramsByKeyword({
      keyword: OnetCode,
      location: searchLocation || occupationLocation
    }).then((response) => {
      const SchoolPrograms = pathOr(null, ['SchoolPrograms'], response);

      setSchoolPrograms(SchoolPrograms);
    });

    getCertificationsByKeyword({
      keyword: OnetCode
    }).then((response) => {
      const CertList = pathOr(null, ['CertList'], response);
      const certificationsList = CertList && Array.isArray(CertList) && CertList.length !== 0 ? CertList : null;

      setCertifications(certificationsList);
    });

    getOccupation({
      keyword: OnetCode,
      location: searchLocation || occupationLocation
      // wages: false,
    }).then((response) => {
      const OccupationDetail = pathOr(null, ['OccupationDetail'], response);
      const KnowledgeDataList = pathOr(null, ['KnowledgeDataList'], response);
      const SkillsDataList = pathOr(null, ['SkillsDataList'], response);
      const Wages = pathOr(null, ['Wages'], response);

      setOccupationDetail(OccupationDetail);
      setKnowledgeDataList(KnowledgeDataList);
      setSkillsDataList(SkillsDataList);
      setWages(Wages);
      setLoading(false);
    });
  };

  const selectSearchResult = (result) => {
    setLoading(true);
    setOccupation(result);
    searchOccupation({ occupationData: result });
  };

  const setLocationAndSearchOccupation = (e) => {
    e.preventDefault();
    const { inputOccupationLocation } = e.currentTarget;

    inputOccupationLocation.blur();

    const searchLocation = inputOccupationLocation.value;

    if (searchLocation === '') {
      toast.info('Please try again');

      return setOccupationLocation(null);
    }

    setOccupationLocation(searchLocation);

    searchOccupation({ searchLocation });
  };

  const handleFocus = (e) => {
    e.preventDefault();

    if (!occupations) {
      setOccupations([]);
    }
  };

  if (orgId && !organization) {
    return (
      <Loading
        text="Cannot load organization"
        className="position-fixed"
      />
    );
  }

  return (
    <Fragment>

      {loading && (
        <Loading
          className="position-fixed"
        />
      )}

      {!isEmbedded && (
        <BackToTurbine />
      )}

      <div className="pathway-tool-container">
        {!occupation && (
          <Fragment>
            <OccupationSearch
              className="mt-4 mb-5"
              loading={loading}
              handleSearch={handleSearch}
              handleFocus={handleFocus}
              searchValue={searchValue}
              searchResults={occupations}
              selectSearchResult={selectSearchResult}
            />

            {/* <BrightOutlookPositions
              className="my-2"
            /> */}
          </Fragment>
        )}

        {occupation && (
          <Occupation
            occupation={occupation}
            occupationLocation={occupationLocation}
            occupationDetail={occupationDetail}
            knowledgeDataList={knowledgeDataList}
            skillsDataList={skillsDataList}
            wages={wages}
            certifications={certifications}
            schoolPrograms={schoolPrograms}
            handleSearchOccupation={setLocationAndSearchOccupation}
            resetOccupation={resetOccupation}
            searchValue={searchValue}
            className="my-5"
            selectedSkills={selectedSkills}
            selectedKnowledge={selectedKnowledge}
            selectedCerts={selectedCerts}
            selectedSchoolPrograms={selectedSchoolPrograms}
            setSelectedSkills={setSelectedSkills}
            setSelectedKnowledge={setSelectedKnowledge}
            setSelectedCerts={setSelectedCerts}
            setSelectedSchoolPrograms={setSelectedSchoolPrograms}
          />
        )}
      </div>

    </Fragment>
  );
};

export default Pathways;
