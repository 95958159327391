import React from 'react';
import Section from '../../Section';

const ApprenticeStandards = ({
  className = 'mb-3'
}) => {
  return (
    <Section
      num={6}
      title="Acceptance of Registered Apprenticeship Standards"
      className={className}
    >
      <p>
        A copy of the Registered Apprenticeship Standards has been received, read and understood. Employer agrees to carry out the intent and purpose of said standards and to abide by the rules and decisions of the program sponsor established under these standards. Apprentices will be trained under the provisions of the adopted apprenticeship standards.
      </p>
    </Section>
  );
};

export default ApprenticeStandards;
