import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import Modal from '../../Modal';
import { resetCurrentModal } from '../../../actions';
import { inviteUsers } from '../../../services/courses';
import SelectMenu from '../../SelectMenu';
import * as URLS from '../../../constants/urls';
import * as ICONS from '../../../constants/icons';
import './style.css';

const initialState = {
  loading: false,
  courseCohortId: null,
  emails: '',
  advancedSettings: false
};

class InviteUser extends Component {
  constructor(props) {
    super(props);

    this.state = {
      key: 'inviteUser',
      ...initialState
    };

    this.userRegLink = React.createRef();
    this.orgLoginLink = React.createRef();
    this.orgRegLink = React.createRef();
  }

  toggleAdvanced = () => {
    const { advancedSettings } = this.state;

    this.setState({
      advancedSettings: !advancedSettings
    });
  }

  handleFieldChange = (e) => {
    const { name, value } = e.currentTarget;

    const data = {
      [name]: value
    };

    this.setState(data);
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const { organization } = this.props;
    const { fields: { name: orgName, email: orgEmail } } = organization;
    const { emails } = this.state;
    const link = this.userRegisterLink();

    this.setState({ loading: true });

    inviteUsers({
      emails,
      link,
      orgName,
      orgEmail
    }).then(() => {
      this.clearForm();
    }).catch((error) => {
      console.error(error);
    });
  }

  clearForm = () => {
    this.setState({
      ...initialState
    });
  }

  handleClose = () => {
    this.clearForm();
    this.props.resetCurrentModal();
  }

  selectAll = (e) => {
    e.currentTarget.select();
  }

  copyLink = (refName) => {
    const { value } = this[refName].current;

    this[refName].current.focus();
    this[refName].current.select();

    navigator.clipboard.writeText(value);

    toast.success('Link copied!');
  }

  userRegisterLink = () => {
    const { courseCohortId } = this.state;
    const { currentCohort, currentClass, organization } = this.props;
    const { fields: { slug } } = organization;
    const id = courseCohortId || (currentCohort && currentCohort.sys.id);

    let url = `${URLS.TURBINE}/login/${slug}?action=signup`;

    // Class
    if (currentClass && currentClass.sys) {
      url += `&classId=${currentClass.sys.id}`;
    }

    // Cohort
    if (id) {
      url += `&cohortId=${id}`;
    }

    return url;
  }

  inviteToText = () => {
    const { currentClass, organization, currentCohort } = this.props;
    const { fields: { name: orgName } } = organization;

    let text = orgName;

    // Class
    if (currentClass && currentClass.fields) {
      text = currentClass.fields.title;
    }

    // Cohort
    if (currentCohort && currentCohort.fields) {
      text += ` [${currentCohort.fields.title}]`;
    }

    return text;
  }

  render() {
    const {
      currentModal,
      currentClass,
      currentCohort,
      organization
    } = this.props;

    if ((currentModal && !currentModal.visible) || !organization) {
      return null;
    }

    const {
      key,
      courseCohortId,
      emails,
      loading,
      advancedSettings
    } = this.state;
    if (currentModal.key !== key) return null;

    const { fields: { slug, name: orgName } } = organization;
    let courseCohorts = null;

    if (currentClass && currentClass.fields) {
      const { fields: { cohorts } } = currentClass;

      if (cohorts) {
        courseCohorts = cohorts.map((cohort) => {
          return {
            text: cohort.fields.title,
            value: cohort.sys.id
          };
        });

        if (currentCohort && currentCohort.sys && currentCohort.sys.id) {
          courseCohorts = courseCohorts.filter((item) => item.value === currentCohort.sys.id);
        }
      }
    }

    return (
      <Modal
        cssClassName={`turbine-modal--${key}`}
        visible={currentModal.key === key}
        close={this.handleClose}
        pos="middle"
      >
        <div className="card">
          <div className="card-header">
            <h1 className="h5 m-0">
              Invite Users
            </h1>
          </div>
          <div className="card-body p-0">

            <div className="border-bottom p-3">
              <form
                id="invite-user"
                name="invite-user"
                onSubmit={this.handleSubmit}
              >
                <h6>
                  to <b>{this.inviteToText()}</b>
                </h6>

                <textarea
                  type="text"
                  name="emails"
                  className="form-control mt-1 mb-3"
                  placeholder="name@gmail.com"
                  aria-label="User Emails"
                  aria-describedby="input-sendInvitation"
                  value={emails}
                  onChange={this.handleFieldChange}
                  disabled={loading}
                  required
                />
                <div className="d-flex justify-content-between align-items-center">
                  <div className="text-muted text-break-all">
                    <button
                      title="Copy Link"
                      type='button'
                      className='btn-link pr-1'
                      onClick={() => {
                        if ((currentClass && currentClass.sys.id) || courseCohortId) {
                          this.copyLink('userRegLink');
                        } else {
                          this.copyLink('orgRegLink');
                        }
                      }}
                    >
                      <i className={ICONS.LINK} /> Copy invite link
                    </button>

                    {!advancedSettings && (
                      <span className="text-muted ml-1">
                        <button
                          title="Show advanced settings"
                          type='button'
                          className='btn-link text-muted pl-1'
                          onClick={this.toggleAdvanced}
                        >
                          Settings
                        </button>
                      </span>
                    )}
                  </div>
                  <button
                    title="Send Invitation"
                    aria-label="Send Invitation"
                    className={`btn ${emails ? 'btn-primary' : 'btn-secondary'}`}
                    type="submit"
                    disabled={loading}
                  >
                    {loading ? 'Sending...' : 'Send'}
                  </button>
                </div>

              </form>
            </div>

            <div id="invite-link-settings" className={advancedSettings ? 'd-block' : 'd-none'}>
              <div className="card-header mb-2 d-flex align-items-center justify-content-between">
                <h6 className="m-0">
                  Advanced Settings
                </h6>
                <button
                  title="Hide advanced settings"
                  type='button'
                  className='btn-link p-0'
                  onClick={this.toggleAdvanced}
                >
                  Close settings
                </button>
              </div>
              <div className="card-body pt-2">
                {currentClass && currentClass.fields && (
                  <Fragment>
                    <h5 className="font-weight-bold">
                      {currentClass.fields.title}
                    </h5>
                    <div className="border p-2 mb-3">
                      {courseCohorts && (
                        <div className="my-2 border p-2">
                          <h6 className="font-weight-bold">
                            <i className="fas fa-users" /> Cohort
                          </h6>
                          <div>
                            Automatically add user to Cohort during registration.
                          </div>
                          <SelectMenu
                            defaultOption={currentCohort ? false : { text: 'Select Cohort', value: '' }}
                            className="form-control-sm mt-2"
                            onChange={this.handleFieldChange}
                            defaultValue={courseCohortId || (currentCohort && currentCohort.sys.id)}
                            name="courseCohortId"
                            options={courseCohorts}
                            required
                          />
                        </div>
                      )}
                      <div className="mb-2">
                        Register:
                      </div>
                      <div className="input-group">
                        <textarea
                          readOnly
                          style={{ minHeight: '100px' }}
                          type="text"
                          autoComplete="off"
                          className="form-control"
                          key={courseCohortId}
                          defaultValue={this.userRegisterLink()}
                          onClick={this.selectAll}
                          ref={this.userRegLink}
                        />
                        <div className="input-group-append">
                          <button
                            title="Copy Link"
                            type='button'
                            className='btn btn-info'
                            onClick={() => {
                              this.copyLink('userRegLink');
                            }}
                          >
                            <i className={ICONS.COPY} />
                          </button>
                        </div>
                      </div>
                    </div>
                  </Fragment>
                )}

                <div>
                  <h6 className="mb-0">
                    <button
                      className="btn btn-link text-left text-dark p-0"
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapseInviteUserOrg"
                      aria-expanded={!currentClass && !currentClass.fields}
                      aria-controls="collapseInviteUserOrg"
                    >
                      <i className="fas fa-caret-right" />
                      <i className="fas fa-caret-down" />
                      {orgName}
                    </button>
                  </h6>

                  <div id="collapseInviteUserOrg" className={`collapse ${currentClass && currentClass.fields ? '' : 'show'}`}>
                    <div className="bg-light border p-2 mt-2">
                      <div className="mb-2">
                        Login:
                      </div>
                      <div className="input-group">
                        <input
                          readOnly
                          type="text"
                          autoComplete="off"
                          className="form-control"
                          defaultValue={`${URLS.TURBINE}/login/${slug}`}
                          onClick={this.selectAll}
                          ref={this.orgLoginLink}
                        />
                        <div className="input-group-append">
                          <button
                            title="Copy Link"
                            type='button'
                            className='btn btn-info'
                            onClick={() => {
                              this.copyLink('orgLoginLink');
                            }}
                          >
                            <i className="far fa-clipboard" />
                          </button>
                        </div>
                      </div>
                      <br />
                      <div className="mb-2">
                        Register:
                      </div>
                      <div className="input-group">
                        <input
                          readOnly
                          type="text"
                          autoComplete="off"
                          className="form-control"
                          defaultValue={`${URLS.TURBINE}/login/${slug}?action=signup`}
                          onClick={this.selectAll}
                          ref={this.orgRegLink}
                        />
                        <div className="input-group-append">
                          <button
                            title="Copy Link"
                            type='button'
                            className='btn btn-info'
                            onClick={() => {
                              this.copyLink('orgRegLink');
                            }}
                          >
                            <i className="far fa-clipboard" />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="card-footer text-muted">
            <b>Note:</b> New users are automatically assigned the role <b>Student</b>. An organization <b>Owner</b> can modify user roles.
          </div>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = ({
  currentClass,
  currentCohort,
  organization,
  currentModal
}) => ({
  currentClass,
  currentCohort,
  organization,
  currentModal
});

export default connect(mapStateToProps, { resetCurrentModal })(InviteUser);
