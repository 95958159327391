import React from 'react';

const AboutThisData = () => {
  return (
    <div
      className="accordion p-0"
      id="accordionOnetExplained"
    >
      <div
        className="mb-0 d-flex align-items-center"
      >
        <div>
          Data by O*NET
          <button
            className="btn-link"
            type="button"
            data-toggle="collapse"
            data-target="#collapseOnetExplainedOne"
            aria-expanded="true"
            aria-controls="collapseOnetExplainedOne"
          >
            Learn more
          </button>
        </div>
      </div>
      <div
        id="collapseOnetExplainedOne"
        className="collapse"
        aria-labelledby="headingOnetExplainedOne"
        data-parent="#accordionOnetExplained"
      >
        <div className="mt-2 pt-2 border-top">
          <p>
            The O*NET database provides detailed information -- O*NET-SOC codes, occupation titles and descriptions, knowledge and skill descriptors -- for over 900 occupations.
          </p>
          <p className="pb-0">
            <em>What is Importance rating?</em>
            <br />
            The Importance rating indicates the degree of importance a particular descriptor is to the occupation.
            {/* <br />
            <a href="https://www.onetonline.org/help/online/scales#score" target="_blank" rel="noopener noreferrer">Read more</a> */}
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutThisData;
