import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  USER_CITIZEN_ENUM,
  USER_CRIMINAL_BKG_ENUM,
  USER_DISABILITY_ENUM,
  USER_EDUCATION_ENUM,
  USER_EMPLOYMENT_ENUM,
  USER_ETHNICITY_ENUM,
  USER_GENDER_ENUM,
  USER_RACE,
  USER_REFERRAL_ENUM,
  USER_VETERAN_ENUM
} from '@apprentage/constants';
import { FaEyeSlash, FaEye } from 'react-icons/fa6';
import { toast } from 'react-toastify';
import { FaExclamationTriangle } from 'react-icons/fa';
import { mmddyyyyDate } from '../../../utils/date';
import { decodePayload, encodePayload } from '../../../services/payload';
import Section from '../Section';
import CheckBoxes from '../CheckBoxes';
import AddressReadable from '../../ManageContent/AddressReadable';
import UserSignatureBlock from '../../UserSignatureBlock';
import { obfuscateSSN } from '../../../utils/ssn';
import { getGeoLocation } from '../../../services/ipApi';

const SectionApprentice = ({
  dataType = '',
  onSave
}) => {
  // Redux
  const apprenticeshipUser = useSelector((state) => state.apprenticeshipUser);
  const userProfile = useSelector((state) => state.userProfile);
  const apprenticeAgreement = useSelector((state) => state.apprenticeAgreement);
  const invite = useSelector((state) => state.invite);
  // Local state
  const [signerSignature, setSignerSignature] = useState(apprenticeAgreement?.signerSignature || '');
  const [signerSignatureDate, setSignerSignatureDate] = useState(apprenticeAgreement?.signerSignatureDate || '');
  const [signerIpAddress, setSignerIpAddress] = useState(apprenticeAgreement?.signerIpAddress || '');
  const [ssn, setSSN] = useState(apprenticeAgreement?.ssn || userProfile?.ssn || '');
  const [showObfuscatedSSN, setShowObfuscatedSSN] = useState(true);

  // DEFAULT DATA (User Profile)
  const gender = apprenticeAgreement?.gender || userProfile?.gender || '';
  const dob = apprenticeAgreement?.dob || userProfile?.dob || '';
  const citizenStatus = apprenticeAgreement?.citizenStatus || userProfile?.citizenStatus || '';
  const employmentStatus = apprenticeAgreement?.employmentStatus || userProfile?.employmentStatus || '';
  const employmentStatusDate = apprenticeAgreement?.employmentStatusDate || userProfile?.employmentStatusDate || '';
  const educationLevel = apprenticeAgreement?.educationLevel || userProfile?.educationLevel || '';
  const addressLine1 = apprenticeAgreement?.addressLine1 || userProfile?.addressLine1 || '';
  const addressLine2 = apprenticeAgreement?.addressLine2 || userProfile?.addressLine2 || '';
  const addressCity = apprenticeAgreement?.addressCity || userProfile?.addressCity || '';
  const addressState = apprenticeAgreement?.addressState || userProfile?.addressState || '';
  const addressPostalCode = apprenticeAgreement?.addressPostalCode || userProfile?.addressPostalCode || '';
  const ethnicity = apprenticeAgreement?.ethnicity || userProfile?.ethnicity || '';
  const races = apprenticeAgreement?.races || userProfile?.races || '';
  const veteranStatus = apprenticeAgreement?.veteranStatus || userProfile?.veteranStatus || '';
  const disability = apprenticeAgreement?.disability || userProfile?.disability || '';
  const orgReferral = apprenticeAgreement?.orgReferral || userProfile?.orgReferral || '';
  const criminalBkg = apprenticeAgreement?.criminalBkg || userProfile?.criminalBkg || '';
  // DEFAULT DATA (Invite)
  const signerInviteSentAt = apprenticeAgreement?.signerInviteSentAt || invite?.sentAt || '';
  // DEFAULT DATA (User)
  const signerName = apprenticeAgreement?.signerName || apprenticeshipUser?.name || '';
  const signerFirstName = apprenticeAgreement?.signerFirstName || apprenticeshipUser?.firstName || '';
  const signerLastName = apprenticeAgreement?.signerLastName || apprenticeshipUser?.lastName || '';
  const signerMiddleName = apprenticeAgreement?.signerMiddleName || apprenticeshipUser?.middleName || '';
  const signerEmail = apprenticeAgreement?.signerEmail || apprenticeshipUser?.email || '';
  const signerPhone = apprenticeAgreement?.signerPhone || apprenticeshipUser?.mobilePhone || apprenticeshipUser?.phone || '';
  // URL Search Params
  const searchParams = new URLSearchParams(document.location.search);

  const obfuscatedSSN = useMemo(() => {
    if (ssn) {
      return obfuscateSSN(ssn);
    }

    return '';
  }, [ssn]);

  const showToggleSSNButton = useMemo(() => {
    if (dataType === 'invite') {
      return true;
    }

    if (searchParams.get('preview') === 'true') {
      return false;
    }

    return true;
  }, []);

  const requiredFieldsText = useMemo(() => {
    if (!signerName) return 'Name required!';
    if (!signerEmail) return 'Email required!';
    if (!signerPhone) return 'Phone required!';
    if (!employmentStatus) return 'Employment Status required!';
    if (employmentStatus === 'ue' && !employmentStatusDate) return 'Date of Last Employment required!';
    if (!ssn) return 'Social Security Number required!';
    if (!gender) return 'Gender required!';
    if (!dob) return 'Date of Birth required!';
    if (!ethnicity) return 'Ethnicity required!';
    if (!races) return 'Race required!';
    if (!veteranStatus) return 'Veteran Status required!';
    if (!disability) return 'Disability Status required!';
    if (!educationLevel) return 'Education Level required!';
    if (!orgReferral) return 'Referral information required!';
    if (!citizenStatus) return 'Citizenship Status required!';
    if (!addressLine1) return 'Street Address required!';
    if (!addressCity) return 'City required!';
    if (!addressState) return 'State required!';
    if (!addressPostalCode) return 'Zip Code required!';
    if (!criminalBkg) return 'Criminal Background required!';
    return '';
  }, [
    signerName,
    signerEmail,
    signerPhone,
    employmentStatus,
    employmentStatusDate,
    ssn,
    gender,
    dob,
    ethnicity,
    races,
    veteranStatus,
    disability,
    educationLevel,
    orgReferral,
    citizenStatus,
    addressLine1,
    addressLine2,
    addressCity,
    addressState,
    addressState,
    addressPostalCode,
    criminalBkg
  ]);

  const disableSignatureBlock = useMemo(() => {
    if (dataType === 'invite' && signerSignature) {
      return true;
    }

    if (requiredFieldsText) {
      return true;
    }

    return false;
  }, [signerSignature, requiredFieldsText]);

  const handleAcceptAgreement = (newSignerSignature, newSignerSignatureDate) => {
    if (!newSignerSignature) {
      toast.error('Signature required!');

      return false;
    }

    const dataToSave = {
      signerEmail,
      signerInviteSentAt,
      signerIpAddress,
      signerName,
      signerFirstName,
      signerLastName,
      signerMiddleName,
      signerPhone,
      signerSignature: newSignerSignature,
      signerSignatureDate: newSignerSignatureDate,
      employmentStatus,
      employmentStatusDate,
      ssn: encodePayload(ssn),
      gender,
      dob,
      ethnicity,
      races,
      veteranStatus,
      disability,
      educationLevel,
      orgReferral,
      citizenStatus,
      addressLine1,
      addressLine2,
      addressCity,
      addressState,
      addressPostalCode,
      criminalBkg
    };

    onSave(dataToSave);
  };

  useEffect(() => {
    getGeoLocation().then(({ ip }) => {
      setSignerIpAddress(ip);
    });
  }, []);

  useEffect(() => {
    if (apprenticeAgreement?.ssn || userProfile?.ssn) {
      decodePayload(apprenticeAgreement?.ssn || userProfile?.ssn).then((responseDecodedSSN) => {
        setSSN(responseDecodedSSN);
        setShowObfuscatedSSN(true);
      });
    }
  }, [apprenticeAgreement?.ssn || userProfile?.ssn]);

  return (
    <>
      <Section
        num="A"
        title="ONLY TO BE COMPLETED BY THE APPRENTICE. PLEASE PRINT CLEARLY."
        className=''
      >
        {/** ROW 1 */}
        <div className='d-flex border-bottom'>
          <div className='p-2 td-4'>
            <div className='form-field-title mb-1'>
              <strong>1. NAME OF APPRENTICE</strong> (First, Middle, Last)
            </div>
            <p className='m-0'>
              {signerName}
            </p>
          </div>
          <div className='p-2 border-left td-25'>
            <div className='form-field-title mb-1'>
              <strong>2. SOCIAL SECURITY NUMBER</strong>
            </div>
            <div className='m-0'>
              <div className='position-relative d-flex align-items-center'>
                <p className='m-0'>
                  {showObfuscatedSSN ? obfuscatedSSN : ssn}
                </p>

                {showToggleSSNButton && (
                  <button
                    className='btn btn-sm btn-link hide-when-printing ml-1 py-1 px-2'
                    type="button"
                    tabIndex="-1"
                    onMouseDown={() => {
                      setShowObfuscatedSSN(false);
                    }}
                    onMouseUp={() => {
                      setShowObfuscatedSSN(true);
                    }}
                  >
                    <span className='d-flex align-items-center'>
                      {showObfuscatedSSN ? (
                        <>
                          <FaEye />
                          <span className='ml-1'>
                            Show
                          </span>
                        </>
                      ) : (
                        <>
                          <FaEyeSlash />
                          <span className='ml-1'>
                            Hide
                          </span>
                        </>
                      )}
                    </span>
                  </button>
                )}
              </div>
            </div>
          </div>
          <div className='p-2 border-left td-45'>
            <div className='form-field-title mb-1'>
              <strong>3. GENDER</strong>
            </div>
            <CheckBoxes
              value={gender}
              optionsEnum={USER_GENDER_ENUM}
              minWidth='90px'
            />
          </div>
        </div>

        {/** ROW 2 */}
        <div className='d-flex border-bottom'>
          <div className='p-2 td-25'>
            <div className='form-field-title mb-1'>
              <strong>4. DATE OF BIRTH</strong> (mm/dd/yyyy)
            </div>
            <p className='m-0'>
              {mmddyyyyDate(dob)}
            </p>
          </div>
          <div className='p-2 border-left td-4'>
            <div className='form-field-title mb-1'>
              <strong>5. EMAIL ADDRESS</strong>
            </div>
            <p className='m-0'>
              {signerEmail}
            </p>
          </div>
          <div className='p-2 border-left td-45'>
            <div className='form-field-title mb-1'>
              <strong>6. PHONE NUMBER</strong>
            </div>
            <p className='m-0'>
              {signerPhone}
            </p>
          </div>
        </div>

        {/** ROW 3 */}
        <div className='d-flex border-bottom'>
          <div className='p-2 td-4'>
            <div className='form-field-title mb-1'>
              <strong>7. ADDRESS OF APPRENTICE</strong>
            </div>
            <AddressReadable
              addressLine1={addressLine1}
              addressLine2={addressLine2}
              addressCity={addressCity}
              addressState={addressState}
              addressPostalCode={addressPostalCode}
            />
          </div>
          <div className='p-2 border-left td-25'>
            <div className='form-field-title mb-1'>
              <strong>8. ETHNICITY</strong>
            </div>
            <CheckBoxes
              value={ethnicity}
              optionsEnum={USER_ETHNICITY_ENUM}
            />
          </div>
          <div className='p-2 border-left td-45'>
            <div className='form-field-title mb-1'>
              <strong>9. RACE</strong> (multiple selections allowed)
            </div>
            <CheckBoxes
              values={races}
              optionsEnum={USER_RACE}
            />
          </div>
        </div>

        {/** ROW 4 */}
        <div className='d-flex border-bottom'>
          <div className='p-2 td-2'>
            <div className='form-field-title mb-1'>
              <strong>10. VETERAN STATUS</strong>
            </div>
            <CheckBoxes
              value={veteranStatus}
              vertical
              optionsEnum={USER_VETERAN_ENUM}
            />
          </div>
          <div className='p-2 border-left td-25'>
            <div className='form-field-title mb-1'>
              <strong>11. DISABILITY</strong>  - <i>See form on page 3</i>
            </div>
            <CheckBoxes
              value={disability}
              vertical
              optionsEnum={USER_DISABILITY_ENUM}
            />
          </div>
          <div className='educationLevel-container p-2 border-left td-6'>
            <div className='form-field-title mb-1'>
              <strong>12. EDUCATION LEVEL</strong>
            </div>
            <CheckBoxes
              values={educationLevel}
              optionsEnum={USER_EDUCATION_ENUM}
              minWidth='150px'
            />
          </div>
        </div>

        {/** ROW 5 */}

        <div className='orgReferral-container border-bottom p-2'>
          <div className='form-field-title mb-1'>
            <strong>13. ORGANIZATION THAT REFERRED YOU TO THIS REGISTERED APPRENTICESHIP PROGRAM</strong>
          </div>
          <CheckBoxes
            value={orgReferral}
            optionsEnum={USER_REFERRAL_ENUM}
            className='mr-1'
          />
        </div>

        {/** ROW 6 */}
        <div className='d-flex border-bottom'>
          <div className='td-55'>
            <div className='p-2 border-bottom h-50'>

              <div className='d-flex'>
                <div className='form-field-title mb-1'>
                  <strong>14. EMPLOYMENT STATUS</strong>
                </div>
                {employmentStatus && (
                  <p className='mb-0 ml-3'>
                    {USER_EMPLOYMENT_ENUM[employmentStatus]}
                  </p>
                )}
              </div>

              <div className='d-flex align-items-center mt-2'>
                <div
                  style={{
                    marginLeft: '150px'
                  }}
                >
                  <small>If unemployed, enter the date of last employment</small>
                </div>
                {employmentStatus && employmentStatus === 'ue' && (
                  <div className='ml-3'>
                    {employmentStatusDate ? mmddyyyyDate(employmentStatusDate) : ''}
                  </div>
                )}
              </div>
            </div>
            <div
              className='p-2 h-50'
              style={{
                minHeight: '55px'
              }}
            >
              <div className='form-field-title mb-1'>
                <strong>15. CRIMINAL BACKGROUND</strong>
              </div>
              <p className='m-0'>
                {criminalBkg && USER_CRIMINAL_BKG_ENUM[criminalBkg] ? USER_CRIMINAL_BKG_ENUM[criminalBkg] : null}
              </p>
            </div>
          </div>
          <div className='p-2 border-left td-45'>
            <div className='form-field-title mb-1'>
              <strong>16. CITIZEN STATUS</strong>
            </div>
            <CheckBoxes
              value={citizenStatus}
              optionsEnum={USER_CITIZEN_ENUM}
              minWidth='130px'
            />
          </div>
        </div>

        {/** ROW 7 */}
        <div
          id="apprentice-signature-container"
          className='d-flex border-bottom'
          style={{
            minHeight: '95px'
          }}
        >
          <div className='p-2 td-5'>
            <div className='mb-1'>
              <div className='d-flex justify-content-between'>

                <div className='form-field-title font-weight-bold'>
                  17. SIGNATURE OF APPRENTICE
                </div>
                <div className='text-left form-field-title mr-5'>
                  <strong>DATE</strong>
                </div>
              </div>
            </div>

            {signerSignature && (
              <div className='d-flex justify-content-between mt-2'>
                <img
                  src={signerSignature}
                  height={50}
                  style={{ height: '50px' }}
                  alt={`${signerName} Signature (Apprentice)`}
                />

                {signerSignatureDate && (
                  <p className='m-0'>
                    {mmddyyyyDate(signerSignatureDate)}
                  </p>
                )}
              </div>
            )}

            {!signerSignature && dataType === 'invite' && (
              <UserSignatureBlock
                text=""
                className='mt-4'
                showSignatureFlag
                signerSignature={signerSignature}
                primaryButtonText="Sign & Accept Agreement"
                disabled={disableSignatureBlock}
                onChange={(newSignerSignature) => {
                  const newDate = new Date().toISOString();

                  setSignerSignatureDate(newDate);
                  setSignerSignature(newSignerSignature);
                  handleAcceptAgreement(newSignerSignature, newDate);
                }}
              />
            )}
          </div>

          <div className='p-2 td-5 border-left'>
            <div className='mb-1'>
              <div className='form-field-title d-flex justify-content-between'>
                <div>
                  <strong>18. SIGNATURE OF PARENT/GUARDIAN</strong> (IF UNDER 18)
                </div>
                <div className='text-left form-field-title mr-5'>
                  <strong>DATE</strong>
                </div>
              </div>
            </div>
            <p className='m-0' />
          </div>
        </div>
      </Section>
      {requiredFieldsText && (
        <div className='bg-danger-light p-5'>
          <p>
            The following information is required before you can sign the agreement:
          </p>
          <p className='mb-0 font-weight-bold d-flex align-items-center'>
            <FaExclamationTriangle size={20} className='text-danger ml-1 mr-2' />
            <span>
              {requiredFieldsText}
            </span>
          </p>
        </div>
      )}
    </>
  );
};

export default SectionApprentice;
