import React, { useState } from 'react';
import { TURBINE_GOOGLE_MAPS_KEY } from '../../constants/globals';
import { isLocalhost } from '../../serviceWorker';

const Location = ({
  occupationLocation,
  location,
  handleSearchOccupation,
  viewOnly
}) => {
  const [hasLocation, setHasLocation] = useState(!!occupationLocation);

  return (
    <div className="card h-100">
      <div className="card-body p-0">
        {occupationLocation && location && !isLocalhost ? (
          <img
            src={`https://maps.googleapis.com/maps/api/staticmap?center=${occupationLocation},${location}&zoom=8&size=700x700&maptype=roadmap&key=${TURBINE_GOOGLE_MAPS_KEY}`}
            className="w-100"
            alt={occupationLocation}
          />
        ) : (
          <img
            src="https://s3.us-west-2.amazonaws.com/turbineworkforce.com/pixelated-map-light.jpg"
            className="w-100"
            alt="Map Loading..."
          />
        )}
      </div>
      <div className="card-footer px-2">
        {hasLocation ? (
          <div className="d-flex align-items-center justify-content-between">
            <div>
              <span>
                {occupationLocation}
              </span>
              {location && (
                <span className="h6 my-0 ml-1">
                  <span className="badge badge-secondary">
                    {location}
                  </span>
                </span>
              )}
            </div>
            {!viewOnly && (
              <button
                type="button"
                onClick={() => {
                  setHasLocation(false);
                }}
                className="btn-link ml-2"
              >
                Change
              </button>
            )}
          </div>
        ) : (
          <form
            onSubmit={(e) => {
              setHasLocation(true);
              handleSearchOccupation(e);
            }}
          >
            <div className="input-group input-group-sm">
              <input
                type="text"
                className="form-control mr-2"
                name="inputOccupationLocation"
                defaultValue={occupationLocation}
                placeholder="Enter zipcode"
              />
              <button type="submit" className="btn btn-primary btn-sm">
                Search
              </button>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default Location;
