import {
  fetchSupabaseEntry
} from './supabaseProxy';

const table = 'integrations';

export const fetchIntegration = async (id) => {
  const response = await fetchSupabaseEntry({
    table,
    id
  });

  return response;
};
